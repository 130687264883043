import React, { useState, useEffect, useContext } from 'react';
import {
  Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Spinner, Text,
  Avatar, Badge, HStack, useToast, Flex, Icon, Button, Menu, MenuButton, MenuList, MenuItem
} from '@chakra-ui/react';
import { FaPhone, FaEnvelope, FaSms, FaEye, FaChevronDown } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TwilioContext } from '../../context/TwilioContext';
import CallActions from '../CallActions';
import { API_BASE_URL } from '../../config';
import io from 'socket.io-client';

/*
Future Improvements:
1. Add direction tracking throughout call lifecycle
2. Implement direction change audit logging
3. Add validation middleware for call direction
4. Create separate routes for inbound/outbound calls
5. Add direction verification in status callbacks
*/

const ActiveLeadsSection = () => {
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const { device, isDeviceReady, activeCall, setActiveCall } = useContext(TwilioContext);
  const [selectedLead, setSelectedLead] = useState(null);
  const [callType, setCallType] = useState('direct');

  // Function to fetch leads
  const fetchLeads = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Add error logging
      console.log('Fetching leads from:', `${API_BASE_URL}/api/leads`);
      
      const response = await axios.get(`${API_BASE_URL}/api/leads`, {
        params: {
          limit: 10,
          sort: '-addedDate'
        },
        // Add timeout
        timeout: 5000,
        // Add headers if needed
        headers: {
          'Content-Type': 'application/json',
        }
      });

      console.log('API Response:', response.data); // Debug log

      if (response.data && Array.isArray(response.data)) {
        setLeads(response.data);
      } else if (response.data && Array.isArray(response.data.leads)) {
        setLeads(response.data.leads);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      
      const errorMessage = error.response?.data?.message || error.message;
      setError(`Failed to fetch leads: ${errorMessage}`);
      
      toast({
        title: 'Error',
        description: `Failed to fetch leads: ${errorMessage}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, []);

  // Helper function to get status color
  const getStatusColor = (status) => {
    const statusMap = {
      active: 'green',
      pending: 'yellow',
      inactive: 'red',
      cold: 'blue',
      hot: 'orange'
    };
    return statusMap[status.toLowerCase()] || 'gray';
  };

  // Updated formatDate function
  const formatDate = (date) => {
    if (!date) return 'Never contacted';
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Function to handle starting a call
  const handleStartCall = async (leadId, phoneNumber, callType) => {
    if (!isDeviceReady || !phoneNumber?.trim()) {
      toast({
        title: 'Cannot initiate call',
        description: !isDeviceReady ? 'Device not ready' : 'Invalid phone number',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/twilio/initiate-call`, {
        to: phoneNumber,
        leadId: leadId,
        callType: callType,
      });

      console.log('Call initiated:', response.data);
      setActiveCall(response.data.call);
      setSelectedLead(leadId);

      toast({
        title: 'Outbound Call Initiated',
        description: `Calling ${leads.find(l => l._id === leadId)?.name || 'Unknown'} at ${phoneNumber}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error starting call:', error);
      toast({
        title: 'Call Failed',
        description: `Unable to start the ${callType} call: ${error.response?.data?.error || error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to handle ending a call
  const handleEndCall = () => {
    if (activeCall) {
      activeCall.disconnect();
      setActiveCall(null);
      setSelectedLead(null);
    }
  };

  // Function to handle sending SMS
  const handleSendSms = async (leadId, phoneNumber) => {
    if (!phoneNumber?.trim()) {
      toast({
        title: 'SMS feature',
        description: 'Invalid phone number',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/sms/send`, {
        to: phoneNumber,
        message: "Your custom message here", // Customize your message
        leadId: leadId,
      });

      toast({
        title: 'SMS Sent',
        description: `SMS successfully sent to ${phoneNumber}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error sending SMS:', error);
      toast({
        title: 'SMS Failed',
        description: `Failed to send SMS: ${error.response?.data?.error || error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Add a useEffect to listen for socket events
  useEffect(() => {
    // Listen for new lead events
    const socket = io(API_BASE_URL);
    
    socket.on('newLead', (newLead) => {
      setLeads(prevLeads => {
        const updatedLeads = [newLead, ...prevLeads].slice(0, 10);
        return updatedLeads;
      });
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  // Add this helper function for MAW score colors
  const getMawScoreColor = (lead) => {
    const score = (lead.motivation ? 1 : 0) + 
                  (lead.ability ? 1 : 0) + 
                  (lead.willingness ? 1 : 0);
    
    switch (score) {
      case 3:
        return "green.500";  // Green for 3/3
      case 2:
        return "yellow.500"; // Yellow for 2/3
      case 1:
        return "orange.500"; // Orange for 1/3
      default:
        return "gray.500";   // Gray for 0/3
    }
  };

  return (
    <Box mb={10} bg="surfaceBg" p={5} borderRadius="md" className="gold-border-glow">
      <Flex justify="space-between" align="center" mb={5}>
        <Heading fontSize="2xl">Active Leads (Latest 10)</Heading>
        <Button
          size="sm"
          onClick={fetchLeads}
          isLoading={isLoading}
        >
          Refresh
        </Button>
      </Flex>
      {isLoading ? (
        <Flex justify="center" align="center" h="200px">
          <Spinner size="xl" color="yellow.500" />
        </Flex>
      ) : error ? (
        <Text color="red.500">{error}</Text>
      ) : leads.length > 0 ? (
        <Table variant="simple" colorScheme="whiteAlpha">
          <Thead>
            <Tr>
              <Th>Lead</Th>
              <Th>Status</Th>
              <Th>Last Activity</Th>
              <Th>MAW Score</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {leads.map((lead) => (
              <Tr key={lead._id}>
                <Td>
                  <HStack>
                    <Avatar name={lead.name} src={lead.avatar} size="sm" />
                    <Box>
                      <Text fontWeight="bold">{lead.name}</Text>
                      <Text fontSize="sm" color="gray.400">{lead.email}</Text>
                    </Box>
                  </HStack>
                </Td>
                <Td>
                  <Badge colorScheme={getStatusColor(lead.status)}>
                    {lead.status}
                  </Badge>
                </Td>
                <Td>{formatDate(lead.lastActivity)}</Td>
                <Td>
                  <Badge 
                    colorScheme={getMawScoreColor(lead)}
                    fontSize="sm"
                    borderRadius="full"
                    px={2}
                  >
                    {`${(lead.motivation ? 1 : 0) + 
                         (lead.ability ? 1 : 0) + 
                         (lead.willingness ? 1 : 0)}/3`}
                  </Badge>
                </Td>
                <Td>
                  <HStack spacing={2}>
                    <Menu>
                      <MenuButton 
                        as={Button} 
                        rightIcon={<FaChevronDown />} 
                        size="sm"
                        isDisabled={!lead.phone || lead.phone.trim() === ''}
                      >
                        Call
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={() => handleStartCall(lead._id, lead.phone, 'direct')}>
                          Direct Call
                        </MenuItem>
                        <MenuItem onClick={() => handleStartCall(lead._id, lead.phone, 'personal')}>
                          Personal Phone Routing
                        </MenuItem>
                      </MenuList>
                    </Menu>
                    <Button
                      size="sm"
                      leftIcon={<Icon as={FaSms} />}
                      onClick={() => handleSendSms(lead._id, lead.phone)}
                    >
                      SMS
                    </Button>
                    <Button
                      size="sm"
                      leftIcon={<Icon as={FaEye} />}
                      onClick={() => navigate(`/leads/${lead._id}`)}
                    >
                      View
                    </Button>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Text>No active leads available.</Text>
      )}
    </Box>
  );
};

export default ActiveLeadsSection;
