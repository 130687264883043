import React from 'react';
import { VStack, Box, Text, HStack, Button } from '@chakra-ui/react';

const ObjectionHandling = ({ handleObjectionResponse }) => {
  return (
    <VStack align="stretch" spacing={4}>
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Inverse Question</Text>
        <Text color="white" mt={2}>
          Would you be comfortable selling your home knowing that you may not be maximizing the equity
          you are going to get out of it for your next home?
        </Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Lead Agreement</Text>
        <Text color="white" mt={2}>
          So would you agree that having a well thought out strategy would help you get the most for your
          current home?
        </Text>
        <Text color="white" mt={2}>
          And would you say that the sooner you could start actioning on the plan the more comfortable
          you would be implementing it?
        </Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Reinforcement</Text>
        <Text color="white" mt={2}>
          I totally agree, I believe it's never too early to start actioning a good strategy. Do you think it would
          be beneficial to create that strategy with someone who had executed very similar plans before for
          other clients just like you?
        </Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">2 Step Close</Text>
        <Text color="white" mt={2}>
          I totally agree! So do you think a good next step would be to get you booked in for a strategy
          session so that we can get the process started right away to get you the most for your current
          home and get you into that next home with 4 bedrooms and a yard so the twins have their own
          rooms and a place to play outside?
        </Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Response Options</Text>
        <HStack spacing={2} mt={2}>
          <Button colorScheme="green" onClick={() => handleObjectionResponse('agree')}>Agree</Button>
          <Button colorScheme="red" onClick={() => handleObjectionResponse('disagree')}>Disagree</Button>
          <Button colorScheme="yellow" onClick={() => handleObjectionResponse('unsure')}>Unsure</Button>
        </HStack>
      </Box>
    </VStack>
  );
};

export default ObjectionHandling;
