import React from 'react';
import { Box, VStack, Text, Button, Heading } from '@chakra-ui/react';

const AlertsBox = ({ alerts, leadId }) => {
  return (
    <Box>
      <Heading size="md" mb={4}>Alerts</Heading>
      <VStack spacing={4} align="stretch">
        {alerts && alerts.length > 0 ? (
          alerts.map((alert, index) => (
            <Box key={index} p={3} bg="gray.700" borderRadius="md">
              <Text>{alert.message}</Text>
              <Text fontSize="sm" color="gray.400">{new Date(alert.date).toLocaleString()}</Text>
            </Box>
          ))
        ) : (
          <Text>No alerts at this time.</Text>
        )}
      </VStack>
      <Button mt={4} colorScheme="orange" size="sm">
        Add Alert
      </Button>
    </Box>
  );
};

export default AlertsBox;