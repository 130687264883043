// darkTheme.js - Dark theme configuration for the application
import { extendTheme } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

// Define the glowing animation keyframes
const glow = keyframes`
  0% { box-shadow: 0 0 5px var(--primary-accent); }
  50% { box-shadow: 0 0 20px var(--primary-accent), 0 0 30px var(--primary-accent); }
  100% { box-shadow: 0 0 5px var(--primary-accent); }
`;

const darkTheme = extendTheme({
  colors: {
    primaryBg: '#000000',          // --primary-bg
    surfaceBg: '#111111',          // --surface-bg
    primaryAccent: '#ff8c00',      // --primary-accent (Dark Orange)
    secondaryAccent: '#ffd700',    // --secondary-accent (Gold)
    textColor: '#ffffff',          // --text-color
    inactiveColor: '#333333',      // --inactive-color
    gray: {
      900: '#000000',
      800: '#111111',
      700: '#333333',
      // ... extend as needed
    },
    yellow: {
      400: '#ffd700',
      500: '#ff8c00',
    },
  },
  styles: {
    global: {
      ':root': {
        '--primary-bg': '#000000',
        '--surface-bg': '#111111',
        '--primary-accent': '#ff8c00',
        '--secondary-accent': '#ffd700',
        '--text-color': '#ffffff',
        '--inactive-color': '#333333',
      },
      body: {
        bg: 'primaryBg',
        color: 'textColor',
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        border: 'none',
      },
      // Scrollbar Styling (WebKit Browsers)
      '::-webkit-scrollbar': {
        width: '4px',
      },
      '::-webkit-scrollbar-thumb': {
        bg: 'primaryAccent',
        borderRadius: '4px',
      },
      /* Gradient Text */
      '.gradient-text': {
        background: 'linear-gradient(to right, var(--primary-accent), var(--secondary-accent))',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        display: 'inline-block',
      },
      /* Decorative Gold Line */
      '.gold-line': {
        height: '2px',
        background: 'linear-gradient(to right, transparent, var(--primary-accent), transparent)',
        margin: '1rem 0',
      },
      /* Gold Border Glow Effect */
      '.gold-border-glow': {
        border: '1px solid var(--primary-accent)',
        boxShadow: '0 0 10px var(--primary-accent)',
        transition: 'box-shadow 0.3s ease',
      },
      '.gold-border-glow:hover': {
        boxShadow: '0 0 20px var(--primary-accent)',
      },
      /* Glowing Animation */
      '.glow': {
        animation: `${glow} 2s infinite`,
      },
      '@keyframes glow': {
        '0%': { boxShadow: '0 0 5px var(--primary-accent)' },
        '50%': {
          boxShadow: '0 0 20px var(--primary-accent), 0 0 30px var(--primary-accent)',
        },
        '100%': { boxShadow: '0 0 5px var(--primary-accent)' },
      },
      /* Respect user preference for reduced motion */
      '@media (prefers-reduced-motion: reduce)': {
        '.glow': {
          animation: 'none',
        },
      },
    },
    '*': {
      borderColor: 'inherit', // Change from 'transparent' to 'inherit'
    },
    '*:focus': {
      boxShadow: 'none',
      outline: 'none',
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: '0.05em',
        _focus: {
          boxShadow: 'none',
        },
      },
      variants: {
        solid: {
          bg: 'yellow.500',
          color: 'black',
          _hover: {
            bg: 'yellow.400',
          },
          _active: {
            bg: 'yellow.600',
          },
          _focus: {
            boxShadow: 'none',
          },
        },
        outline: {
          borderColor: 'yellow.500',
          color: 'yellow.500',
          _hover: {
            bg: 'yellow.900',
          },
          _focus: {
            boxShadow: 'none',
          },
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          bg: 'black',
          borderColor: 'primaryAccent',
        },
        item: {
          bg: 'black',
          color: 'textColor',
          _hover: {
            bg: 'gray.900', // Slightly lighter on hover for better UX
          },
          _focus: {
            bg: 'gray.900',
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        color: 'primaryAccent',
        textShadow: '0 0 10px var(--primary-accent)',
      },
      sizes: {
        xl: {
          fontSize: '1.5rem',
          fontWeight: 'bold',
        },
        '4xl': {
          fontSize: '2.5rem',
          fontWeight: 'bold',
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          borderColor: 'primaryAccent',
          color: 'textColor',
          bg: 'black', // Black background
          _hover: {
            borderColor: 'secondaryAccent',
          },
          _focus: {
            borderColor: 'secondaryAccent',
            boxShadow: '0 0 0 1px var(--secondary-accent)',
          },
        },
      },
      variants: {
        filled: {
          field: {
            bg: 'black', // Black background
            _hover: {
              bg: 'black', // Stays black on hover
            },
            _focus: {
              bg: 'black', // Stays black on focus
              boxShadow: 'none',
            },
          },
        },
      },
    },
    Select: {
      baseStyle: {
        field: {
          borderColor: 'primaryAccent',
          color: 'textColor',
          bg: 'black',
          _hover: {
            borderColor: 'secondaryAccent',
          },
          _focus: {
            borderColor: 'secondaryAccent',
            boxShadow: 'none',
          },
        },
        icon: {
          color: 'primaryAccent', // This ensures the dropdown arrow is visible
        },
      },
      variants: {
        filled: {
          field: {
            bg: 'black',
            _hover: {
              bg: 'black',
            },
            _focus: {
              bg: 'black',
              boxShadow: 'none',
            },
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderColor: 'primaryAccent',
          _checked: {
            bg: 'primaryAccent',
            borderColor: 'primaryAccent',
          },
          _focus: {
            boxShadow: 'none',
          },
        },
      },
    },
    Box: {
      variants: {
        'dashboard-section': {
          bg: 'surfaceBg',
          p: 5,
          borderRadius: '0',
          position: 'relative',
          border: '1px solid',
          borderColor: 'primaryAccent',
          _before: {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            boxShadow: '0 0 10px var(--primary-accent)',
            opacity: 0.5,
            transition: 'opacity 0.3s ease',
          },
          _hover: {
            _before: {
              opacity: 0.7,
            },
          },
        },
      },
    },
  },
  fonts: {
    heading: 'Arial, sans-serif',
    body: 'Arial, sans-serif',
  },
});

export default darkTheme;
