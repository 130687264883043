import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Heading, 
  VStack, 
  Text, 
  Select, 
  useToast,
  Textarea,
  Button,
  FormControl,
  FormLabel,
  Spinner,
  SimpleGrid,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, 
  List,
  ListItem,
} from '@chakra-ui/react';
import { FaExclamationTriangle, FaEdit, FaTrash, FaUpload, FaDownload, FaUndo } from 'react-icons/fa';
import axios from 'axios';
import ErrorBoundary from '../components/ErrorBoundary';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Settings() {
  // State declarations using promptTemplates
  const [transcriptionService, setTranscriptionService] = useState('spectropic');
  const [spectropicModel, setSpectropicModel] = useState('standard');
  const [analysisModel, setAnalysisModel] = useState('gpt-4o');
  const [customPrompt, setCustomPrompt] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPromptTemplate, setSelectedPromptTemplate] = useState('concise');
  const [templates, setTemplates] = useState({});
  const [newTemplateName, setNewTemplateName] = useState('');
  const [promptHistory, setPromptHistory] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);

  const [loadingStates, setLoadingStates] = useState({
    transcriptionService: false,
    spectropicModel: false,
    analysisModel: false,
    customPrompt: false
  });

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [templateToEdit, setTemplateToEdit] = useState(null);
  const [importExportData, setImportExportData] = useState('');

  const [supportedModels, setSupportedModels] = useState(null);
  const [pricing, setPricing] = useState(null);

  const fetchCurrentSettings = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await axios.get('/api/settings');
      
      if (response.data) {
        setAnalysisModel(response.data.analysisModel);
        setTemplates(response.data.analysisPromptTemplates);
        setSelectedPromptTemplate(response.data.selectedPromptTemplate);
        setCustomPrompt(response.data.customAnalysisPrompt);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
      setError('Failed to load settings');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    
    const getSettings = async () => {
      if (isMounted) {
        await fetchCurrentSettings();
      }
    };

    getSettings();

    return () => {
      isMounted = false;
    };
  }, [fetchCurrentSettings]);

  useEffect(() => {
    const fetchModelsAndPricing = async () => {
      try {
        const response = await axios.get('/api/settings/models');
        setSupportedModels(response.data.supportedModels);
        setPricing(response.data.pricing);
      } catch (error) {
        console.error('Error fetching models:', error);
        toast({
          title: 'Error',
          description: 'Failed to load model information',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchModelsAndPricing();
  }, []);

  const handleServiceChange = async (e) => {
    const newService = e.target.value;
    const previousService = transcriptionService;
    
    try {
      setLoading('transcriptionService', true);
      setTranscriptionService(newService);
      
      const response = await axios.post('/api/settings/update', { 
        transcriptionService: newService 
      });

      if (response.data.success) {
        if (newService === 'spectropic') {
          setSpectropicModel('standard');
          await axios.post('/api/settings/update', { spectropicModel: 'standard' });
        }
        
        toast({
          title: 'Settings updated',
          description: `Transcription service changed to ${newService}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating settings:', error);
      setTranscriptionService(previousService);
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to update settings',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading('transcriptionService', false);
    }
  };

  const handleModelChange = async (e) => {
    const newModel = e.target.value;
    const previousModel = spectropicModel;
    
    try {
      setLoading('spectropicModel', true);
      setSpectropicModel(newModel);
      
      await axios.post('/api/settings/update', { spectropicModel: newModel });
      toast({
        title: 'Settings updated',
        description: `Spectropic model changed to ${newModel}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating settings:', error);
      setSpectropicModel(previousModel);
      toast({
        title: 'Error',
        description: 'Failed to update settings',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading('spectropicModel', false);
    }
  };

  const handleAnalysisModelChange = async (e) => {
    const newModel = e.target.value;
    console.log('🔄 Changing analysis model:', { from: analysisModel, to: newModel });
    
    setAnalysisModel(newModel);
    try {
      console.log('📡 Sending model update request to server');
      const response = await axios.post('/api/settings/update', { analysisModel: newModel });
      console.log('✅ Model update successful:', response.data);
      
      toast({
        title: 'Settings updated',
        description: `Analysis model changed to ${newModel}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('❌ Error updating analysis model:', error);
      toast({
        title: 'Error',
        description: 'Failed to update settings',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handlePromptTemplateChange = (e) => {
    const template = e.target.value;
    setSelectedPromptTemplate(template);
    setCustomPrompt(templates[template]);
    setIsEditing(true);
  };

  const handlePromptSave = async () => {
    console.log('💾 Attempting to save custom prompt');
    
    if (validationError) {
      console.warn('⚠️ Validation error prevented save:', validationError);
      toast({
        title: 'Validation Error',
        description: 'Please resolve the validation errors before saving.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      console.log('📝 Saving prompt with length:', customPrompt.length);
      const newVersion = {
        content: customPrompt,
        timestamp: new Date(),
        user: 'CurrentUser',
      };
      
      await axios.post('/api/settings/update', { 
        customAnalysisPrompt: customPrompt,
        analysisPromptTemplates: {
          ...templates,
          [selectedPromptTemplate]: customPrompt
        },
        promptHistory: newVersion 
      });
      
      console.log('✅ Prompt saved successfully');
      setIsEditing(false);
      toast({
        title: 'Success',
        description: 'Analysis prompt updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('❌ Error saving prompt:', error);
      toast({
        title: 'Error',
        description: 'Failed to update prompt',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const setLoading = (key, value) => {
    setLoadingStates(prev => ({ ...prev, [key]: value }));
  };

  const handleAddTemplate = async () => {
    if (newTemplateName.trim() === '') {
      toast({
        title: 'Invalid Name',
        description: 'Template name cannot be empty.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (templates[newTemplateName]) {
      toast({
        title: 'Duplicate Template',
        description: 'A template with this name already exists.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setTemplates(prev => ({
        ...prev,
        [newTemplateName]: '',
      }));
      await axios.post('/api/templates/add', { templateName: newTemplateName, content: '' });
      setNewTemplateName('');
      toast({
        title: 'Template Added',
        description: `Template '${newTemplateName}' has been added.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding template:', error);
      toast({
        title: 'Error',
        description: 'Failed to add new template.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteTemplate = async (templateName) => {
    if (templateName === 'default') return;

    try {
      const updatedTemplates = { ...templates };
      delete updatedTemplates[templateName];
      setTemplates(updatedTemplates);
      await axios.post('/api/templates/delete', { templateName });
      
      if (selectedPromptTemplate === templateName) {
        setSelectedPromptTemplate('default');
        setCustomPrompt(templates.default || '');
      }
      
      toast({
        title: 'Template Deleted',
        description: `Template '${templateName}' has been deleted.`,
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting template:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete the template.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handlePreview = () => {
    setIsPreviewOpen(true);
    // Implement preview functionality as needed
  };

  const handleRevertVersion = async (version) => {
    try {
      setCustomPrompt(version.content);
      await axios.post('/api/settings/update', { customAnalysisPrompt: version.content });
      setSelectedVersion(null);
      toast({
        title: 'Reverted',
        description: 'Custom prompt has been reverted to a previous version.',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error reverting version:', error);
      toast({
        title: 'Error',
        description: 'Failed to revert to the selected version.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const validatePrompt = (prompt) => {
    const text = prompt.replace(/<[^>]+>/g, ''); // Remove HTML tags
    if (text.length < 50) {
      return 'Prompt is too short. Minimum 50 characters required.';
    }
    if (!text.includes('AI assistant')) {
      return 'Prompt should mention it\'s for an AI assistant.';
    }
    // Add more comprehensive validation rules as needed
    return '';
  };

  const handlePromptChange = (value) => {
    setCustomPrompt(value);
    const error = validatePrompt(value);
    setValidationError(error);
  };

  const handleImportTemplates = async () => {
    try {
      const importedTemplates = JSON.parse(importExportData);
      if (typeof importedTemplates !== 'object') throw new Error('Invalid format');
      setTemplates(prev => ({ ...prev, ...importedTemplates }));
      await axios.post('/api/templates/import', { templates: importedTemplates });
      setImportExportData('');
      toast({
        title: 'Import Successful',
        description: 'Prompt templates have been imported.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error importing templates:', error);
      toast({
        title: 'Import Failed',
        description: 'Invalid JSON format or import error.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleExportTemplates = () => {
    const dataStr = JSON.stringify(templates, null, 2);
    const blob = new Blob([dataStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'prompt_templates.json';
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleEditTemplate = (templateName) => {
    if (templateName === 'default') return;
    setTemplateToEdit(templateName);
    setIsEditModalOpen(true);
  };

  const handleResetToTemplate = async () => {
    try {
      await axios.post('/api/settings/update', { 
        customAnalysisPrompt: null  // Clear custom prompt
      });
      setCustomPrompt('');  // Clear the editor
      toast({
        title: 'Reset Successful',
        description: `Reverted to template: ${selectedPromptTemplate}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error resetting prompt:', error);
      toast({
        title: 'Error',
        description: 'Failed to reset prompt',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" bg="black">
        <Spinner size="xl" color="orange.400" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" py={10} px={6} bg="black" color="white">
        <Heading as="h2" size="xl" color="red.500" mb={4}>
          Error
        </Heading>
        <Text fontSize="lg">{error}</Text>
        <Button mt={4} colorScheme="orange" onClick={fetchCurrentSettings}>
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <ErrorBoundary>
      <Box
        p={{ base: 4, md: 6, lg: 8 }}
        pt={{ base: 6, md: 20 }}
        minH="100vh"
        w="100%"
        bg="black"
        color="white"
      >
        <Box maxW="1400px" mx="auto">
          <Heading
            mb={{ base: 6, md: 8 }}
            fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
            fontWeight="bold"
            fontFamily="'Space Grotesk', sans-serif"
            letterSpacing="-0.02em"
            textAlign="center"
            bgGradient="linear(45deg, orange.400, yellow.200)"
            bgClip="text"
          >
            System Settings
          </Heading>

          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
            {/* Transcription Settings Card */}
            <Box
              bg="gray.800"
              p={6}
              borderRadius="xl"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.4)"
              border="1px solid"
              borderColor="orange.400"
            >
              <Heading 
                size="md" 
                mb={6} 
                color="orange.400"
                fontFamily="'Space Grotesk', sans-serif"
                letterSpacing="-0.02em"
              >
                🎙️ Transcription Settings
              </Heading>
              
              <VStack spacing={6} align="stretch">
                <FormControl>
                  <FormLabel 
                    color="gray.300"
                    fontFamily="'Inter', sans-serif"
                    fontWeight="500"
                  >
                    Transcription Service
                  </FormLabel>
                  <Select 
                    value={transcriptionService} 
                    onChange={handleServiceChange}
                    bg="gray.700"
                    borderColor="gray.600"
                    color="white"
                    fontFamily="'Poppins', sans-serif"
                    fontWeight="400"
                    _hover={{ borderColor: "orange.400" }}
                  >
                    {supportedModels?.transcription && 
                      Object.keys(supportedModels.transcription).map(service => (
                        <option key={service} value={service}>
                          {service.charAt(0).toUpperCase() + service.slice(1)}
                        </option>
                      ))
                    }
                  </Select>
                </FormControl>

                {transcriptionService === 'spectropic' && (
                  <FormControl>
                    <FormLabel color="gray.300" className="settings-label">Spectropic Model</FormLabel>
                    <Select 
                      value={spectropicModel} 
                      onChange={handleModelChange}
                      bg="gray.700"
                      borderColor="gray.600"
                      color="white"
                      _hover={{ borderColor: "orange.400" }}
                      className="settings-value"
                    >
                      {supportedModels?.transcription?.spectropic?.map(model => (
                        <option key={model} value={model}>
                          {model.charAt(0).toUpperCase() + model.slice(1)}
                        </option>
                      ))}
                    </Select>
                    {pricing?.spectropic && (
                      <Box mt={4} p={4} bg="gray.700" borderRadius="md" fontSize="sm">
                        <Text color="orange.300" fontWeight="bold" mb={2} fontFamily="'Inter', sans-serif">
                          Pricing Information
                        </Text>
                        {Object.entries(pricing.spectropic).map(([model, rates]) => (
                          <Text key={model} color="gray.300" fontFamily="'Inter', sans-serif">
                            • {model.charAt(0).toUpperCase() + model.slice(1)}: 
                            ${rates.perSecond}/second (${rates.perHour}/hour)
                          </Text>
                        ))}
                      </Box>
                    )}
                  </FormControl>
                )}
              </VStack>
            </Box>

            {/* Analysis Settings Card */}
            <Box
              bg="gray.800"
              p={6}
              borderRadius="xl"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.4)"
              border="1px solid"
              borderColor="orange.400"
            >
              <Heading size="md" mb={6} color="orange.400" className="settings-heading">
                🤖 Analysis Settings
              </Heading>
              
              <VStack spacing={6} align="stretch">
                <FormControl>
                  <FormLabel color="gray.300" className="settings-label">Analysis Model</FormLabel>
                  <Select
                    value={analysisModel}
                    onChange={handleAnalysisModelChange}
                    bg="gray.700"
                    borderColor="gray.600"
                    color="white"
                    _hover={{ borderColor: "orange.400" }}
                    className="settings-value"
                  >
                    {supportedModels?.analysis &&
                      Object.values(supportedModels.analysis)
                        .flat()
                        .map((model) => (
                          <option key={model} value={model}>
                            {model}
                          </option>
                        ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel color="gray.300" className="settings-label">Analysis Template</FormLabel>
                  <HStack>
                    <Select
                      value={selectedPromptTemplate}
                      onChange={handlePromptTemplateChange}
                      bg="gray.700"
                      borderColor="gray.600"
                      color="white"
                      _hover={{ borderColor: "orange.400" }}
                      className="settings-value"
                    >
                      {Object.keys(templates).map(template => (
                        <option key={template} value={template}>{template}</option>
                      ))}
                    </Select>
                    <IconButton
                      aria-label="Edit Template"
                      icon={<FaEdit />}
                      colorScheme="blue"
                      variant="outline"
                      onClick={() => handleEditTemplate(selectedPromptTemplate)}
                      isDisabled={selectedPromptTemplate === 'default'}
                    />
                    <IconButton
                      aria-label="Delete Template"
                      icon={<FaTrash />}
                      colorScheme="red"
                      variant="outline"
                      onClick={() => handleDeleteTemplate(selectedPromptTemplate)}
                      isDisabled={selectedPromptTemplate === 'default'}
                    />
                  </HStack>
                  <HStack mt={4}>
                    <Input
                      placeholder="New Template Name"
                      value={newTemplateName}
                      onChange={(e) => setNewTemplateName(e.target.value)}
                      bg="gray.700"
                      borderColor="gray.600"
                      color="white"
                    />
                    <Button
                      colorScheme="blue"
                      onClick={handleAddTemplate}
                      isDisabled={newTemplateName.trim() === ''}
                    >
                      Add Template
                    </Button>
                  </HStack>
                  {/* Import/Export Functionality */}
                  <HStack spacing={4} mt={4}>
                    <Button leftIcon={<FaUpload />} onClick={handleImportTemplates} colorScheme="blue">Import Templates</Button>
                    <Button leftIcon={<FaDownload />} onClick={handleExportTemplates} colorScheme="green">Export Templates</Button>
                  </HStack>
                  <Textarea
                    placeholder="Paste JSON here to import templates..."
                    value={importExportData}
                    onChange={(e) => setImportExportData(e.target.value)}
                    bg="gray.700"
                    borderColor="gray.600"
                    color="white"
                    mt={2}
                  />
                </FormControl>
              </VStack>
            </Box>

            {/* Custom Prompt Card - Full Width */}
            <Box
              bg="gray.800"
              p={6}
              borderRadius="xl"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.4)"
              border="1px solid"
              borderColor="orange.400"
              gridColumn={{ lg: "span 2" }}
            >
              <Heading size="md" mb={6} color="orange.400" className="settings-heading">
                ✨ Custom Analysis Prompt
              </Heading>
              
              <VStack spacing={4} align="stretch">
                <Box mb={2}>
                  <Text color="orange.300" fontSize="sm">
                    Current Active Prompt: {
                      customPrompt ? 
                      "Custom Prompt" : 
                      `Template: ${selectedPromptTemplate}`
                    }
                  </Text>
                </Box>
                <ReactQuill
                  value={customPrompt}
                  onChange={handlePromptChange}
                  placeholder="Enter custom analysis prompt..."
                  readOnly={!isEditing}
                  theme={isEditing ? "snow" : "bubble"}
                />
                
                {validationError && (
                  <Text color="red.500" fontSize="sm">
                    {validationError}
                  </Text>
                )}

                <HStack>
                  {!isEditing ? (
                    <Button 
                      colorScheme="orange" 
                      onClick={() => setIsEditing(true)}
                      size="lg"
                      fontFamily="'Inter', sans-serif"
                      fontWeight="600"
                    >
                      Edit Prompt
                    </Button>
                  ) : (
                    <HStack spacing={4}>
                      <Button 
                        colorScheme="green" 
                        onClick={handlePromptSave}
                        size="lg"
                        isDisabled={validationError !== ''}
                      >
                        Save Changes
                      </Button>
                      <Button 
                        variant="outline" 
                        onClick={() => {
                          setIsEditing(false);
                          fetchCurrentSettings();
                        }}
                        size="lg"
                        color="white"
                        _hover={{ bg: "gray.700" }}
                      >
                        Cancel
                      </Button>
                    </HStack>
                  )}
                  <Button 
                    colorScheme="teal" 
                    onClick={handlePreview}
                    size="lg"
                    isDisabled={!customPrompt}
                  >
                    Preview
                  </Button>
                  <Button 
                    colorScheme="purple" 
                    onClick={handleResetToTemplate}
                    size="lg"
                    isDisabled={!customPrompt}
                  >
                    Reset to Template
                  </Button>
                </HStack>

                {/* Version History */}
                {promptHistory.length > 0 && (
                  <Box>
                    <Heading size="sm" mb={2} color="orange.400">Prompt History</Heading>
                    <List spacing={2}>
                      {promptHistory.map((version, index) => (
                        <ListItem key={index}>
                          <HStack>
                            <Text>Version {index + 1} - {new Date(version.timestamp).toLocaleString()}</Text>
                            <Button size="xs" onClick={() => handleRevertVersion(version)}>
                              <FaUndo />
                            </Button>
                          </HStack>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}

                {/* Edit Template Modal */}
                <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader
                      fontFamily="'Space Grotesk', sans-serif"
                      letterSpacing="-0.02em"
                    >
                      Edit Template: {templateToEdit}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <ReactQuill
                        value={templates[templateToEdit]}
                        onChange={(value) => setTemplates(prev => ({ ...prev, [templateToEdit]: value }))}
                        placeholder="Edit your prompt..."
                        theme="snow"
                      />
                      {validationError && <Text color="red.500" fontSize="sm">{validationError}</Text>}
                    </ModalBody>
                    <ModalFooter>
                      <Button 
                        colorScheme="green" 
                        mr={3} 
                        onClick={async () => {
                          try {
                            await axios.post('/api/templates/update', { templateName: templateToEdit, content: templates[templateToEdit] });
                            setIsEditModalOpen(false);
                            toast({
                              title: 'Template Updated',
                              description: `Template '${templateToEdit}' has been updated.`,
                              status: 'success',
                              duration: 3000,
                              isClosable: true,
                            });
                          } catch (error) {
                            console.error('Error updating template:', error);
                            toast({
                              title: 'Error',
                              description: 'Failed to update the template.',
                              status: 'error',
                              duration: 3000,
                              isClosable: true,
                            });
                          }
                        }}
                        isDisabled={validationError !== ''}
                      >
                        Save
                      </Button>
                      <Button variant="ghost" onClick={() => setIsEditModalOpen(false)}>Cancel</Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </VStack>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
    </ErrorBoundary>
  );
}

export default function SettingsWithErrorBoundary() {
  return (
    <ErrorBoundary>
      <Settings />
    </ErrorBoundary>
  );
}


/*
Improvements:
- Implement edit functionality for existing templates.
- Enhance versioning with timestamps and user details.
- Allow importing/exporting of prompt templates.
- Provide more comprehensive validation rules.
- Integrate with backend for persistent storage of templates and versions.

Future Improvements:
- Add user authentication to track which user makes changes.
- Implement search and filter for prompt templates.
- Provide a rich text editor with more customization options.
- Enable role-based access control for managing templates.
- Optimize performance for handling a large number of templates.
*/
