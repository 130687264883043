import React from 'react';
import { 
  Box, 
  Heading, 
  Accordion, 
  AccordionItem, 
  AccordionButton, 
  AccordionPanel, 
  AccordionIcon, 
  Text, 
  Container,
  Icon,
  Flex,
  Button,
  Divider,
  useColorModeValue,
  VStack
} from '@chakra-ui/react';
import { FaQuestionCircle, FaArrowRight, FaPhoneAlt, FaDollarSign, FaRocket, FaShieldAlt } from 'react-icons/fa';

const FAQSection = () => {
  const faqs = [
    {
      category: "Getting Started",
      icon: FaRocket,
      questions: [
        {
          question: "How quickly can I get started with Real Talk Pro?",
          answer: "You can be up and running in less than 5 minutes! Our AI system automatically configures itself based on your business profile and market area. Simply sign up, complete a brief onboarding questionnaire, and you're ready to start closing more deals.",
          highlight: "🚀 5-minute setup process"
        },
        {
          question: "Is there a free trial available?",
          answer: "Yes! We offer a risk-free 30-day trial with full access to all Pro features. No credit card required to start. Experience the full power of AI-driven real estate success with zero commitment.",
          highlight: "✨ 30-day free trial, no credit card needed"
        }
      ]
    },
    {
      category: "Technology & Integration",
      icon: FaPhoneAlt,
      questions: [
        {
          question: "How does Real Talk Pro integrate with my existing CRM?",
          answer: "Real Talk Pro seamlessly connects with all major real estate CRMs including Salesforce, Follow Up Boss, and more. Our smart API ensures your data syncs automatically in real-time, eliminating double entry and keeping your workflow smooth.",
          highlight: "🔄 Instant sync with major CRMs"
        },
        {
          question: "Is the AI actually effective during real calls?",
          answer: "Absolutely! Our ScriptSense™ AI has been trained on over 1 million successful real estate conversations. It provides real-time suggestions, objection handlers, and closing techniques that feel natural and drive results. On average, agents see a 27% increase in conversion rates within the first month.",
          highlight: "📈 27% average conversion increase"
        }
      ]
    },
    {
      category: "Pricing & Plans",
      icon: FaDollarSign,
      questions: [
        {
          question: "Can I upgrade or downgrade my plan later?",
          answer: "Yes! You have complete flexibility to adjust your plan at any time from your dashboard. When upgrading, you'll instantly get access to additional features. If downgrading, you'll maintain access to your current features until the end of your billing cycle.",
          highlight: "🔄 Flexible plan adjustments"
        },
        {
          question: "What's included in the beta pricing offer?",
          answer: "Beta users get lifetime access to our Pro plan at 50% off the regular price. This includes unlimited AI calls, advanced analytics, priority support, and all future features we release. This is a limited-time offer for early adopters only.",
          highlight: "🎯 50% lifetime discount for beta users"
        }
      ]
    },
    {
      category: "Security & Support",
      icon: FaShieldAlt,
      questions: [
        {
          question: "How secure is my data with Real Talk Pro?",
          answer: "We take security seriously. All data is encrypted using military-grade encryption (AES-256), and we're fully compliant with PIPEDA and GDPR. Your client information is safer with us than in most bank vaults.",
          highlight: "🔒 Military-grade security"
        },
        {
          question: "What kind of support do I get?",
          answer: "Pro users get priority 24/7 support via chat, email, and phone. Our average response time is under 3 minutes. Plus, you'll have access to our exclusive community of top-performing agents and weekly live training sessions.",
          highlight: "🎯 24/7 priority support"
        }
      ]
    }
  ];

  return (
    <Box
      p={{ base: 4, md: 6, lg: 8 }}
      pt={{ base: 6, md: 20 }}
      w="100%"
      bg="primaryBg"
      color="textColor"
      borderColor="yellow.500"
      borderWidth="1px"
      borderTopWidth="0"
      borderLeftWidth="0"
      borderRightWidth="0"
      borderBottomWidth="0"
      boxShadow="0 0 10px 2px rgba(255, 165, 0, 0.3)"
    >
      {/* Content wrapper with max width */}
      <Box maxW="1400px" mx="auto">
        {/* Header Section */}
        <Box textAlign="center" mb={{ base: 8, md: 12 }}>
          <Heading
            mb={{ base: 4, md: 6 }}
            fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
            fontWeight="bold"
            className="gradient-text"
          >
            Frequently Asked Questions
          </Heading>
          <Text
            fontSize={{ base: 'md', md: 'lg' }}
            color="gray.400"
            maxW="800px"
            mx="auto"
          >
            Everything you need to know about transforming your real estate business with AI
          </Text>
        </Box>

        {/* FAQ Categories */}
        <Flex direction="column" gap={{ base: 4, md: 6 }}>
          {faqs.map((category, idx) => (
            <Box 
              key={idx} 
              mb={{ base: 6, md: 8 }}
              p={{ base: 4, md: 6 }}
              bg="linear-gradient(145deg, rgba(0,0,0,0.8), rgba(0,0,0,0.6))"
              borderRadius="xl"
              borderColor="yellow.500"
              borderWidth="1px"
            >
              {/* Category Header */}
              <Flex 
                align="center" 
                mb={{ base: 4, md: 6 }}
                pb={4}
                borderBottom="1px solid"
                borderColor="yellow.500"
                opacity={0.8}
              >
                <Icon 
                  as={category.icon} 
                  w={6} 
                  h={6} 
                  color="yellow.400" 
                  mr={3} 
                />
                <Text 
                  fontSize={{ base: 'lg', md: 'xl' }} 
                  fontWeight="bold" 
                  color="yellow.400"
                >
                  {category.category}
                </Text>
              </Flex>
              
              {/* Questions Accordion */}
              <Accordion allowMultiple>
                {category.questions.map((faq, index) => (
                  <AccordionItem 
                    key={index} 
                    border="none" 
                    mb={4}
                    bg="linear-gradient(145deg, rgba(0,0,0,0.7), rgba(0,0,0,0.5))"
                    borderRadius="lg"
                    overflow="hidden"
                    transition="all 0.3s ease"
                    _hover={{
                      transform: 'translateY(-2px)',
                      boxShadow: '0 10px 30px rgba(255, 165, 0, 0.1)'
                    }}
                  >
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton 
                          p={6}
                          _hover={{ bg: 'transparent' }}
                          _expanded={{ 
                            bg: 'linear-gradient(145deg, rgba(255,165,0,0.1), transparent)',
                            color: 'yellow.400'
                          }}
                        >
                          <Box flex="1" textAlign="left">
                            <Text fontSize="lg" fontWeight="medium">
                              {faq.question}
                            </Text>
                          </Box>
                          <AccordionIcon 
                            transform={isExpanded ? 'rotate(180deg)' : 'none'}
                            transition="transform 0.3s ease"
                          />
                        </AccordionButton>
                        <AccordionPanel pb={6} px={6}>
                          <Text color="gray.300" mb={4}>
                            {faq.answer}
                          </Text>
                          <Box
                            bg="rgba(255,165,0,0.1)"
                            p={3}
                            borderRadius="md"
                            borderLeft="4px solid"
                            borderColor="yellow.500"
                          >
                            <Text color="yellow.400" fontWeight="medium">
                              {faq.highlight}
                            </Text>
                          </Box>
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
          ))}
        </Flex>

        {/* Call to Action Section */}
        <Box 
          mt={{ base: 8, md: 12 }}
          p={{ base: 6, md: 8 }}
          borderRadius="xl"
          bg="linear-gradient(145deg, rgba(0,0,0,0.8), rgba(0,0,0,0.6))"
          borderColor="yellow.500"
          borderWidth="1px"
          textAlign="center"
        >
          <VStack spacing={4}>
            <Text 
              fontSize={{ base: 'xl', md: '2xl' }} 
              fontWeight="bold"
              className="gradient-text"
            >
              Still have questions?
            </Text>
            <Text color="gray.400">
              Our AI experts are ready to help you get started
            </Text>
            <Button
              colorScheme="yellow"
              size="lg"
              rightIcon={<FaArrowRight />}
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: '0 10px 30px rgba(255, 165, 0, 0.2)'
              }}
              transition="all 0.3s ease"
            >
              Contact Support
            </Button>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};

export default FAQSection; 