import React, { useState } from 'react';
import { Box, Text, VStack, Button, Input, HStack, IconButton, useColorModeValue } from '@chakra-ui/react';
import { MdAdd, MdDelete } from 'react-icons/md';

export const SMSTemplates = ({ onSelect }) => {
  const [templates, setTemplates] = useState([
    "Hello! I hope you're doing well.",
    "Thank you for reaching out to us.",
    "Please let us know if you have any questions.",
    "We're excited to assist you further!",
    "Have a great day!"
  ]);
  const [newTemplate, setNewTemplate] = useState('');

  const bgColor = useColorModeValue('gray.100', 'gray.800');
  const borderColor = useColorModeValue('gray.300', 'gray.600');
  const buttonBg = useColorModeValue('white', 'gray.700');

  const addTemplate = () => {
    if (newTemplate.trim()) {
      setTemplates([...templates, newTemplate.trim()]);
      setNewTemplate('');
    }
  };

  const deleteTemplate = (index) => {
    const updatedTemplates = templates.filter((_, i) => i !== index);
    setTemplates(updatedTemplates);
  };

  return (
    <Box 
      bg={bgColor}
      borderRadius="md" 
      p={6} 
      width="100%"
      maxHeight="100%"
      overflowY="auto"
    >
      <Text fontWeight="bold" mb={4} fontSize="lg">SMS Templates</Text>
      <VStack spacing={4} align="stretch">
        {templates.map((template, index) => (
          <HStack key={index}>
            <Button 
              onClick={() => onSelect(template)}
              variant="outline"
              colorScheme="blue"
              size="md"
              flex="1"
              justifyContent="flex-start"
              whiteSpace="normal"
              height="auto"
              py={2}
            >
              {template}
            </Button>
            <IconButton
              icon={<MdDelete />}
              size="md"
              variant="ghost"
              colorScheme="red"
              aria-label="Delete Template"
              onClick={() => deleteTemplate(index)}
            />
          </HStack>
        ))}
      </VStack>
      <HStack mt={6}>
        <Input
          placeholder="New template"
          value={newTemplate}
          onChange={(e) => setNewTemplate(e.target.value)}
          size="md"
          bg={buttonBg}
        />
        <IconButton
          icon={<MdAdd />}
          size="md"
          colorScheme="green"
          aria-label="Add Template"
          onClick={addTemplate}
        />
      </HStack>
    </Box>
  );
};

export default SMSTemplates;
