import React from 'react';
import { Box, Tag, Wrap, WrapItem } from '@chakra-ui/react';

function TagsBox({ tags = ['First Time Buyer', 'Luxury Seller', 'Needs Financing'] }) {
  return (
    <Box bg="black" p={4} >
      <Wrap spacing={2}>
        {tags.map((tag) => (
          <WrapItem key={tag}>
            <Tag colorScheme="yellow">{tag}</Tag>
          </WrapItem>
        ))}
      </Wrap>
    </Box>
  );
}

export default TagsBox;