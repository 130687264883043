import React from 'react';
import {
  Box,
  Heading,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  Flex,
  VStack,
  HStack,
  Button,
  Icon,
  useColorModeValue,
  Divider,
} from '@chakra-ui/react';
import {
  FaChartLine,
  FaUsers,
  FaPhoneAlt,
  FaCalendarCheck,
  FaRegBell,
  FaRegEnvelope,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';
import PageTransition from '../components/PageTransition';
import WelcomeTour from '../components/WelcomeTour';
import { useDisclosure } from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';

const LightHomePage = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  
  // Color values for light theme
  const bgColor = useColorModeValue('white', 'gray.800');
  const cardBg = useColorModeValue('gray.50', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const accentColor = useColorModeValue('gold.500', 'yellow.400');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const activityTextColor = useColorModeValue('gray.500', 'gray.400');

  // Add this near the top of the component
  const isMobile = useBreakpointValue({ base: true, md: false });

  const StatCard = ({ icon, label, value, delta }) => (
    <Box
      p={6}
      bg={cardBg}
      borderRadius="xl"
      boxShadow="lg"
      border="1px solid"
      borderColor={borderColor}
      transition="transform 0.2s"
      _hover={{ transform: 'translateY(-2px)' }}
    >
      <VStack spacing={4} align="start">
        <Icon as={icon} boxSize={6} color={accentColor} />
        <Stat>
          <StatLabel fontSize="lg" color={textColor}>
            {label}
          </StatLabel>
          <StatNumber fontSize="3xl" fontWeight="bold" color={accentColor}>
            {value}
          </StatNumber>
        </Stat>
      </VStack>
    </Box>
  );

  const QuickActionButton = ({ icon, label, onClick }) => (
    <Button
      leftIcon={<Icon as={icon} />}
      variant="outline"
      size="lg"
      borderColor={accentColor}
      color={textColor}
      _hover={{
        bg: accentColor,
        color: 'white',
      }}
      onClick={onClick}
      flex={1}
    >
      {label}
    </Button>
  );

  return (
    <PageTransition>
      <Box
        p={{ base: 4, md: 8 }}
        pt={{ base: "80px", md: "20" }}
        bg={bgColor}
        minH="100vh"
      >
        <Box maxW="1400px" mx="auto">
          {/* Welcome Section */}
          <VStack spacing={{ base: 4, md: 8 }} align="stretch" mb={{ base: 8, md: 12 }}>
            <Heading
              fontSize={{ base: '2xl', md: '4xl' }}
              fontWeight="bold"
              textAlign="center"
              color={textColor}
            >
              Welcome to Real•Talk•Pro
            </Heading>
            <Text
              fontSize="xl"
              textAlign="center"
              color={useColorModeValue('gray.600', 'gray.300')}
            >
              Your Professional Communication Hub
            </Text>
          </VStack>

          {/* Stats Grid */}
          <SimpleGrid 
            columns={{ base: 1, md: 2, lg: 4 }} 
            spacing={{ base: 4, md: 8 }} 
            mb={{ base: 8, md: 12 }}
          >
            <StatCard icon={FaChartLine} label="Total Revenue" value="$24,890" />
            <StatCard icon={FaUsers} label="Active Leads" value="127" />
            <StatCard icon={FaPhoneAlt} label="Calls Today" value="45" />
            <StatCard icon={FaCalendarCheck} label="Appointments" value="12" />
          </SimpleGrid>

          {/* Quick Actions */}
          <Box mb={{ base: 8, md: 12 }}>
            <Heading 
              size="lg" 
              mb={{ base: 4, md: 6 }} 
              color={textColor}
              px={{ base: 2, md: 0 }}
            >
              Quick Actions
            </Heading>
            <SimpleGrid 
              columns={{ base: 1, md: 2 }} 
              spacing={{ base: 3, md: 4 }}
              px={{ base: 2, md: 0 }}
            >
              <QuickActionButton
                icon={FaUsers}
                label="Manage Leads"
                onClick={() => navigate('/leads')}
              />
              <QuickActionButton
                icon={FaPhoneAlt}
                label="Start Call"
                onClick={() => navigate('/call-script')}
              />
              <QuickActionButton
                icon={FaRegBell}
                label="View Notifications"
                onClick={() => navigate('/notifications')}
              />
              <QuickActionButton
                icon={FaRegEnvelope}
                label="Send Message"
                onClick={() => navigate('/communications')}
              />
            </SimpleGrid>
          </Box>

          {/* Recent Activity */}
          <Box mb={{ base: 8, md: 12 }}>
            <Heading 
              size="lg" 
              mb={{ base: 4, md: 6 }} 
              color={textColor}
              px={{ base: 2, md: 0 }}
            >
              Recent Activity
            </Heading>
            <VStack
              spacing={{ base: 3, md: 4 }}
              align="stretch"
              p={{ base: 4, md: 6 }}
              mx={{ base: 2, md: 0 }}
              bg={cardBg}
              borderRadius="xl"
              boxShadow="lg"
              border="1px solid"
              borderColor={borderColor}
            >
              {[1, 2, 3].map((item) => (
                <HStack key={item} justify="space-between" p={4}>
                  <Text color={textColor}>Lead contact updated</Text>
                  <Text color={activityTextColor}>
                    2 hours ago
                  </Text>
                </HStack>
              ))}
            </VStack>
          </Box>

          {/* Footer */}
          <Divider mb={6} />
          <Text textAlign="center" color={useColorModeValue('gray.500', 'gray.400')}>
            © 2024 Real•Talk•Pro. All rights reserved.
          </Text>
        </Box>

        {/* Add Welcome Tour */}
        <WelcomeTour isOpen={isOpen} onClose={onClose} />
      </Box>
    </PageTransition>
  );
};

export default LightHomePage; 