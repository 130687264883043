import React from 'react';
import { Box, Heading, SimpleGrid, Icon, Flex, Text, Stack } from '@chakra-ui/react';
import { FaCheck, FaStar } from 'react-icons/fa';

const TransformBusinessSection = ({ features }) => {
  return (
    <Box position="relative" px={{ base: 4, md: 8 }}>
      <Heading
        as="h2"
        textAlign="center"
        mb={12}
        fontSize={{ base: '2xl', md: '4xl' }}
        className="gradient-text"
      >
        Transform Your Real Estate Business
      </Heading>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} mt={16}>
        {features.map((feature, index) => (
          <Box
            key={index}
            position="relative"
            p={8}
            borderRadius="2xl"
            bg="linear-gradient(145deg, rgba(0,0,0,0.9), rgba(0,0,0,0.7))"
            borderColor="yellow.500"
            borderWidth="1px"
            overflow="hidden"
            _before={{
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '4px',
              background: 'linear-gradient(90deg, #FFD700, #FFA500)',
            }}
            _hover={{ 
              transform: 'translateY(-8px)',
              boxShadow: '0 20px 40px rgba(255, 165, 0, 0.15)'
            }}
            transition="all 0.4s ease"
          >
            <Flex direction="column" height="100%">
              <Icon 
                as={feature.icon} 
                w={12} 
                h={12} 
                color="yellow.400" 
                mb={6}
                _hover={{ transform: 'scale(1.1)' }}
                transition="transform 0.3s ease"
              />
              <Heading as="h3" fontSize="xl" mb={4} color="yellow.400">
                {feature.title}
              </Heading>
              <Text color="gray.300" mb={6} flex="1">
                {feature.description}
              </Text>
              <Stack spacing={4} mt="auto">
                <Flex align="center" color="green.400">
                  <Icon as={FaCheck} mr={2} />
                  <Text fontWeight="medium">{feature.feature}</Text>
                </Flex>
                <Flex align="center" color="yellow.400">
                  <Icon as={FaStar} mr={2} />
                  <Text fontWeight="medium">{feature.benefit}</Text>
                </Flex>
              </Stack>
            </Flex>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default TransformBusinessSection; 