import React from 'react';
import {
  Box,
  Container,
  Stack,
  Heading,
  Text,
  SimpleGrid,
  Icon,
  Flex,
  Button
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';

const FinalCTASection = () => {
  return (
    <Container maxW="100%" py={20}>
      <Stack spacing={8} align="center" textAlign="center">
        <Heading
          fontSize={{ base: '2xl', md: '4xl' }}
          className="gradient-text"
          mb={6}
        >
          Don't Let Another Deal Slip Away
        </Heading>

        <Text fontSize="lg" color="gray.400" maxW="800px" mb={8}>
          Every minute you wait is another opportunity for your competition to get ahead. Here's what you get when you join Real Talk Pro today:
        </Text>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} maxW="900px" w="100%">
          <Box
            p={6}
            borderRadius="lg"
            bg="linear-gradient(145deg, rgba(0,0,0,0.8), rgba(0,0,0,0.6))"
            borderColor="yellow.500"
            borderWidth="1px"
          >
            <Stack spacing={4}>
              <Heading size="md" color="yellow.400">Immediate Benefits</Heading>
              <Stack spacing={3}>
                <Flex align="center">
                  <Icon as={FaCheck} color="green.400" mr={2} />
                  <Text>AI-Powered Call Support</Text>
                </Flex>
                <Flex align="center">
                  <Icon as={FaCheck} color="green.400" mr={2} />
                  <Text>Automated Follow-up System</Text>
                </Flex>
                <Flex align="center">
                  <Icon as={FaCheck} color="green.400" mr={2} />
                  <Text>Lead Management Dashboard</Text>
                </Flex>
              </Stack>
            </Stack>
          </Box>

          <Box
            p={6}
            borderRadius="lg"
            bg="linear-gradient(145deg, rgba(0,0,0,0.8), rgba(0,0,0,0.6))"
            borderColor="yellow.500"
            borderWidth="1px"
          >
            <Stack spacing={4}>
              <Heading size="md" color="yellow.400">Exclusive Bonuses</Heading>
              <Stack spacing={3}>
                <Flex align="center">
                  <Icon as={FaCheck} color="green.400" mr={2} />
                  <Text>30-Day Money-Back Guarantee</Text>
                </Flex>
                <Flex align="center">
                  <Icon as={FaCheck} color="green.400" mr={2} />
                  <Text>Free Strategy Session ($500 Value)</Text>
                </Flex>
                <Flex align="center">
                  <Icon as={FaCheck} color="green.400" mr={2} />
                  <Text>Priority Support Access</Text>
                </Flex>
              </Stack>
            </Stack>
          </Box>
        </SimpleGrid>

        <Box
          mt={12}
          p={6}
          borderRadius="lg"
          bg="red.600"
          maxW="600px"
          w="100%"
        >
          <Text fontWeight="bold" mb={2}>
            ⚠️ WARNING: Beta Access Closing Soon
          </Text>
          <Text fontSize="sm">
            Lock in your 50% lifetime discount before all spots are gone!
          </Text>
        </Box>

        <Button
          as={RouterLink}
          to="/register"
          size="lg"
          height="60px"
          px={8}
          fontSize="xl"
          bg="linear-gradient(45deg, #FFD700, #FFA500)"
          color="black"
          _hover={{
            transform: 'scale(1.05)',
            bg: 'linear-gradient(45deg, #FFA500, #FFD700)',
          }}
          transition="all 0.3s ease"
          boxShadow="0 0 20px rgba(255, 165, 0, 0.4)"
          mt={8}
        >
          Start Your Risk-Free Trial Now
        </Button>

        <Text fontSize="sm" color="gray.400" mt={4}>
          30-Day Money-Back Guarantee • Cancel Anytime • 24/7 Support
        </Text>
      </Stack>
    </Container>
  );
};

export default FinalCTASection; 