import React from 'react';
import {
  VStack, Box, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,
  UnorderedList, ListItem
} from '@chakra-ui/react';

const ObstacleInquiries = () => {
  return (
    <VStack align="stretch" spacing={4}>
      {/* Purpose Accordion */}
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontWeight="bold" color="white">Purpose</Text>
              </Box>
              <AccordionIcon color="yellow.500" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <UnorderedList spacing={2} color="gray.400">
              <ListItem>Uncover the prospect's problems, pains, worries, fears, needs, and desires</ListItem>
              <ListItem>Make subconscious issues conscious. This approach helps prospects realize their own needs rather than you telling them.</ListItem>
              <ListItem>Build rapport and reduce sales resistance</ListItem>
              <ListItem>Set the stage for presenting solutions</ListItem>
              <ListItem>Start in the positive lane, going as far as possible. Use neutral language to transition when you can't go further in the positive lane. Move to the negative lane, exploring what the prospect would like to change.</ListItem>
              <ListItem>The goal is to make prospects feel understood, not sold to</ListItem>
              <ListItem>Start with positivity to reduce sales resistance and stand out from other salespeople</ListItem>
              <ListItem>Use the two-lane approach to structure your conversation</ListItem>
              <ListItem>Build rapport throughout the process - make it feel like a conversation, not a sales pitch</ListItem>
              <ListItem>Use non-committal language when transitioning to negatives or exploring changes</ListItem>
              <ListItem>Always follow up with probing questions to get more details</ListItem>
              <ListItem>Help prospects uncover subconscious issues they might not be aware of</ListItem>
              <ListItem>Be thorough - explore both likes and dislikes to get a complete picture</ListItem>
              <ListItem>Listen actively and adjust your approach based on the prospect's responses</ListItem>
              <ListItem>Be patient - give prospects time to think and respond fully</ListItem>
              <ListItem>Avoid immediate problem-solving. Focus on uncovering issues rather than jumping to solutions at this stage</ListItem>
              <ListItem>Look for signs that prospects are selling themselves on the need for change</ListItem>
              <ListItem>Listen for responses like "I hadn't actually thought about that before"</ListItem>
              <ListItem>No one likes 100% of what they have - always probe for potential changes</ListItem>
              <ListItem>Uncovering hidden objections now can prevent issues later in the sales process</ListItem>
              <ListItem>Remember that every prospect is unique - tailor your questions to their situation</ListItem>
              <ListItem>When restating their situation, focus on key points to show active listening</ListItem>
              <ListItem>Use a curious, caring tone to maintain a non-threatening atmosphere</ListItem>
              <ListItem>Allow prospects to discover potential issues on their own through gentle guidance</ListItem>
              <ListItem>Keep the conversation feeling natural and avoid a rigid, scripted approach</ListItem>
              <ListItem>Use pauses and curious tones to encourage deeper reflection from the prospect</ListItem>
              <ListItem>Prepare for the "way out acknowledgement" phase that follows</ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {/* Introduction */}
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Introduction</Text>
        <Text color="gray.400" mt={2}>RESTATE THEIR CURRENT SITUATION TO ACKNOWLEDGE YOU ARE ACTIVELY LISTENING</Text>
      </Box>

      {/* Doubt Causing Questions */}
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Doubt Causing Questions</Text>
        <UnorderedList color="gray.400" mt={2}>
          <ListItem>MR.OWNER, Do you (pausecurious tone) like the home that you're living in now?</ListItem>
          <ListItem>MR.RENTER, Do you like how things are going with your current rental and landlord?</ListItem>
        </UnorderedList>
      </Box>

      {/* Opening Positive Questions */}
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Opening Positive Questions</Text>
        <UnorderedList color="gray.400" mt={2}>
          <ListItem>I'm curious, what do you like about the property you're in now?</ListItem>
          <ListItem>Probe: Why do you like....</ListItem>
          <ListItem>Probe: Is there anything else about the property that you really like? Tell me more about that</ListItem>
        </UnorderedList>
      </Box>

      {/* Transition to Negatives */}
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Transition to Negatives (Use non-committal language)</Text>
        <UnorderedList color="gray.400" mt={2}>
          <ListItem>In a perfect world, what would you change about your current property?</ListItem>
          <ListItem>If we could do one thing, what ideally would you change about that property?</ListItem>
        </UnorderedList>
      </Box>

      {/* Exploring Potential Changes */}
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Exploring Potential Changes</Text>
        <UnorderedList color="gray.400" mt={2}>
          <ListItem>Probe: Why don't you like...?</ListItem>
          <ListItem>Probe: Is there anything else you don't like about...?</ListItem>
        </UnorderedList>
      </Box>

      {/* Two Truths Question */}
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">(Only for satisfied prospects) Two Truths Question </Text>
        <Text color="gray.400" mt={2}>If they say they love their situation and they aren't looking to make a change. (No One Likes 100% of What They like or Have)</Text>
        <UnorderedList color="gray.400" mt={2}>
          <ListItem>(Skeptically) It sounds like things are going fairly well for you. Is there anything you would change, if you could?</ListItem>
          <ListItem>Follow-up question "Why would you change that?"</ListItem>
        </UnorderedList>
      </Box>

      {/* Wrapping Up and Transition */}
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Wrapping Up and Transition</Text>
        <Text color="gray.400" mt={2}>
          Based on what you've shared, it sounds like [summarize key points]. Is there anything else you'd like to add about your current situation or what you're looking for?
        </Text>
      </Box>

      {/* Key Takeaways Accordion */}
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontWeight="bold" color="white">Key Takeaways</Text>
              </Box>
              <AccordionIcon color="yellow.500" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <UnorderedList spacing={2} color="gray.400">
              <ListItem>Start with positivity to reduce sales resistance and stand out from other salespeople</ListItem>
              <ListItem>Use the two-lane approach to structure your conversation</ListItem>
              <ListItem>Build rapport throughout the process - make it feel like a conversation, not a sales pitch</ListItem>
              <ListItem>Use non-committal language when transitioning to negatives or exploring changes</ListItem>
              <ListItem>Always follow up with probing questions to get more details</ListItem>
              <ListItem>Help prospects uncover subconscious issues they might not be aware of</ListItem>
              <ListItem>Be thorough - explore both likes and dislikes to get a complete picture</ListItem>
              <ListItem>Listen actively and adjust your approach based on the prospect's responses</ListItem>
              <ListItem>Be patient - give prospects time to think and respond fully</ListItem>
              <ListItem>Avoid immediate problem-solving. Focus on uncovering issues rather than jumping to solutions at this stage</ListItem>
              <ListItem>Look for signs that prospects are selling themselves on the need for change</ListItem>
              <ListItem>Listen for responses like "I hadn't actually thought about that before"</ListItem>
              <ListItem>No one likes 100% of what they have - always probe for potential changes</ListItem>
              <ListItem>Uncovering hidden objections now can prevent issues later in the sales process</ListItem>
              <ListItem>Remember that every prospect is unique - tailor your questions to their situation</ListItem>
              <ListItem>When restating their situation, focus on key points to show active listening</ListItem>
              <ListItem>Use a curious, caring tone to maintain a non-threatening atmosphere</ListItem>
              <ListItem>Allow prospects to discover potential issues on their own through gentle guidance</ListItem>
              <ListItem>Keep the conversation feeling natural and avoid a rigid, scripted approach</ListItem>
              <ListItem>Use pauses and curious tones to encourage deeper reflection from the prospect</ListItem>
              <ListItem>Prepare for the "way out acknowledgement" phase that follows</ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </VStack>
  );
};

export default ObstacleInquiries;