import React from 'react';
import { Box, Heading, SimpleGrid, VStack, Text, Flex, Button, Badge, Progress, Stat, StatLabel, StatNumber, StatHelpText, StatArrow } from '@chakra-ui/react';
import { FaChartLine, FaHandshake, FaMoneyBillWave } from 'react-icons/fa';

const OpportunitiesDealsSection = () => {
  // Fake data for opportunities
  const opportunities = [
    { id: 1, title: "Luxury Penthouse Suite", value: "$2.5M", probability: 75, stage: "Negotiation" },
    { id: 2, title: "Beachfront Property Development", value: "$15M", probability: 60, stage: "Proposal" },
    { id: 3, title: "Downtown Commercial Space", value: "$5.8M", probability: 90, stage: "Closing" },
    { id: 4, title: "Suburban Family Homes Project", value: "$8.2M", probability: 40, stage: "Prospecting" },
  ];

  // Fake data for recent deals
  const recentDeals = [
    { id: 1, title: "Skyline Apartments", value: "$12.7M", date: "Sep 28, 2024", increase: 15 },
    { id: 2, title: "Green Valley Estates", value: "$6.9M", date: "Sep 22, 2024", increase: 8 },
    { id: 3, title: "Tech Hub Office Complex", value: "$22.5M", date: "Sep 15, 2024", increase: 25 },
  ];

  return (
    <Box>
      <Heading mb={5} fontSize="2xl">Opportunities and Deals</Heading>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
        {/* Opportunities Card */}
        <Box bg="surfaceBg" p={5} borderRadius="md" className="gold-border-glow">
          <Flex justify="space-between" align="center" mb={4}>
            <Heading fontSize="xl">Hot Opportunities</Heading>
            <FaChartLine color="#F6AD55" size="24px" />
          </Flex>
          <VStack align="stretch" spacing={4}>
            {opportunities.map((opp) => (
              <Box key={opp.id} p={3} bg="blackAlpha.300" borderRadius="md">
                <Flex justify="space-between" align="center" mb={2}>
                  <Text fontWeight="bold">{opp.title}</Text>
                  <Badge colorScheme={opp.probability > 70 ? "green" : opp.probability > 40 ? "yellow" : "red"}>
                    {opp.probability}% Probable
                  </Badge>
                </Flex>
                <Flex justify="space-between" align="center" mb={2}>
                  <Text color="orange.300" fontWeight="semibold">{opp.value}</Text>
                  <Badge colorScheme="purple">{opp.stage}</Badge>
                </Flex>
                <Progress value={opp.probability} colorScheme="orange" size="sm" borderRadius="full" />
              </Box>
            ))}
          </VStack>
          <Button mt={4} leftIcon={<FaHandshake />} colorScheme="orange" size="sm">
            View All Opportunities
          </Button>
        </Box>

        {/* Deals Card */}
        <Box bg="surfaceBg" p={5} borderRadius="md" className="gold-border-glow">
          <Flex justify="space-between" align="center" mb={4}>
            <Heading fontSize="xl">Recent Deals</Heading>
            <FaMoneyBillWave color="#F6AD55" size="24px" />
          </Flex>
          <VStack align="stretch" spacing={4}>
            {recentDeals.map((deal) => (
              <Box key={deal.id} p={3} bg="blackAlpha.300" borderRadius="md">
                <Flex justify="space-between" align="center" mb={2}>
                  <Text fontWeight="bold">{deal.title}</Text>
                  <Text color="gray.400" fontSize="sm">{deal.date}</Text>
                </Flex>
                <Stat>
                  <StatLabel>Deal Value</StatLabel>
                  <StatNumber color="green.400">{deal.value}</StatNumber>
                  <StatHelpText>
                    <StatArrow type="increase" />
                    {deal.increase}% increase
                  </StatHelpText>
                </Stat>
              </Box>
            ))}
          </VStack>
          <Button mt={4} leftIcon={<FaMoneyBillWave />} colorScheme="green" size="sm">
            View All Deals
          </Button>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default OpportunitiesDealsSection;
