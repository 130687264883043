import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Heading,
  Button,
  Flex,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
  Input,
  FormControl,
  FormLabel,
  Checkbox,
  IconButton,
  Tooltip,
  Select, // Import Select for timeframe selection
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { MdCallReceived, MdCallMade, MdPlayArrow, MdEdit, MdDelete, MdOutlineDescription, MdAnalytics } from 'react-icons/md';

function CallsLogPage() {
  const [calls, setCalls] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCall, setSelectedCall] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [isLoadingTranscript, setIsLoadingTranscript] = useState(false);

  // Add new state for transcript modal
  const [isTranscriptModalOpen, setIsTranscriptModalOpen] = useState(false);
  const [selectedTranscript, setSelectedTranscript] = useState(null);

  const [isAnalysisModalOpen, setIsAnalysisModalOpen] = useState(false);
  const [selectedAnalysis, setSelectedAnalysis] = useState(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingCall, setEditingCall] = useState(null);

  const [selectedCalls, setSelectedCalls] = useState([]);

  const [metrics, setMetrics] = useState([]);
  const [timeframe, setTimeframe] = useState('weekly'); // Default timeframe

  const fetchCalls = useCallback(async (page) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/api/twilio/calls?page=${page}&limit=20`);
      setCalls(response.data.calls);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
    } catch (err) {
      console.error('Error fetching calls:', err);
      const errorMessage = err.response?.data?.error || err.message || 'An unknown error occurred';
      setError(`Error fetching calls: ${errorMessage}. Status: ${err.response?.status}`);
      toast({
        title: 'Error fetching calls',
        description: `${errorMessage}. Please check the server logs for more information.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [toast]);

  const fetchMetrics = useCallback(async (selectedTimeframe) => {
    try {
      const response = await axios.get(`/api/twilio/metrics?timeframe=${selectedTimeframe}`);
      if (response.data.success) {
        setMetrics(response.data.metrics);
      } else {
        console.error('Failed to fetch metrics');
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
      toast({
        title: 'Error fetching metrics',
        description: 'Unable to load metrics. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchCalls(1);
    fetchMetrics(timeframe); // Fetch metrics on component mount
  }, [fetchCalls, fetchMetrics, timeframe]);

  const handlePageChange = useCallback((newPage) => {
    fetchCalls(newPage);
  }, [fetchCalls]);

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed': return 'green';
      case 'missed': return 'red';
      case 'failed': return 'orange';
      default: return 'gray';
    }
  };

  const handlePlayAudio = async (call) => {
    setSelectedCall(call);
    onOpen();

    if (!call.recordingUrl) {
      toast({
        title: 'No recording available',
        description: 'This call does not have a recording.',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    }

    // If transcript is not available, try to fetch it
    if (!call.transcript) {
      setIsLoadingTranscript(true);
      try {
        const response = await axios.get(`/api/twilio/calls/${call.twilioCallSid}/transcript`);
        if (response.data && response.data.transcript) {
          setSelectedCall(prevCall => ({
            ...prevCall,
            transcript: response.data.transcript
          }));
        } else {
          console.log('No transcript available in the response:', response.data);
        }
      } catch (error) {
        console.error('Error fetching transcript:', error);
        toast({
          title: 'Error fetching transcript',
          description: 'Unable to load the transcript. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoadingTranscript(false);
      }
    }
  };

  const handleViewTranscript = async (call) => {
    setIsLoadingTranscript(true);
    try {
      if (call.spectropicStatus !== 'completed') {
        // Attempt to regenerate the transcript
        const regenerateResponse = await axios.post(`/api/twilio/process-call/${call.twilioCallSid}`);
        if (regenerateResponse.data && regenerateResponse.data.transcript) {
          const transcriptText = regenerateResponse.data.transcript?.displayText || 
            regenerateResponse.data.transcript?.rawText ||
            (Array.isArray(regenerateResponse.data.transcript?.segments) ?
              regenerateResponse.data.transcript.segments
                .map(segment => `${segment.speaker}: ${segment.text}`)
                .join('\n') :
              regenerateResponse.data.transcript);
          
          setSelectedTranscript(transcriptText);
          setIsTranscriptModalOpen(true);
        } else {
          throw new Error('Failed to regenerate transcript');
        }
      } else {
        const response = await axios.get(`/api/twilio/calls/${call.twilioCallSid}/transcript`);
        if (response.data && response.data.transcript) {
          setSelectedTranscript(response.data.transcript);
          setIsTranscriptModalOpen(true);
        } else {
          throw new Error('No transcript available');
        }
      }
    } catch (error) {
      console.error('Error fetching/regenerating transcript:', error);
      toast({
        title: 'Error with transcript',
        description: error.message || 'Unable to load or regenerate the transcript. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingTranscript(false);
    }
  };

  const handleViewAnalysis = async (call) => {
    setIsLoadingTranscript(true);
    try {
      if (call.spectropicStatus !== 'completed' || !call.transcript || !call.analysis) {
        // Attempt to regenerate the analysis
        const regenerateResponse = await axios.post(`/api/twilio/process-call/${call.twilioCallSid}`);
        if (regenerateResponse.data && regenerateResponse.data.analysis) {
          setSelectedAnalysis(regenerateResponse.data.analysis);
          setIsAnalysisModalOpen(true);
        } else {
          throw new Error('Failed to regenerate analysis');
        }
      } else {
        setSelectedAnalysis(call.analysis);
        setIsAnalysisModalOpen(true);
      }
    } catch (error) {
      console.error('Error fetching/regenerating analysis:', error);
      toast({
        title: 'Error with analysis',
        description: error.message || 'Unable to load or regenerate the analysis. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingTranscript(false);
    }
  };

  const handleEditCall = (call) => {
    setEditingCall({ ...call });
    setIsEditModalOpen(true);
  };

  const handleSaveEdit = async () => {
    try {
      const response = await axios.put(`/api/twilio/calls/${editingCall._id}`, editingCall);
      if (response.data) {
        // Update the calls array with the edited call
        setCalls(calls.map(call => call._id === editingCall._id ? response.data : call));
        setIsEditModalOpen(false);
        toast({
          title: 'Call updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating call:', error);
      toast({
        title: 'Error updating call',
        description: 'Unable to update the call. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSelectCall = (callId) => {
    setSelectedCalls(prev => 
      prev.includes(callId) ? prev.filter(id => id !== callId) : [...prev, callId]
    );
  };

  const handleSelectAllCalls = (event) => {
    if (event.target.checked) {
      setSelectedCalls(calls.map(call => call._id));
    } else {
      setSelectedCalls([]);
    }
  };

  const handleDeleteSelected = async () => {
    if (window.confirm(`Are you sure you want to delete ${selectedCalls.length} calls?`)) {
      try {
        const response = await axios.post('/api/twilio/delete-calls', { callIds: selectedCalls });
        if (response.data.success) {
          // Remove deleted calls from the state
          setCalls(calls.filter(call => !selectedCalls.includes(call._id)));
          setSelectedCalls([]);
          toast({
            title: 'Calls deleted successfully',
            description: `${response.data.deletedCount} calls were deleted.`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        } else {
          throw new Error('Failed to delete calls');
        }
      } catch (error) {
        console.error('Error deleting calls:', error);
        toast({
          title: 'Error deleting calls',
          description: 'Unable to delete the selected calls. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  // Handler for timeframe change
  const handleTimeframeChange = (e) => {
    setTimeframe(e.target.value);
    fetchMetrics(e.target.value);
  };

  if (error) {
    return (
      <Box bg="black" minHeight="calc(100vh - 60px)" p={8} color="white">
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Error!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
        <Button mt={4} onClick={() => fetchCalls(1)} colorScheme="orange">
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <Box bg="black" minHeight="calc(100vh - 60px)" p={8} color="white">
      <Heading mb={6}>Calls Log</Heading>
      
      {/* Metrics Section */}
      <Box mb={6} p={4} bg="gray.700" borderRadius="md">
        <Flex justify="space-between" align="center" mb={4}>
          <Heading size="md">Sales Call Metrics</Heading>
          <FormControl width="200px">
            <FormLabel color="white">Timeframe</FormLabel>
            <Select 
              value={timeframe} 
              onChange={handleTimeframeChange}
              bg="gray.800"
              color="white"
              borderColor="gray.600"
              _hover={{ borderColor: "gray.500" }}
              _focus={{ borderColor: "blue.300", boxShadow: "0 0 0 1px #3182ce" }}
            >
              <option style={{backgroundColor: '#2D3748'}} value="daily">Daily</option>
              <option style={{backgroundColor: '#2D3748'}} value="weekly">Weekly</option>
              <option style={{backgroundColor: '#2D3748'}} value="monthly">Monthly</option>
              <option style={{backgroundColor: '#2D3748'}} value="quarterly">Quarterly</option>
              <option style={{backgroundColor: '#2D3748'}} value="yearly">Yearly</option>
            </Select>
          </FormControl>
        </Flex>
        {/* Display Metrics */}
        <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={4}>
          {metrics.map((metric, index) => (
            <GridItem key={index} p={3} bg="gray.800" borderRadius="md">
              <Text fontSize="lg" fontWeight="bold">
                {metric._id.year ? `Year: ${metric._id.year}` : ''}
                {metric._id.quarter ? ` Q${metric._id.quarter}` : ''}
                {metric._id.week ? ` Week: ${metric._id.week}` : ''}
                {metric._id.$dateToString ? ` Date: ${metric._id.$dateToString}` : ''}
              </Text>
              <Text>Total Calls: {metric.totalCalls}</Text>
              <Text>Dials Made: {metric.dialed}</Text>
              <Text>Pickups: {metric.pickups}</Text>
              <Text>Connections: {metric.connections}</Text>
              <Text>Conversations ≥ 2 mins: {metric.conversations}</Text>
              <Text>Appointments Booked: {metric.appointmentsBooked}</Text>
              <Text>Showings Executed: {metric.showingsExecuted}</Text>
              <Text>Offers Accepted: {metric.offersAccepted}</Text>
            </GridItem>
          ))}
        </Grid>
      </Box>

      {/* Existing Calls Log Table */}
      <Flex justify="space-between" align="center" mb={4}>
        <Checkbox 
          isChecked={selectedCalls.length === calls.length}
          onChange={handleSelectAllCalls}
        >
          Select All
        </Checkbox>
        <Button 
          leftIcon={<MdDelete />} 
          colorScheme="red" 
          onClick={handleDeleteSelected}
          isDisabled={selectedCalls.length === 0}
        >
          Delete Selected ({selectedCalls.length})
        </Button>
      </Flex>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th color="orange"></Th>
            <Th color="orange">Direction</Th>
            <Th color="orange">Lead Name</Th>
            <Th color="orange">Phone Number</Th>
            <Th color="orange">Date & Time</Th>
            <Th color="orange">Duration</Th>
            <Th color="orange">Status</Th>
            <Th color="orange">Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {calls.map((call) => (
            <Tr key={call._id}>
              <Td>
                <Checkbox 
                  isChecked={selectedCalls.includes(call._id)}
                  onChange={() => handleSelectCall(call._id)}
                />
              </Td>
              <Td>
                <Flex align="center">
                  {call.direction === 'inbound' ? <MdCallReceived /> : <MdCallMade />}
                  <Text ml={2}>{call.direction}</Text>
                </Flex>
              </Td>
              <Td>{call.leadId?.name || 'Unknown'}</Td>
              <Td>
                {call.direction === 'inbound' 
                  ? call.from 
                  : (call.to || call.originalTo || 'No number available')}
              </Td>
              <Td>{new Date(call.timestamp).toLocaleString()}</Td>
              <Td>{formatDuration(call.duration)}</Td>
              <Td>
                <Badge colorScheme={getStatusColor(call.status)}>
                  {call.status}
                </Badge>
              </Td>
              <Td>
                <Flex>
                  <Tooltip label="Play Audio">
                    <IconButton 
                      icon={<MdPlayArrow />} 
                      onClick={() => handlePlayAudio(call)} 
                      mr={2}
                      size="sm"
                      colorScheme="blue"
                      aria-label="Play audio"
                    />
                  </Tooltip>
                  <Tooltip label="View Transcript">
                    <IconButton 
                      icon={<MdOutlineDescription />} 
                      onClick={() => handleViewTranscript(call)} 
                      mr={2}
                      size="sm"
                      colorScheme="green"
                      aria-label="View transcript"
                    />
                  </Tooltip>
                  <Tooltip label="View Analysis">
                    <IconButton 
                      icon={<MdAnalytics />} 
                      onClick={() => handleViewAnalysis(call)} 
                      mr={2}
                      size="sm"
                      colorScheme="purple"
                      aria-label="View analysis"
                    />
                  </Tooltip>
                  <Tooltip label="Edit Call">
                    <IconButton 
                      icon={<MdEdit />} 
                      onClick={() => handleEditCall(call)}
                      size="sm"
                      colorScheme="orange"
                      aria-label="Edit call"
                    />
                  </Tooltip>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify="center" mt={4}>
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          isDisabled={currentPage === 1 || isLoading}
          mr={2}
        >
          Previous
        </Button>
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          isDisabled={currentPage === totalPages || isLoading}
          ml={2}
        >
          Next
        </Button>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white">
          <ModalHeader>Call Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedCall && (
              <>
                <Text><strong>Twilio Call SID:</strong> {selectedCall.twilioCallSid}</Text>
                <Text><strong>Direction:</strong> {selectedCall.direction}</Text>
                <Text><strong>Duration:</strong> {formatDuration(selectedCall.duration)}</Text>
                <Text><strong>Status:</strong> {selectedCall.status}</Text>
                <Text mt={4}><strong>Transcript:</strong></Text>
                {isLoadingTranscript ? (
                  <Spinner />
                ) : (
                  <Box 
                    mt={2} 
                    p={3} 
                    bg="gray.700" 
                    borderRadius="md" 
                    maxHeight="200px" 
                    overflowY="auto"
                  >
                    {selectedCall.transcript?.displayText || 
                     selectedCall.transcript?.rawText || 
                     (Array.isArray(selectedCall.transcript?.segments) ?
                       selectedCall.transcript.segments
                         .map(segment => `${segment.speaker}: ${segment.text}`)
                         .join('\n') :
                       'No transcript available'
                     )}
                  </Box>
                )}
                <Box mt={4}>
                  {selectedCall.recordingUrl ? (
                    <audio controls src={selectedCall.recordingUrl}>
                      Your browser does not support the audio element.
                    </audio>
                  ) : (
                    <Text>No recording available</Text>
                  )}
                </Box>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* New Transcript Modal */}
      <Modal isOpen={isTranscriptModalOpen} onClose={() => setIsTranscriptModalOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white">
          <ModalHeader>Call Transcript</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box 
              mt={2} 
              p={3} 
              bg="gray.700" 
              borderRadius="md" 
              maxHeight="400px" 
              overflowY="auto"
            >
              <Text whiteSpace="pre-wrap">{selectedTranscript}</Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setIsTranscriptModalOpen(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isAnalysisModalOpen} onClose={() => setIsAnalysisModalOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white">
          <ModalHeader>Call Analysis</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box 
              mt={2} 
              p={3} 
              bg="gray.700" 
              borderRadius="md" 
              maxHeight="400px" 
              overflowY="auto"
            >
              <Text whiteSpace="pre-wrap">{selectedAnalysis}</Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setIsAnalysisModalOpen(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* New Edit Modal */}
      <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white">
          <ModalHeader>Edit Call</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {editingCall && (
              <>
                <FormControl mb={4}>
                  <FormLabel>Lead Name</FormLabel>
                  <Input 
                    value={editingCall.leadId?.name || ''}
                    onChange={(e) => setEditingCall({...editingCall, leadId: {...editingCall.leadId, name: e.target.value}})}
                  />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Phone Number</FormLabel>
                  <Input 
                    value={editingCall.direction === 'inbound' ? editingCall.from : (editingCall.to || editingCall.originalTo || '')}
                    onChange={(e) => setEditingCall({...editingCall, [editingCall.direction === 'inbound' ? 'from' : 'to']: e.target.value})}
                  />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Status</FormLabel>
                  <Input 
                    value={editingCall.status}
                    onChange={(e) => setEditingCall({...editingCall, status: e.target.value})}
                  />
                </FormControl>
                {/* Add more fields as needed */}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveEdit}>
              Save
            </Button>
            <Button variant="ghost" onClick={() => setIsEditModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
export default CallsLogPage;
