// frontend/src/pages/Login.js

import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Button, Flex, Heading, Text, VStack, Box } from '@chakra-ui/react';
import { auth, provider } from '../firebase';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

// Add a custom font for a more unique look
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Matrix Code NFI';
    src: url('/path-to-your-font/matrix-code-nfi.ttf') format('truetype');
  }
`;

const matrixAnimation = keyframes`
  0% { transform: translateY(-100%); opacity: 0; }
  2% { opacity: 1; }
  80% { opacity: 1; } // Start fading out at 80%
  100% { transform: translateY(100%); opacity: 0; }
`;

const MatrixBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0;
  background-color: #000;
`;

const MatrixColumn = styled.div`
  position: absolute;
  top: 0;
  left: ${props => props.left}%;
  font-family: 'Courier New', monospace;
  font-size: ${props => props.size}px;
  line-height: 1;
  color: #FFA500; // Changed to orange
  text-shadow: 0 0 8px #FFA500; // Changed shadow to match
  white-space: pre;
  animation: ${matrixAnimation} ${props => props.duration}s linear infinite;
  animation-delay: ${props => props.delay}s;
  opacity: ${props => props.opacity};
`;

const StyledButton = styled(Button)`
  background: transparent;
  border: 2px solid #FFA500;
  color: #FFA500;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;

  // Override Chakra UI's default styles
  &:not(:disabled) {
    background: transparent;
  }

  &:hover, &:focus {
    background: rgba(255, 165, 0, 0.2); // Semi-transparent orange background
    color: #FFD700; // Slightly lighter orange (gold) for better contrast
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(255, 165, 0, 0.4);
  }

  // Glow effect on hover
  &:hover::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: none;
    border-radius: inherit;
    box-shadow: 0 0 10px 2px #FFA500;
    opacity: 0;
    animation: glowing 2s ease-in-out infinite;
  }

  @keyframes glowing {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
`;

const InfoText = styled(Text)`
  color: #FFA500;
  font-size: 0.8rem;
  text-align: center;
  opacity: 0.8;
`;

const LoginContainer = styled(VStack)`
  transform: perspective(1000px) rotateX(5deg);
  transition: transform 0.3s ease;
  width: min(500px, 90vw); // Make container responsive
  padding: 1rem; // Adjust padding for mobile

  &:hover {
    transform: perspective(1000px) rotateX(0deg);
  }
`;

const FixedWidthHeading = styled(Heading)`
  width: min(500px, 90vw); // Use min() for responsive width
  height: auto; // Remove fixed height
  min-height: 60px; // Minimum height for smaller screens
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  white-space: normal; // Allow text wrapping on mobile
  font-size: clamp(1.2rem, 4vw, 1.8rem); // Responsive font size
`;

const PulsatingBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, #000000, #1a1a1a);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  z-index: -1;

  @keyframes gradientBG {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const Login = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  // Function to handle Google sign-in
  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      console.log("Signed in with Google:", result.user);
      navigate('/'); // Redirect to home page after successful login
    } catch (error) {
      console.error("Error during sign-in:", error);
      setError(error.message);
    }
  };

  const [matrixColumns, setMatrixColumns] = useState([]);

  useEffect(() => {
    const columns = [];
    // Reduce column count on mobile
    const columnCount = window.innerWidth < 768 ? 100 : 200;

    for (let i = 0; i < columnCount; i++) {
      columns.push({
        left: Math.random() * 100,
        duration: 15 + Math.random() * 20,
        delay: Math.random() * -20,
        characters: generateRandomCharacters(),
        size: 8 + Math.floor(Math.random() * 12), // Smaller font size range
        opacity: 0.5 + Math.random() * 0.5
      });
    }

    setMatrixColumns(columns);
  }, []);

  function generateRandomCharacters() {
    const interestingAffirmations = [
      "I Turn Houses Into Dream Homes",
      "My Negotiation Skills Are Legendary",
      "I'm The Architect Of Financial Futures",
      "I Don't Sell Properties, I Fulfill Destinies",
      "My Market Insights Are Razor-Sharp",
      "I Transform Neighborhoods One Sale At A Time",
      "My Network Is My Net Worth",
      "I'm The Sherlock Holmes Of Real Estate",
      "I Don't Chase Success, I Attract It",
      "My Clients Call Me The Deal Whisperer",
      "I'm Not In Real Estate, I'm In The People Business",
      "I Create Win-Win Scenarios In My Sleep",
      "My Business Card Reads: Opportunity Maker",
      "I'm The Michelangelo Of Home Staging",
      "I Don't Break Records, I Set New Standards",
      "My Persistence Moves Mountains And Closes Deals",
      "I'm The Alchemist Of Property Value",
      "My Handshake Is As Solid As My Contracts",
      "I Navigate Market Trends Like A Seasoned Captain",
      "I Don't Just Meet Expectations, I Redefine Them",
      "My Clients' Dreams Are My Blueprint For Success",
      "I'm The Maestro Of Mortgage Solutions",
      "I Turn 'For Sale' Into 'Sold' With Finesse",
      "My Intuition Is My Secret Weapon In Negotiations",
      "I'm The Bridge Between Houses And Happily Ever Afters",
      "My Optimism Is Contagious And Closes Deals",
      "I'm Not Just An Agent, I'm A Lifestyle Curator",
      "My Words Turn Skeptics Into Believers",
      "I Don't Just Sell Homes, I Craft Legacies",
      "My Determination Turns Challenges Into Triumphs"
    ];
    
    // Select a random affirmation
    const affirmation = interestingAffirmations[Math.floor(Math.random() * interestingAffirmations.length)];
    
    // Create a vertical string of characters from the affirmation
    return affirmation.split('').join('\n');
  }

  return (
    <>
      <GlobalStyle />
      <Flex
        direction="column"
        height="100vh"
        bg="black"
        position="relative"
        overflow="hidden"
        border="1px solid"
        borderColor="orange.400"
      >
        {/* Matrix background */}
        <MatrixBackground>
          {matrixColumns.map((column, index) => (
            <MatrixColumn
              key={index}
              left={column.left}
              duration={column.duration}
              delay={column.delay}
              size={column.size}
              opacity={column.opacity}
            >
              {column.characters}
            </MatrixColumn>
          ))}
        </MatrixBackground>

        {/* Pulsating background */}
        <PulsatingBackground />
        
        {/* Main content */}
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          zIndex={1}
          position="relative"
        >
          <LoginContainer 
            spacing={4}
            p={[4, 6, 12]}
            rounded={6} 
            bg="rgba(0, 0, 0, 0.6)" 
            backdropFilter="blur(10px)"
            border="1px solid #FFA500"
          >
            <FixedWidthHeading 
              color="white" 
              fontFamily="'Matrix Code NFI', sans-serif"
              fontSize="3xl"
            >
              Welcome to
            </FixedWidthHeading>
            <StyledButton 
              onClick={handleGoogleSignIn} 
              leftIcon={<FontAwesomeIcon icon={faGoogle} />}
              size="lg"
              width="full"
              variant="outline"
            >
              Sign in with Google
            </StyledButton>
            <InfoText>
              You'll be redirected to Google's secure login page.
            </InfoText>
            {error && (
              <Text color="red.500" fontSize="sm">
                {error}
              </Text>
            )}
          </LoginContainer>
        </Flex>
      </Flex>
    </>
  );
}

export default Login;
