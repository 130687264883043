import React, { useState, useEffect, useRef } from 'react';
import { Box, Heading, SimpleGrid, Stat, StatLabel, StatNumber, StatHelpText, StatArrow, Icon } from '@chakra-ui/react';
import { FaUsers, FaPhoneVolume, FaChartLine, FaClipboardList, FaDollarSign, FaHome } from 'react-icons/fa';
import './StatisticsSection.css';

// Sample data for the chart
const chartData = [
  { name: 'Jan', value: 4000 },
  { name: 'Feb', value: 3000 },
  { name: 'Mar', value: 5000 },
  { name: 'Apr', value: 4500 },
  { name: 'May', value: 6000 },
  { name: 'Jun', value: 5500 },
  { name: 'Jul', value: 7000 },
];

// Enhanced chart component
const EnhancedChart = () => {
  const [animated, setAnimated] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const chartRef = useRef(null);

  const maxValue = Math.max(...chartData.map(item => item.value));
  const aspectRatio = 16 / 9; // Adjust this for desired aspect ratio
  const padding = 40;

  useEffect(() => {
    const updateDimensions = () => {
      if (chartRef.current) {
        const { width } = chartRef.current.getBoundingClientRect();
        setDimensions({ width, height: width / aspectRatio });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    setAnimated(true);

    return () => window.removeEventListener('resize', updateDimensions);
  }, [aspectRatio]);

  const getPath = () => {
    return chartData.map((item, index) => {
      const x = (index / (chartData.length - 1)) * (dimensions.width - padding * 2) + padding;
      const y = dimensions.height - (item.value / maxValue) * (dimensions.height - padding * 2) - padding;
      return `${index === 0 ? 'M' : 'L'} ${x} ${y}`;
    }).join(' ');
  };

  return (
    <div ref={chartRef} style={{ width: '100%', height: '100%' }}>
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
        preserveAspectRatio="xMidYMid meet"
        className="enhanced-chart"
      >
        <defs>
          <linearGradient id="lineGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#FFA500" stopOpacity={0.8} />
            <stop offset="100%" stopColor="#FFA500" stopOpacity={0.2} />
          </linearGradient>
          <linearGradient id="areaGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#FFA500" stopOpacity={0.5} />
            <stop offset="100%" stopColor="#FFA500" stopOpacity={0.1} />
          </linearGradient>
        </defs>

        {/* Area under the line */}
        <path
          d={`${getPath()} L ${dimensions.width - padding} ${dimensions.height - padding} L ${padding} ${dimensions.height - padding} Z`}
          fill="url(#areaGradient)"
          className={animated ? 'animate-path' : ''}
        />

        {/* Line */}
        <path
          d={getPath()}
          fill="none"
          stroke="url(#lineGradient)"
          strokeWidth="3"
          className={animated ? 'animate-path' : ''}
        />

        {/* Data points */}
        {chartData.map((item, index) => {
          const x = (index / (chartData.length - 1)) * (dimensions.width - padding * 2) + padding;
          const y = dimensions.height - (item.value / maxValue) * (dimensions.height - padding * 2) - padding;
          return (
            <g key={item.name} className={animated ? 'animate-point' : ''}>
              <circle cx={x} cy={y} r="6" fill="#FFA500" />
              <circle cx={x} cy={y} r="4" fill="white" />
              <text x={x} y={dimensions.height - 10} textAnchor="middle" fontSize="12" fill="#666">
                {item.name}
              </text>
              <text x={x} y={y - 15} textAnchor="middle" fontSize="12" fill="#FFA500" fontWeight="bold">
                {item.value}
              </text>
            </g>
          );
        })}
      </svg>
    </div>
  );
};

const StatisticsSection = () => {
  return (
    <Box mb={10} bg="surfaceBg" p={5} borderRadius="md" className="gold-border-glow">
      <Heading mb={5} fontSize="2xl">Key Statistics</Heading>
      
      {/* Enhanced Chart */}
      <Box mb={8} height="400px"> {/* Adjust height as needed */}
        <EnhancedChart />
      </Box>
      
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
        {/* Total Leads */}
        <Stat>
          <StatLabel display="flex" alignItems="center">
            <Icon as={FaUsers} mr={2} color="orange.400" />
            Total Leads
          </StatLabel>
          <StatNumber color="orange.400">1,245</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            23.36%
          </StatHelpText>
        </Stat>

        {/* Active Calls */}
        <Stat>
          <StatLabel display="flex" alignItems="center">
            <Icon as={FaPhoneVolume} mr={2} color="green.400" />
            Active Calls
          </StatLabel>
          <StatNumber color="green.400">78</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            12.5%
          </StatHelpText>
        </Stat>

        {/* Conversion Rate */}
        <Stat>
          <StatLabel display="flex" alignItems="center">
            <Icon as={FaChartLine} mr={2} color="purple.400" />
            Conversion Rate
          </StatLabel>
          <StatNumber color="purple.400">18.7%</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            3.2%
          </StatHelpText>
        </Stat>

        {/* Pending Tasks */}
        <Stat>
          <StatLabel display="flex" alignItems="center">
            <Icon as={FaClipboardList} mr={2} color="yellow.400" />
            Pending Tasks
          </StatLabel>
          <StatNumber color="yellow.400">34</StatNumber>
          <StatHelpText>
            <StatArrow type="decrease" />
            5.8%
          </StatHelpText>
        </Stat>

        {/* Revenue Generated */}
        <Stat>
          <StatLabel display="flex" alignItems="center">
            <Icon as={FaDollarSign} mr={2} color="green.400" />
            Revenue Generated
          </StatLabel>
          <StatNumber color="green.400">$2.3M</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            15.4%
          </StatHelpText>
        </Stat>

        {/* Properties Sold */}
        <Stat>
          <StatLabel display="flex" alignItems="center">
            <Icon as={FaHome} mr={2} color="blue.400" />
            Properties Sold
          </StatLabel>
          <StatNumber color="blue.400">87</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            9.7%
          </StatHelpText>
        </Stat>
      </SimpleGrid>
    </Box>
  );
};

export default StatisticsSection;