import { FaVolumeUp, FaTachometerAlt, FaComments, FaLanguage } from 'react-icons/fa';

const essentialSkills = [
    {
      title: "Tonality",
      content: "Lower your tone and aim to sound unattached from the outcome.",
      icon: FaVolumeUp,
      tips: [
        "Lower your tone instead of sounding overly happy or friendly",
        "Aim to sound unattached from the outcome of the call",
        "Focus on helping the person rather than making a sale",
        "Avoid the 'commissioned breath' that can come across in your tone"
      ],
      examples: [
        "Practice modulating your voice to sound calm and confident",
        "Record yourself and listen back to identify areas where you sound too eager or sales-y",
        "Think of yourself as a consultant rather than a salesperson",
        "Visualize yourself as someone who's there to help, not to push a product or service"
      ]
    },
    {
      title: "Pacing",
      content: "Speak at a calm, measured pace to convey confidence.",
      icon: FaTachometerAlt,
      tips: [
        "Speak at a calm, measured pace",
        "Avoid talking too quickly, which can make you sound nervous or scripted",
        "Aim to make the prospect feel comfortable and calm",
        "Use variations in pacing to convey importance, secrecy, or excitement (advanced technique)"
      ],
      examples: [
        "Practice speaking more slowly than feels natural at first",
        "Use pauses effectively to allow information to sink in",
        "Learn to match and slightly slow down the prospect's speaking pace",
        "Experiment with changing your pace to emphasize key points"
      ]
    },
    {
      title: "Verbal Cues",
      content: "Use short acknowledgments to show engagement and active listening.",
      icon: FaComments,
      tips: [
        "Use short acknowledgments every 8-15 seconds during the conversation",
        "Examples include 'oh,' 'okay,' 'interesting,' etc.",
        "These cues show you're engaged and listening, similar to nodding in person",
        "Helps create a two-way conversation feel instead of an interrogation"
      ],
      examples: [
        "Develop a repertoire of verbal cues that feel natural to you",
        "Practice active listening skills to genuinely engage with what the prospect is saying",
        "Learn to use different verbal cues to convey different emotions or levels of agreement",
        "Understand the importance of timing in using verbal cues effectively"
      ]
      },
      {
        title: "Neutral Language",
        content: "Use hypothetical and noncommittal language to avoid pressure.",
        icon: FaLanguage,
        tips: [
          "Replace 'when' with 'if' in your statements",
          "Keep things hypothetical to avoid making the prospect feel pressured to commit",
          "Use phrases like 'in a perfect world' to make scenarios feel less binding",
          "Practice reframing common sales questions into more neutral language",
          "Learn to recognize and avoid assumptive language in your speech",
          "Understand the psychology behind why people resist commitments with strangers"
        ],
        examples: [
          "I guess I should probably ask…",
          "Just to make sure that I can help you best…",
          "Out of curiosity…",
          "With that being said, let me ask…",
          "Just before I let you go…",
          "Do you mind if I ask…",
          "I'm guessing you haven't got around to it."
        ]
    }
    ];

// Export the essentialSkills array as the default export
export default essentialSkills;