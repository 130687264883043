const API_BASE_URL = 'https://www.realtalkpro.com';
const SOCKET_URL = 'wss://www.realtalkpro.com';

export { API_BASE_URL, SOCKET_URL };

/* 
 * COMMENTS:
 * - Production API endpoint: https://www.realtalkpro.com
 * - Production WebSocket endpoint: wss://www.realtalkpro.com
 * - For local development, set in .env:
 *   REACT_APP_API_BASE_URL=http://localhost:5000
 *   REACT_APP_SOCKET_URL=ws://localhost:5000
 */

