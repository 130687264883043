import React, { useState, useEffect } from 'react';
import {
  VStack, HStack, Box, Text, Button, UnorderedList, ListItem
} from '@chakra-ui/react';

const AcknowledgeAndAddValue = ({ onResponse, defaultResponse }) => {
    const [selectedScenario, setSelectedScenario] = useState('tooBroad');
    const [response, setResponse] = useState(defaultResponse || 'yes');
  
    useEffect(() => {
      if (defaultResponse) {
        setResponse(defaultResponse);
      }
    }, [defaultResponse]);
  
    const handleScenarioSelect = (scenario) => {
      setSelectedScenario(scenario);
    };
  
    return (
      <VStack align="stretch" spacing={4}>
        <Box 
          borderWidth="1px"
          borderColor="yellow.500"
          borderRadius="md"
          p={4}
        >
          <Text fontWeight="bold" color="white">
            {response === 'yes' 
              ? "Perfect. Out of the X properties I sent, which one would you say was the closest to something you are looking for?"
              : "I understand. Let's discuss what you're looking for in a property."}
          </Text>
        </Box>
        
        <HStack spacing={2}>
          <Button 
            onClick={() => handleScenarioSelect('tooBroad')}
            colorScheme={selectedScenario === 'tooBroad' ? "yellow" : "gray"}
          >
            Too Broad List
          </Button>
          <Button 
            onClick={() => handleScenarioSelect('limitedInventory')}
            colorScheme={selectedScenario === 'limitedInventory' ? "yellow" : "gray"}
          >
            Limited Inventory
          </Button>
        </HStack>
  
        {selectedScenario === 'tooBroad' && (
          <VStack align="stretch" spacing={2}>
            {[
                "**Honestly, the list we sent is quite broad, it includes literally every single listing under [$X PRICE]...**",
              "I'm not sure if it's for you, but I could send you a list that is more custom for you so that:",
              "You don't have to go to 20, 30, 40 listings",
              "You're only seeing the 2-3 that actually work for you",
              "(pause - use curious tone) Would That Help You?"
            ].map((line, index) => (
              <Box 
                key={index}
                borderWidth="1px"
                borderColor="yellow.500"
                borderRadius="md"
                p={4}
              >
                  <Text fontWeight={index === 0 ? "bold" : "normal"}>{line}</Text>
              </Box>
            ))}
          </VStack>
        )}
  
        {selectedScenario === 'limitedInventory' && (
          <VStack align="stretch" spacing={2}>
            {[
                "**Honestly, the list we sent is quite narrow.**",
                "**Unfortunately, there's far less inventory than usual in the neighborhood right now.**",
              "I'm not sure if it's for you, but what I could do is actually send you a more custom list that:",
              "Expands beyond just this neighborhood, gives you more options - maybe 20, 30, or even 40 listings, and provides a better perspective of what's going on in the market right now",
              "(pause - use curious tone) Would that be helpful?"
            ].map((line, index) => (
              <Box 
                key={index}
                borderWidth="1px"
                borderColor="yellow.500"
                borderRadius="md"
                p={4}
              >
                  <Text fontWeight={index === 0 || index === 1 ? "bold" : "normal"}>{line}</Text>
              </Box>
            ))}
          </VStack>
        )}
  
        <HStack spacing={2}>
          <Button colorScheme="green" onClick={() => onResponse('yes_helpful')}>Yes, that would be helpful</Button>
          <Button colorScheme="red" onClick={() => onResponse('no_not_helpful')}>No, that wouldn't be helpful</Button>
        </HStack>
      </VStack>
    );
  };

export default AcknowledgeAndAddValue;