import React, { useCallback, useState, useRef } from 'react';
import {
  Box,
  VStack,
  HStack,
  Input,
  Select,
  Text,
  SimpleGrid,
  Button,
  Icon,
  Divider,
  Tooltip,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Checkbox,
  Grid,
  Wrap,
  Spinner,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import {
  FaGlobe,
  FaClipboardList,
  FaHome,
  FaHistory,
  FaUserFriends,
  FaRedoAlt,
  FaBuilding,
  FaCity,
  FaHouseUser,
  FaCar,
  FaLock,
  FaPaw,
  FaUndo,
  FaRedo,
  FaCheck,
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

const CONSTANTS = {
  statusOptions: [
    { value: 'New', color: 'blue.500' },
    { value: 'TBD', color: 'gray.500' },
    { value: 'Do Not Contact', color: 'black' },
    { value: 'Not Responsive', color: 'purple.500' },
    { value: 'Bogus', color: 'orange.400' },
    { value: 'Just Browsing', color: 'blue.500' },
    { value: 'Criteria Obtained', color: 'green.500' },
    { value: 'Future Follow Up', color: 'cyan.500' },
    { value: 'Warm', color: 'yellow.500' },
    { value: 'HOT', color: 'red.500' },
    { value: 'Taken on Showings', color: 'pink.500' },
    { value: 'Submitted Offer', color: 'teal.500' },
    { value: 'Client', color: 'green.500' },
    { value: 'Raving Fan', color: 'gold' },
  ],
  leadSources: [
    { type: 'website_signup', icon: FaGlobe, label: 'Website Signup' },
    { type: 'Downloaded_Lead_Magnet', icon: FaClipboardList, label: 'Lead Magnet' },
    { type: 'open_house', icon: FaHome, label: 'Open House' },
    { type: 'older_lead', icon: FaHistory, label: 'Older Lead' },
    { type: 'referral', icon: FaUserFriends, label: 'Referral' },
    { type: 'followup', icon: FaRedoAlt, label: 'Follow-up' },
  ],
  propertyTypes: [
    { type: 'Condo', icon: FaBuilding },
    { type: 'House', icon: FaHome },
    { type: 'Townhouse', icon: FaCity },
    { type: 'Duplex', icon: FaHouseUser },
  ],
};

const SaveIndicator = ({ isSaving, isSaved }) => (
  <Box position="absolute" right="2" top="2">
    {isSaving ? (
      <Spinner size="xs" color="orange.400" />
    ) : isSaved ? (
      <Icon as={FaCheck} color="green.400" />
    ) : null}
  </Box>
);

const HistoryTracker = ({ canUndo, canRedo, onUndo, onRedo }) => (
  <HStack spacing={2}>
    <IconButton
      icon={<FaUndo />}
      size="sm"
      isDisabled={!canUndo}
      onClick={onUndo}
      variant="ghost"
      colorScheme="orange"
      aria-label="Undo"
    />
    <IconButton
      icon={<FaRedo />}
      size="sm"
      isDisabled={!canRedo}
      onClick={onRedo}
      variant="ghost"
      colorScheme="orange"
      aria-label="Redo"
    />
  </HStack>
);

const FormField = ({ label, value, onChange, placeholder, type, options }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  
  const debouncedOnChange = useCallback(
    debounce(async (value) => {
      setIsSaving(true);
      try {
        await onChange(value);
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 2000);
      } catch (error) {
        console.error('Error saving:', error);
      } finally {
        setIsSaving(false);
      }
    }, 500),
    [onChange]
  );

  return (
    <Box position="relative">
      <Text mb={1} color="gray.300" fontSize="sm">{label}</Text>
      {type === 'select' ? (
        <Select
          size="sm"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          bg="black"
          borderColor="orange.400"
          color="white"
          _hover={{ borderColor: 'orange.400' }}
          _focus={{ borderColor: 'orange.400', boxShadow: 'none' }}
        >
          {options.map(option => (
            <option 
              key={option.type || option.value} 
              value={option.type || option.value}
              style={{ backgroundColor: 'black', color: 'white' }}
            >
              {option.label || option.value}
            </option>
          ))}
        </Select>
      ) : (
        <Input
          size="sm"
          defaultValue={value}
          onChange={(e) => debouncedOnChange(e.target.value)}
          placeholder={placeholder}
          bg="black"
          borderColor="orange.400"
          _hover={{ borderColor: 'orange.400' }}
          _focus={{ borderColor: 'orange.400', boxShadow: 'none' }}
          _placeholder={{ color: 'gray.500' }}
        />
      )}
      <SaveIndicator isSaving={isSaving} isSaved={isSaved} />
    </Box>
  );
};

const BasicInfoSection = ({ lead, onJobUpdate, onStatusChange, onLeadSourceSelect }) => (
  <VStack align="stretch" spacing={4} height="100%">
    <Box>
      <FormField
        label="Name Note (Pronunciation/Nickname)"
        value={lead.nameNote}
        onChange={(value) => onJobUpdate('nameNote', value)}
        placeholder="How to pronounce their name or preferred nickname"
      />
    </Box>
    <Box>
      <FormField
        label="Job Title"
        value={lead.job}
        onChange={(value) => onJobUpdate('job', value)}
        placeholder="Enter job title"
      />
    </Box>
    <Box>
      <FormField
        label="Lead Status"
        value={lead?.criteria?.status}
        onChange={onStatusChange}
        type="select"
        options={CONSTANTS.statusOptions}
      />
    </Box>
    <Box>
      <FormField
        label="Lead Source"
        value={lead.leadSource}
        onChange={onLeadSourceSelect}
        type="select"
        options={CONSTANTS.leadSources}
      />
    </Box>
  </VStack>
);

const PropertyTypeSelector = ({ selectedTypes, onUpdate }) => (
  <Box mb={4}>
    <Text mb={1} color="gray.300" fontSize="sm">Property Types</Text>
    <Grid templateColumns="repeat(4, 1fr)" gap={2}>
      {CONSTANTS.propertyTypes.map(property => (
        <Button
          key={property.type}
          onClick={() => {
            const currentTypes = selectedTypes || [];
            const updatedTypes = currentTypes.includes(property.type)
              ? currentTypes.filter(t => t !== property.type)
              : [...currentTypes, property.type];
            onUpdate(updatedTypes);
          }}
          colorScheme="orange"
          variant={selectedTypes?.includes(property.type) ? 'solid' : 'outline'}
          size="sm"
          width="100%"
          height="32px"
          fontSize="xs"
          leftIcon={<Icon as={property.icon} boxSize={3} />}
        >
          {property.type}
        </Button>
      ))}
    </Grid>
  </Box>
);

const validateCriteriaInput = (field, value) => {
  switch (field) {
    case 'bedrooms':
    case 'bathrooms':
      return value >= 0 && value <= 10;
    case 'squareFootage':
      return value >= 0;
    case 'propertyTypes':
      return Array.isArray(value);
    case 'status':
      return CONSTANTS.statusOptions.some(option => option.value === value);
    default:
      return true;
  }
};

const CriteriaGroup1 = ({ criteria, onUpdate }) => {
  const handleUpdate = (field, value) => {
    if (validateCriteriaInput(field, value)) {
      onUpdate(field, value);
    }
  };

  const handleNumberInput = (field, value) => {
    const parsedValue = parseInt(value);
    if (!isNaN(parsedValue) && parsedValue >= 0) {
      handleUpdate(field, parsedValue);
    }
  };

  return (
    <VStack align="stretch" spacing={4} height="100%">
      <Box>
        <Text mb={1} color="gray.300" fontSize="sm">Bedrooms</Text>
        <NumberInput
          value={criteria?.bedrooms || 0}
          onChange={(value) => handleNumberInput('bedrooms', value)}
          min={0}
          max={10}
          size="sm"
          sx={{
            '& input:hover': { borderColor: 'var(--chakra-colors-orange-400) !important' },
            '& input:focus': { borderColor: 'var(--chakra-colors-orange-400) !important' }
          }}
        >
          <NumberInputField bg="black" borderColor="orange.400" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Box>
      <Box>
        <Text mb={1} color="gray.300" fontSize="sm">Bathrooms</Text>
        <NumberInput
          value={criteria?.bathrooms || 0}
          onChange={(value) => handleUpdate('bathrooms', parseInt(value))}
          min={0}
          max={10}
          size="sm"
          sx={{
            '& input:hover': { borderColor: 'var(--chakra-colors-orange-400) !important' },
            '& input:focus': { borderColor: 'var(--chakra-colors-orange-400) !important' }
          }}
        >
          <NumberInputField bg="black" borderColor="orange.400" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Box>
      <Box>
        <Text mb={1} color="gray.300" fontSize="sm">Square Footage</Text>
        <NumberInput
          value={criteria?.squareFootage || 0}
          onChange={(value) => handleUpdate('squareFootage', parseInt(value))}
          min={0}
          size="sm"
          sx={{
            '& input:hover': { borderColor: 'var(--chakra-colors-orange-400) !important' },
            '& input:focus': { borderColor: 'var(--chakra-colors-orange-400) !important' }
          }}
        >
          <NumberInputField bg="black" borderColor="orange.400" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Box>
      <PropertyTypeSelector 
        selectedTypes={criteria?.propertyTypes || []}
        onUpdate={(types) => handleUpdate('propertyTypes', types)}
      />
    </VStack>
  );
};

const AdditionalFeatures = ({ criteria, onUpdate }) => {
  const features = [
    { key: 'hasBalcony', label: 'Balcony', icon: FaHome },
    { key: 'hasParking', label: 'Parking', icon: FaCar },
    { key: 'hasLocker', label: 'Locker', icon: FaLock },
    { key: 'isPetFriendly', label: 'Pet Friendly', icon: FaPaw },
  ];

  return (
    <Box mb={4}>
      <Text mb={1} color="gray.300" fontSize="sm">Additional Features</Text>
      <Grid templateColumns="repeat(4, 1fr)" gap={2}>
        {features.map(feature => (
          <Button
            key={feature.key}
            onClick={() => onUpdate(feature.key, !criteria?.[feature.key])}
            colorScheme="orange"
            variant={criteria?.[feature.key] ? 'solid' : 'outline'}
            size="sm"
            width="100%"
            height="32px"
            fontSize="xs"
            leftIcon={<Icon as={feature.icon} boxSize={3} />}
          >
            {feature.label}
          </Button>
        ))}
      </Grid>
    </Box>
  );
};

const CriteriaGroup2 = ({ criteria, onUpdate }) => {
  const debouncedUpdate = useCallback(
    debounce((field, value) => {
      onUpdate(field, value);
    }, 500),
    [onUpdate]
  );

  return (
    <VStack align="stretch" spacing={4} height="100%">
      <Box>
        <Text mb={1} color="gray.300" fontSize="sm">Price Range</Text>
        <Input
          defaultValue={criteria?.priceRange || ''}
          onChange={(e) => debouncedUpdate('priceRange', e.target.value)}
          placeholder="e.g., $300,000 - $500,000"
          bg="black"
          borderColor="orange.400"
          size="sm"
          _hover={{ borderColor: 'orange.400' }}
          _focus={{ borderColor: 'orange.400', boxShadow: 'none' }}
          _placeholder={{ color: 'gray.500' }}
        />
      </Box>
      <Box>
        <Text mb={1} color="gray.300" fontSize="sm">Location</Text>
        <Input
          defaultValue={criteria?.areasServiced || ''}
          onChange={(e) => debouncedUpdate('areasServiced', e.target.value)}
          placeholder="Enter preferred locations"
          bg="black"
          borderColor="orange.400"
          size="sm"
          _placeholder={{ color: 'gray.500' }}
        />
      </Box>
      <AdditionalFeatures criteria={criteria} onUpdate={onUpdate} />
    </VStack>
  );
};

const CriteriaGroup3 = ({ criteria, onUpdate }) => {
  const debouncedUpdate = useCallback(
    debounce((field, value) => {
      onUpdate(field, value);
    }, 500),
    [onUpdate]
  );

  return (
    <VStack align="stretch" spacing={4} height="100%">
      <Box>
        <Text mb={1} color="gray.300" fontSize="sm">Pre-Approval</Text>
        <Input
          defaultValue={criteria?.preApproval || ''}
          onChange={(e) => debouncedUpdate('preApproval', e.target.value)}
          placeholder="Pre-approval details"
          bg="black"
          borderColor="orange.400"
          size="sm"
          _hover={{ borderColor: 'orange.400' }}
          _focus={{ borderColor: 'orange.400', boxShadow: 'none' }}
          _placeholder={{ color: 'gray.500' }}
        />
      </Box>
      <Box>
        <Text mb={1} color="gray.300" fontSize="sm">Credit Score</Text>
        <Input
          defaultValue={criteria?.creditScore || ''}
          onChange={(e) => debouncedUpdate('creditScore', e.target.value)}
          placeholder="Credit score"
          bg="black"
          borderColor="orange.400"
          size="sm"
          _hover={{ borderColor: 'orange.400' }}
          _focus={{ borderColor: 'orange.400', boxShadow: 'none' }}
          _placeholder={{ color: 'gray.500' }}
        />
      </Box>
      <Box>
        <Text mb={1} color="gray.300" fontSize="sm">Lawyer</Text>
        <Input
          defaultValue={criteria?.lawyer || ''}
          onChange={(e) => debouncedUpdate('lawyer', e.target.value)}
          placeholder="Lawyer details"
          bg="black"
          borderColor="orange.400"
          size="sm"
          _hover={{ borderColor: 'orange.400' }}
          _focus={{ borderColor: 'orange.400', boxShadow: 'none' }}
          _placeholder={{ color: 'gray.500' }}
        />
      </Box>
      <Box>
        <Text mb={1} color="gray.300" fontSize="sm">Mortgage Agent</Text>
        <Input
          defaultValue={criteria?.mortgageAgent || ''}
          onChange={(e) => debouncedUpdate('mortgageAgent', e.target.value)}
          placeholder="Mortgage agent details"
          bg="black"
          borderColor="orange.400"
          size="sm"
          _hover={{ borderColor: 'orange.400' }}
          _focus={{ borderColor: 'orange.400', boxShadow: 'none' }}
          _placeholder={{ color: 'gray.500' }}
        />
      </Box>
    </VStack>
  );
};

const LeadProfileSection = ({ lead, onJobUpdate, onStatusChange, onLeadSourceSelect, onCriteriaUpdate }) => {
  const [history, setHistory] = useState({ past: [], present: lead, future: [] });
  const [isSaving, setIsSaving] = useState(false);
  const toast = useToast();

  // Add the applyChanges function
  const applyChanges = async (newState) => {
    try {
      // Update all relevant fields from the new state
      if (newState.job !== lead.job) {
        await onJobUpdate(newState.job);
      }
      if (newState.leadSource !== lead.leadSource) {
        await onLeadSourceSelect(newState.leadSource);
      }
      if (newState.criteria) {
        const criteriaChanges = Object.entries(newState.criteria)
          .filter(([key, value]) => value !== lead.criteria?.[key]);
        
        for (const [field, value] of criteriaChanges) {
          await onCriteriaUpdate(field, value);
        }
      }
    } catch (error) {
      toast({
        title: "Error applying changes",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handle undo/redo
  const handleUndo = () => {
    if (history.past.length === 0) return;
    
    const previous = history.past[history.past.length - 1];
    const newPast = history.past.slice(0, history.past.length - 1);
    
    setHistory({
      past: newPast,
      present: previous,
      future: [history.present, ...history.future],
    });
    
    applyChanges(previous);
  };

  const handleRedo = () => {
    if (history.future.length === 0) return;
    
    const next = history.future[0];
    const newFuture = history.future.slice(1);
    
    setHistory({
      past: [...history.past, history.present],
      present: next,
      future: newFuture,
    });
    
    applyChanges(next);
  };

  // Optimistic update handler
  const handleOptimisticUpdate = async (field, value) => {
    // Save current state to history
    setHistory(prev => ({
      past: [...prev.past, prev.present],
      present: {
        ...prev.present,
        criteria: { ...prev.present.criteria, [field]: value }
      },
      future: []
    }));

    setIsSaving(true);
    try {
      // Optimistically update the UI
      const optimisticLead = {
        ...lead,
        criteria: { ...lead.criteria, [field]: value }
      };
      
      // Actual API call
      await onCriteriaUpdate(field, value);
      
      toast({
        title: "Changes saved",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      // Revert on error
      handleUndo();
      toast({
        title: "Error saving changes",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSaving(false);
    }
  };

  // Add error handling
  const handleCriteriaUpdate = async (field, value) => {
    try {
      await onCriteriaUpdate(field, value);
    } catch (error) {
      console.error('Error updating criteria:', error);
      // You might want to add toast notification here
    }
  };

  // Add loading state if needed
  if (!lead) {
    return (
      <Box p={4}>
        <Text>Loading lead information...</Text>
      </Box>
    );
  }

  return (
    <Box 
      borderRadius="md" 
      borderColor="orange.400" 
      borderWidth="none"
    >
      <HStack justify="space-between" mb={4}>
        <HistoryTracker
          canUndo={history.past.length > 0}
          canRedo={history.future.length > 0}
          onUndo={handleUndo}
          onRedo={handleRedo}
        />
        {isSaving && (
          <HStack>
            <Spinner size="xs" color="orange.400" />
            <Text fontSize="sm" color="gray.400">Saving changes...</Text>
          </HStack>
        )}
      </HStack>
      
      <Grid 
        templateColumns={{ base: "1fr", sm: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }}
        gap={6}
        mb={6}
        alignItems="start"
      >
        {/* Basic Info Column */}
        <Box height="100%" position="relative">
          <Text 
            fontSize="lg" 
            fontWeight="bold" 
            mb={4} 
            color="orange.400"
            borderBottom="2px solid"
            borderColor="orange.400"
            pb={2}
            position="sticky"
            top={0}
            bg="black"
            zIndex={1}
          >
            Basic Info
          </Text>
          <BasicInfoSection 
            lead={lead} 
            onJobUpdate={onJobUpdate} 
            onStatusChange={onStatusChange}
            onLeadSourceSelect={onLeadSourceSelect}
          />
        </Box>

        {/* 2. Personal Info */}
        <Box height="100%" position="relative">
          <Text 
            fontSize="lg" 
            fontWeight="bold" 
            mb={4} 
            color="orange.400"
            borderBottom="2px solid"
            borderColor="orange.400"
            pb={2}
            position="sticky"
            top={0}
            bg="black"
            zIndex={1}
          >
            Personal Info
          </Text>
          <CriteriaGroup3 criteria={lead?.criteria} onUpdate={onCriteriaUpdate} />
        </Box>

        {/* 3. Property Details */}
        <Box height="100%" position="relative">
          <Text 
            fontSize="lg" 
            fontWeight="bold" 
            mb={4} 
            color="orange.400"
            borderBottom="2px solid"
            borderColor="orange.400"
            pb={2}
            position="sticky"
            top={0}
            bg="black"
            zIndex={1}
          >
            Property Details
          </Text>
          <VStack spacing={4} align="stretch" height="100%">
            <CriteriaGroup1 criteria={lead?.criteria} onUpdate={onCriteriaUpdate} />
          </VStack>
        </Box>

        {/* 4. Requirements */}
        <Box height="100%" position="relative">
          <Text 
            fontSize="lg" 
            fontWeight="bold" 
            mb={4} 
            color="orange.400"
            borderBottom="2px solid"
            borderColor="orange.400"
            pb={2}
            position="sticky"
            top={0}
            bg="black"
            zIndex={1}
          >
            Requirements
          </Text>
          <CriteriaGroup2 criteria={lead?.criteria} onUpdate={onCriteriaUpdate} />
        </Box>
      </Grid>
    </Box>
  );
};

LeadProfileSection.propTypes = {
  lead: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    job: PropTypes.string,
    leadSource: PropTypes.string,
    criteria: PropTypes.shape({
      status: PropTypes.string,
      bedrooms: PropTypes.number,
      bathrooms: PropTypes.number,
      squareFootage: PropTypes.number,
      propertyTypes: PropTypes.arrayOf(PropTypes.string),
      priceRange: PropTypes.string,
      areasServiced: PropTypes.string,
      hasBalcony: PropTypes.bool,
      hasParking: PropTypes.bool,
      hasLocker: PropTypes.bool,
      isPetFriendly: PropTypes.bool,
      preApproval: PropTypes.string,
      creditScore: PropTypes.string,
      lawyer: PropTypes.string,
      mortgageAgent: PropTypes.string
    })
  }).isRequired,
  onJobUpdate: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onLeadSourceSelect: PropTypes.func.isRequired,
  onCriteriaUpdate: PropTypes.func.isRequired
};

export default LeadProfileSection; 