import React from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  Icon,
  Stack,
} from '@chakra-ui/react';
import { FaRocket, FaUserCheck, FaChartLine, FaClock } from 'react-icons/fa';

const steps = [
  {
    icon: FaRocket,
    title: 'Get Started Instantly',
    description: 'Sign up and connect your communication channels in minutes.'
  },
  {
    icon: FaUserCheck,
    title: 'AI-Powered Support',
    description: 'Let our AI assist you during calls and follow-ups.'
  },
  {
    icon: FaChartLine,
    title: 'Track Your Success',
    description: 'Monitor your performance with detailed analytics.'
  },
  {
    icon: FaClock,
    title: 'Save Time & Close More',
    description: 'Automate repetitive tasks and focus on closing deals.'
  }
];

const HowItWorksSection = () => {
  return (
    <Box position="relative" py={20}>
      <Container maxW="100%">
        <Stack spacing={8} align="center" textAlign="center">
          <Heading
            fontSize={{ base: '2xl', md: '4xl' }}
            className="gradient-text"
          >
            How It Works
          </Heading>
          
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10} mt={10}>
            {steps.map((step, index) => (
              <Box
                key={index}
                p={6}
                borderRadius="xl"
                bg="linear-gradient(145deg, rgba(0,0,0,0.8), rgba(0,0,0,0.6))"
                borderColor="yellow.500"
                borderWidth="1px"
                _hover={{
                  transform: 'translateY(-5px)',
                  boxShadow: '0 10px 30px rgba(255, 165, 0, 0.15)'
                }}
                transition="all 0.3s ease"
              >
                <Stack spacing={4} align="center">
                  <Icon
                    as={step.icon}
                    w={10}
                    h={10}
                    color="yellow.400"
                  />
                  <Text
                    fontWeight="bold"
                    fontSize="xl"
                    color="yellow.400"
                  >
                    {step.title}
                  </Text>
                  <Text color="gray.300">
                    {step.description}
                  </Text>
                </Stack>
              </Box>
            ))}
          </SimpleGrid>
        </Stack>
      </Container>
    </Box>
  );
};

export default HowItWorksSection; 