import React from 'react';
import { Box, Heading, Container, AspectRatio } from '@chakra-ui/react';

const VideoSection = () => {
  return (
    <Box bg="gray.900" color="white" py={16}>
      <Container maxW="800px" mx="auto" textAlign="center">
        <Heading mb={8} className="gradient-text">
          See Real Talk Pro in Action
        </Heading>
        <AspectRatio ratio={16 / 9}>
          <iframe
            title="Real Talk Pro Demo"
            src="https://www.youtube.com/embed/your-video-id"
            allowFullScreen
          />
        </AspectRatio>
      </Container>
    </Box>
  );
};

export default VideoSection; 