import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Box,
  HStack,
  Progress,
  useColorModeValue,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

const tourSteps = [
  {
    title: "Welcome to Real•Talk•Pro! 👋",
    content: "Let's take a quick tour of your new professional communication hub.",
  },
  {
    title: "Dashboard Overview 📊",
    content: "Your dashboard shows key metrics and recent activity at a glance.",
  },
  {
    title: "Quick Actions ⚡",
    content: "Access common tasks like managing leads and starting calls with one click.",
  },
  {
    title: "Recent Activity 🔄",
    content: "Stay updated with your latest interactions and updates.",
  },
  {
    title: "You're All Set! 🎉",
    content: "Start exploring Real•Talk•Pro and boost your communication efficiency!",
  },
];

const WelcomeTour = ({ isOpen, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [hasSeenTour, setHasSeenTour] = useState(false);
  
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const accentColor = useColorModeValue('gold.500', 'yellow.400');

  useEffect(() => {
    const tourSeen = localStorage.getItem('welcomeTourSeen');
    setHasSeenTour(!!tourSeen);
  }, []);

  const handleClose = () => {
    localStorage.setItem('welcomeTourSeen', 'true');
    onClose();
  };

  const handleNext = () => {
    if (currentStep < tourSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      handleClose();
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const MotionBox = motion(Box);

  return (
    <Modal 
      isOpen={isOpen && !hasSeenTour} 
      onClose={handleClose}
      isCentered
      size="lg"
    >
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent
        bg={bgColor}
        borderRadius="xl"
        boxShadow="xl"
        border="1px solid"
        borderColor={accentColor}
      >
        <ModalHeader 
          borderBottom="1px solid"
          borderColor={accentColor}
          color={textColor}
        >
          {tourSteps[currentStep].title}
        </ModalHeader>
        
        <ModalBody py={6}>
          <AnimatePresence mode="wait">
            <MotionBox
              key={currentStep}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
            >
              <Text color={textColor} fontSize="lg">
                {tourSteps[currentStep].content}
              </Text>
            </MotionBox>
          </AnimatePresence>
          
          <Progress
            value={(currentStep + 1) * (100 / tourSteps.length)}
            size="sm"
            colorScheme="yellow"
            mt={6}
            borderRadius="full"
          />
        </ModalBody>

        <ModalFooter borderTop="1px solid" borderColor={accentColor}>
          <HStack spacing={4}>
            <Button
              variant="ghost"
              onClick={handleClose}
              color={textColor}
            >
              Skip Tour
            </Button>
            <Button
              variant="outline"
              onClick={handlePrev}
              isDisabled={currentStep === 0}
              borderColor={accentColor}
              color={textColor}
            >
              Previous
            </Button>
            <Button
              colorScheme="yellow"
              onClick={handleNext}
            >
              {currentStep === tourSteps.length - 1 ? "Finish" : "Next"}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WelcomeTour; 