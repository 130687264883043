// src/pages/Dashboard.js
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import {
  Box,
  Heading,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Text,
  Flex,
  Icon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Badge,
  VStack,
  HStack,
  Divider,
  Select,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, 
  Spinner,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import {
  FaArrowUp,
  FaArrowDown,
  FaPhone,
  FaEnvelope,
  FaSms,
  FaTasks,
  FaGlobe,
  FaClipboardList,
  FaHome,
  FaHistory,
  FaUserFriends,
  FaRedoAlt,
  FaExclamationTriangle,
} from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DuplicateLeadsModal from '../components/DuplicateLeadsModal';
import debounce from 'lodash.debounce';
import { TwilioContext } from '../context/TwilioContext';
import StatisticsSection from '../components/dashboard/StatisticsSection';
import AddNewLeadSection from '../components/dashboard/AddNewLeadSection';
import ActiveLeadsSection from '../components/dashboard/ActiveLeadsSection';
import TaskOverviewSection from '../components/dashboard/TaskOverviewSection';
import AlertsNotificationsSection from '../components/dashboard/AlertsNotificationsSection';
import OpportunitiesDealsSection from '../components/dashboard/OpportunitiesDealsSection';
import AppFeaturesToAdd from '../components/AppFeaturesToAdd';
import ErrorBoundary from '../components/ErrorBoundary';
import TextRenderingDemo from '../components/dashboard/TextRenderingDemo';
import { useTheme } from '../context/ThemeContext';

/**
 * Dashboard Component
 * Main dashboard interface displaying various sections for lead management and business metrics
 * Implements responsive design for mobile, tablet, and desktop views
 */
const Dashboard = () => {
  const { currentTheme, isDark, showBorders } = useTheme();

  return (
    // Main container with responsive padding and custom styling
    <Box
      p={{ base: 4, md: 6, lg: 8 }}
      pt={{ base: "70px", md: "84px" }} // Increased top padding to account for Navbar
      w="100%"
      minH="100vh" // Ensure full viewport height
      bg={isDark 
        ? 'primaryBg' 
        : currentTheme.colors.gradients.dashboard
      }
      color={currentTheme.colors.textColor}
      backgroundAttachment="fixed" // Keeps gradient fixed while scrolling
    >
      {/* Content wrapper with max width for larger screens */}
      <Box maxW="1400px" mx="auto">
        {/* Main heading with gradient effect */}
        <Heading
          mb={{ base: 4, md: 6 }}
          fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
          fontWeight="bold"
          color={currentTheme.colors.heading}
          className={isDark ? "gradient-text-dark" : "gradient-text-light"}
          textAlign="center"
        >
          Let's Make Money Bastani!
        </Heading>

        {/* Update Divider colors */}
        <Divider 
          my={{ base: 6, md: 8 }} 
          borderColor={currentTheme.colors.divider}
        />

        {/* Main content layout container */}
        <Flex direction="column" gap={{ base: 4, md: 6 }}>
          {/* Primary action sections - full width on all screen sizes */}
          <Box variant="dashboard-section">
            <AddNewLeadSection theme={currentTheme} />
          </Box>
          <Box variant="dashboard-section">
            <ActiveLeadsSection theme={currentTheme} />
          </Box>

          {/* Responsive two-column layout - stacks on mobile, side-by-side on desktop */}
          <Flex direction={{ base: 'column', lg: 'row' }} gap={{ base: 4, md: 6 }}>
            {/* Left column - Tasks overview (wider) */}
            <Box 
              flex={{ lg: 2 }} 
              width="100%"
              bg={currentTheme.colors.sectionBg}
              borderRadius="lg"
              p={4}
            >
              <TaskOverviewSection theme={currentTheme} />
            </Box>

            {/* Right column - Alerts and Opportunities (narrower) */}
            <Box 
              flex={{ lg: 1 }} 
              width="100%"
            >
              <VStack spacing={{ base: 4, md: 6 }} align="stretch">
                <AlertsNotificationsSection theme={currentTheme} />
                <OpportunitiesDealsSection theme={currentTheme} />
              </VStack>
            </Box>
          </Flex>

          {/* Add TextRenderingDemo here, inside the main Flex container */}
          <ErrorBoundary fallback={
            <Spinner color={currentTheme.colors.spinner} />
          }>
            <TextRenderingDemo theme={currentTheme} />
          </ErrorBoundary>
        </Flex>

        {/* Update Divider colors */}
        <Divider 
          my={{ base: 6, md: 8 }} 
          borderColor={currentTheme.colors.divider}
        />

        {/* Statistics section with error handling */}
        <ErrorBoundary fallback={
          <Spinner color={currentTheme.colors.spinner} />
        }>
          <StatisticsSection theme={currentTheme} />
        </ErrorBoundary>

        {/* Update Divider colors */}
        <Divider 
          my={{ base: 6, md: 8 }} 
          borderColor={currentTheme.colors.divider}
        />

        {/* Text Rendering Demo section */}
        <ErrorBoundary fallback={
          <Spinner color={currentTheme.colors.spinner} />
        }>
          <TextRenderingDemo theme={currentTheme} />
        </ErrorBoundary>

        {/* Future features and improvements section */}
        <AppFeaturesToAdd theme={currentTheme} />
      </Box>
    </Box>
  );
};

export default Dashboard;

/* 
 * === Component Structure ===
 * The dashboard is organized in a hierarchical layout:
 * 1. Main container with responsive padding
 * 2. Content wrapper with max width
 * 3. Heading section
 * 4. Main content area with:
 *    - Full-width sections (AddNewLead, ActiveLeads)
 *    - Two-column layout (Tasks | Alerts & Opportunities)
 * 5. Statistics section
 * 6. Future features section
 *
 * === Responsive Design ===
 * - Mobile: Single column layout with reduced padding
 * - Tablet: Enhanced spacing and font sizes
 * - Desktop: Two-column layout for better space utilization
 *
 * === Future Improvements ===
 * - Consider adding a collapsible menu for mobile view
 * - Implement skeleton loading states for better UX
 * - Add data caching for performance optimization
 * - Consider removing border/shadow effects on mobile
 * - Add pull-to-refresh functionality for mobile users
 */
