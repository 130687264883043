import React from 'react';
import { Box, Image, Container, Stack, Heading, Text } from '@chakra-ui/react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper core and required modules
import SwiperCore from 'swiper';
import { Autoplay, FreeMode } from 'swiper/modules';
// Import Swiper styles
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';

// Register Swiper modules
SwiperCore.use([Autoplay, FreeMode]);

const partnerLogos = [
  // New company logos
  {
    id: 1,
    src: "https://cdn.prod.website-files.com/6452fc5f71b71358e5f0d512/649ab2663c2752b43239ea21_the%20agency%20texas.svg",
    alt: "The Agency Texas"
  },
  {
    id: 2,
    src: "https://cdn.prod.website-files.com/6452fc5f71b71358e5f0d512/649ab25780b2e8dad1affbd0_robertslack.svg",
    alt: "Robert Slack"
  },
  {
    id: 3,
    src: "https://cdn.prod.website-files.com/6452fc5f71b71358e5f0d512/649d93b24d4c36c14d3ad2c6_Group%201171276790.svg",
    alt: "Group 1171276790"
  },
  {
    id: 4,
    src: "https://cdn.prod.website-files.com/6452fc5f71b71358e5f0d512/649ab23e407c579218f33b86_bigblock%20realty.svg",
    alt: "BigBlock Realty"
  },
  {
    id: 5,
    src: "https://cdn.prod.website-files.com/6452fc5f71b71358e5f0d512/649ab2301b3f375a9c9e4308_barry%20jenkins.svg",
    alt: "Barry Jenkins"
  },
  {
    id: 6,
    src: "https://cdn.prod.website-files.com/6452fc5f71b71358e5f0d512/649ab22395ff8f60ae6035d7_ashton.svg",
    alt: "Ashton"
  },
  // Existing Brad Lamb logos
  {
    id: 1,
    src: "https://cdn.prod.website-files.com/656e028d620a64f37be375a6/656e9286a27d8d81101b26a3_bradlamb%20(2).png",
    alt: "Brad Lamb 2"
  },
  {
    id: 2,
    src: "https://cdn.prod.website-files.com/656e028d620a64f37be375a6/656f78fd76153a9b5ce7e5b0_bradlamb%20(8).png",
    alt: "Brad Lamb 8"
  },
  {
    id: 3,
    src: "https://cdn.prod.website-files.com/656e028d620a64f37be375a6/656e92869a06d8003ac52653_bradlamb.png",
    alt: "Brad Lamb"
  },
  {
    id: 4,
    src: "https://cdn.prod.website-files.com/656e028d620a64f37be375a6/656e9286fbfd7b9601bd933a_bradlamb%20(5).png",
    alt: "Brad Lamb 5"
  },
  {
    id: 5,
    src: "https://cdn.prod.website-files.com/656e028d620a64f37be375a6/656e9286b2a89d45b45c4002_bradlamb%20(6).png",
    alt: "Brad Lamb 6"
  },
  {
    id: 6,
    src: "https://cdn.prod.website-files.com/656e028d620a64f37be375a6/656f7d30ea6cc146f1902d49_bradlamb%20(14).png",
    alt: "Brad Lamb 14"
  },
  {
    id: 7,
    src: "https://cdn.prod.website-files.com/656e028d620a64f37be375a6/656e9286c8daab5cf055d5bd_bradlamb%20(4).png",
    alt: "Brad Lamb 4"
  },
  {
    id: 8,
    src: "https://cdn.prod.website-files.com/656e028d620a64f37be375a6/656e92867573bb31539c898a_bradlamb%20(1).png",
    alt: "Brad Lamb 1"
  },
  {
    id: 9,
    src: "https://cdn.prod.website-files.com/656e028d620a64f37be375a6/656e92868ccb2855ef837d1d_bradlamb%20(3).png",
    alt: "Brad Lamb 3"
  },
  {
    id: 10,
    src: "https://cdn.prod.website-files.com/656e028d620a64f37be375a6/656f7b422371e0b56ef8a0e1_bradlamb%20(12).png",
    alt: "Brad Lamb 12"
  },
  {
    id: 11,
    src: "https://cdn.prod.website-files.com/656e028d620a64f37be375a6/656f7b506fb8b9cf508f2092_bradlamb%20(11).png",
    alt: "Brad Lamb 11"
  }
];

const PartnerLogoCarousel = () => {
  return (
    <Box 
      width="100%" 
      position="relative" 
      overflow="hidden"
    >
      <Stack spacing={8} align="center" textAlign="center" mb={12}>
        <Heading
          fontSize={{ base: '2xl', md: '4xl' }}
          className="gradient-text"
          textAlign="center"
        >
          Trusted By Leading Agencies
        </Heading>
        <Text 
          fontSize={{ base: 'lg', md: 'xl' }}
          color="gray.400"
          maxW="800px"
        >
          Join the growing network of successful real estate professionals using Real Talk Pro
        </Text>
      </Stack>

      <Box
        py={8}
        bg="rgba(0, 0, 0, 0.3)"
        borderColor="yellow.500"
        width="100%"
      >
        <Box className="partner-carousel">
          <Swiper
            modules={[Autoplay, FreeMode]}
            slidesPerView="auto"
            spaceBetween={40}
            freeMode={true}
            loop={true}
            speed={11000}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            className="partner-swiper"
          >
            {[...partnerLogos, ...partnerLogos].map((logo, index) => (
              <SwiperSlide
                key={`${logo.id}-${index}`}
                style={{
                  width: 'auto',
                  height: '60px'
                }}
              >
                <Image
                  src={logo.src}
                  alt={logo.alt}
                  h="full"
                  filter="brightness(0) invert(1)"
                  opacity={0.6}
                  _hover={{ opacity: 1 }}
                  transition="opacity 0.3s"
                  objectFit="contain"
                  maxW="200px"
                  style={{
                    filter: logo.src.includes('.svg') ? 'brightness(0) invert(1)' : 'brightness(0) invert(1)'
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Box>
  );
};

export default PartnerLogoCarousel; 