import React from 'react';
import { Box, VStack, Text, Button } from '@chakra-ui/react';

const TasksBox = ({ tasks = [], leadId }) => {
  return (
    <Box bg="surfaceBg" p={4} borderRadius="md" boxShadow="md">
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        Tasks
      </Text>
      <VStack align="stretch" spacing={4}>
        {Array.isArray(tasks) && tasks.length > 0 ? (
          tasks.map((task, index) => (
            <Box key={index} p={2} bg="gray.100" borderRadius="md">
              <Text>{task.description}</Text>
              <Text fontSize="sm" color="gray.500">
                Due: {new Date(task.dueDate).toLocaleDateString()}
              </Text>
            </Box>
          ))
        ) : (
          <Text>No tasks available.</Text>
        )}
      </VStack>
      <Button mt={4} colorScheme="blue">
        Add Task
      </Button>
    </Box>
  );
};

export default TasksBox;