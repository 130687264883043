import React from 'react';
import { VStack, Box, Text, Heading, HStack, Button } from '@chakra-ui/react';

const CloseOptionB = ({ leadData, leadGoal, setLeadGoal }) => {
  const platinumValuePropositions = {
    'First-time Home Buyer': (
      <>
        <Text fontWeight="bold" color="white">Given your situation as a first-time buyer, the next step in the process is a first-time home buyer strategy session.</Text>
        <Text color="white" mt={2}>So that we can carefully assess your needs and preferences, guide you through the complexities of the market, and identify opportunities that match your criteria and budget.</Text>
        <Text color="white" mt={2}>So that you can feel secure and well-informed as you make one of the most important purchases of your life, ensuring that your first home is a perfect match for your lifestyle and future plans.</Text>
        <Text color="white" mt={2}>Our Buyer Assistance Program has successfully guided numerous first-time buyers, helping them navigate the market efficiently and effectively, often securing properties at competitive prices that meet or exceed their expectations. This process is designed to make your first home-buying experience as smooth and successful as possible.</Text>
      </>
    ),
    'Generic Buyer': (
      <>
        <Text color="white">What we usually do with most clients that are looking to move into their new home in the next 6 months is to set up a quick 30 minute Zoom which we call a Strategy Call.</Text>
        <Text color="white" mt={2}>What I'll do on this call is walk you through the entire buying process in an easy to understand step-by-step fashion. I'll share my screen with you and show you what happens every step of the way from the first zoom call to actually getting the keys. Some of the key things for you specifically, will be _____, ______, _____.</Text>
      </>
    ),
    'Seller': (
      <>
        <Text fontWeight="bold" color="white">Given your situation as a first-time seller, the next step in the process is a home selling strategy session.</Text>
        <Text color="white" mt={2}>So that we can analyze the market and put together a plan to maximize the sale price for your current home and show you projects you can start on right away that will improve the value of your home.</Text>
        <Text color="white" mt={2}>So that you can feel confident that you are going to get the maximum equity out of your home to put into your next dream home.</Text>
        <Text color="white" mt={2}>Our Listing Strategies have helped many of our other clients who were selling for the first time get on average 17% more for their homes than the market average. This is a testament to our tailored approach and our commitment to ensuring you receive top dollar for your investment.</Text>
      </>
    ),
    'Renter': (
      <Text fontWeight="bold" color="white">2 secret words, F**K O**!</Text>
    )
  };

  return (
    <VStack align="stretch" spacing={4}>
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text color="white">
          Ok {leadData.name || '[name]'}, this is awesome information, I really appreciate you taking my call.
        </Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Heading size="md" color="yellow.500" mb={2}>Lead's Goal</Heading>
        <HStack spacing={2}>
          <Button onClick={() => setLeadGoal('First-time Home Buyer')} colorScheme={leadGoal === 'First-time Home Buyer' ? 'yellow' : 'gray'}>First-time Home Buyer</Button>
          <Button onClick={() => setLeadGoal('Generic Buyer')} colorScheme={leadGoal === 'Generic Buyer' ? 'yellow' : 'gray'}>Generic Buyer</Button>
          <Button onClick={() => setLeadGoal('Seller')} colorScheme={leadGoal === 'Seller' ? 'yellow' : 'gray'}>Seller</Button>
          <Button onClick={() => setLeadGoal('Renter')} colorScheme={leadGoal === 'Renter' ? 'yellow' : 'gray'}>Renter</Button>
        </HStack>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Heading size="md" color="yellow.500" mb={2}>Platinum Value Proposition</Heading>
        {platinumValuePropositions[leadGoal]}
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text color="white">
          There is no obligation to work with me/ our team, the call is strictly to provide value and
          education to you. If you feel that we might be the right fit to help you, and if we feel you might be
          the right fit for our client roster we can make a decision after that call and set up a property tour
          with you but again, no pressure.
        </Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">
          When would be a good time to book that in?
        </Text>
      </Box>
    </VStack>
  );
};

export default CloseOptionB;