import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import axios from 'axios';
import { API_BASE_URL } from './config';

// Set the base URL for Axios
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || API_BASE_URL;

// Create root with error handling
const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);

// Render the App component wrapped in StrictMode
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
