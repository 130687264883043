import React, { useState, useMemo, useEffect } from 'react';
import { 
  Box, 
  VStack, 
  Text, 
  Icon, 
  Flex, 
  Button, 
  Accordion, 
  AccordionItem, 
  AccordionButton, 
  AccordionPanel, 
  AccordionIcon, 
  useColorMode, 
  useColorModeValue,
  Spinner,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure
} from '@chakra-ui/react';
import { 
  MdSms, 
  MdNote, 
  MdCallMade, 
  MdCallReceived, 
  MdBrightness4, 
  MdBrightness7, 
  MdRefresh 
} from 'react-icons/md';
import { format, isValid, parseISO } from 'date-fns';
import { motion } from 'framer-motion';

/*
TODO: Future improvements
- Add animations when new items are loaded
- Implement infinite scroll instead of Load More button
- Add color coding for different status types
- Add tooltips for additional information
- Implement collapsible sections for long content
- Add skeleton loading state while data is being fetched
- Add error states with better visual feedback
- Implement dark/light theme support
- Optimize performance for large datasets
- Add unit and integration tests
*/

const MotionBox = motion(Box);

const LeadTimeline = ({ timelineItems, preventAutoScroll = false }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue('gray.100', 'black');
  const textColor = useColorModeValue('gray.800', 'white');
  const cardBg = useColorModeValue('white', 'black');
  const messageBg = useColorModeValue('gray.100', 'black');
  const [displayCount, setDisplayCount] = useState(10);
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.300');
  const borderColorValue = useColorModeValue('gray.300', 'gray.600');

  // Remove console.log statements in production
  // console.log('Timeline Items received:', timelineItems);

  // Memoize getIcon function to prevent unnecessary recreations
  const getIcon = useMemo(() => (type, direction) => {
    switch (type) {
      case 'Call':
        return direction?.toLowerCase().includes('in') ? MdCallReceived : MdCallMade;
      case 'SMS':
        return MdSms;
      case 'Note':
      default:
        return MdNote;
    }
  }, []);

  const formatDate = (dateInput) => {
    try {
      if (!dateInput) return 'Unknown Date';

      let date;
      if (typeof dateInput === 'string') {
        date = parseISO(dateInput);
      } else if (dateInput instanceof Date) {
        date = dateInput;
      } else {
        throw new Error(`Invalid date input type: ${typeof dateInput}`);
      }

      if (!isValid(date)) {
        throw new Error('Invalid date object');
      }

      return format(date, 'PPPp');
    } catch (err) {
      console.error('Date formatting error:', err);
      return 'Invalid Date';
    }
  };

  // Optimize sorting by moving date parsing logic to a memoized helper
  const getItemDate = useMemo(() => (item) => {
    return item.timestamp ? new Date(item.timestamp) : 
           item.createdAt ? new Date(item.createdAt) : null;
  }, []);

  const sortedTimelineItems = useMemo(() => {
    return [...timelineItems].sort((a, b) => {
      const dateA = getItemDate(a);
      const dateB = getItemDate(b);

      if (!dateA && !dateB) return 0;
      if (!dateA) return 1;
      if (!dateB) return -1;

      return dateB.getTime() - dateA.getTime();
    });
  }, [timelineItems, getItemDate]);

  // console.log('Sorted Timeline Items:', sortedTimelineItems);

  const displayedItems = sortedTimelineItems.slice(0, displayCount);

  const loadMore = () => {
    setDisplayCount(prevCount => prevCount + 10);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTranscript, setSelectedTranscript] = useState(null);

  const renderTranscriptPreview = (transcript) => {
    if (!transcript || (Array.isArray(transcript) && transcript.length === 0)) {
      return <Text color={secondaryTextColor}>Processing transcript...</Text>;
    }

    // Handle different transcript formats
    let previewText = '';
    if (Array.isArray(transcript)) {
      // If transcript is an array of segments
      previewText = transcript
        .slice(0, 2)
        .map(segment => `${segment.speaker}: ${segment.text}`)
        .join('\n');
    } else if (typeof transcript === 'object' && transcript.displayText) {
      // If transcript is an object with displayText
      previewText = transcript.displayText.slice(0, 200);
    } else if (typeof transcript === 'object' && transcript.rawText) {
      // If transcript is an object with rawText
      previewText = transcript.rawText.slice(0, 200);
    } else if (typeof transcript === 'string') {
      // If transcript is a plain string
      previewText = transcript.slice(0, 200);
    } else {
      return <Text color={secondaryTextColor}>Invalid transcript format</Text>;
    }

    return (
      <Box>
        <Button
          onClick={() => {
            setSelectedTranscript(transcript);
            onOpen();
          }}
          colorScheme="orange"
          variant="outline"
          size="sm"
          mt={2}
        >
          View Full Transcript
        </Button>
        
        <Box mt={2}>
          <Text color={secondaryTextColor} fontSize="sm">
            {previewText}
            {previewText.length >= 200 && '...'}
          </Text>
        </Box>
      </Box>
    );
  };

  const transcriptBg = useColorModeValue('gray.50', 'gray.700');
  const transcriptTextColor = useColorModeValue('gray.700', 'gray.100');

  const TranscriptDrawer = () => {
    const formatTranscriptContent = (transcript) => {
      if (!transcript) return [];
      
      // If transcript is already an array of segments
      if (Array.isArray(transcript)) {
        return transcript;
      }
      
      // If transcript is a string, split by speaker markers
      if (typeof transcript === 'string') {
        const segments = transcript.split(/SPEAKER_\d+:/).filter(Boolean);
        return segments.map((text, index) => ({
          start: index,
          speaker: `Speaker ${index + 1}`,
          text: text.trim()
        }));
      }
      
      // If transcript is an object with displayText or rawText
      if (typeof transcript === 'object') {
        const text = transcript.displayText || transcript.rawText;
        if (text) {
          // Split the text by speaker markers and create segments
          const segments = text.split(/SPEAKER_\d+:/).filter(Boolean);
          return segments.map((text, index) => ({
            start: index,
            speaker: `Speaker ${index + 1}`,
            text: text.trim()
          }));
        }
      }
      
      return [];
    };

    const transcriptContent = formatTranscriptContent(selectedTranscript);

    return (
      <Drawer isOpen={isOpen} onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Call Transcript</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              {transcriptContent.map((segment, index) => (
                <Box
                  key={index}
                  p={4}
                  bg={transcriptBg}
                  borderRadius="md"
                  borderLeft="4px solid"
                  borderLeftColor="orange.400"
                >
                  <Text fontWeight="bold" color="orange.400" mb={2}>
                    {segment.speaker}
                  </Text>
                  <Text color={transcriptTextColor}>
                    {segment.text}
                  </Text>
                </Box>
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  };

  const formatTimestamp = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${padZero(mins)}:${padZero(secs)}`;
  };

  const padZero = (num) => {
    return num < 10 ? `0${num}` : num.toString();
  };

  // Add error state
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Add aria labels and error handling to audio player
  const renderAudioPlayer = (recordingUrl) => (
    <Box mt={2}>
      <audio 
        controls
        aria-label="Call Recording"
        onError={(e) => {
          console.error('Audio playback error:', e);
          setError('Failed to load audio');
        }}
        style={{ width: '100%' }}
      >
        <source src={recordingUrl} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      {error && <Text color="red.400">Audio playback is not supported or failed to load</Text>}
    </Box>
  );

  // Add this before the return statement to debug timeline items
  useEffect(() => {
    // console.log('Timeline Items:', timelineItems.map(item => ({
    //   type: item.type,
    //   direction: item.direction,
    //   message: item.message || item.content,
    //   from: item.from,
    //   to: item.to
    // })));
    if (timelineItems) {
      setLoading(false);
    }
  }, [timelineItems]);

  // Add useEffect to set initial dark mode
  useEffect(() => {
    if (colorMode === 'light') {
      toggleColorMode();
    }
  }, []); // Empty dependency array means this runs once on mount

  // Add this useEffect to prevent auto-scrolling
  useEffect(() => {
    if (preventAutoScroll) {
      window.scrollTo(0, 0);
    }
  }, [preventAutoScroll, timelineItems]);

  const [processingCallId, setProcessingCallId] = useState(null);
  const [processingError, setProcessingError] = useState(null);

  const handleRetryProcessing = async (callSid) => {
    try {
      setProcessingCallId(callSid);
      setProcessingError(null);
      
      // First check if recording exists in S3
      const checkResponse = await fetch(`/api/twilio/calls/${callSid}/recording-status`);
      if (!checkResponse.ok) {
        throw new Error('Failed to check recording status');
      }
      
      const { hasS3Recording } = await checkResponse.json();
      
      if (!hasS3Recording) {
        // If no S3 recording, first upload to S3
        const uploadResponse = await fetch(`/api/twilio/calls/${callSid}/upload-recording`, {
          method: 'POST'
        });
        if (!uploadResponse.ok) {
          throw new Error('Failed to upload recording to S3');
        }
      }

      // Now process the call
      const response = await fetch(`/api/twilio/process-call/${callSid}`, {
        method: 'POST',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to process call');
      }

      // Refresh the component data
      window.location.reload();
    } catch (error) {
      console.error('Error processing call:', error);
      setProcessingError(error.message || 'Failed to process call. Please try again.');
    } finally {
      setProcessingCallId(null);
    }
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100%">
        <Spinner size="xl" color="orange.400" />
      </Flex>
    );
  }

  return (
    <VStack 
      spacing={6}
      align="stretch"
      p={5}
      bg={bgColor}
      borderRadius="0"
      boxShadow="md"
      role="feed"
      aria-label="Timeline"
      style={{ overflow: 'hidden' }}
    >

      {error && (
        <Box p={4} bg="red.600" color="white" borderRadius="md">
          {error}
        </Box>
      )}

      {displayedItems.map((item, index) => (
        <MotionBox
          key={`${item.type}-${item.timestamp || item.createdAt}-${index}`}
          role="article"
          aria-label={`${item.type} entry from ${formatDate(item.timestamp || item.createdAt)}`}
          p={6}
          bg={cardBg}
          borderRadius="0"
          border="1px solid"
          borderColor="orange.300"
          mx={-5}
          _hover={{ 
            borderColor: "orange.400",
            transform: "translateY(-4px)",
            boxShadow: "lg"
          }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ 
            duration: 0.5,
            ease: "easeOut"
          }}
        >
          <Flex align="center" mb={4}>
            <Icon 
              as={getIcon(item.type, item.direction)} 
              color="orange.400" 
              boxSize={6}
              mr={4} 
            />
            <Text color={textColor} fontWeight="bold" fontSize="xl">
              {item.type} {item.direction && `(${item.direction === 'incoming' ? 'Incoming' : 'Outgoing'})`}
            </Text>
          </Flex>
          <Text color="gray.500" fontSize="md" mb={4}>
            {formatDate(item.timestamp || item.createdAt)}
          </Text>

          {item.type === 'Call' && (
            <VStack align="stretch" spacing={4}>
              <Flex justify="space-between">
                <Text color={secondaryTextColor}>Status:</Text>
                <Text color={textColor} fontWeight="medium">{item.status || 'Unknown'}</Text>
              </Flex>
              <Flex justify="space-between">
                <Text color={secondaryTextColor}>From:</Text>
                <Text color={textColor} fontWeight="medium">{item.from || 'Unknown'}</Text>
              </Flex>
              <Flex justify="space-between">
                <Text color={secondaryTextColor}>To:</Text>
                <Text color={textColor} fontWeight="medium">
                  {item.to && item.to !== '$to' ? item.to : (item.leadId && item.leadId.phone) || 'Unknown'}
                </Text>
              </Flex>
              {(item.duration || item.callDuration) && (
                <Text color={textColor}>Duration: {item.duration || item.callDuration} seconds</Text>
              )}
              {item.recordingUrl && renderAudioPlayer(item.recordingUrl)}
              {item.transcript && renderTranscriptPreview(item.transcript)}
              {item.analysis && (
                <>
                  <Box my={3} borderBottom="1px" borderColor={borderColorValue} />
                  <Text color={textColor} fontWeight="bold">Analysis:</Text>
                  <Text color={textColor} whiteSpace="pre-wrap">{item.analysis}</Text>
                </>
              )}
              {(!item.transcript || !item.analysis || item.analysis === 'No analysis available') && (
                <Box>
                  <Button
                    onClick={() => handleRetryProcessing(item.twilioCallSid)}
                    colorScheme="orange"
                    variant="outline"
                    size="sm"
                    leftIcon={<MdRefresh />}
                    isLoading={processingCallId === item.twilioCallSid}
                    loadingText="Processing..."
                    mb={2}
                  >
                    Retry Processing
                  </Button>
                  {processingError && processingCallId === item.twilioCallSid && (
                    <Text color="red.400" fontSize="sm">{processingError}</Text>
                  )}
                </Box>
              )}
            </VStack>
          )}

          {item.type === 'SMS' && (
            <VStack align="stretch" spacing={4}>
              <Box 
                mt={2} 
                p={4} 
                bg={messageBg}
                borderRadius="md"
                border="1px solid"
                borderColor="orange.300"
                borderLeft="4px solid"
                borderLeftColor="orange.400"
                transition="all 0.3s ease"
                _hover={{
                  borderLeftColor: "orange.600",
                  boxShadow: 'md'
                }}
              >
                <Text color={textColor}>{item.message || item.content}</Text>
              </Box>
            </VStack>
          )}

          {item.type === 'Note' && (
            <Box 
              mt={4} 
              p={4} 
              bg={messageBg}
              borderRadius="md"
              border="1px solid"
              borderColor="orange.300"
              borderLeft="4px solid"
              borderLeftColor="orange.400"
              transition="all 0.3s ease"
              _hover={{
                borderLeftColor: "orange.600",
                boxShadow: 'md'
              }}
            >
              <Text color={textColor}>{item.content}</Text>
            </Box>
          )}
        </MotionBox>
      ))}
      
      {displayCount < sortedTimelineItems.length && (
        <Flex justify="center">
          <Button 
            onClick={loadMore} 
            colorScheme="orange" 
            variant="solid"
            size="lg"
            leftIcon={<MdCallReceived />}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg'
            }}
          >
            Load More
          </Button>
        </Flex>
      )}
      
      <TranscriptDrawer />
    </VStack>
  );
};

export default LeadTimeline;
