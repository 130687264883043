  // frontend/src/components/IncomingCallNotification.js
  import React, { useContext, useEffect, useCallback, useState } from 'react';
  import { TwilioContext } from '../context/TwilioContext';
  import { 
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalBody, 
    ModalFooter, 
    Button, 
    Text, 
    useDisclosure, 
    useToast, 
    VStack,
    Box,
    Heading
  } from '@chakra-ui/react';
  import { FaPhone, FaPhoneSlash, FaForward } from 'react-icons/fa';
  import axios from 'axios';

  const IncomingCallNotification = ({ onCallStatusChange }) => {
    const { device, isDeviceReady, callData, setCallData } = useContext(TwilioContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [isAcceptButtonDisabled, setIsAcceptButtonDisabled] = useState(false);
    // const ringtoneRef = useRef(null);
    const [timer, setTimer] = useState(5); // Change to 5 seconds
    const [isCallActive, setIsCallActive] = useState(false);

    // const stopRingtone = useCallback(() => {
    //   if (ringtoneRef.current) {
    //     ringtoneRef.current.pause();
    //     ringtoneRef.current.currentTime = 0;
    //   }
    // }, []);

    const handleClose = useCallback(() => {
      setCallData(null);
      onClose();
      setIsAcceptButtonDisabled(false); // Reset the accept button
      setTimer(5); // Reset timer to 5 seconds
      setIsCallActive(false); // Reset call active state
    }, [onClose, setCallData]);

    // Define checkCallActiveInOtherTab before it's used
    const checkCallActiveInOtherTab = useCallback(() => {
      const activeCall = localStorage.getItem('activeCall');
      if (activeCall) {
        const { timestamp, status } = JSON.parse(activeCall);
        const currentTime = new Date().getTime();
        // Consider the call active if it was accepted within the last 5 seconds
        if (status === 'accepted' && currentTime - timestamp < 5000) {
          setIsCallActive(true);
          handleClose();
        }
      }
    }, [handleClose]);

    // const playRingtone = useCallback(() => {
    //   if (isDeviceReady) {
    //     if (!ringtoneRef.current) {
    //       ringtoneRef.current = new Audio('/api/twilio/ringtone');
    //       ringtoneRef.current.loop = true;
    //     }
    //     ringtoneRef.current.play().catch(error => {
    //       console.warn('Ringtone playback failed:', error);
    //     });
    //   } else {
    //     console.log('Audio not ready. Ringtone will not play.');
    //   }
    // }, [isDeviceReady]);

    const handleForwardCall = useCallback(async () => {
      if (callData) {
        try {
          console.log('Forwarding call:', callData.callSid);
          const response = await axios.post('/api/twilio/forward-call', { callSid: callData.callSid });
          console.log('Forward call response:', response.data);
          handleClose();
          if (onCallStatusChange) {
            onCallStatusChange('forwarded');
          }
          toast({
            title: "Call Forwarded",
            description: "The call has been forwarded to your personal number.",
            status: "info",
            duration: 5000,
            isClosable: true,
          });
        } catch (error) {
          console.error('Error forwarding call:', error);
          let errorMessage = 'Failed to forward the incoming call.';
          if (error.response) {
            if (error.response.status === 400 && error.response.data.status) {
              errorMessage = `Call cannot be forwarded. Current status: ${error.response.data.status}`;
            } else {
              errorMessage += ` ${error.response.data.error || error.message}`;
            }
          }
          toast({
            title: 'Forward Call Error',
            description: errorMessage,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          handleClose(); // Close the modal even if forwarding fails
        }
      }
    }, [callData, handleClose, onCallStatusChange, toast]);

    const handleAcceptCall = useCallback(async () => {
      if (callData && device && isDeviceReady) {
        try {
          console.log('Accepting call:', callData.callSid);
          
          // Set call as active in localStorage
          localStorage.setItem('activeCall', JSON.stringify({
            timestamp: new Date().getTime(),
            status: 'accepted'
          }));
          
          // Accept the incoming connection instead of creating a new one
          const call = callData.connection;
          await call.accept();
          
          console.log('Call accepted:', call);

          call.on('accept', () => {
            console.log('Call has been accepted');
            handleClose();
            setIsCallActive(true);
            if (onCallStatusChange) {
              onCallStatusChange('in-progress');
            }
            toast({
              title: 'Call Accepted',
              description: 'You are now connected to the caller.',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          });

          call.on('disconnect', () => {
            console.log('Call has been disconnected');
            handleClose();
            if (onCallStatusChange) {
              onCallStatusChange('completed');
            }
          });

        } catch (error) {
          console.error('Error accepting call:', error);
          toast({
            title: 'Accept Call Error',
            description: `Failed to accept the incoming call. ${error.message}`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        console.error('No active call to accept or device not ready');
      }
    }, [callData, device, isDeviceReady, handleClose, onCallStatusChange, toast]);

    const handleRejectWithVoicemail = useCallback(async () => {
      if (callData) {
        try {
          console.log('Rejecting call with voicemail:', callData.callSid);
          const response = await axios.post('/api/twilio/reject-call-voicemail', { callSid: callData.callSid });
          console.log('Reject call with voicemail response:', response.data);
          handleClose();
          if (onCallStatusChange) {
            onCallStatusChange('rejected-voicemail');
          }
          toast({
            title: "Call Rejected",
            description: "The caller has been directed to voicemail.",
            status: "info",
            duration: 3000,
            isClosable: true,
          });
        } catch (error) {
          console.error('Error rejecting call with voicemail:', error);
          let errorMessage = 'Failed to reject the call with voicemail.';
          if (error.response && error.response.data && error.response.data.error) {
            errorMessage += ' ' + error.response.data.error;
          } else {
            errorMessage += ' ' + error.message;
          }
          toast({
            title: 'Reject Call Error',
            description: errorMessage,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          // If we couldn't reject with voicemail, let's try to forward the call
          handleForwardCall();
        }
      }
    }, [callData, handleClose, onCallStatusChange, toast, handleForwardCall]);

    const handleIncomingCall = useCallback(async () => {
      if (callData) {
        console.log('Processing incoming call:', callData);
        try {
          // Enhanced call notification message
          const notificationMessage = `
Caller: ${callData.from}
Time: ${new Date().toLocaleTimeString()}
Status: Incoming Call`;

          const response = await axios.post('/api/notifications/pushover', {
            title: '📞 Incoming Call',
            message: notificationMessage,
            priority: 1 // Higher priority for calls
          });

          console.log('Pushover notification sent for call:', response.data);
          onOpen();
        } catch (error) {
          console.error('Failed to send Pushover notification:', error.response?.data || error.message);
          toast({
            title: 'Notification Error',
            description: 'Failed to send push notification',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    }, [callData, onOpen, toast]);

    useEffect(() => {
      if (callData && !isCallActive) {
        handleIncomingCall();
      }
    }, [callData, isCallActive, handleIncomingCall]);

    useEffect(() => {
      if (callData && !isCallActive) {
        checkCallActiveInOtherTab();
        if (!isCallActive) {
          onOpen();
          setIsAcceptButtonDisabled(false);
        }
      } else if (!callData) {
        // Close the modal when callData is null
        handleClose();
      }
    }, [callData, onOpen, isCallActive, checkCallActiveInOtherTab, handleClose]);

    useEffect(() => {
      let interval;
      if (isOpen && timer > 0) {
        interval = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      } else if (timer === 0) {
        handleForwardCall();
      }
      return () => {
        clearInterval(interval);
        if (timer === 0) {
          handleClose(); // Ensure the modal closes even if forwarding fails
        }
      };
    }, [isOpen, timer, handleForwardCall, handleClose]);

    const handleReject = useCallback(async () => {
      if (callData && device) {
        try {
          console.log('Rejecting call:', callData.callSid);
          await axios.post('/api/twilio/reject-call', { callSid: callData.callSid });
          await device.disconnectAll();
          handleClose();
          if (onCallStatusChange) {
            onCallStatusChange('rejected');
          }
          toast({
            title: "Call Rejected",
            status: "info",
            duration: 3000,
            isClosable: true,
          });
        } catch (error) {
          console.error('Error rejecting call:', error);
          toast({
            title: 'Reject Call Error',
            description: 'Failed to reject the incoming call. ' + error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        console.error('No active call to reject');
      }
    }, [callData, device, handleClose, onCallStatusChange, toast]);

    // Add new useEffect for storage event listener
    useEffect(() => {
      const handleStorageChange = (e) => {
        if (e.key === 'activeCall') {
          checkCallActiveInOtherTab();
        }
      };

      window.addEventListener('storage', handleStorageChange);
      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }, [checkCallActiveInOtherTab]);

    return (
      <Modal isOpen={isOpen} onClose={handleClose} isCentered closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white" borderColor="yellow.500" borderWidth="1px">
          <ModalHeader>
            <Heading size="lg" className="gradient-text">Incoming Call</Heading>
          </ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Box>
                <Text fontWeight="bold">Call from:</Text>
                <Text>{callData?.from}</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">Time remaining:</Text>
                <Text>{timer} seconds</Text>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <VStack spacing={3} align="stretch" width="100%">
              <Button 
                colorScheme="green" 
                leftIcon={<FaPhone />} 
                onClick={handleAcceptCall}
                isDisabled={isAcceptButtonDisabled}
              >
                Accept
              </Button>
              <Button 
                colorScheme="red" 
                leftIcon={<FaPhoneSlash />} 
                onClick={handleRejectWithVoicemail}
              >
                Reject (Voicemail)
              </Button>
              <Button 
                colorScheme="blue" 
                leftIcon={<FaForward />} 
                onClick={handleForwardCall}
              >
                Forward to Personal Number
              </Button>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  export default IncomingCallNotification;
