import React, { useState, useMemo } from 'react';
import { 
  VStack, 
  HStack, 
  Text, 
  Button, 
  Textarea, 
  IconButton, 
  useDisclosure,
  Box,
  useColorModeValue,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { FaPlus, FaEdit, FaTrash, FaStickyNote } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

const NotesBox = ({ notes = [], leadId, onAddNote, onEditNote, onDeleteNote }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newNote, setNewNote] = useState('');
  const [editingNoteId, setEditingNoteId] = useState(null);

  // Color mode values
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('orange.300', 'orange.400');
  const textColor = useColorModeValue('gray.800', 'white');
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400');

  const formatDate = (date) => {
    return format(new Date(date), 'PPp');
  };

  const handleSubmit = async () => {
    if (!newNote.trim()) return;

    try {
      if (editingNoteId) {
        // Edit existing note
        await onEditNote(editingNoteId, {
          content: newNote,
          createdBy: 'System User' // You might want to get this from user context
        });
      } else {
        // Add new note
        await onAddNote({
          content: newNote,
          leadId: leadId
        });
      }
      
      setNewNote('');
      setEditingNoteId(null);
      onClose();
    } catch (error) {
      console.error('Error handling note:', error);
      // You might want to show an error toast here
    }
  };

  const handleEdit = (note) => {
    setNewNote(note.content);
    setEditingNoteId(note._id);
    onOpen();
  };

  const handleDelete = async (noteId) => {
    try {
      await onDeleteNote(noteId);
    } catch (error) {
      console.error('Error deleting note:', error);
      // You might want to show an error toast here
    }
  };

  // Add default empty array to prevent undefined errors
  const sortedNotes = useMemo(() => {
    return [...(notes || [])].sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  }, [notes]);

  return (
    <VStack spacing={4} align="stretch">
      {/* Add Note Button */}
      <Button
        leftIcon={<FaPlus />}
        onClick={() => {
          setEditingNoteId(null);
          setNewNote('');
          onOpen();
        }}
        colorScheme="orange"
        size="md"
        width="100%"
      >
        Add New Note
      </Button>

      {/* Notes List */}
      {sortedNotes && sortedNotes.map((note) => (
        <Box
          key={note._id}
          p={4}
          bg={bgColor}
          borderRadius="lg"
          border="1px solid"
          borderColor={borderColor}
          _hover={{
            borderColor: "orange.400",
            transform: "translateY(-2px)",
            boxShadow: "md",
          }}
          transition="all 0.2s"
        >
          <Flex justify="space-between" align="center" mb={2}>
            <HStack>
              <FaStickyNote color="orange" />
              <Text color={secondaryTextColor} fontSize="sm">
                {note.createdAt && formatDate(note.createdAt)}
              </Text>
            </HStack>
            <HStack spacing={2}>
              <IconButton
                icon={<FaEdit />}
                onClick={() => handleEdit(note)}
                aria-label="Edit note"
                size="sm"
                colorScheme="orange"
                variant="ghost"
              />
              <IconButton
                icon={<FaTrash />}
                onClick={() => handleDelete(note._id)}
                aria-label="Delete note"
                size="sm"
                colorScheme="red"
                variant="ghost"
              />
            </HStack>
          </Flex>
          <Text color={textColor} whiteSpace="pre-wrap">
            {note.content}
          </Text>
        </Box>
      ))}

      {/* Add/Edit Note Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent
          bg={bgColor}
          borderColor="orange.400"
          borderWidth="1px"
        >
          <ModalHeader color={textColor}>
            {editingNoteId ? 'Edit Note' : 'Add New Note'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Enter your note here... notesbox"
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              minH="200px"
              color={textColor}
              bg={bgColor}
              borderColor={borderColor}
              _hover={{ borderColor: 'orange.400' }}
              _focus={{ borderColor: 'orange.400', boxShadow: 'none' }}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="orange" onClick={handleSubmit}>
              {editingNoteId ? 'Update Note' : 'Save Note'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

NotesBox.propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    })
  ).isRequired,
  leadId: PropTypes.string.isRequired,
  onAddNote: PropTypes.func.isRequired,
  onEditNote: PropTypes.func.isRequired,
  onDeleteNote: PropTypes.func.isRequired,
};

export default NotesBox;