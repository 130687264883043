import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Select,
  HStack,
  Tooltip,
  IconButton,
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  useDisclosure,
  Box,
  SimpleGrid,
  FormControl,
  FormLabel,
  Badge,
  Avatar,
  useToast,
  Icon,
} from '@chakra-ui/react';
import {
  FaPhone,
  FaPhoneSlash,
  FaMicrophone,
  FaMicrophoneSlash,
  FaVolumeUp,
  FaVolumeMute,
  FaSms,
  FaSearch,
  FaPlus,
  FaGlobe,
  FaClipboardList,
  FaHome,
  FaHistory,
  FaUserFriends,
  FaRedoAlt,
} from 'react-icons/fa';
import AddNoteModal from './AddNoteModal'; // Ensure this path is correct
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { useNavigate } from 'react-router-dom';

/**
 * CallActions Component
 * 
 * This component provides options to start a call (Direct or Personal), send SMS,
 * search for leads, and add a new lead.
 * 
 * Props:
 * - callType: current selected call type ('direct' or 'personal')
 * - setCallType: function to update the call type
 * - handleStartCall: function to initiate the call
 * - handleSendSms: function to send SMS
 * - isCallActive: boolean indicating if a call is active
 * - isMuted: boolean indicating if the call is muted
 * - handleToggleMute: function to toggle mute
 * - isSpeakerOn: boolean indicating if the speaker is on
 * - handleToggleSpeaker: function to toggle speaker
 * - handleEndCall: function to end the call
 * - currentCallInfo: object containing call information
 */
const CallActions = ({
  callType,
  setCallType,
  handleStartCall,
  handleSendSms,
  isCallActive,
  isMuted,
  handleToggleMute,
  isSpeakerOn,
  handleToggleSpeaker,
  handleEndCall,
  currentCallInfo,
}) => {
  // Disclosure hooks for modals
  const {
    isOpen: isAddLeadOpen,
    onOpen: onAddLeadOpen,
    onClose: onAddLeadClose,
  } = useDisclosure();

  const {
    isOpen: isSearchOpen,
    onOpen: onSearchOpen,
    onClose: onSearchClose,
  } = useDisclosure();

  // State for search functionality
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);

  // Add state for tracking mute status
  const [isMuting, setIsMuting] = useState(false);

  const navigate = useNavigate();
  const [newLead, setNewLead] = useState({ name: '', email: '', phone: '', leadSource: '' });
  const [suggestedDuplicates, setSuggestedDuplicates] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const toast = useToast();

  /**
   * Handles the lead search functionality.
   * Sends a GET request to the API with the search query.
   */
  const handleSearchLeads = async () => {
    if (!searchQuery.trim()) return;

    setIsSearching(true);
    try {
      // Use the existing duplicate check endpoint for smart search
      const response = await axios.post(`${API_BASE_URL}/api/leads/search-duplicates`, {
        name: searchQuery,
        email: searchQuery,
        phone: searchQuery
      });

      setSearchResults(response.data.duplicates);
    } catch (error) {
      console.error('Error searching leads:', error);
      toast({
        title: 'Search Error',
        description: error.response?.data?.message || 'Failed to search leads',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSearching(false);
    }
  };

  // Add function to handle lead selection
  const handleLeadSelect = (lead) => {
    setSelectedLead(lead);
    // Navigate to call script with selected lead
    navigate('/call-script', {
      state: {
        leadId: lead._id,
        leadData: lead,
        currentStageIndex: { section: 0, stage: 0 }
      }
    });
    onSearchClose();
  };

  // Modify the mute toggle handler
  const handleMuteToggle = async () => {
    try {
      setIsMuting(true);
      
      // Get the current active call from Twilio Device
      const activeCall = window.TwilioDevice?.activeConnection();
      
      if (!activeCall) {
        console.error('No active call found');
        return;
      }

      // Toggle mute state on the Twilio connection
      const newMuteState = !isMuted;
      activeCall.mute(newMuteState);

      // Call the backend to update mute status
      await axios.post(`${API_BASE_URL}/api/twilio/toggle-mute`, {
        callSid: activeCall.parameters.CallSid,
        muted: newMuteState
      });

      // Call the parent component's handler
      handleToggleMute();

    } catch (error) {
      console.error('Error toggling mute:', error);
      // You might want to add error handling/notification here
    } finally {
      setIsMuting(false);
    }
  };

  // Add new functions for lead handling
  const handleLeadSourceSelect = (selectedSource) => {
    setNewLead({ ...newLead, leadSource: selectedSource });
  };

  const handleNewLeadChange = async (e) => {
    const updatedLead = { ...newLead, [e.target.name]: e.target.value };
    setNewLead(updatedLead);

    // Check for duplicates if name, email, or phone is updated
    if (['name', 'email', 'phone'].includes(e.target.name)) {
      const searchParams = {
        name: updatedLead.name,
        email: updatedLead.email,
        phone: updatedLead.phone,
      };
      
      if (Object.values(searchParams).some(val => val?.trim())) {
        try {
          const response = await axios.post(`${API_BASE_URL}/api/leads/search-duplicates`, searchParams);
          setSuggestedDuplicates(response.data.duplicates);
          setShowSuggestions(response.data.duplicates.length > 0);
        } catch (error) {
          console.error('Error checking duplicates:', error);
        }
      }
    }
  };

  const handleCreateLead = async (forceProceed = false) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/leads`, { ...newLead, forceProceed });
      
      toast({
        title: 'Lead created',
        description: 'New lead added successfully. Redirecting to Call Script.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      navigate('/call-script', {
        state: {
          leadId: response.data._id,
          leadData: response.data,
          leadSource: newLead.leadSource,
          currentStageIndex: { section: 0, stage: 0 }
        }
      });

      onAddLeadClose();
      setNewLead({ name: '', email: '', phone: '', leadSource: '' });
      setSuggestedDuplicates([]);
      setShowSuggestions(false);
    } catch (error) {
      console.error('Error creating lead:', error);
      toast({
        title: 'Error',
        description: error.response?.data?.error || 'Failed to create lead',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Update the Add Lead Modal content
  const addLeadModalContent = (
    <VStack spacing={4} align="stretch">
      <SimpleGrid columns={{ base: 1, md: 1 }} spacing={4}>
        <FormControl>
          <FormLabel color="gray.300">Name</FormLabel>
          <Input
            name="name"
            value={newLead.name}
            onChange={handleNewLeadChange}
            bg="black"
            borderColor="yellow.500"
            _hover={{ borderColor: "orange.400" }}
            _focus={{ borderColor: "yellow.300" }}
          />
        </FormControl>
        <FormControl>
          <FormLabel color="gray.300">Email</FormLabel>
          <Input
            name="email"
            type="email"
            value={newLead.email}
            onChange={handleNewLeadChange}
            bg="black"
            borderColor="yellow.500"
            _hover={{ borderColor: "orange.400" }}
            _focus={{ borderColor: "yellow.300" }}
          />
        </FormControl>
        <FormControl>
          <FormLabel color="gray.300">Phone</FormLabel>
          <Input
            name="phone"
            type="tel"
            value={newLead.phone}
            onChange={handleNewLeadChange}
            bg="black"
            borderColor="yellow.500"
            _hover={{ borderColor: "orange.400" }}
            _focus={{ borderColor: "yellow.300" }}
          />
        </FormControl>
      </SimpleGrid>

      {/* Lead Source Buttons */}
      <SimpleGrid columns={{ base: 2, sm: 3 }} spacing={2}>
        {[
          { type: 'website_signup', icon: FaGlobe, label: 'Website' },
          { type: 'Downloaded_Lead_Magnet', icon: FaClipboardList, label: 'Lead Magnet' },
          { type: 'open_house', icon: FaHome, label: 'Open House' },
          { type: 'older_lead', icon: FaHistory, label: 'Older Lead' },
          { type: 'referral', icon: FaUserFriends, label: 'Referral' },
          { type: 'followup', icon: FaRedoAlt, label: 'Follow-up' },
        ].map((source) => (
          <Button
            key={source.type}
            onClick={() => handleLeadSourceSelect(source.type)}
            colorScheme="orange"
            variant={newLead.leadSource === source.type ? "solid" : "outline"}
            leftIcon={<source.icon />}
            size="sm"
          >
            {source.label}
          </Button>
        ))}
      </SimpleGrid>

      {/* Duplicate Suggestions */}
      {showSuggestions && (
        <Box mt={4}>
          <Text color="orange.400" fontWeight="bold" mb={2}>Potential Duplicates</Text>
          <VStack spacing={2}>
            {suggestedDuplicates.map(lead => (
              <Box
                key={lead._id}
                p={2}
                bg="gray.900"
                borderRadius="md"
                width="100%"
                borderColor="yellow.500"
                borderWidth="1px"
              >
                <HStack spacing={3}>
                  <Avatar size="sm" name={lead.name} />
                  <VStack align="start" spacing={0} flex={1}>
                    <Text color="white">{lead.name}</Text>
                    <Text fontSize="sm" color="gray.300">{lead.email}</Text>
                  </VStack>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => navigate(`/call-script`, {
                      state: {
                        leadId: lead._id,
                        leadData: lead,
                        currentStageIndex: { section: 0, stage: 0 }
                      }
                    })}
                  >
                    Use Existing
                  </Button>
                </HStack>
              </Box>
            ))}
          </VStack>
        </Box>
      )}
    </VStack>
  );

  // Update the Search Modal content
  const searchModalContent = (
    <VStack spacing={4} align="stretch">
      <HStack>
        <Input
          placeholder="Search by name, email, or phone"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSearchLeads()}
          bg="gray.800"
          color="white"
          borderColor="teal.400"
          _hover={{ borderColor: "teal.300" }}
          _focus={{ borderColor: "teal.300", boxShadow: "none" }}
        />
        <Button
          colorScheme="teal"
          onClick={handleSearchLeads}
          isLoading={isSearching}
          leftIcon={<FaSearch />}
        >
          Search
        </Button>
      </HStack>

      {/* Display search results */}
      {searchResults.length > 0 ? (
        <VStack spacing={2} maxH="400px" overflowY="auto">
          {searchResults.map((lead) => (
            <Box
              key={lead._id}
              p={3}
              bg="gray.700"
              borderRadius="md"
              width="100%"
              cursor="pointer"
              onClick={() => handleLeadSelect(lead)}
              _hover={{ bg: "gray.600" }}
            >
              <HStack justify="space-between">
                <VStack align="start" spacing={1}>
                  <Text fontWeight="bold" color="white">
                    {lead.name}
                    {lead.status && (
                      <Badge ml={2} colorScheme={getStatusColor(lead.status)}>
                        {lead.status}
                      </Badge>
                    )}
                  </Text>
                  <Text fontSize="sm" color="gray.300">{lead.email}</Text>
                  <Text fontSize="sm" color="gray.300">{lead.phone}</Text>
                </VStack>
                <Button
                  size="sm"
                  colorScheme="teal"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLeadSelect(lead);
                  }}
                >
                  Select
                </Button>
              </HStack>
              {lead.lastActivity && (
                <Text fontSize="xs" color="gray.400" mt={1}>
                  Last Activity: {new Date(lead.lastActivity).toLocaleDateString()}
                </Text>
              )}
            </Box>
          ))}
        </VStack>
      ) : searchQuery && !isSearching ? (
        <Box textAlign="center" p={4}>
          <Text color="gray.400">No leads found.</Text>
          <Button
            mt={2}
            colorScheme="green"
            leftIcon={<FaPlus />}
            onClick={() => {
              onSearchClose();
              onAddLeadOpen();
            }}
          >
            Create New Lead
          </Button>
        </Box>
      ) : null}
    </VStack>
  );

  // Helper function for status colors
  const getStatusColor = (status) => {
    const statusColors = {
      'HOT': 'red',
      'Warm': 'orange',
      'TBD': 'gray',
      'Client': 'green',
      'Do Not Contact': 'red',
      'Not Responsive': 'yellow',
      'Future Follow Up': 'blue',
      // Add more status colors as needed
    };
    return statusColors[status] || 'gray';
  };

  return (
    <HStack 
      spacing={3} 
      align="center" 
      wrap="wrap" 
      p={4}
      bg="black"
      borderColor="orange.400"
    >
      {!isCallActive ? (
        <>
          {/* Call Type Selection */}
          <Select 
            value={callType} 
            onChange={(e) => setCallType(e.target.value)} 
            color="white"
            bg="gray.800"
            borderColor="orange.400"
            borderWidth="1px"
            borderRadius="0"
            placeholder="Select Call Type"
            flex="1"
            minWidth="150px"
            height="40px"
            _hover={{ borderColor: "orange.400" }}
            _focus={{ borderColor: "orange.400", boxShadow: "none" }}
            sx={{
              '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none',
              }
            }}
          >
            <option value="direct">Direct Call</option>
            <option value="personal">Personal Phone Routing</option>
          </Select>

          {/* Start Call Button */}
          <Button
            leftIcon={callType === 'direct' ? <FaPhone /> : <FaPhoneSlash />}
            colorScheme="orange"
            onClick={() => handleStartCall(callType)}
            flex="1"
            minWidth="150px"
            bg="gray.800"
            borderColor="orange.400"
            borderWidth="1px"
            borderRadius="0"
            color="white"
            height="40px"
            _hover={{ bg: "orange.400" }}
          >
            Start {callType === 'direct' ? 'Direct' : 'Personal'} Call
          </Button>

          {/* Send SMS Button */}
          <Button
            leftIcon={<FaSms />}
            colorScheme="orange"
            onClick={handleSendSms}
            flex="1"
            minWidth="150px"
            bg="gray.800"
            borderColor="orange.400"
            borderWidth="1px"
            borderRadius="0"
            color="white"
            height="40px"
            _hover={{ bg: "orange.400" }}
          >
            Send SMS
          </Button>

          {/* Search Leads Button */}
          <Button
            leftIcon={<FaSearch />}
            colorScheme="teal"
            onClick={onSearchOpen}
            flex="1"
            minWidth="150px"
            bg="gray.800"
            borderColor="teal.400"
            borderWidth="1px"
            borderRadius="0"
            color="white"
            height="40px"
            _hover={{ bg: "teal.500" }}
          >
            Search Leads
          </Button>

          {/* Add Lead Button */}
          <Button
            leftIcon={<FaPlus />}
            colorScheme="green"
            onClick={onAddLeadOpen}
            flex="1"
            minWidth="150px"
            bg="gray.800"
            borderColor="green.400"
            borderWidth="1px"
            borderRadius="0"
            color="white"
            height="40px"
            _hover={{ bg: "green.500" }}
          >
            Add Lead
          </Button>
        </>
      ) : (
        <>
          {/* Call Controls */}
          <ButtonGroup spacing={2} width="100%">
            <Tooltip label={isMuted ? 'Unmute' : 'Mute'} aria-label="Mute Tooltip">
              <IconButton
                icon={isMuted ? <FaMicrophoneSlash /> : <FaMicrophone />}
                onClick={handleMuteToggle}
                aria-label={isMuted ? 'Unmute' : 'Mute'}
                colorScheme={isMuted ? "red" : "yellow"}
                isLoading={isMuting}
                flex={1}
                bg={isMuted ? "red.800" : "gray.800"}
                borderColor="orange.400"
                borderWidth="1px"
                borderRadius="0"
                color="white"
                height="40px"
                _hover={{ bg: isMuted ? "red.600" : "orange.400" }}
              >
                {isMuted ? 'Unmute' : 'Mute'}
              </IconButton>
            </Tooltip>
            <Tooltip
              label={isSpeakerOn ? 'Disable Speaker' : 'Enable Speaker'}
              aria-label="Speaker Tooltip"
            >
              <IconButton
                icon={isSpeakerOn ? <FaVolumeMute /> : <FaVolumeUp />}
                onClick={handleToggleSpeaker}
                aria-label={isSpeakerOn ? 'Disable Speaker' : 'Enable Speaker'}
                colorScheme="blue"
                flex={1}
                bg="gray.800"
                borderColor="orange.400"
                borderWidth="1px"
                borderRadius="0"
                color="white"
                height="40px"
                _hover={{ bg: "orange.400" }}
              >
                {isSpeakerOn ? 'Speaker Off' : 'Speaker On'}
              </IconButton>
            </Tooltip>
            <Tooltip label="End Call" aria-label="End Call Tooltip">
              <IconButton
                icon={<FaPhoneSlash />}
                onClick={handleEndCall}
                aria-label="End Call"
                colorScheme="red"
                flex={1}
                bg="gray.800"
                borderColor="orange.400"
                borderWidth="1px"
                borderRadius="0"
                color="white"
                height="40px"
                _hover={{ bg: "orange.400" }}
              >
                End Call
              </IconButton>
            </Tooltip>
          </ButtonGroup>

          {/* Call Info Display */}
          {currentCallInfo && (
            <Text color="white" fontSize="sm" width="100%" mt={2}>
              Calling: {currentCallInfo.leadName || 'Unknown'} ({currentCallInfo.to})
              <br />
              Status: {currentCallInfo.status}
              {currentCallInfo.duration > 0 && ` • Duration: ${currentCallInfo.duration}s`}
            </Text>
          )}
        </>
      )}

      {/* Add Lead Modal */}
      <Modal isOpen={isAddLeadOpen} onClose={onAddLeadClose} size="xl">
        <ModalOverlay />
        <ModalContent bg="gray.900" borderColor="orange.400" borderWidth="1px">
          <ModalHeader color="orange.400">Add New Lead</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            {addLeadModalContent}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="orange"
              mr={3}
              onClick={() => handleCreateLead(false)}
              isDisabled={!newLead.name || !newLead.email || !newLead.phone}
            >
              {suggestedDuplicates.length > 0 ? "Create Anyway" : "Add Lead"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Search Leads Modal */}
      <Modal isOpen={isSearchOpen} onClose={onSearchClose} size="xl">
        <ModalOverlay />
        <ModalContent bg="gray.900" borderColor="teal.400" borderWidth="1px">
          <ModalHeader color="teal.400">Search Leads</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            {searchModalContent}
          </ModalBody>
        </ModalContent>
      </Modal>
    </HStack>
  );
};

export default CallActions;
