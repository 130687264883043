// frontend/src/context/AuthContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';

// Create the AuthContext
const AuthContext = createContext(null);

// Custom hook to use the AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set up the observer
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.email === 'sasha@property.ca') {
        setCurrentUser(user);
      } else if (user) {
        // If it's not the allowed user, sign them out
        signOut(auth).then(() => {
          console.log('Unauthorized user signed out');
        }).catch((error) => {
          console.error('Error signing out:', error);
        });
        setCurrentUser(null);
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    // Clean up the observer on unmount
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    loading,
    isAuthorized: currentUser && currentUser.email === 'sasha@property.ca'
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
