// frontend/src/firebase.js

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth"; // Import GoogleAuthProvider

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC6A2gfwhx-6RwGwU_X1CjbdbCWmjo5kNI",
  authDomain: "realtalkpro-bc30b.firebaseapp.com",
  projectId: "realtalkpro-bc30b",
  storageBucket: "realtalkpro-bc30b.appspot.com",
  messagingSenderId: "32674183996",
  appId: "1:32674183996:web:4072e7e8f6d8fcc27c7c39",
  measurementId: "G-5F8C47QFW3"
};

// Initialize Firebase
let app;
let auth;
let analytics;
let provider;

try {
  // Initialize the Firebase app
  app = initializeApp(firebaseConfig);
  
  // Initialize Firebase Auth and Analytics
  auth = getAuth(app);
  analytics = getAnalytics(app);
  
  // Initialize Google Auth Provider
  provider = new GoogleAuthProvider();
  
  console.log("Firebase initialized successfully");
} catch (error) {
  console.error("Error initializing Firebase:", error);
}

export { auth, app, analytics, provider };