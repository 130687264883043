import React from 'react';
import { Box, Heading, VStack, Text, Flex, Icon, Badge, Button, HStack } from '@chakra-ui/react';
import { FaExclamationTriangle, FaBell, FaUserPlus, FaCalendarCheck, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

const AlertsNotificationsSection = () => {
  // Fake data for notifications
  const notifications = [
    { id: 1, type: 'urgent', icon: FaExclamationTriangle, message: 'Urgent: Follow up with high-value lead John Doe', time: '5 minutes ago' },
    { id: 2, type: 'new_lead', icon: FaUserPlus, message: 'New lead assigned: Sarah Smith (Buyer)', time: '10 minutes ago' },
    { id: 3, type: 'appointment', icon: FaCalendarCheck, message: 'Upcoming appointment: Property viewing at 123 Main St', time: '1 hour ago' },
    { id: 4, type: 'call', icon: FaPhoneAlt, message: 'Missed call from potential client Mike Johnson', time: '2 hours ago' },
    { id: 5, type: 'email', icon: FaEnvelope, message: 'New email: Offer acceptance from Emily Davis', time: '3 hours ago' },
  ];

  // Function to determine badge color based on notification type
  const getBadgeColor = (type) => {
    switch (type) {
      case 'urgent': return 'red';
      case 'new_lead': return 'green';
      case 'appointment': return 'blue';
      case 'call': return 'purple';
      case 'email': return 'orange';
      default: return 'gray';
    }
  };

  return (
    <Box bg="surfaceBg" p={5} borderRadius="md" className="gold-border-glow">
      <Flex justify="space-between" align="center" mb={4}>
        <Heading fontSize="xl">Alerts and Notifications</Heading>
        <Badge colorScheme="red" fontSize="md" borderRadius="full" px={2}>
          {notifications.length} New
        </Badge>
      </Flex>
      <VStack align="stretch" spacing={4}>
        {notifications.map((notification) => (
          <Box key={notification.id} p={3} bg="gray.800" borderRadius="md" borderLeft="4px solid" borderLeftColor={`${getBadgeColor(notification.type)}.500`}>
            <Flex align="center" justify="space-between">
              <HStack spacing={3}>
                <Icon as={notification.icon} color={`${getBadgeColor(notification.type)}.500`} boxSize={5} />
                <Text fontWeight="medium">{notification.message}</Text>
              </HStack>
              <Text fontSize="xs" color="gray.400">{notification.time}</Text>
            </Flex>
          </Box>
        ))}
      </VStack>
      <Flex justify="space-between" mt={4}>
        <Button leftIcon={<FaBell />} variant="outline" size="sm" colorScheme="yellow">
          Mark All as Read
        </Button>
        <Button variant="solid" size="sm" colorScheme="orange">
          View All Notifications
        </Button>
      </Flex>
    </Box>
  );
};

export default AlertsNotificationsSection;
