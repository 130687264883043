import { useEffect, useRef } from 'react';
import { useToast, Box, Text } from '@chakra-ui/react';
import { connectSocket } from '../socket'; // Import connectSocket instead of socket
import axios from 'axios';

/**
 * @file IncomingSmsNotification.js
 * @description Real-Time SMS Notification Component with Sound & Visual Alerts
 * 
 * @component Structure
 * ├── Socket Connection Management
 * ├── Audio Notification System
 * ├── Toast Notifications
 * ├── Pushover Integration
 * └── Phone Number Formatting
 * 
 * @features
 * - Real-time SMS notifications via WebSocket
 * - Audio alerts for incoming messages
 * - Visual toast notifications with contact details
 * - Different styling for new vs existing contacts
 * - Pushover mobile notifications integration
 * - Phone number formatting utility
 * 
 * @dependencies
 * - @chakra-ui/react (UI components & toast)
 * - socket.io-client (WebSocket connection)
 * - axios (API requests)
 * 
 * @usage
 * Import and include this component at the app's root level
 * to enable global SMS notifications.
 * 
 * @author Your Name
 * @lastModified 2024
 */

const IncomingSmsNotification = () => {
  const toast = useToast();
  const audioRef = useRef(new Audio('/sounds/magic_sms.mp3'));

  useEffect(() => {
    // Initialize socket connection
    const socketInstance = connectSocket();
    
    if (!socketInstance) {
      console.error('❌ Socket instance not available for notifications');
      return;
    }

    // Connect the socket if it's not already connected
    if (!socketInstance.connected) {
      socketInstance.connect();
      console.log('🔌 Socket connected for notifications');
    }

    /**
     * Event handler for new SMS messages.
     * Displays a toast notification if the message is incoming.
     */
    const handleNewMessage = async (message) => {
      console.log('📨 Received message in IncomingSmsNotification:', message);
      
      if (message.direction === 'incoming') {
        // Play sound notification
        try {
          await audioRef.current.play();
        } catch (error) {
          console.error('Failed to play notification sound:', error);
        }

        try {
          // Get lead name with better fallback handling
          const displayName = message.leadName && message.leadName !== 'Unknown Contact' 
            ? message.leadName 
            : formatPhoneNumber(message.from);

          // Create a more detailed notification message
          const notificationMessage = `
📱 ${message.leadId ? 'Message' : '⚠️ New Contact'}
From: ${displayName}
Time: ${new Date().toLocaleTimeString()}
${message.body}`;

          // Send Pushover notification
          await axios.post('/api/notifications/pushover', {
            title: `📱 ${message.leadId ? 'SMS from' : 'New Contact SMS from'} ${displayName}`,
            message: notificationMessage,
            priority: 1
          });

          // Show enhanced toast notification with better name handling
          toast({
            title: `${message.leadId ? 'SMS from' : 'New Contact SMS'}`,
            description: (
              <Box>
                <Text fontWeight="bold" mb={1}>
                  {displayName}
                </Text>
                <Text>{message.body}</Text>
                {!message.leadId && (
                  <Text color="orange.400" mt={1} fontSize="sm">
                    ⚠️ New Contact Added
                  </Text>
                )}
              </Box>
            ),
            status: message.leadId ? 'info' : 'warning',
            duration: 8000,
            isClosable: true,
            position: 'top-right',
            variant: 'solid',
            containerStyle: {
              maxWidth: '400px'
            }
          });
        } catch (error) {
          console.error('❌ Notification error:', error);
          toast({
            title: 'Notification Error',
            description: 'Failed to process incoming message notification',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
      }
    };

    console.log('✅ New message listener set up');
    socketInstance.on('new_message', handleNewMessage);

    // Cleanup function
    return () => {
      console.log('🔄 Cleaned up message listener');
      socketInstance.off('new_message', handleNewMessage);
    };
  }, [toast]); // Only depend on toast

  return null; // This component does not render anything
};

// Add this helper function
const formatPhoneNumber = (phone) => {
  if (!phone) return 'Unknown Number';
  const cleaned = phone.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }
  return phone;
};

export default IncomingSmsNotification;
