import { extendTheme } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

const glow = keyframes`
  0% { box-shadow: 0 0 5px var(--primary-accent-light); }
  50% { box-shadow: 0 0 20px var(--primary-accent-light), 0 0 30px var(--primary-accent-light); }
  100% { box-shadow: 0 0 5px var(--primary-accent-light); }
`;

const lightTheme = extendTheme({
  colors: {
    primaryBg: '#ffffff',
    surfaceBg: '#f5f5f5',
    primaryAccent: '#2B4C7E',      // Medium dark blue
    secondaryAccent: '#567EBB',    // Lighter blue
    textColor: '#2d2d2d',
    inactiveColor: '#e0e0e0',
    gray: {
      50: '#f5f5f5',
      100: '#e0e0e0',
      200: '#cccccc',
    },
    blue: {
      400: '#567EBB',
      500: '#2B4C7E',
    },
    gradients: {
      dashboard: 'linear-gradient(180deg, #e4f1ff 0%, #ffffff 100%)', // Soft blue to white gradient
    }
  },
  styles: {
    global: {
      ':root': {
        '--primary-bg-light': '#ffffff',
        '--surface-bg-light': '#f5f5f5',
        '--primary-accent-light': '#2B4C7E',
        '--secondary-accent-light': '#567EBB',
        '--text-color-light': '#2d2d2d',
        '--inactive-color-light': '#e0e0e0',
      },
      body: {
        bg: 'primaryBg',
        color: 'textColor',
        fontFamily: 'Montserrat, sans-serif',
      },
    },
  },
  components: {
    Box: {
      variants: {
        'dashboard-section': {
          bg: 'white',
          p: 5,
          borderRadius: 'xl',
          position: 'relative',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          _hover: {
            boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
          },
          transition: 'all 0.2s ease',
        },
      },
    },
    // ... other component styles ...
  },
});

export default lightTheme; 