import { Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverBody, SlideFade, Flex, Box, Text, List, ListItem, useDisclosure } from '@chakra-ui/react';

const EssentialSkillIcon = ({ skill }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const bgColor = 'black';
    const borderColor = 'orange.400';
    const hoverBgColor = 'orange.800';
      const textColor = "white";
    const iconColor = 'orange.400';
    
    return (
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom-start"
        closeOnBlur={true}
        trigger="hover"
      >
        <PopoverTrigger>
          <Flex 
            align="center" 
            cursor="pointer" 
            p={2} 
            borderColor={borderColor}
            _hover={{ bg: hoverBgColor }}
            transition="all 0.2s"
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
              minWidth="auto"  // Changed from fixed width to auto
              whiteSpace="nowrap"  // Ensure text stays on one line
            >
              <Box as={skill.icon} size="24px" color={iconColor} mr={2} flexShrink={0} />
              <Text 
                fontWeight="medium" 
                color={textColor} 
                textAlign="left"
              >
                {skill.title}
              </Text>
          </Flex>
        </PopoverTrigger>
        <PopoverContent p={5} width="300px" boxShadow="xl" bg={bgColor} borderColor={borderColor}>
          <PopoverArrow bg={bgColor} />
          <PopoverCloseButton color={textColor} />
          <PopoverBody>
            <SlideFade in={isOpen} offsetY="20px">
              <Text mb={4} color={textColor}>{skill.content}</Text>
              <Text fontWeight="bold" mb={2} color="yellow.500">Tips:</Text>
              <List spacing={2}>
                {skill.tips.map((tip, index) => (
                  <ListItem key={index} color={textColor}>• {tip}</ListItem>
                ))}
              </List>
              <Text fontWeight="bold" mt={4} mb={2} color="yellow.500">Examples:</Text>
              <List spacing={2}>
                {skill.examples.map((example, index) => (
                  <ListItem key={index} color={textColor}>• {example}</ListItem>
                ))}
              </List>
            </SlideFade>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
    };

// Add the export statement for the component
export default EssentialSkillIcon;  // Ensure the component is exported