import React from 'react';
import { VStack, Box, Text, HStack, Button } from '@chakra-ui/react';

const GoldenTurn = ({ leadSource, handleLeadResponse }) => {
  const getGoldenTurnQuestion = () => {
    switch (leadSource) {
      case 'Downloaded_Lead_Magnet':
        return "I should probably ask you what caught your attention to download the list of properties in the first place?";
      case 'open_house':
        return "I should probably ask what caught your attention about [property address] that made you want to attend the open house?";
      case 'website_signup':
        return "I should probably ask you what caught your attention on our website that made you want to sign up for more information?";
      case 'older_lead':
        return "I should probably ask what initially caught your attention about our services that made you reach out to us when we first connected?";
      case 'specific_property':
        return "I should probably ask what caught your attention about [property address] that made you click on the listing and request more information?";
      default:
        return "I should probably ask you what caught your attention to [item/action] that [reason for contact]?";
    }
  };

  return (
    <VStack align="stretch" spacing={4}>
      <Box 
        borderWidth="1px"
        borderColor="yellow.500"
        borderRadius="md"
        p={4}
      >
        <Text fontWeight="bold" color="white">{getGoldenTurnQuestion()}</Text>
      </Box>

      <Box
        borderWidth="1px"
        borderColor="yellow.500"
        borderRadius="md"
        p={4}
      >
        <Text fontWeight="bold" color="yellow.500" mb={2}>Follow-up Options:</Text>
        <Text color="white" fontWeight="bold">Option A) Was there anything else that attracted your attention?</Text>
        <Text color="white" mt={2} fontWeight="bold">Option B) Do you know what you're looking for already, whenever the time is right?</Text>
        <Text color="gray.400" mt={4}>Ask the first question without immediate follow-up options. Do use one more open-ended follow-up if needed.</Text>
      </Box>

      <HStack spacing={2}>
        <Button onClick={() => handleLeadResponse('positive')}>Positive Response</Button>
        <Button onClick={() => handleLeadResponse('neutral')}>Neutral Response</Button>
        <Button onClick={() => handleLeadResponse('negative')}>Negative Response</Button>
      </HStack>
    </VStack>
  );
};

export default GoldenTurn;