import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Heading, Text, Stack, SimpleGrid, Icon, useDisclosure } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import WaitlistModal from './WaitlistModal';

const matrixAnimation = keyframes`
  0% { transform: translateY(-100%); opacity: 0; }
  2% { opacity: 1; }
  80% { opacity: 1; }
  100% { transform: translateY(100%); opacity: 0; }
`;

const MatrixBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.97);
`;

const MatrixColumn = styled.div`
  position: absolute;
  top: 0;
  left: ${props => props.left}%;
  font-family: 'Courier New', monospace;
  font-size: ${props => props.size}px;
  line-height: 2;
  color: #FFA500;
  text-shadow: 0 0 8px #FFA500;
  white-space: pre;
  animation: ${matrixAnimation} ${props => props.duration}s linear infinite;
  animation-delay: ${props => props.delay}s;
  opacity: ${props => props.opacity};
`;

const ContentOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6) 0%,      /* Start darker */
    rgba(0, 0, 0, 0.7) 15%,     /* Getting darker */
    rgba(0, 0, 0, 0.8) 25%,     /* Continuing to darken */
    rgba(0, 0, 0, 0.9) 35%,     /* Almost black */
    rgba(0, 0, 0, 0.95) 45%,    /* Nearly black */
    rgba(0, 0, 0, 1) 50%,       /* Completely black */
    rgba(0, 0, 0, 1) 100%       /* Stay black to bottom */
  );
  z-index: 1;
`;

const HeroSection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const [matrixColumns, setMatrixColumns] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        const { hours, minutes, seconds } = prevTime;
        
        if (hours === 0 && minutes === 0 && seconds === 0) {
          clearInterval(timer);
          return prevTime;
        }

        let newSeconds = seconds - 1;
        let newMinutes = minutes;
        let newHours = hours;

        if (newSeconds < 0) {
          newSeconds = 59;
          newMinutes -= 1;
        }

        if (newMinutes < 0) {
          newMinutes = 59;
          newHours -= 1;
        }

        return {
          hours: newHours,
          minutes: newMinutes,
          seconds: newSeconds
        };
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const columns = [];
    const columnCount = 50;

    for (let i = 0; i < columnCount; i++) {
      columns.push({
        left: Math.random() * 100,
        duration: 20 + Math.random() * 25,
        delay: Math.random() * -20,
        characters: generateRandomCharacters(),
        size: 20 + Math.floor(Math.random() * 10),
        opacity: 0.6 + Math.random() * 0.4
      });
    }

    setMatrixColumns(columns);
  }, []);

  function generateRandomCharacters() {
    const symbols = [
      '📱',  // phone
      '🤖',  // robot
      '💰',  // money bag
    ];
    
    const pattern = [];
    for (let i = 0; i < 10; i++) {  // Create 10 sets of symbols
      pattern.push(...symbols);
    }
    
    return pattern.join('\n');  // Join with newlines for vertical display
  }

  return (
    <Box 
      width="100vw"
      position="relative"
      left="50%"
      right="50%"
      marginLeft="-50vw"
      marginRight="-50vw"
      py={{ base: 10, md: 20 }} 
      mt="70px"
      bg="rgba(0, 0, 0, 0.97)"
    >
      <MatrixBackground>
        {matrixColumns.map((column, index) => (
          <MatrixColumn
            key={index}
            left={column.left}
            duration={column.duration}
            delay={column.delay}
            size={column.size}
            opacity={column.opacity}
          >
            {column.characters}
          </MatrixColumn>
        ))}
      </MatrixBackground>

      <ContentOverlay />

      <Container maxW="container.xl" position="relative" zIndex={2}>
        <Stack spacing={8} align="center" textAlign="center">

        <Heading
            as="h2"
            fontSize={{ base: '2xl', md: '4xl' }}
            className="gradient-text"
            fontWeight="semibold"
            mb={4}
          >
            Attention Toronto Realtors:
          </Heading>

          <Heading
            as="h1"
            fontSize={{ base: '3xl', md: '5xl', lg: '6xl' }}
            className="gradient-text"
            fontWeight="bold"
            animation="fadeIn 1s ease-in"
            lineHeight="1.2"
            mb={6}
          >
            🔓 Unlock the AI Tool That Closes Deals FOR You, Effortlessly, in Record Time!
          </Heading>

          <Text 
            fontSize={{ base: 'lg', md: 'xl' }} 
            maxW="800px"
            color="yellow.400"
            px={4}
          >Every missed call is a missed opportunity. Every awkward conversation is a potential deal slipping away. Every communication barrier is leaving money on the table.
<br /><br />
          You're not alone. <br />Stop letting these challenges hold you back.<br /><br />

            Your solution is here: an AI-powered system that turns communication challenges into closing opportunities. It manages your calls, captures crucial details, and provides winning insights—empowering you to dominate the Toronto real estate market.
          </Text>

          {/* Modified Countdown Timer */}
          <Box 
            bg="rgba(255, 0, 0, 0.1)" 
            p={6} 
            borderRadius="xl"
            border="2px solid"
            borderColor="red.500"
            maxW="600px"
            w="100%"
            position="relative"
            opacity="0.8"
          >
            {/* Add CLOSED banner */}
            <Box
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%) rotate(-15deg)"
              bg="red.600"
              px={8}
              py={2}
              borderRadius="md"
              zIndex={3}
              boxShadow="lg"
            >
              <Text fontSize="2xl" fontWeight="bold">CLOSED</Text>
            </Box>

            <Text fontSize="xl" fontWeight="bold" mb={3}>⏰ Early Access Has Ended:</Text>
            <Stack direction="row" justify="center" spacing={4}>
              {[
                { label: 'Hours', value: 0 },
                { label: 'Minutes', value: 0 },
                { label: 'Seconds', value: 0 }
              ].map(({ label, value }) => (
                <Box key={label} bg="black" p={3} borderRadius="md" minW="80px">
                  <Text fontSize="2xl" fontWeight="bold">
                    {String(value).padStart(2, '0')}
                  </Text>
                  <Text fontSize="sm">{label}</Text>
                </Box>
              ))}
            </Stack>
          </Box>

          <Button
            as={RouterLink}
            to="#"
            size={{ base: 'md', md: 'xl' }}
            height={{ base: '60px', md: '100px' }}
            fontSize={{ base: 'sm', md: '2xl' }}
            flex={1}
            bg="linear-gradient(45deg, #FFD700, #FFA500)"
            color="black"
            isLoading={isLoading}
            loadingText="Reserving Your Spot..."
            onClick={(e) => {
              e.preventDefault();
              setIsLoading(true);
              setTimeout(() => {
                setIsLoading(false);
                onOpen();
              }, 1000);
            }}
            _hover={{ 
              transform: 'scale(1.05)',
              bg: 'linear-gradient(45deg, #FFA500, #FFD700)',
            }}
            transition="all 0.3s ease"
            boxShadow="0 0 20px rgba(255, 165, 0, 0.4)"
            mb={6}
            px={{ base: 4, md: 8 }}
            py={{ base: 3, md: 6 }}
          >
            <Box>
              <Text 
                textDecoration="line-through" 
                mb={1}
                fontSize={{ base: 'xs', md: 'xl' }}
              >
                👉 Claim Your Secret Weapon Now—Only 100 Agents Per District!
              </Text>
              <Text 
                fontWeight="bold"
                fontSize={{ base: 'sm', md: '2xl' }}
              >
                🔥 Join the Waitlist - Don't Miss Out When Spots Open Up!
              </Text>
            </Box>
          </Button>

          {/* Social proof metrics */}
          <SimpleGrid columns={{ base: 2, md: 4 }} spacing={8} width="100%">
            {[
              { value: '2,847+', label: 'Automated Calls Managed Seamlessly' },
              { value: '37%', label: 'Increase in Lead Response Rates' },
              { value: '127+', label: 'Toronto Agents Thriving with Us' },
              { value: '96%', label: 'Client Retention Rate' }
            ].map((metric, index) => (
              <Box key={index} textAlign="center">
                <Text fontSize="4xl" fontWeight="bold" color="yellow.400">{metric.value}</Text>
                <Text fontSize="sm">{metric.label}</Text>
              </Box>
            ))}
          </SimpleGrid>

          <Text 
            fontSize={{ base: 'lg', md: 'xl' }} 
            maxW="800px"
            color="yellow.400"
            mt={8}
            px={4}
          >
            Discover the AI-Powered "Money Magnet System" That's Helping Smart Agents Double Their Closings While Working Less Hours
          </Text>

          <Box 
            bg="red.600" 
            p={3} 
            textAlign="center" 
            animation="pulse 2s infinite"
            borderRadius="md"
            width="100%"
            maxW="800px"
          >
            <Text fontWeight="bold">
              ⚠️ BETA PROGRAM LIMITED TO 25 AGENTS PER DISTRICT - Early Access Pricing Ends When Timer Expires ⏰
            </Text>
          </Box>
        </Stack>
      </Container>
      <WaitlistModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default HeroSection; 