import React from 'react';
import { VStack, Box, Text, UnorderedList, ListItem, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Heading, Button, HStack, OrderedList } from '@chakra-ui/react';

const WayOutAcknowledgement = ({ wayOutResponse, setWayOutResponse }) => {
  return (
    <VStack align="stretch" spacing={4}>
      {/* Purpose Accordion */}
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontWeight="bold" color="white">Purpose</Text>
              </Box>
              <AccordionIcon color="yellow.500" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Box>
              <Heading size="md" mb={2}>Way Out Acknowledgement Questions</Heading>
              <Text fontWeight="bold" mb={2}>
                So before today, were you out looking for you looked at any properties to…[IMPROVE SITUATION]….or what were you doing to [SOLVE PROBLEM]?
              </Text>
              <Box mt={2}>
                <Heading size="sm" mb={2}>Yes, Probing</Heading>
                <OrderedList spacing={2} mb={4}>
                  <ListItem>Help prospects recognize there's a solution to their current situation</ListItem>
                  <ListItem>Build trust and rapport through strategic questioning</ListItem>
                  <ListItem>Understand the prospect's current state and past efforts</ListItem>
                  <ListItem>Gauge the prospect's motivation and readiness</ListItem>
                  <ListItem>Help prospects visualize consequences of inaction and benefits of change</ListItem>
                  <ListItem>Position yourself as a trusted advisor</ListItem>
                  <ListItem>Set the stage for in-depth qualification questions</ListItem>
                  <ListItem>Avoid wasting time on unqualified leads</ListItem>
                  <ListItem>Design a strategy that truly works for the prospect</ListItem>
                  <ListItem>Gauge the prospect's level of commitment and specific needs</ListItem>
                </OrderedList>
              </Box>
            
              <Text fontWeight="bold" mb={2}>
                So before today, were you out looking for you looked at any properties to…[IMPROVE SITUATION]….or what were you doing to [SOLVE PROBLEM]?
              </Text>
              <Box mt={2}>
                <Heading size="sm" mb={2}>Yes, Probing</Heading>
                <UnorderedList spacing={2}>
                  <ListItem>Can I ask was that in person, or online?</ListItem>
                  <ListItem>Ah, I understand. What specifically were you looking for?</ListItem>
                  <ListItem>Did you end up liking any of the properties that you saw?</ListItem>
                  <ListItem>What do you think held you back from moving forward with any of the properties that you've looked at so far?</ListItem>
                  <ListItem>What did you find….</ListItem>
                  <ListItem>How did it/that work for you….</ListItem>
                  <ListItem>What would you have changed about the process if you could?</ListItem>
                </UnorderedList>
              </Box>
              <Box mt={2}>
                <Heading size="sm" mb={2}>No</Heading>
                <UnorderedList spacing={2}>
                  <ListItem>What's prevented you from making a change? PROBE FURTHER</ListItem>
                  <ListItem>Has that had an impact on you?</ListItem>
                </UnorderedList>
              </Box>
              <Box mt={2}>
                <Heading size="sm" mb={2}>If They Say They've Worked With Several Agents But Haven't Chosen One</Heading>
                <Text>So what do you feel they went over with you that caused you to feel uncertain about what they were offering?</Text>
              </Box>
            </Box>

            <Box mt={4}>
              <Heading size="md" mb={2}>IDEAL CRITERIA</Heading>
              <Text fontWeight="bold" mb={2}>
                All right, let's do this, just to make sure that what we do would actually work for you…
              </Text>
              <Text mb={2}>
                Say you were sitting in our office with myself or one of our senior buyers agents, and we were sitting down designing a strategy for you…
              </Text>
              <Text mb={2}>
                Beside (WHAT THEY SAID THEY WANT)...what do you feel like you need…to make sure you feel supported by us to make the process as smooth as possible for you?
              </Text>
              <UnorderedList spacing={2} mb={2}>
                <ListItem>...like what would be that ideal outcome?</ListItem>
                <ListItem>Why is XYZ important to you?</ListItem>
                <ListItem>Is there anything else you're wanting?</ListItem>
              </UnorderedList>
              <Heading size="sm" mb={2}>PRETEND QUESTIONS</Heading>
              <Text mb={2}>If you decided to make a move in the future…</Text>
              <UnorderedList spacing={2}>
                <ListItem>What budget would you like to stay within?</ListItem>
                <ListItem>When would you like to have the keys?</ListItem>
                <ListItem>What would be an important factor in who you decide to hire as your agent?</ListItem>
                <ListItem>Are you committed to working with an agent at this point?</ListItem>
              </UnorderedList>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {/* Main Questions */}
      <Box borderWidth="1px" borderColor="yellow.500" p={4}>
        <Text fontWeight="bold" mb={2}>Main Questions</Text>
        <Text>So before today, were you out looking for you looked at any properties to…[IMPROVE SITUATION]….or what were you doing to [SOLVE PROBLEM]?</Text>
        <HStack mt={2}>
          <Button 
            colorScheme={wayOutResponse === 'yes' ? "yellow" : "gray"} 
            variant={wayOutResponse === 'yes' ? "solid" : "outline"}
            onClick={() => setWayOutResponse('yes')}
          >
            Yes
          </Button>
          <Button 
            colorScheme={wayOutResponse === 'no' ? "yellow" : "gray"} 
            variant={wayOutResponse === 'no' ? "solid" : "outline"}
            onClick={() => setWayOutResponse('no')}
          >
            No
          </Button>
        </HStack>
      </Box>

      {/* Yes box */}
      {wayOutResponse === 'yes' && (
        <Box borderWidth="1px" borderColor="yellow.500" p={4}>
          <Text fontWeight="bold" mb={2}>Yes</Text>
          <UnorderedList spacing={2}>
            <ListItem>Can I ask was that in person, or online?</ListItem>
            <ListItem>Ah, I understand. What specifically were you looking for?</ListItem>
            <ListItem>Did you end up liking any of the properties that you saw?</ListItem>
            <ListItem>What do you think held you back from moving forward with any of the properties that you've looked at so far?</ListItem>
            <ListItem>What did you find….</ListItem>
            <ListItem>How did it/that work for you….</ListItem>
            <ListItem>What would you have changed about the process if you could?</ListItem>
          </UnorderedList>
        </Box>
      )}

      {/* No box */}
      {wayOutResponse === 'no' && (
        <Box borderWidth="1px" borderColor="yellow.500" p={4}>
          <Text fontWeight="bold" mb={2}>No</Text>
          <UnorderedList spacing={2}>
            <ListItem>What's prevented you from making a change?</ListItem>
            <ListItem>PROBE FURTHER Has that had an impact on you?</ListItem>
          </UnorderedList>
        </Box>
      )}

      {/* Special scenario box */}
      <Box borderWidth="1px" borderColor="yellow.500" p={4}>
        <Text fontWeight="bold" mb={2}>Special Scenario</Text>
        <Text fontStyle="italic" mb={2}>If They Say They've Worked With Several Agents But Haven't Chosen One:</Text>
        <Text>So what do you feel they went over with you that caused you to feel uncertain about what they were offering?</Text>
      </Box>

      {/* Ideal Criteria box */}
      <Box borderWidth="1px" borderColor="yellow.500" p={4}>
        <Text fontWeight="bold" mb={2}>IDEAL CRITERIA</Text>
        <UnorderedList spacing={2}>
          <ListItem>All right, let's do this, just to make sure that what we do would actually work for you…</ListItem>
          <ListItem>Say you were sitting in our office with myself or one of our senior buyers agents, and we were sitting down designing a strategy for you…</ListItem>
          <ListItem>Besides (WHAT THEY SAID THEY WANT)...what do you feel like you need…to make sure you feel supported by us to make the process as smooth as possible for you?</ListItem>
          <ListItem>...like what would be that ideal outcome?</ListItem>
          <ListItem>Why is XYZ important to you?</ListItem>
          <ListItem>Is there anything else you're wanting?</ListItem>
        </UnorderedList>
      </Box>

      {/* Pretend Questions box */}
      <Box borderWidth="1px" borderColor="yellow.500" p={4}>
        <Text fontWeight="bold" mb={2}>PRETEND QUESTIONS</Text>
        <Text mb={2}>If you decided to make a move in the future…</Text>
        <UnorderedList spacing={2}>
          <ListItem>What budget would you like to stay within?</ListItem>
          <ListItem>When would you like to have the keys?</ListItem>
          <ListItem>What would be an important factor in who you decide to hire as your agent?</ListItem>
          <ListItem>Are you committed to working with an agent at this point?</ListItem>
        </UnorderedList>
      </Box>

      {/* Takeaways Accordion */}
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontWeight="bold" color="white">Takeaways</Text>
              </Box>
              <AccordionIcon color="yellow.500" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <UnorderedList spacing={2} color="gray.400">
              <ListItem>The "way out acknowledgement" phase helps prospects realize there's a solution to their current situation.</ListItem>
              <ListItem>Strategic questioning is more effective than offering pre-made solutions.</ListItem>
              <ListItem>This approach builds trust, lowers sales resistance, and improves rapport.</ListItem>
              <ListItem>Understand the prospect's current state and past efforts before suggesting solutions.</ListItem>
              <ListItem>Asking about in-person property viewings provides insights into motivation and readiness.</ListItem>
              <ListItem>Help prospects visualize consequences of inaction and benefits of change.</ListItem>
              <ListItem>Keep the questioning process natural and caring, not condescending or intrusive.</ListItem>
              <ListItem>By this stage, you should have built enough rapport for more personal questions.</ListItem>
              <ListItem>Position yourself as a trusted advisor rather than just another salesperson.</ListItem>
              <ListItem>This phase sets the stage for in-depth qualification questions about ability and willingness to change.</ListItem>
              <ListItem>"Pretend" questions help gauge commitment and needs without applying pressure.</ListItem>
              <ListItem>Understand why certain criteria are important to the prospect, not just what they are.</ListItem>
              <ListItem>This process helps identify qualified prospects through strategic conversation.</ListItem>
              <ListItem>Be flexible in questioning - probe further or change direction based on responses.</ListItem>
              <ListItem>The ultimate aim is to design a strategy that truly works for the prospect, making them feel supported.</ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </VStack>
  );
};

export default WayOutAcknowledgement;