export const enhancedTestimonials = [
  {
    quote: "Real Talk Pro has completely transformed how I handle client communications. The AI support during calls gives me the confidence to handle any situation.",
    author: "Michael Chen",
    role: "Top Producer, Toronto",
    rating: 5,
    avatarUrl: "/images/avatars/michael-chen.jpg",
    badgeText: "Verified User",
    stats: {
      dealsCompleted: 47,
      responseRate: "98%",
      avgRating: 4.9
    }
  },
  {
    quote: "The automated follow-up system is a game-changer. I'm closing more deals while working fewer hours. This is the future of real estate!",
    author: "Sarah Williams",
    role: "Luxury Real Estate Specialist",
    rating: 5,
    avatarUrl: "/images/avatars/sarah-williams.jpg",
    badgeText: "Elite Member",
    stats: {
      dealsCompleted: 63,
      responseRate: "99%",
      avgRating: 5.0
    }
  },
  {
    quote: "Since implementing Real Talk Pro, my response time has improved by 300% and my conversion rate has doubled. The ROI is incredible.",
    author: "David Thompson",
    role: "Team Lead, GTA Properties",
    rating: 5,
    avatarUrl: "/images/avatars/david-thompson.jpg",
    badgeText: "Team Leader",
    stats: {
      dealsCompleted: 85,
      responseRate: "97%",
      avgRating: 4.8
    }
  },
  {
    quote: "Finally, a tech solution that actually delivers on its promises. The AI calling assistant feels like having a seasoned mentor by my side.",
    author: "Jennifer Lee",
    role: "Rising Star Agent",
    rating: 5,
    avatarUrl: "/images/avatars/jennifer-lee.jpg",
    badgeText: "Rising Star",
    stats: {
      dealsCompleted: 31,
      responseRate: "96%",
      avgRating: 4.9
    }
  }
];

// Add styling constants to match Dashboard theme
export const testimonialStyles = {
  container: {
    maxW: "1400px",
    mx: "auto",
    p: { base: 4, md: 6, lg: 8 },
    bg: "primaryBg",
    color: "textColor",
  },
  heading: {
    fontSize: { base: '2xl', md: '3xl', lg: '4xl' },
    fontWeight: "bold",
    className: "gradient-text",
    textAlign: "center",
  },
  card: {
    borderColor: "yellow.500",
    borderWidth: "1px",
    boxShadow: "0 0 10px 2px rgba(255, 165, 0, 0.3)",
  }
}; 