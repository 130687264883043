import React from 'react';
import { Box, Heading, SimpleGrid, VStack, Text, Flex, Icon, Button, Progress, Avatar, Badge } from '@chakra-ui/react';
import { FaTasks, FaCalendarAlt, FaPhoneAlt, FaEnvelope, FaFileContract, FaUserPlus } from 'react-icons/fa';

const TaskOverviewSection = () => {
  // Placeholder data for tasks
  const tasks = [
    { id: 1, title: 'Follow up with Michael Lee', dueDate: 'Today', icon: FaPhoneAlt, priority: 'High', progress: 75 },
    { id: 2, title: 'Prepare contract for Sarah Wilson', dueDate: 'Tomorrow', icon: FaFileContract, priority: 'Medium', progress: 30 },
    { id: 3, title: 'Schedule viewing with John Doe', dueDate: 'Sep 25', icon: FaCalendarAlt, priority: 'Low', progress: 0 },
    { id: 4, title: 'Send listing details to Emma Thompson', dueDate: 'Sep 26', icon: FaEnvelope, priority: 'Medium', progress: 50 },
  ];

  // Function to get color based on priority
  const getPriorityColor = (priority) => {
    switch (priority.toLowerCase()) {
      case 'high': return 'red.500';
      case 'medium': return 'orange.400';
      case 'low': return 'green.500';
      default: return 'gray.500';
    }
  };

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
      <Box bg="surfaceBg" p={5} borderRadius="md" className="gold-border-glow">
        <Heading fontSize="xl" mb={4}>Upcoming Tasks</Heading>
        <VStack align="stretch" spacing={4}>
          {tasks.map((task) => (
            <Box key={task.id} p={3} bg="whiteAlpha.100" borderRadius="md">
              <Flex justify="space-between" align="center" mb={2}>
                <Flex align="center">
                  <Icon as={task.icon} mr={2} color="primaryAccent" />
                  <Text fontWeight="bold">{task.title}</Text>
                </Flex>
                <Badge colorScheme={getPriorityColor(task.priority)} px={2} py={1} borderRadius="full">
                  {task.priority}
                </Badge>
              </Flex>
              <Flex justify="space-between" align="center" mb={2}>
                <Text fontSize="sm" color="gray.400">
                  <Icon as={FaCalendarAlt} mr={1} />
                  Due: {task.dueDate}
                </Text>
                <Text fontSize="sm" fontWeight="bold" color={task.progress === 100 ? "green.400" : "orange.400"}>
                  {task.progress}% Complete
                </Text>
              </Flex>
              <Progress value={task.progress} size="sm" colorScheme="orange" borderRadius="full" />
            </Box>
          ))}
        </VStack>
        <Button mt={4} leftIcon={<FaTasks />} colorScheme="orange" size="sm">
          View All Tasks
        </Button>
      </Box>

      <Box bg="surfaceBg" p={5} borderRadius="md" className="gold-border-glow">
        <Heading fontSize="xl" mb={4}>Monthly Performance</Heading>
        <VStack align="stretch" spacing={4}>
          <Flex justify="space-between" align="center">
            <Text>Leads Generated</Text>
            <Text fontWeight="bold" color="green.400">42</Text>
          </Flex>
          <Progress value={70} size="sm" colorScheme="green" borderRadius="full" />
          
          <Flex justify="space-between" align="center">
            <Text>Deals Closed</Text>
            <Text fontWeight="bold" color="orange.400">8</Text>
          </Flex>
          <Progress value={40} size="sm" colorScheme="orange" borderRadius="full" />
          
          <Flex justify="space-between" align="center">
            <Text>Client Satisfaction</Text>
            <Text fontWeight="bold" color="blue.400">95%</Text>
          </Flex>
          <Progress value={95} size="sm" colorScheme="blue" borderRadius="full" />
        </VStack>
        <Flex mt={6} justify="center">
          <Avatar size="lg" name="John Doe" src="https://bit.ly/broken-link" />
          <Box ml={4}>
            <Text fontWeight="bold">John Doe</Text>
            <Text fontSize="sm" color="gray.400">Top Performer This Month</Text>
          </Box>
        </Flex>
        <Button mt={4} leftIcon={<FaUserPlus />} colorScheme="orange" size="sm" width="full">
          View Team Performance
        </Button>
      </Box>
    </SimpleGrid>
  );
};

export default TaskOverviewSection;
