   // frontend/src/context/TwilioContext.js

/**
 * =============================================================================
 * TwilioContext - Voice Calling Context Provider
 * =============================================================================
 * 
 * This context manages the Twilio Voice SDK integration for the application.
 * 
 * Key Features:
 * - Manages Twilio Device lifecycle (setup, registration, cleanup)
 * - Handles incoming call events
 * - Maintains call state and device status
 * 
 * Context States:
 * - device: Current Twilio Device instance
 * - isDeviceReady: Boolean indicating if device is ready for calls
 * - callData: Information about current/incoming calls
 * - activeCall: Reference to the active call connection
 * 
 * Main Functions:
 * - setupTwilioDevice: Initializes and configures Twilio Device
 * - cleanupDevice: Handles proper cleanup of Twilio Device
 * 
 * @context TwilioContext
 * @provider TwilioProvider
 * =============================================================================
 */

import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import { Device } from '@twilio/voice-sdk';
import axios from 'axios';
import { API_BASE_URL } from '../config';

export const TwilioContext = createContext();

export const TwilioProvider = ({ children }) => {
  const [device, setDevice] = useState(null);
  const [isDeviceReady, setIsDeviceReady] = useState(false);
  const [callData, setCallData] = useState(null);
  const [activeCall, setActiveCall] = useState(null);
  const setupInProgress = useRef(false);
  const deviceRef = useRef(null);

  const cleanupDevice = useCallback(async () => {
    if (deviceRef.current) {
      try {
        const currentDevice = deviceRef.current;
        
        // Only attempt to unregister if the device is in 'registered' state
        if (currentDevice.state === 'registered') {
          await currentDevice.unregister();
        }
        
        // Destroy the device
        currentDevice.destroy();
        deviceRef.current = null;
        setDevice(null);
        setIsDeviceReady(false);
      } catch (error) {
        console.error('Error cleaning up Twilio device:', error);
      }
    }
  }, []);

  const setupTwilioDevice = useCallback(async () => {
    if (setupInProgress.current) {
      console.log('Device setup already in progress');
      return;
    }

    // Clean up existing device first
    await cleanupDevice();

    setupInProgress.current = true;

    try {
      console.log('Setting up Twilio device...');
      const response = await axios.get(`${API_BASE_URL}/api/twilio/token`);
      const token = response.data.token;
      const identity = response.data.identity;
      console.log('Received token:', token);
      console.log('Received identity:', identity);

      const newDevice = new Device(token, {
        codecPreferences: ['opus', 'pcmu'],
        fakeLocalDTMF: true,
        enableRingingState: true,
      });

      // Store device reference
      deviceRef.current = newDevice;

      newDevice.on('ready', () => {
        console.log('Twilio Device is ready');
        setIsDeviceReady(true);
      });

      newDevice.on('error', (error) => {
        console.error('Twilio Device error:', error);
        setIsDeviceReady(false);
      });

      newDevice.on('incoming', (incomingCall) => {
        console.log('Incoming call received:', incomingCall);
        setCallData({
          from: incomingCall.parameters.From,
          callSid: incomingCall.parameters.CallSid,
          connection: incomingCall,
        });

        // Add event listener for when the call is disconnected
        incomingCall.on('disconnect', () => {
          console.log('Call disconnected, resetting callData');
          setCallData(null);
        });
      });

      console.log('Registering device...');
      await newDevice.register();
      setDevice(newDevice);
      setIsDeviceReady(true);
      console.log('Device setup complete');
    } catch (error) {
      console.error('Error setting up Twilio device:', error);
      setIsDeviceReady(false);
      deviceRef.current = null;
    } finally {
      setupInProgress.current = false;
    }
  }, [cleanupDevice]);

  useEffect(() => {
    setupTwilioDevice();

    // Cleanup function
    return () => {
      cleanupDevice();
    };
  }, [setupTwilioDevice, cleanupDevice]);

  return (
    <TwilioContext.Provider value={{
      device,
      setDevice,
      isDeviceReady,
      setIsDeviceReady,
      callData,
      setCallData,
      activeCall,
      setActiveCall,
      setupTwilioDevice // Expose setup function if needed
    }}>
      {children}
    </TwilioContext.Provider>
  );
};
