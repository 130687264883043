import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  VStack,
  useToast,
  Select,
  RadioGroup,
  Radio,
  Stack,
  Progress,
  Heading,
  Box,
} from '@chakra-ui/react';
import axios from 'axios';
import styled from 'styled-components';

const GradientBox = styled(Box)`
  background: linear-gradient(45deg, rgba(0,0,0,0.95), rgba(0,0,0,0.98));
  border: 1px solid #FFA500;
  border-radius: 15px;
`;

const WaitlistModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    experience: '',
    dealVolume: '',
    district: '',
    painPoints: '',
    referralSource: '',
    mainFeature: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const totalSteps = 3;
  const progress = (step / totalSteps) * 100;

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleNext = () => {
    if (validateCurrentStep()) {
      setStep(prev => prev + 1);
    } else {
      toast({
        title: 'Please fill in all fields',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleBack = () => {
    setStep(prev => prev - 1);
  };

  const validateCurrentStep = () => {
    switch (step) {
      case 1:
        return formData.name && formData.phone && formData.email;
      case 2:
        return formData.experience && formData.dealVolume && formData.referralSource;
      case 3:
        return formData.district && formData.painPoints && formData.mainFeature;
      default:
        return true;
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      const message = `
New Waitlist Registration:
Name: ${formData.name}
Phone: ${formData.phone}
Email: ${formData.email}
Experience: ${formData.experience}
Monthly Deal Volume: ${formData.dealVolume}
District: ${formData.district}
Main Pain Point: ${formData.painPoints}
Referral Source: ${formData.referralSource}
Main Feature Interest: ${formData.mainFeature}
      `.trim();

      // Create a temporary lead for the waitlist submission
      const tempLead = await axios.post('/api/leads', {
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        status: 'waitlist'
      });

      // Send SMS using the new lead ID
      await axios.post('/api/twilio/send-sms', {
        to: '+16478356368', // Your notification number
        message: message,
        leadId: tempLead.data._id // Use the new lead's ID
      });

      toast({
        title: 'Successfully joined waitlist!',
        description: "We'll contact you when spots open up.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      console.error('Error submitting waitlist form:', error);
      toast({
        title: 'Error joining waitlist',
        description: error.response?.data?.error || "Please try again later.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <VStack spacing={4}>
            <Text color="yellow.400" mb={4}>
              Start your journey to AI-powered real estate success! Let's get to know you better.
            </Text>
            <FormControl isRequired>
              <FormLabel color="white">Name</FormLabel>
              <Input
                placeholder="Your name"
                value={formData.name}
                onChange={(e) => handleInputChange('name', e.target.value)}
                bg="whiteAlpha.100"
                color="white"
                _placeholder={{ color: 'whiteAlpha.500' }}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel color="white">Phone</FormLabel>
              <Input
                placeholder="Your phone number"
                value={formData.phone}
                onChange={(e) => handleInputChange('phone', e.target.value)}
                bg="whiteAlpha.100"
                color="white"
                _placeholder={{ color: 'whiteAlpha.500' }}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel color="white">Email</FormLabel>
              <Input
                placeholder="Your email"
                value={formData.email}
                onChange={(e) => handleInputChange('email', e.target.value)}
                bg="whiteAlpha.100"
                color="white"
                _placeholder={{ color: 'whiteAlpha.500' }}
              />
            </FormControl>
          </VStack>
        );

      case 2:
        return (
          <VStack spacing={4}>
            <Text color="yellow.400" mb={4}>
              Tell us about your real estate experience
            </Text>
            <FormControl isRequired>
              <FormLabel color="white">Years in Real Estate</FormLabel>
              <Select
                value={formData.experience}
                onChange={(e) => handleInputChange('experience', e.target.value)}
                bg="whiteAlpha.100"
                color="white"
              >
                <option value="">Select</option>
                <option value="0-2">0-2 years</option>
                <option value="2-5">2-5 years</option>
                <option value="5-10">5-10 years</option>
                <option value="10-20">10-20 years</option>
                <option value="20+">20+ years</option>
              </Select>
            </FormControl>

            <FormControl isRequired>
              <FormLabel color="white">Monthly Deal Volume</FormLabel>
              <Select
                value={formData.dealVolume}
                onChange={(e) => handleInputChange('dealVolume', e.target.value)}
                bg="whiteAlpha.100"
                color="white"
              >
                <option value="">Select</option>
                <option value="0-1">0-1 deals</option>
                <option value="2-5">2-5 deals</option>
                <option value="6-10">6-10 deals</option>
                <option value="11-20">11-20 deals</option>
                <option value="20+">20+ deals</option>
              </Select>
            </FormControl>

            <FormControl isRequired>
              <FormLabel color="white">How did you hear about us?</FormLabel>
              <Select
                value={formData.referralSource}
                onChange={(e) => handleInputChange('referralSource', e.target.value)}
                bg="whiteAlpha.100"
                color="white"
              >
                <option value="">Select</option>
                <option value="google">Google Search</option>
                <option value="facebook">Facebook</option>
                <option value="instagram">Instagram</option>
                <option value="linkedin">LinkedIn</option>
                <option value="referral">Referral from Agent</option>
                <option value="brokerage">Through my Brokerage</option>
                <option value="youtube">YouTube</option>
                <option value="tiktok">TikTok</option>
                <option value="other">Other</option>
              </Select>
            </FormControl>
          </VStack>
        );

      case 3:
        return (
          <VStack spacing={4}>
            <Text color="yellow.400" mb={4}>
              Help us understand your needs better
            </Text>
            <FormControl isRequired>
              <FormLabel color="white">Which district do you primarily work in?</FormLabel>
              <Input
                placeholder="e.g., Downtown Toronto, North York, etc."
                value={formData.district}
                onChange={(e) => handleInputChange('district', e.target.value)}
                bg="whiteAlpha.100"
                color="white"
                _placeholder={{ color: 'whiteAlpha.500' }}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel color="white">What's your main pain point?</FormLabel>
              <Select
                value={formData.painPoints}
                onChange={(e) => handleInputChange('painPoints', e.target.value)}
                bg="whiteAlpha.100"
                color="white"
              >
                <option value="">Select</option>
                <option value="lead-generation">Lead Generation</option>
                <option value="follow-up">Follow-up Management</option>
                <option value="time-management">Time Management</option>
                <option value="client-communication">Client Communication</option>
                <option value="deal-organization">Deal Organization</option>
                <option value="marketing">Marketing</option>
                <option value="other">Other</option>
              </Select>
            </FormControl>

            <FormControl isRequired>
              <FormLabel color="white">Which feature interests you most?</FormLabel>
              <Select
                value={formData.mainFeature}
                onChange={(e) => handleInputChange('mainFeature', e.target.value)}
                bg="whiteAlpha.100"
                color="white"
              >
                <option value="">Select</option>
                <option value="ai-calling">AI-Powered Calling</option>
                <option value="auto-followup">Automated Follow-ups</option>
                <option value="lead-nurturing">AI Lead Nurturing</option>
                <option value="deal-tracking">Deal Pipeline Tracking</option>
                <option value="market-analysis">Market Analysis Tools</option>
                <option value="client-portal">Client Portal</option>
                <option value="document-automation">Document Automation</option>
                <option value="all">All Features</option>
              </Select>
            </FormControl>

            <Button
              width="100%"
              bg="linear-gradient(45deg, #FFD700, #FFA500)"
              color="black"
              _hover={{
                transform: 'scale(1.02)',
                bg: 'linear-gradient(45deg, #FFA500, #FFD700)',
              }}
              onClick={handleSubmit}
              isLoading={isSubmitting}
              loadingText="Submitting..."
              mt={4}
            >
              Join Waitlist
            </Button>
          </VStack>
        );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent 
        bg="linear-gradient(45deg, rgba(0,0,0,0.97), rgba(0,0,0,0.95))"
        border="1px solid #FFA500"
        boxShadow="0 0 20px rgba(255,165,0,0.1)"
        borderRadius="15px"
      >
        <ModalHeader 
          className="gradient-text" 
          fontSize="2xl" 
          textAlign="center"
          pt={4}
          pb={4}
        >
          🚀 Join the Future of Real Estate
        </ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody pt={0} pb={6}>
          <VStack spacing={6}>
            <Progress 
              value={progress} 
              size="sm" 
              colorScheme="yellow" 
              borderRadius="full" 
              bg="whiteAlpha.100" 
              sx={{
                '& > div': {
                  background: 'linear-gradient(45deg, #FFD700, #FFA500)',
                }
              }}
            />
            <GradientBox p={4} w="100%">
              {renderStep()}
            </GradientBox>
            
            <Stack 
              direction="row" 
              spacing={4} 
              width="100%" 
              justify="center"
              mt={2}
            >
              {step > 1 && (
                <Button
                  onClick={handleBack}
                  variant="outline"
                  color="yellow.400"
                  borderColor="yellow.400"
                  _hover={{
                    bg: 'whiteAlpha.100',
                    transform: 'scale(1.02)'
                  }}
                >
                  ← Back
                </Button>
              )}
              {step < totalSteps ? (
                <Button
                  onClick={handleNext}
                  bg="linear-gradient(45deg, #FFD700, #FFA500)"
                  color="black"
                  _hover={{
                    transform: 'scale(1.02)',
                    bg: 'linear-gradient(45deg, #FFA500, #FFD700)'
                  }}
                >
                  Next →
                </Button>
              ) : null}
            </Stack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WaitlistModal; 