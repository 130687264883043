import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Link,
  IconButton,
  Stack,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useBreakpointValue,
  Text
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { motion } from 'framer-motion';

const LandingNavigationBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const NavLink = ({ to, children }) => (
    <Link
      as={RouterLink}
      to={to}
      px={4}
      py={2}
      rounded="md"
      fontWeight="medium"
      color="white"
      _hover={{
        textDecoration: 'none',
        color: 'orange.400',
        transform: 'translateY(-2px)',
      }}
      transition="all 0.3s ease"
    >
      {children}
    </Link>
  );

  const DesktopNav = () => (
    <Stack direction="row" spacing={8} align="center">
      <NavLink to="/#features">Features</NavLink>
      <NavLink to="/#pricing">Pricing</NavLink>
      <NavLink to="/#testimonials">Testimonials</NavLink>
      <NavLink to="/#faq">FAQ</NavLink>
      <Button
        as={RouterLink}
        to="/login"
        colorScheme="orange"
        size="md"
        ml={4}
        _hover={{
          transform: 'translateY(-2px)',
          boxShadow: 'xl',
        }}
      >
        Sign In
      </Button>
      <Button
        as={RouterLink}
        to="/register"
        variant="outline"
        colorScheme="orange"
        size="md"
        _hover={{
          transform: 'translateY(-2px)',
          boxShadow: 'xl',
        }}
      >
        Get Started
      </Button>
    </Stack>
  );

  const MobileNav = () => (
    <Stack spacing={4}>
      <NavLink to="/#features">Features</NavLink>
      <NavLink to="/#pricing">Pricing</NavLink>
      <NavLink to="/#testimonials">Testimonials</NavLink>
      <NavLink to="/#faq">FAQ</NavLink>
      <Button
        as={RouterLink}
        to="/login"
        colorScheme="orange"
        size="md"
        width="full"
        mb={2}
      >
        Sign In
      </Button>
      <Button
        as={RouterLink}
        to="/register"
        variant="outline"
        colorScheme="orange"
        size="md"
        width="full"
      >
        Get Started
      </Button>
    </Stack>
  );

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      bg="rgba(0, 0, 0, 0.5)"
      backdropFilter="blur(5px)"
      borderBottom="1px solid"
      borderColor="orange.400"
      zIndex={1000}
    >
      <Flex
        h="70px"
        alignItems="center"
        justifyContent="space-between"
        maxW="100%"
        mx="auto"
        px={6}
      >
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Link
            as={RouterLink}
            to="/"
            _hover={{ textDecoration: 'none' }}
          >
            <Text
              fontSize="2xl"
              fontWeight="extrabold"
              bgGradient="linear(to-r, orange.400, orange.600)"
              bgClip="text"
            >
              🔓 Real•Talk•Pro
            </Text>
          </Link>
        </motion.div>

        {isMobile ? (
          <IconButton
            icon={<GiHamburgerMenu />}
            aria-label="Open Menu"
            onClick={onOpen}
            variant="ghost"
            color="white"
            _hover={{
              bg: "orange.400",
              transform: "scale(1.1)",
            }}
          />
        ) : (
          <DesktopNav />
        )}

        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay zIndex={1500} />
          <DrawerContent bg="black" color="white" zIndex={1500}>
            <DrawerCloseButton 
              color="white"
              _hover={{
                bg: "orange.400",
                transform: "rotate(90deg)",
                transition: "all 0.3s",
              }}
            />
            <DrawerHeader
              borderBottomWidth="1px"
              borderColor="orange.400"
            >
              Menu
            </DrawerHeader>
            <DrawerBody>
              <MobileNav />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  );
};

export default LandingNavigationBar; 