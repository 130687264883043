import React from 'react';
import { Box, Button, Container, Heading, Text, Stack, Image, Flex, SimpleGrid, Icon, IconButton, useDisclosure, Collapse, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Divider } from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FaExclamationTriangle, FaTasks, FaRocket, FaChartLine, FaClock, FaRobot, FaBars, FaCheck, FaStar, FaQuoteLeft, FaPhone, FaSms, FaArrowUp, FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import FAQSection from '../components/FAQSection';
import VideoSection from '../components/VideoSection';
import HowItWorksSection from '../components/landing/HowItWorksSection';
import ChatWidget from '../components/ChatWidget';
import { scroller } from 'react-scroll';
import PartnerLogoCarousel from '../components/PartnerLogoCarousel';
import LandingNavigationBar from '../components/landing/LandingNavigationBar';
import HeroSection from '../components/landing/HeroSection';
import PainPointsSection from '../components/landing/PainPointsSection';
import PainEndsHereSection from '../components/landing/PainEndsHereSection';
import TransformBusinessSection from '../components/landing/TransformBusinessSection';
import { enhancedFeatures } from '../components/landing/features';
import { enhancedTestimonials } from '../components/landing/testimonials';
import { enhancedPlans } from '../components/landing/plans';
import PricingSection from '../components/landing/PricingSection';
import FinalCTASection from '../components/landing/FinalCTASection';
import Footer from '../components/landing/Footer';

const GlowingDivider = () => (
  <Box
    my={16}
    position="relative"
    width="100%"
    height="1px"
    _before={{
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '1px',
      background: 'orange.400',
      boxShadow: '0 0 10px rgba(255, 165, 0, 0.5), 0 0 5px rgba(255, 165, 0, 0.3)',
    }}
  />
);

const LandingPage = () => {
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();

  // Add these new navigation handlers
  const handleNavigateToCommunications = () => {
    navigate('/communications');
  };

  const handleNavigateToCallReports = () => {
    navigate('/call-reports');
  };

  const scrollToSection = (sectionId) => {
    scroller.scrollTo(sectionId, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollButton = document.querySelector('[aria-label="Scroll to top"]');
      if (scrollButton) {
        if (window.scrollY > window.innerHeight) {
          scrollButton.classList.add('visible');
        } else {
          scrollButton.classList.remove('visible');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const calculateScrollProgress = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight - windowHeight;
      const scrolled = window.scrollY;
      const progress = (scrolled / documentHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', calculateScrollProgress);
    return () => window.removeEventListener('scroll', calculateScrollProgress);
  }, []);

  return (
    <Box 
      bg="primaryBg" 
      minH="100vh" 
      color="textColor"
      position="relative"
      display="flex"
      flexDirection="column"
      zIndex={0}
      overflowX="hidden"
      borderTop="1px solid #FFA500"
    >
      {/* Progress Bar - Updated styling with higher z-index */}
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        height="1px"
        zIndex={9999}
        sx={{
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '1px',
            background: '#FFA500',
            opacity: 1,
            boxShadow: '0 0 8px rgba(255, 165, 0, 0.6)'
          }
        }}
      >
        <Box
          height="100%"
          bg="linear-gradient(90deg, #FFD700, #FFA500)"
          width={`${scrollProgress}%`}
          transition="width 0.1s"
          zIndex={9999}
        />
      </Box>

      {/* Navigation */}
      <LandingNavigationBar />

      {/* Main Content Container */}
      <Container maxW="container.xl" px={{ base: 4, md: 8 }}>
        {/* Hero Section */}
        <HeroSection />
      </Container>

      <GlowingDivider />

      {/* Partner Logo Section */}
      <Box mb={10}>
        <Container maxW="100%">
          <PartnerLogoCarousel />
        </Container>
      </Box>

      <GlowingDivider />

      {/* Pain Points Section */}
      <PainPointsSection />

      <GlowingDivider />

      {/* Pain Ends Here Section */}
      <PainEndsHereSection />

      <GlowingDivider />

      {/* Transform Your Real Estate Business Section */}
      <TransformBusinessSection features={enhancedFeatures} />

      <GlowingDivider />

      {/* Testimonials Section - Enhanced */}
      <Box position="relative">
        <Heading
          textAlign="center"
          mb={12}
          fontSize={{ base: '2xl', md: '4xl' }}
          className="gradient-text"
        >
          Success Stories
        </Heading>

        <Box 
          maxW="1000px" 
          mx="auto"
          px={8}
          position="relative"
        >
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
            {enhancedTestimonials.map((testimonial, index) => (
              <Box
                key={index}
                bg="linear-gradient(145deg, rgba(0,0,0,0.8), rgba(0,0,0,0.6))"
                p={8}
                borderRadius="xl"
                borderColor="yellow.500"
                borderWidth="1px"
                position="relative"
                _hover={{
                  transform: 'translateY(-5px)',
                  boxShadow: '0 10px 30px rgba(255, 165, 0, 0.15)'
                }}
                transition="all 0.3s ease"
              >
                <Icon 
                  as={FaQuoteLeft} 
                  w={8} 
                  h={8} 
                  color="yellow.500" 
                  opacity={0.3}
                  mb={4}
                />
                <Text 
                  fontSize="lg" 
                  fontStyle="italic" 
                  mb={6}
                  color="gray.300"
                >
                  {testimonial.quote}
                </Text>
                <Flex align="center" mt={4}>
                  <Box>
                    <Text 
                      fontWeight="bold" 
                      color="yellow.400"
                    >
                      {testimonial.author}
                    </Text>
                    <Text 
                      fontSize="sm" 
                      color="gray.400"
                    >
                      {testimonial.role}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Box>

      <GlowingDivider />

      {/* Additional Sections as Needed */}

      {/* Add before the Pricing section */}
      <GlowingDivider />

      {/* Beta Discount Banner */}
      <Box 
        bg="linear-gradient(45deg, #FFD700, #FFA500)" 
        color="black"
        p={4}
        mb={10}
        borderRadius="lg"
        textAlign="center"
        maxW="600px"
        mx="auto"
      >
        <Text fontWeight="bold">
          🎉 Beta Launch Special: 50% off all plans for life! 🎉
        </Text>
        <Text fontSize="sm" mt={1}>
          Limited time offer for early adopters
        </Text>
      </Box>

      {/* Pricing Section - Enhanced */}
      <PricingSection />

      {/* How It Works Section - Moved up and styled consistently */}
      <GlowingDivider />

      <Container maxW="100%">
        <Stack spacing={8} align="center" textAlign="center">
          <HowItWorksSection />
        </Stack>
      </Container>

      {/* FAQ Section */}
      <GlowingDivider />
      <FAQSection />

      {/* Explainer Video Section */}
      <GlowingDivider />
      <VideoSection />

      {/* Add before the Footer section */}
      <GlowingDivider />

      <FinalCTASection />

      <Footer />

      {/* Add ChatWidget at the end */}
      <ChatWidget />

      <IconButton
        aria-label="Scroll to top"
        icon={<FaArrowUp />}
        position="fixed"
        bottom="20px"
        left="20px"
        colorScheme="yellow"
        size="lg"
        opacity={0.8}
        _hover={{ opacity: 1 }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        display={{ base: 'none', md: 'flex' }}
        sx={{
          visibility: 'hidden',
          '@media screen and (min-width: 48em)': {
            '&.visible': {
              visibility: 'visible'
            }
          }
        }}
      />
    </Box>
  );
};

// Add this testimonials data array before the LandingPage component
const testimonials = [
  {
    quote: "Since I started using Real Talk Pro, my close rate has increased by 30%. The AI follow-ups are a lifesaver!",
    author: "Michael T.",
    role: "Toronto Realtor"
  },
  {
    quote: "I was skeptical at first, but this app has transformed my workflow. I'm closing more deals and finally have my evenings back.",
    author: "Sofia R.",
    role: "Mississauga Agent"
  },
  {
    quote: "The AI-powered calling feature has revolutionized how I handle client conversations. It's like having a personal assistant!",
    author: "James K.",
    role: "GTA Real Estate Agent"
  }
];

// Add this new component after the LandingPage component
const TestimonialCarousel = ({ testimonials }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % testimonials.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(timer);
  }, [testimonials.length]);

  return (
    <Box>
      <Box
        minH="200px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          key={currentSlide}
          animation="fadeIn 0.5s ease-in"
          textAlign="center"
        >
          <Text 
            fontSize="xl" 
            fontStyle="italic" 
            mb={4}
            color="gray.300"
          >
            "{testimonials[currentSlide].quote}"
          </Text>
          <Text 
            fontWeight="bold" 
            color="yellow.400"
          >
            {testimonials[currentSlide].author}
          </Text>
          <Text 
            fontSize="sm" 
            color="gray.400"
          >
            {testimonials[currentSlide].role}
          </Text>
        </Box>
      </Box>

      <Slider
        aria-label="testimonial-slider"
        value={currentSlide}
        onChange={(v) => setCurrentSlide(v)}
        min={0}
        max={testimonials.length - 1}
        step={1}
        mt={6}
      >
        <SliderTrack bg="gray.600">
          <SliderFilledTrack bg="yellow.500" />
        </SliderTrack>
        <SliderThumb boxSize={4} />
      </Slider>
    </Box>
  );
};

// Add features data array:
const features = [
  {
    icon: FaPhone,
    title: 'AI-Powered Calling',
    description: 'Enhance your communication with real-time AI support during calls.',
    feature: 'Real-time AI support during calls',
    benefit: 'Increase closing rate by 30%',
  },
  {
    icon: FaSms,
    title: 'Automated SMS Follow-ups',
    description: 'Keep your clients engaged with timely and personalized SMS messages.',
    feature: 'Automated SMS follow-ups',
    benefit: 'Improve client retention',
  },
  // ... existing features ...
];

export default LandingPage;