import React from 'react';
import { Box, VStack, Text, Button } from '@chakra-ui/react';

const ShowingsBox = ({ showings = [], leadId }) => {
  return (
    <Box bg="surfaceBg" p={4} borderRadius="md" boxShadow="md">
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        Showings
      </Text>
      <VStack align="stretch" spacing={4}>
        {Array.isArray(showings) && showings.length > 0 ? (
          showings.map((showing, index) => (
            <Box key={index} p={2} bg="gray.100" borderRadius="md">
              <Text>{showing.propertyAddress}</Text>
              <Text fontSize="sm" color="gray.500">
                Date: {new Date(showing.date).toLocaleString()}
              </Text>
            </Box>
          ))
        ) : (
          <Text>No showings available.</Text>
        )}
      </VStack>
      <Button mt={4} colorScheme="blue">
        Schedule Showing
      </Button>
    </Box>
  );
};

export default ShowingsBox;