import React from 'react';
import { VStack, Box, Text } from '@chakra-ui/react';

const CloseOptionA = () => {
  return (
    <VStack align="stretch" spacing={4}>
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">When do you think you'll decide whether to stay in the house or move forward?</Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">When would you like to speak with an agent to figure out what repairs you should do on your home for max ROI?</Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Looking to Sell: Would you like me to keep you updated on how much your home is worth?</Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">
          Ok this is all great, I'll follow up with you in [x] month to chat more about [the lender/searching for homes,] if there is anything you need in the meantime you can reach out to myself, again my name's Sasha Bastani. Looking forward to chatting in [month] have a great day!
        </Text>
      </Box>
    </VStack>
  );
};

export default CloseOptionA;