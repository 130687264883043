import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Heading, Divider, VStack, Text, Icon, Select, Flex, Input, InputLeftElement, InputGroup } from '@chakra-ui/react';
import { MdWarning, MdSearch } from 'react-icons/md';
import PropTypes from 'prop-types';

const LeadsList = ({ leads, onSelectLead, selectedLead }) => {
  const [sortBy, setSortBy] = useState('lastActivity');
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allLeads, setAllLeads] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // Update allLeads when leads prop changes
  useEffect(() => {
    setIsLoading(true);
    if (!leads) {
      setAllLeads([]);
      setIsLoading(false);
      return;
    }

    // Handle paginated data
    if (leads.leads && Array.isArray(leads.leads)) {
      setAllLeads(prevLeads => {
        if (page === 1) {
          return leads.leads;
        }
        // Combine leads, avoiding duplicates
        const existingLeadIds = new Set(prevLeads.map(l => l._id));
        const uniqueNewLeads = leads.leads.filter(l => !existingLeadIds.has(l._id));
        return [...prevLeads, ...uniqueNewLeads];
      });
      setHasMore(page < leads.totalPages);
    } 
    // Handle direct array
    else if (Array.isArray(leads)) {
      setAllLeads(leads);
      setHasMore(false);
    }
    setIsLoading(false);
  }, [leads, page]);

  // Memoized filtered and sorted leads
  const filteredAndSortedLeads = useMemo(() => {
    console.log('Filtering leads. Current allLeads:', allLeads);
    if (!Array.isArray(allLeads)) {
      console.warn('allLeads is not an array:', allLeads);
      return [];
    }
    
    return [...allLeads]
      .filter(lead => {
        if (!lead) return false;
        const searchLower = searchTerm.toLowerCase();
        return (
          (lead.name && lead.name.toLowerCase().includes(searchLower)) ||
          (lead.email && lead.email.toLowerCase().includes(searchLower)) ||
          (lead.phone && lead.phone.includes(searchTerm))
        );
      })
      .sort((a, b) => {
        if (!a || !b) return 0;
        
        switch (sortBy) {
          case 'lastActivity':
            return new Date(b.lastActivity || 0) - new Date(a.lastActivity || 0);
          case 'name':
            return (a.name || '').localeCompare(b.name || '');
          case 'email':
            return (a.email || '').localeCompare(b.email || '');
          default:
            return 0;
        }
      });
  }, [allLeads, sortBy, searchTerm]);

  // Handle infinite scroll
  const handleScroll = useCallback((e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && !isLoading && hasMore) {
      setPage(prev => prev + 1);
    }
  }, [isLoading, hasMore]);

  return (
    <Box 
      height="100%"
      display="flex"
      flexDirection="column"
      overflow="hidden"
    >
      {error && (
        <Text color="red.500" mb={4}>{error}</Text>
      )}
      
      {/* Header Section - Fixed */}
      <Box 
        p={4} 
        bg="black"
        borderBottom="1px solid"
        borderColor="orange.400"
      >
        <Heading size="md" mb={2}>
          Leads ({filteredAndSortedLeads.length})
          {isLoading && ' Loading...'}
        </Heading>
        <Flex gap={2}>
          <InputGroup size="sm">
            <InputLeftElement>
              <Icon as={MdSearch} color="gray.400" />
            </InputLeftElement>
            <Input
              placeholder="Search leads..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              bg="gray.700"
              color="white"
              borderColor="orange.400"
              _placeholder={{ color: 'gray.400' }}
            />
          </InputGroup>
          <Select 
            size="sm" 
            width="auto" 
            value={sortBy} 
            onChange={(e) => setSortBy(e.target.value)}
            bg="gray.700"
            color="white"
            borderColor="gray.600"
          >
            <option value="lastActivity">Latest Activity</option>
            <option value="name">Name</option>
            <option value="email">Email</option>
          </Select>
        </Flex>
      </Box>

      {/* Scrollable List Section */}
      <Box 
        flex="1"
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray.500',
            borderRadius: '24px',
          },
        }}
      >
        <VStack 
          spacing={2} 
          align="stretch" 
          p={2} 
          onScroll={handleScroll}
        >
          {filteredAndSortedLeads.map(lead => (
            <Box
              key={lead._id}
              onClick={() => onSelectLead(lead)}
              bg={selectedLead && selectedLead._id === lead._id ? 'gray.700' : 'transparent'}
              _hover={{ bg: 'gray.700' }}
              cursor="pointer"
              p={4}
              borderRadius="md"
              borderBottomWidth="1px"
              borderBottomColor="gray.700"
              position="relative"
              transition="all 0.2s"
            >
              <Text fontWeight="bold">{lead.name || 'Unknown'}</Text>
              <Text fontSize="sm" color="gray.400">{lead.email || 'No email'}</Text>
              <Text fontSize="sm" color="gray.400">{lead.phone || 'No phone number'}</Text>
              <Text fontSize="xs" color="gray.500">
                {lead.lastActivity 
                  ? `Last Activity: ${new Date(lead.lastActivity).toLocaleString()}`
                  : 'Never contacted'}
              </Text>
              {!lead.phone && (
                <Icon
                  as={MdWarning}
                  color="yellow.500"
                  position="absolute"
                  top={2}
                  right={2}
                  title="Missing phone number"
                />
              )}
            </Box>
          ))}
          
          {isLoading && (
            <Box textAlign="center" py={4}>
              <Text>Loading more leads...</Text>
            </Box>
          )}
          
          {!hasMore && filteredAndSortedLeads.length > 0 && (
            <Box textAlign="center" py={4}>
              <Text color="gray.500">No more leads to load</Text>
            </Box>
          )}
          
          {filteredAndSortedLeads.length === 0 && !isLoading && (
            <Box textAlign="center" py={4}>
              <Text color="gray.500">No leads found</Text>
            </Box>
          )}
        </VStack>
      </Box>
    </Box>
  );
};

LeadsList.propTypes = {
  leads: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      leads: PropTypes.array,
      totalLeads: PropTypes.number,
      currentPage: PropTypes.number,
      totalPages: PropTypes.number
    })
  ]),
  onSelectLead: PropTypes.func.isRequired,
  selectedLead: PropTypes.object
};

export default LeadsList;
