import React from 'react';
import { VStack, Box, Text } from '@chakra-ui/react';

const FunnelingQuestions = () => {
  return (
    <VStack align="stretch" spacing={4}>
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Perfect……..</Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Do Weekdays or Weekends usually work better for you?</Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Okay so weekdays are best, generally at the beginning of the week or closer to the weekend</Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">So the start of the week would be best, would you say evening or during the day would be more convenient?</Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">So the start of the week in the evening, correct?</Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Great so which evening at the start of next week would you have open for the strategy session?</Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Great, is there any reason that [X TIME] might not work for you?</Text>
      </Box>

      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Look forward to seeing you then!</Text>
      </Box>
    </VStack>
  );
};

export default FunnelingQuestions;