import React from 'react';
import {
  Box,
  Container,
  Stack,
  Heading,
  Text,
  SimpleGrid,
  Icon,
  Flex
} from '@chakra-ui/react';
import { FaCheck, FaClock, FaChartLine, FaRocket } from 'react-icons/fa';
import { motion } from 'framer-motion';

// Define motion components
const MotionBox = motion(Box);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);

const imaginePoints = [
  {
    icon: FaCheck,
    text: 'Your automated empire generates $500K+ in commissions while you focus on what matters - building relationships and closing deals'
  },
  {
    icon: FaClock,
    text: 'Never miss another hot lead - your AI assistant responds instantly 24/7, scheduling showings while you sleep'
  },
  {
    icon: FaChartLine,
    text: 'Watch your conversion rate soar 5X as your system delivers perfectly timed, personalized follow-ups that make clients feel like royalty'
  },
  {
    icon: FaRocket,
    text: 'Join the elite 1% who\'ve cracked the code - building wealth through automation while others drown in busywork'
  }
];

const PainEndsHereSection = () => {
  return (
    <Box 
      position="relative"
      py={16}
      bg="linear-gradient(145deg, rgba(0,0,0,0.9), rgba(0,0,0,0.7))"
      borderRadius="xl"
      overflow="hidden"
      mx={4}
      _after={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: 'url("/path/to/subtle-pattern.png")',
        opacity: 0.05,
        zIndex: 0,
      }}
    >
      <Container maxW="1400px">
        <Stack spacing={12} align="center">
          <MotionHeading
            textAlign="center"
            fontSize={{ base: '3xl', md: '4xl' }}
            className="gradient-text"
            position="relative"
            _before={{
              content: '""',
              position: 'absolute',
              bottom: '-10px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100px',
              height: '4px',
              background: 'linear-gradient(90deg, #FFD700, #FFA500)'
            }}
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Stop Struggling. Start Dominating. Your Empire Awaits.
          </MotionHeading>

          <MotionText 
            fontSize={{ base: 'xl', md: '2xl' }} 
            color="yellow.400" 
            fontWeight="bold"
            textAlign="center"
            maxW="800px"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
          >
            Imagine Waking Up Tomorrow To:
          </MotionText>

          <SimpleGrid 
            columns={{ base: 1, md: 2 }} 
            spacing={8} 
            maxW="1000px"
          >
            {imaginePoints.map((point, index) => (
              <MotionBox
                key={index}
                p={6}
                bg="linear-gradient(145deg, rgba(0,0,0,0.8), rgba(0,0,0,0.6))"
                borderRadius="lg"
                borderColor="yellow.500"
                borderWidth="1px"
                whileHover={{ 
                  scale: 1.05, 
                  boxShadow: '0 10px 30px rgba(255, 165, 0, 0.3)',
                  borderColor: 'yellow.400'
                }}
                transition={{ duration: 0.3 }}
              >
                <Flex align="center" gap={4}>
                  <Icon 
                    as={point.icon} 
                    w={8} 
                    h={8} 
                    color="yellow.400"
                  />
                  <Text 
                    fontSize={{ base: 'lg', md: 'xl' }}
                    color="gray.100"
                  >
                    {point.text}
                  </Text>
                </Flex>
              </MotionBox>
            ))}
          </SimpleGrid>

          <MotionText 
            fontSize={{ base: 'xl', md: '2xl' }} 
            color="red.400" 
            fontWeight="bold"
            textAlign="center"
            maxW="800px"
            mt={8}
            p={4}
            border="1px solid"
            borderColor="red.500"
            borderRadius="lg"
            bg="rgba(255, 0, 0, 0.1)"
            initial={{ opacity: 0, y: 30 }}
            animate={{ 
              scale: [1, 1.02, 1],
              opacity: [0.9, 1, 0.9] 
            }}
            transition={{ 
              repeat: Infinity,
              duration: 2
            }}
          >
            WARNING: Every Day You Cling to Old Systems Costs You $1,000+ in Lost Opportunities. Your Competition is Already Automating. How Much Longer Can You Afford to Wait?
          </MotionText>
        </Stack>
      </Container>
    </Box>
  );
};

export default PainEndsHereSection; 