import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
  VStack,
} from '@chakra-ui/react';

const AddNoteModal = ({ isOpen, onClose, onSubmit, isLoading }) => {
  const [noteContent, setNoteContent] = useState('');

  const handleSubmit = async () => {
    try {
      await onSubmit(noteContent);
      setNoteContent('');
      onClose();
    } catch (error) {
      console.error('Error submitting note:', error);
      // Error handling is done in the parent component
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="gray.900" borderColor="orange.400" borderWidth="1px">
        <ModalHeader color="orange.400">Add New Note</ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody>
          <VStack spacing={4}>
            <Textarea
              value={noteContent}
              onChange={(e) => setNoteContent(e.target.value)}
              placeholder="Enter your note here... in AddNoteModal"
              size="lg"
              bg="gray.800"
              color="white"
              borderColor="orange.400"
              _hover={{ borderColor: "orange.300" }}
              _focus={{ borderColor: "orange.300", boxShadow: "0 0 0 1px #ED8936" }}
            />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="orange"
            mr={3}
            onClick={handleSubmit}
            isLoading={isLoading}
            isDisabled={!noteContent.trim() || isLoading}
          >
            Add Note
          </Button>
          <Button variant="ghost" onClick={onClose} isDisabled={isLoading}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddNoteModal;