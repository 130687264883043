// frontend/src/App.js

import React, { useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet, useLocation } from 'react-router-dom';
import { ChakraProvider, Box, Flex } from '@chakra-ui/react';
import Dashboard from './pages/Dashboard';
import LeadManagement from './pages/LeadManagement';
import CallScript from './pages/CallScript';
import NavBar from './components/Navbar';
import theme from './theme.js.old.delete';
import LeadDetails from './pages/LeadDetails';
import Communications from './pages/Communications';
import CallsLogPage from './pages/CallsLogPage';
import IncomingCallNotification from './components/IncomingCallNotification';
import IncomingSmsNotification from './components/IncomingSmsNotification';
import Settings from './pages/SettingsPage';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import { TwilioProvider } from './context/TwilioContext';
import { connectSocket, disconnectSocket } from './socket';
import { AuthProvider, useAuth } from './context/AuthContext';
import { StyleSheetManager } from 'styled-components';
import ReportsPage from './pages/ReportsPage';
import CallReports from './pages/CallReports';
import ErrorBoundary from './components/ErrorBoundary';
import LandingPage from './pages/LandingPage';
import LogRocket from 'logrocket';
import { ThemeProvider, useTheme } from './context/ThemeContext';
import CustomizationPage from './pages/CustomizationPage';
import OldHomePage from './pages/OldHomePage';
import NewHomePage from './pages/NewHomePage';
import { AnimatePresence } from 'framer-motion';

// Initialize LogRocket at the top level
LogRocket.init('9r0rwk/real-talk-pro');

// Create a PrivateRoute component
const PrivateRoute = () => {
  const { currentUser, loading } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  return currentUser ? <Outlet /> : <Navigate to="/login" />;
};

// Move the App component inside AppWithProviders
const AppWithProviders = () => {
  return (
    <Router>
      <AuthProvider>
        <ThemeProvider>
          <AppContent />
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
};

// Rename App to AppContent and keep its implementation
const AppContent = () => {
  const { currentTheme, showBorders, isDark } = useTheme();
  const location = useLocation();
  
  const handleCallStatusChange = useCallback((status) => {
    console.log('Call status changed:', status);
  }, []);

  const { currentUser, loading } = useAuth();

  React.useEffect(() => {
    connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <StyleSheetManager shouldForwardProp={(prop) => prop !== 'theme'}>
      <ChakraProvider theme={currentTheme}>
        <AnimatePresence mode="wait">
          <TwilioProvider>
            <Flex 
              direction="column" 
              height="100vh"
              className="theme-transition"
              overflow="hidden"
              position="relative"
              border={showBorders ? "1px solid" : "none"}
              borderColor={currentTheme.colors.primaryAccent}
            >
              <Box
                position="fixed"
                top={0}
                left={0}
                right={0}
                height="1px"
                bg="orange.400"
                zIndex={1001}
              />
              <Box
                position="fixed"
                bottom={0}
                left={0}
                right={0}
                height="1px"
                bg="orange.400"
                zIndex={1001}
              />
              <Box
                position="fixed"
                top={0}
                left={0}
                width="1px"
                height="100vh"
                bg="orange.400"
                zIndex={1001}
              />
              <Box
                position="fixed"
                top={0}
                right={0}
                width="1px"
                height="100vh"
                bg="orange.400"
                zIndex={1001}
              />

              {currentUser && <NavBar />}
              {currentUser && <IncomingCallNotification onCallStatusChange={handleCallStatusChange} />}
              {currentUser && <IncomingSmsNotification />}
              <Box 
                flex="1" 
                mt={currentUser ? "64px" : "0"}
                overflow="auto"
                display="flex"
                flexDirection="column"
                position="relative"
                className="font-body"
              >
                <Routes location={location} key={location.pathname}>
                  {/* Update landing page route to be accessible when logged in */}
                  <Route 
                    path="/" 
                    element={currentUser ? <Navigate to="/dashboard" /> : <LandingPage />} 
                  />
                  
                  {/* Add new route for accessing landing page when logged in */}
                  <Route 
                    path="/landing" 
                    element={<LandingPage />} 
                  />
                  
                  {/* Login route with redirect if already logged in */}
                  <Route 
                    path="/login" 
                    element={!currentUser ? <Login /> : <Navigate to="/dashboard" />} 
                  />
                  
                  {/* Private routes */}
                  <Route element={<PrivateRoute />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/newhome" element={<NewHomePage />} />
                    <Route path="/oldhome" element={<OldHomePage />} />
                    <Route path="/leads" element={<LeadManagement />} />
                    <Route path="/leads/:id" element={<LeadDetails />} />
                    <Route path="/call-script" element={<CallScript />} />
                    <Route path="/communications" element={<Communications />} />
                    <Route path="/lead/:id" element={<LeadDetails />} />
                    <Route path="/calls-log" element={<CallsLogPage />} />
                    <Route path="/call-reports" element={<CallReports />} />
                    <Route path="/reports" element={<ReportsPage />} />
                    <Route 
                      path="/settings" 
                      element={
                        <ErrorBoundary>
                          <Settings />
                        </ErrorBoundary>
                      } 
                    />
                  </Route>

                  <Route path="/customize" element={<CustomizationPage />} />

                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Box>
            </Flex>
          </TwilioProvider>
        </AnimatePresence>
      </ChakraProvider>
    </StyleSheetManager>
  );
}

export default AppWithProviders;
