import React from 'react';
import { Box, Heading, Text, SimpleGrid, Icon, Flex, Container } from '@chakra-ui/react';
import { FaExclamationTriangle, FaClock, FaRocket, FaExclamationCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';

// Define motion components
const MotionBox = motion(Box);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);

const enhancedPainPoints = [
  {
    icon: FaExclamationTriangle,
    title: 'Missing Out on $50K-$100K Commissions While You Sleep',
    description: 'Every unanswered evening call or weekend inquiry is a potential multi-million dollar listing lost forever. Your competition\'s automated systems are capturing these leads at 2 AM while you\'re sleeping. How many dream clients slipped through your fingers last month alone?'
  },
  {
    icon: FaClock,
    title: 'Burning $150,000+ Yearly on Tasks a Robot Could Do',
    description: 'Calculate this: 4 hours daily on follow-ups, data entry, and scheduling at your $500/hour potential rate = $730,000 in opportunity cost yearly. Top producers automated these $15/hour tasks years ago. They\'re closing deals while you\'re updating spreadsheets.'
  },
  {
    icon: FaRocket,
    title: 'Tech-Savvy Competitors Are Eating Your Lunch',
    description: 'While you\'re manually following up, younger agents with automated systems are stealing your market share. They\'re delivering instant responses, 24/7 availability, and concierge-level service at scale. Every day you delay modernizing, they\'re converting more of your potential clients.'
  },
  {
    icon: FaExclamationCircle,
    title: 'Your Reputation Is Silently Suffering',
    description: 'In the age of instant gratification, delayed responses make you look unprofessional. High-net-worth clients expect white-glove service. They\'re quietly judging your 4-hour response time while your competitors reply instantly. How many referrals are you losing?'
  },
  // Additional Enhancements
  {
    icon: FaExclamationTriangle,
    title: 'Struggling to Manage High Volume Leads',
    description: 'As your business grows, so does the number of leads. Without an efficient system, managing and nurturing these leads becomes overwhelming, leading to missed opportunities and decreased client satisfaction.'
  },
  {
    icon: FaClock,
    title: 'Inefficient Follow-Up Processes Draining Your Time',
    description: 'Manual follow-ups consume valuable time that could be better spent on closing deals and building client relationships. Streamlining these processes is essential for maximizing productivity and revenue.'
  },
];

const PainPointsSection = () => {
  return (
    <Container maxW="100%" px={{ base: 4, md: 8 }}>
      <MotionHeading
        as="h2"
        textAlign="center"
        mb={10}
        fontSize={{ base: '2xl', md: '3xl' }}
        className="gradient-text"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        The BRUTAL REALITY: Why 87% of Toronto Realtors Are Struggling While Top 1% Producers Clear $1M+ Annually
      </MotionHeading>
      
      <MotionText 
        fontSize="md" 
        color="gray.400" 
        maxW="800px" 
        mx="auto" 
        mb={10}
        textAlign="center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.8 }}
      >
        In Toronto\'s explosive $13.2B luxury market, every minute of delay costs you thousands. With 80,000+ hungry agents fighting for the same high-net-worth clients, and tech-savvy millennials inheriting wealth, outdated systems aren\'t just inefficient—they\'re career suicide. Top producers aren\'t just working smarter; they\'ve built automated empires that generate leads, nurture relationships, and close deals 24/7/365.
      </MotionText>

      <MotionHeading
        as="h2"
        textAlign="center"
        mb={10}
        fontSize={{ base: '2xl', md: '3xl' }}
        className="gradient-text"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.8, duration: 0.8 }}
      >
        Stop Lying to Yourself - Face These Brutal Truths:
      </MotionHeading>

      <SimpleGrid 
        columns={{ base: 1, md: 2, lg: 3 }} 
        spacing={8}
        maxW="1200px"
        mx="auto"
      >
        {enhancedPainPoints.map((point, index) => (
          <MotionBox
            key={index}
            p={8}
            borderRadius="xl"
            bg="linear-gradient(145deg, rgba(0,0,0,0.8), rgba(0,0,0,0.6))"
            borderColor="yellow.500"
            borderWidth="1px"
            position="relative"
            whileHover={{ scale: 1.05, boxShadow: '0 10px 30px rgba(255, 165, 0, 0.3)' }}
            transition={{ duration: 0.3 }}
          >
            <Flex direction="column" align="center" textAlign="center">
              <Icon 
                as={point.icon} 
                w={12} 
                h={12} 
                color="yellow.500" 
                mb={6}
                _hover={{ transform: 'rotate(360deg)' }}
                transition="transform 0.6s ease"
              />
              <Text 
                fontWeight="bold" 
                fontSize="xl"
                mb={4} 
                color="yellow.400"
              >
                {point.title}
              </Text>
              <Text fontSize="md" color="gray.300">{point.description}</Text>
            </Flex>
          </MotionBox>
        ))}
      </SimpleGrid>
    </Container>
  );
};

export default PainPointsSection; 