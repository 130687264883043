import React from 'react';
import {
  Box,
  Container,
  Stack,
  Heading,
  Text,
  SimpleGrid,
  Button,
  Icon,
  Flex
} from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import { enhancedPlans } from './plans';

const PricingSection = () => {
  return (
    <Box 
      position="relative"
      py={20}
      bg="linear-gradient(145deg, rgba(0,0,0,0.9), rgba(0,0,0,0.7))"
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor="orange.400"
    >
      <Container maxW="100%">
        <Stack spacing={8} align="center" textAlign="center" mb={12}>
          <Box 
            position="relative" 
            width="100%"
            textAlign="center"
            _before={{
              content: '""',
              position: 'absolute',
              top: '-20px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '200px',
              height: '4px',
              background: 'linear-gradient(90deg, transparent, #FFD700, transparent)',
            }}
          >
            <Heading
              fontSize={{ base: '2xl', md: '4xl' }}
              className="gradient-text"
              textAlign="center"
              mx="auto"
            >
              Exclusive Beta Pricing
            </Heading>
          </Box>
          <Text 
            fontSize={{ base: 'lg', md: 'xl' }}
            color="gray.400"
            maxW="800px"
            textAlign="center"
          >
            Lock in our lowest rates ever - Limited time beta pricing available now
          </Text>
        </Stack>

        <SimpleGrid 
          columns={{ base: 1, md: 3 }} 
          spacing={8} 
          maxW="1200px" 
          mx="auto"
          px={4}
        >
          {enhancedPlans.map((plan, index) => (
            <Box
              key={index}
              position="relative"
              bg="linear-gradient(145deg, rgba(0,0,0,0.9), rgba(0,0,0,0.7))"
              borderRadius="2xl"
              overflow="hidden"
              transform={plan.isPopular ? 'scale(1.05)' : 'scale(1)'}
              zIndex={plan.isPopular ? 2 : 1}
              borderColor="orange.400"
              borderWidth="1px"
              boxShadow={plan.isPopular ? '0 0 40px rgba(255, 165, 0, 0.3)' : 'none'}
              _hover={{ 
                transform: plan.isPopular ? 'scale(1.07)' : 'scale(1.02)',
                boxShadow: '0 20px 40px rgba(255, 165, 0, 0.15)'
              }}
              transition="all 0.4s ease"
            >
              {plan.isPopular && (
                <Box
                  position="absolute"
                  top={4}
                  right={4}
                  bg="yellow.400"
                  color="black"
                  px={3}
                  py={1}
                  borderRadius="full"
                  fontSize="sm"
                  fontWeight="bold"
                >
                  Most Popular
                </Box>
              )}
              <Box p={8}>
                <Text
                  fontSize="2xl"
                  fontWeight="bold"
                  mb={2}
                  color="yellow.400"
                >
                  {plan.name}
                </Text>
                <Flex align="baseline" mb={6}>
                  <Text fontSize="5xl" fontWeight="bold" color="white">
                    ${plan.price}
                  </Text>
                  <Text ml={2} color="gray.400">/month</Text>
                </Flex>
                <Button
                  w="full"
                  colorScheme={plan.isPopular ? "yellow" : "gray"}
                  mb={8}
                  size="lg"
                  _hover={{
                    transform: 'translateY(-2px)',
                    boxShadow: '0 10px 20px rgba(255, 165, 0, 0.2)'
                  }}
                >
                  Get Started
                </Button>
                <Stack spacing={4}>
                  {plan.features.map((feature, idx) => (
                    <Flex 
                      key={idx} 
                      align="center"
                      opacity={idx < 5 ? 1 : 0.8}
                    >
                      <Icon 
                        as={FaCheck} 
                        color="green.400" 
                        mr={3}
                        w={5}
                        h={5}
                      />
                      <Text>{feature}</Text>
                    </Flex>
                  ))}
                </Stack>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default PricingSection; 