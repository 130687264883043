import { io } from 'socket.io-client';

// Update the SOCKET_SERVER_URL to use the main domain
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL || 'https://www.realtalkpro.com';

// Initialize socket as null
let socket = null;

// Add connection status tracking
let isConnecting = false;

// Function to connect socket
export const connectSocket = () => {
  if (!socket && !isConnecting) {
    isConnecting = true;
    
    socket = io(SOCKET_SERVER_URL, {
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 2000,
      timeout: 20000,
      autoConnect: true,
      withCredentials: true,
      path: '/socket.io',
      auth: {
        token: localStorage.getItem('authToken')
      }
    });

    // Add reconnect event handler
    socket.on('reconnect_attempt', (attempt) => {
      console.log(`Reconnection attempt ${attempt}`);
    });

    // Add error logging
    socket.on('error', (error) => {
      console.error('Socket error:', error);
    });
  }
  return socket;
};

// Function to get the socket instance
export const getSocket = () => {
  if (!socket) {
    return connectSocket();
  }
  return socket;
};

// Function to disconnect socket
export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
    isConnecting = false;
    console.log('Socket disconnected and cleaned up');
  }
};

// Export the getSocket function instead of the socket instance
export default getSocket;
