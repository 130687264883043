import React, { useState } from 'react';
import {
  VStack, Box, Text, Button, SimpleGrid, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,
  UnorderedList, ListItem, HStack
} from '@chakra-ui/react';

const CurrentStateQuestions = ({ step, handleLeadDataChange, setCurrentStageIndex, callFlow, leadSource, getEstablishAndDisarmContent }) => {
  const [selectedUserType, setSelectedUserType] = useState('end_user');

  const renderCurrentStateQuestions = () => {
    const handleResponseAndNavigate = (response) => {
      // Log the response
      console.log("Current State Response:", response);
      console.log("Current lead source:", leadSource);
      console.log("Establish and disarm content:", getEstablishAndDisarmContent());
      
      // Update lead data with the response
      handleLeadDataChange('currentStateResponse', response);
      
      // Navigate to Obstacle Inquiries
      const introSectionIndex = callFlow.findIndex(section => section.id === 'intro');
      const obstacleInquiriesIndex = callFlow[introSectionIndex].stages.findIndex(stage => stage.id === 'obstacle_inquiries');
      setCurrentStageIndex({ section: introSectionIndex, stage: obstacleInquiriesIndex });
    };

    return (
      <VStack align="stretch" spacing={4}>
        {/* Purpose as an accordion */}
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text fontWeight="bold" color="white">Purpose</Text>
                </Box>
                <AccordionIcon color="yellow.500" />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <UnorderedList>
                {step.purpose.map((item, index) => (
                  <ListItem key={index} color="white">{item}</ListItem>
                ))}
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        {/* User Type Selection */}
        <SimpleGrid columns={2} spacing={4} width="100%">
          <Button 
            onClick={() => setSelectedUserType('end_user')}
            colorScheme={selectedUserType === 'end_user' ? "yellow" : "gray"}
            width="100%"
            height="50px"
          >
            End User
          </Button>
          <Button 
            onClick={() => setSelectedUserType('investor')}
            colorScheme={selectedUserType === 'investor' ? "yellow" : "gray"}
            width="100%"
            height="50px"
          >
            Investor
          </Button>
        </SimpleGrid>

        {/* Questions based on selected user type */}
        <VStack align="stretch" spacing={2}>
          {selectedUserType === 'end_user' ? (
            <>
              <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
                <Text fontWeight="bold" color="white">Can you tell me a little bit about your current living situation?</Text>
                <Text fontSize="sm" color="gray.400" mt={2}>Tip: Use an open-ended, curious tone to avoid sounding interrogative</Text>
              </Box>
              <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
                <Text fontWeight="bold" color="white">How long have you been living there?</Text>
              </Box>
              <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
                <Text fontWeight="bold" color="white">I'm curious, what caused you to go with that property at the time over something else?</Text>
                <Text fontSize="sm" color="gray.400" mt={2}>Tip: The first thing mentioned is often the most important</Text>
              </Box>
              <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
                <Text fontWeight="bold" color="white">If you even decided to move, what would you be looking for?</Text>
              </Box>
            </>
          ) : (
            <>
              <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
                <Text fontWeight="bold" color="white">Oh, a rental property. That's great. Do you have an existing portfolio now?</Text>
                <Text fontSize="sm" color="gray.400" mt={2}>Tip: Use an open-ended, curious tone to avoid sounding interrogative</Text>
              </Box>
              <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
                <Text fontWeight="bold" color="white">I'm curious, what's piqued your interest to invest your money into real estate over other types of investments?</Text>
                <Text fontSize="sm" color="gray.400" mt={2}>Tip: The first thing mentioned is often the most important</Text>
              </Box>
              <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
                <Text fontWeight="bold" color="white">How long have you been thinking about purchasing an investment property?</Text>
              </Box>
              <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
                <Text fontWeight="bold" color="white">We talk to a lot of different investors, what is the most important component to you when it comes to your investment property?</Text>
              </Box>
            </>
          )}
        </VStack>

        {/* Tips as an accordion */}
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text fontWeight="bold" color="white">Tips</Text>
                </Box>
                <AccordionIcon color="yellow.500" />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <UnorderedList>
                {step.tips.map((tip, index) => (
                  <ListItem key={index} color="white">{tip}</ListItem>
                ))}
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        {/* New Neutral Language Phrases accordion */}
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text fontWeight="bold" color="white">Neutral Language Phrases</Text>
                </Box>
                <AccordionIcon color="yellow.500" />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <UnorderedList>
                <ListItem color="white">I guess I should probably ask…</ListItem>
                <ListItem color="white">Just to make sure that I can help you best…</ListItem>
                <ListItem color="white">Out of curiosity…</ListItem>
                <ListItem color="white">With that being said, let me ask…</ListItem>
                <ListItem color="white">Just before I let you go…</ListItem>
                <ListItem color="white">Do you mind if I ask…</ListItem>
                <ListItem color="white">I'm guessing you haven't got around to it.</ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        {/* Response Buttons */}
        <Box mt={4}>
          <Text fontWeight="bold" color="white" mb={2}>Select Response:</Text>
          <HStack spacing={2}>
            <Button 
              colorScheme="green" 
              onClick={() => handleResponseAndNavigate('Positive')}
            >
              Positive
            </Button>
            <Button 
              colorScheme="yellow" 
              onClick={() => handleResponseAndNavigate('Neutral')}
            >
              Neutral
            </Button>
            <Button 
              colorScheme="red" 
              onClick={() => handleResponseAndNavigate('Negative')}
            >
              Negative
            </Button>
          </HStack>
        </Box>
      </VStack>
    );
  };

  // Render the current state questions
  return renderCurrentStateQuestions();
};

export default CurrentStateQuestions;