import React from 'react';
import { Box, Button, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverArrow, PopoverCloseButton, PopoverBody, Text, VStack } from '@chakra-ui/react';

const MatchingLeadsPopover = ({ matchingLeads, onSelectLead, isOpen, onClose }) => {
  const groupedLeads = matchingLeads.reduce((acc, lead) => {
    if (!acc[lead.email]) {
      acc[lead.email] = [];
    }
    acc[lead.email].push(lead);
    return acc;
  }, {});

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="bottom" closeOnBlur={false}>
      <PopoverTrigger>
        <Box></Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight="semibold">Matching Leads</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <VStack align="stretch">
            {Object.entries(groupedLeads).map(([email, leads]) => (
              <Box key={email}>
                <Text fontWeight="bold">{email}</Text>
                {leads.map((lead) => (
                  <Button
                    key={lead._id}
                    onClick={() => onSelectLead(lead)}
                    variant="outline"
                    justifyContent="flex-start"
                    size="sm"
                    mt={1}
                  >
                    {lead.name} - {lead.phone}
                  </Button>
                ))}
              </Box>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default MatchingLeadsPopover;