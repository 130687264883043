import React from 'react';
import { VStack, Box, Text, UnorderedList, ListItem, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';

const QualificationQuestions = () => {
  return (
    <VStack align="stretch" spacing={4}>
      {/* Purpose Accordion */}
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontWeight="bold" color="white">Purpose</Text>
              </Box>
              <AccordionIcon color="yellow.500" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <UnorderedList spacing={2} color="gray.400">
              <ListItem>Assess the prospect's readiness to make a change</ListItem>
              <ListItem>Understand the urgency and motivation behind their interest</ListItem>
              <ListItem>Evaluate the prospect's financial capability</ListItem>
              <ListItem>Explore potential scenarios and their impact on the prospect</ListItem>
              <ListItem>Gauge the prospect's level of commitment to making a move</ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
  
      {/* Qualification Questions */}
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Why is that important to you though?</Text>
      </Box>
  
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Why now though…why not just wait and do it later?</Text>
      </Box>
  
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">If there was a property that popped up that met your criteria, would you even have the funding to make a change?</Text>
      </Box>
  
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">How do you think it might affect you if...</Text>
        <UnorderedList color="gray.400" mt={2}>
          <ListItem>Your landlord decides to sell, or move back in?</ListItem>
          <ListItem>Your commute stays the same?</ListItem>
          <ListItem>Interest rates stay the same?</ListItem>
        </UnorderedList>
      </Box>
  
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Do you feel with your current rental payments, and how much you're saving each month, you're on track...</Text>
      </Box>
  
      <Box borderWidth="1px" borderColor="yellow.500" borderRadius="md" p={4}>
        <Text fontWeight="bold" color="white">Extra Probing</Text>
        <Text color="gray.400" mt={2}>You mentioned you're not thrilled about [LOCATION, RENTING, CURRENT HOME, LIVING AT HOME]...If a property popped in your [INSERT CRITERIA]....how important would it be for you to be...</Text>
      </Box>
  
      {/* Takeaways Accordion */}
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontWeight="bold" color="white">Takeaways</Text>
              </Box>
              <AccordionIcon color="yellow.500" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <UnorderedList spacing={2} color="gray.400">
              <ListItem>These questions help determine if the prospect is truly qualified and ready to move forward</ListItem>
              <ListItem>Understanding the 'why' behind their interest reveals their true motivation</ListItem>
              <ListItem>Exploring potential scenarios helps the prospect visualize the impact of making or not making a change</ListItem>
              <ListItem>Financial questions are crucial to ensure the prospect can actually make a move if they find the right property</ListItem>
              <ListItem>The extra probing question ties back to earlier pain points, reinforcing the need for change</ListItem>
              <ListItem>These questions also help you tailor your approach and recommendations moving forward</ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </VStack>
  );
};

export default QualificationQuestions;