// frontend\src\pages\CallScript.js
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useRef, useMemo, useContext } from 'react';
import {
  Box, VStack, HStack, Heading, Text, Button, Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex, Grid, GridItem, IconButton, Textarea, Input, Select, useToast,
  List, ListItem, ListIcon, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, SimpleGrid, Divider, 
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  useDisclosure, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverArrow, PopoverCloseButton,
  ChakraProvider, Collapse, OrderedList, UnorderedList, SlideFade, Icon, Portal, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Checkbox,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
AlertDialogHeader,
AlertDialogContent,
AlertDialogOverlay,
FormControl,
FormLabel,
Center,
Tooltip,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Badge,
  Spinner,
  ButtonGroup,
} from '@chakra-ui/react';
import { MdNavigateNext, MdNavigateBefore, MdCheckCircle, MdInfo, MdArrowForward, MdHandshake, MdLightbulb, MdRocketLaunch, MdQuestionAnswer, MdSearch, MdChat, MdAssignment, MdWarning, MdEventNote, MdSort, MdZoomIn, MdSchedule, MdMeetingRoom, FaCheckCircle, MdEye, MdPowerOff } from 'react-icons/md';
import { FaUsers, FaUserAlt,FaVolumeUp, FaHome, FaLanguage, FaTachometerAlt, FaSave, FaComments, FaUserFriends, FaCaretDown, FaPhone, FaMicrophoneAlt, FaEllipsisV, FaMicrophoneAltSlash, FaUserCircle, FaUserTie, FaBalanceScale, FaShieldAlt, FaHeart, FaClipboardList, FaGlobe, FaHistory, FaPlus, FaRedoAlt, FaClock, FaMicrophone, FaFileAlt, FaSms, FaEnvelope, FaPhoneSlash, FaPhoneVolume, FaCommentDots, FaEnvelopeOpenText, FaEye, FaExclamationTriangle } from 'react-icons/fa';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { motion } from "framer-motion";
import { keyframes } from '@emotion/react';
import { useLocation, useNavigate } from 'react-router-dom'; 
import LeadTimeline from '../components/LeadTimeline';
import { debounce } from 'lodash';
import { Device } from '@twilio/voice-sdk';
import DuplicateLeadsModal from '../components/DuplicateLeadsModal';
import { formatPhoneNumber, validateAndFormatPhoneNumber, isValidPhoneNumber } from '../utils/phoneUtils';
import NotesBox from '../components/NotesBox'; // Import NotesBox component
import io from 'socket.io-client';
import CallActions from '../components/CallActions'; // Import the new component
import { TwilioContext } from '../context/TwilioContext';
import essentialSkills from '../components/callScriptComponents/EssentialSkills';
import EssentialSkillIcon from '../components/callScriptComponents/EssentialSkillIcon';
import AcknowledgeAndAddValue from '../components/callScriptComponents/AcknowledgeAndAddValue';
import callFlow from '../components/callScriptComponents/CallFlowData';
import GoldenTurn from '../components/callScriptComponents/GoldenTurn';
import MatchingLeadsPopover from '../components/MatchingLeadsPopover';
import CurrentStateQuestions from '../components/callScriptComponents/CurrentStateQuestions';
import ObstacleInquiries from '../components/callScriptComponents/ObstacleInquiries';
import WayOutAcknowledgement from '../components/callScriptComponents/WayOutAcknowledgement';
import QualificationQuestions from '../components/callScriptComponents/QualificationQuestions';
import CloseOptionA from '../components/callScriptComponents/CloseOptionA';
import CloseOptionB from '../components/callScriptComponents/CloseOptionB';
import ObjectionHandling from '../components/callScriptComponents/ObjectionHandling';
import FunnelingQuestions from '../components/callScriptComponents/FunnelingQuestions';
import { websiteSignupVariants, greetingVariants, introductionVariants } from '../components/callScriptComponents/CallFlowData';
import styled from '@emotion/styled';
import { useTheme } from '../context/ThemeContext'; // Add this import


// Add this before the CallScript component definition
const debouncedHandleNoteChange = debounce((value, handleLeadDataChange) => {
  handleLeadDataChange('newNote', value);
}, 300); // 300ms delay

// Set the base URL for API calls
axios.defaults.baseURL = process.env.REACT_APP_API_URL;


const ScriptSection = ({ title, content, icon, isOrdered = false }) => {
const [isOpen, setIsOpen] = useState(false);
const bgColor = 'orange.800';

return (
  <Box 
    borderRadius="xl" 
    boxShadow="lg" 
    bg='black'
    backdropFilter="blur(10px)"
    border='orange.800'
    transition="all 0.2s"
    p={4} 
    mb={2}
  >
    <HStack spacing={4} onClick={() => setIsOpen(!isOpen)} cursor="pointer">
      <Box as={icon} size="24px" color="brand.500" />
      <Heading size="md">{title}</Heading>
    </HStack>
    <Collapse in={isOpen} animateOpacity>
      <Box mt={4} bg={bgColor} p={4} borderRadius="md">
        {Array.isArray(content) ? (
          isOrdered ? (
            <OrderedList spacing={2}>
              {content.map((item, index) => (
                <ListItem key={index}>{item}</ListItem>
              ))}
            </OrderedList>
          ) : (
            <List spacing={2}>
              {content.map((item, index) => (
                <ListItem key={index}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  {item}
                </ListItem>
              ))}
            </List>
          )
        ) : (
          <Text>{content}</Text>
        )}
      </Box>
    </Collapse>
  </Box>
);
};

// Define the call flow stages

// Add these styled components
const StyledVariantControl = styled(Box)`
  position: relative;
  padding: 1rem;
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid;
  border-color: var(--chakra-colors-yellow-500);
  margin-bottom: 1rem;
  
  &:hover {
    border-color: var(--chakra-colors-yellow-300);
  }
`;

const VariantButton = styled(IconButton)`
  transition: all 0.2s;
  
  &:not(:disabled):hover {
    transform: scale(1.1);
    background: var(--chakra-colors-yellow-500);
    color: black;
  }
`;

const MotionBox = motion(Box);

const StageButton = ({ icon, label, onClick, isActive }) => (
  <Button
    leftIcon={<Icon as={icon} boxSize="1.5em" color={isActive ? "white" : "yellow.500"} />}
    onClick={onClick}
    width="100%"
    justifyContent="flex-start"
    bg={isActive ? "yellow.500" : "transparent"}
    color={isActive ? "black" : "white"}
    _hover={{
      bg: isActive ? "yellow.600" : "yellow.700",
      color: isActive ? "black" : "yellow.500",
    }}
    mb={1}
    height="50px"
    whiteSpace="normal"
    textAlign="left"
    px={2}
    overflow="hidden"
  >
    <Text fontSize="sm" isTruncated>{label}</Text>
  </Button>
);



const processLeadData = (data) => {
  return {
    ...data,
    notes: Array.isArray(data.notes) ? data.notes : [],
  };
};

// Use this function whenever you set leadData from backend responses



const EnhancedCallScriptStep = ({
  step,
  leadSource,
  leadData,
  propertyAddress,
  onResponse,
  handleLeadSourceButtonClick,
  handleLeadDataChange,
  setCurrentStageIndex,
  callFlow,
  wayOutResponse,
  setWayOutResponse,
  handleObjectionResponse,
  handleVariantChange,
  greetingVariant,
  introductionVariant,
  isDuplicateAware, // Add this prop
}) => {
  // Add useToast hook
  const toast = useToast();

  const getEstablishAndDisarmContent = () => {
    // Get the current variant from leadData or default to option1
    const currentVariant = leadData.websiteSignupVariant || 'option1';
    
    // Get all variant keys
    const variantKeys = Object.keys(websiteSignupVariants);
    const currentIndex = variantKeys.indexOf(currentVariant);

    // Get previous and next variants
    const prevVariant = currentIndex > 0 ? variantKeys[currentIndex - 1] : null;
    const nextVariant = currentIndex < variantKeys.length - 1 ? variantKeys[currentIndex + 1] : null;

    // Add this function to get the current introduction script
    const getCurrentIntroductionScript = () => {
      const variant = leadData.introductionVariant || 'option1';
      return introductionVariants[variant]?.script;
      {/* Update the introduction variant display */}
            <Box mt={4}>
              <Text color="white" fontWeight="bold">Introduction:</Text>
              <Text color="white">{getCurrentIntroductionScript()}</Text>
            </Box>
    };

    switch (leadSource) {
      case 'website_signup':
        return (
          <Box position="relative">
            <HStack spacing={4} mb={2}>
              <Tooltip label={prevVariant ? websiteSignupVariants[prevVariant].name : "No previous variant"}>
                <VariantButton
                  icon={<MdNavigateBefore />}
                  onClick={() => handleVariantChange(prevVariant)}
                  isDisabled={!prevVariant}
                  colorScheme="yellow"
                  variant="ghost"
                  aria-label="Previous variant"
                />
              </Tooltip>
              
              <Tooltip label={websiteSignupVariants[currentVariant].name}>
                <Text flex="1" textAlign="center" color="yellow.500" fontWeight="bold">
                  Variant {currentIndex + 1} of {variantKeys.length}
                </Text>
              </Tooltip>
              
              <Tooltip label={nextVariant ? websiteSignupVariants[nextVariant].name : "No next variant"}>
                <VariantButton
                  icon={<MdNavigateNext />}
                  onClick={() => handleVariantChange(nextVariant)}
                  isDisabled={!nextVariant}
                  colorScheme="yellow"
                  variant="ghost"
                  aria-label="Next variant"
                />
              </Tooltip>
            </HStack>
            <Text color="white">{websiteSignupVariants[currentVariant].script}</Text>
          </Box>
        );
      case 'Downloaded_Lead_Magnet':
        return "You signed up for a copy of our list of properties under $X. I just wanted to confirm you actually received your copy.";
      case 'open_house':
        return "You had attended one of my open houses at ABC PROPERTY. I'm just following up because I told you I'll send a list of recent sales in the neighborhood. I just wanted to confirm you actually received your copy.";
      case 'older_lead':
        return "You and I connected, honestly I think it was January of last year, at the open house of 123 ABC PROPERTY. I know you are probably busy with [current season] coming up, but if you had 7 seconds for me I just had one quick question?";
      case 'referral':
        return "You were referred to me by a mutual contact. I wanted to introduce myself and see how I can assist you.";
      case 'followup':
        return "We had a conversation recently about your home search. I wanted to follow up and see if you had any updates.";
      default:
        return "Hi, I'm calling to follow up on your interest in real estate. Is this a good time to chat?";
    }
  };

const boxBgColor = 'orange.800';
const [expandedSections, setExpandedSections] = useState([0, 1]); // Expand first two sections by default
const [leadResponse, setLeadResponse] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState('end_user');
  const [leadGoal, setLeadGoal] = useState('Generic Buyer');

const toggleSection = (index) => {
  setExpandedSections(prev => 
    prev.includes(index) 
      ? prev.filter(i => i !== index)
      : [...prev, index]
  );
};

const handleLeadResponse = (response) => {
  setLeadResponse(response);
  onResponse(response);
};

  // Add this function to check if the current step is valid
  const isStepValid = () => true;

   // Render content only if the step is valid
  if (!isStepValid()) {
    return (
      <VStack spacing={4} align="stretch" width="100%">
        <Text color="white" mb={4}>Please select a lead source and complete the previous steps before accessing this stage or select a lead source below:</Text>
        
        {[
          { source: 'website_signup', icon: FaGlobe, label: 'Website Signup' },
          { source: 'Downloaded_Lead_Magnet', icon: FaClipboardList, label: 'Lead Magnet' },
          { source: 'open_house', icon: FaHome, label: 'Open House' },
          { source: 'older_lead', icon: FaHistory, label: 'Older Lead' },
          { source: 'referral', icon: FaUserFriends, label: 'Referral' },
          { source: 'followup', icon: FaRedoAlt, label: 'Follow-up' },
        ].map((lead) => (
          <Button
            key={lead.source}
            onClick={() => handleLeadSourceButtonClick(lead.source)}
            colorScheme="yellow"
            variant="outline"
            height="80px"
            width="100%"
            _hover={{ bg: 'yellow.900' }}
            borderWidth="2px"
            leftIcon={<Icon as={lead.icon} boxSize="24px" color="yellow.500" />}
            color="white"
            justifyContent="flex-start"
            fontSize="md"
            fontWeight="bold"
          >
            {lead.label}
          </Button>
        ))}
      </VStack>
    );
  }

  // Modify this function to always render content
  const renderEstablishAndDisarm = () => (
    <VStack align="stretch" spacing={4}>
      {/* Greeting Script Box */}
      <Box
        borderWidth="1px"
        borderColor="yellow.500"
        borderRadius="md"
        p={4}
        bg="blackAlpha.400"
      >
        <Text color="yellow.500" fontWeight="bold" mb={2}>Greeting:</Text>
        <Text color="white">
          {greetingVariants[leadData.greetingVariant || 'option1'].script
            .replace('[Prospect Name]', leadData.name || '[Prospect Name]')}
        </Text>
      </Box>

      {/* Introduction Script Box */}
      <Box
        borderWidth="1px"
        borderColor="yellow.500"
        borderRadius="md"
        p={4}
        bg="blackAlpha.400"
      >
        <Text color="yellow.500" fontWeight="bold" mb={2}>Introduction:</Text>
        <Text color="white">
          {introductionVariants[leadData.introductionVariant || 'option1'].script}
        </Text>
      </Box>

      {/* Reason for Call Script Box */}
      <Box
        borderWidth="1px"
        borderColor="yellow.500"
        borderRadius="md"
        p={4}
        bg="blackAlpha.400"
      >
        <Text color="yellow.500" fontWeight="bold" mb={2}>Reason for Call:</Text>
        <Text color="white">{getEstablishAndDisarmContent()}</Text>
      </Box>

      {/* Notes and Tips */}
      <Box
        borderWidth="1px"
        borderColor="yellow.500"
        borderRadius="md"
        p={4}
        bg="blackAlpha.400"
      >
        <Text color="yellow.500" fontWeight="bold" mb={2}>Important Notes:</Text>
        <UnorderedList color="white" spacing={2}>
          <ListItem>Do not ask, "How are you?"</ListItem>
          <ListItem>Use proper tone (lost in a city and need help, unattached from the outcome)</ListItem>
          <ListItem>Use neutral phrasing so they don't feel like their back is against the wall on a sales call</ListItem>
        </UnorderedList>
      </Box>

      {/* Response Buttons */}
      <HStack spacing={2} mt={4}>
        <Button
          onClick={() => handleLeadResponse('yes')}
          colorScheme="yellow"
          variant="outline"
          flex="1"
        >
          Yes, received
        </Button>
        <Button
          onClick={() => handleLeadResponse('no')}
          colorScheme="yellow"
          variant="outline"
          flex="1"
        >
          No, haven't received
        </Button>
        <Button
          onClick={() => handleLeadResponse('not_reviewed')}
          colorScheme="yellow"
          variant="outline"
          flex="1"
        >
          Received but not reviewed
        </Button>
      </HStack>
    </VStack>
  );

  // Helper functions for variant navigation
  const getPrevVariant = () => {
    const currentVariant = leadData.websiteSignupVariant || 'option1';
    const variantKeys = Object.keys(websiteSignupVariants);
    const currentIndex = variantKeys.indexOf(currentVariant);
    return currentIndex > 0 ? variantKeys[currentIndex - 1] : null;
  };

  const getNextVariant = () => {
    const currentVariant = leadData.websiteSignupVariant || 'option1';
    const variantKeys = Object.keys(websiteSignupVariants);
    const currentIndex = variantKeys.indexOf(currentVariant);
    return currentIndex < variantKeys.length - 1 ? variantKeys[currentIndex + 1] : null;
  };

  // Modify this function to always render content
  const renderAcknowledgeAndProvideValue = () => (
    <AcknowledgeAndAddValue 
      onResponse={handleLeadResponse} 
      defaultResponse="yes"  // Always assume "Yes Received"
    />
  );

  // Replace the renderGoldenTurn function with this:
  const renderGoldenTurn = () => (
    <GoldenTurn
      leadSource={leadSource}
      handleLeadResponse={handleLeadResponse}
    />
  );

  const renderCurrentStateQuestions = () => (
    <CurrentStateQuestions
      step={step}
      handleLeadDataChange={handleLeadDataChange}
      setCurrentStageIndex={setCurrentStageIndex}
      callFlow={callFlow}
    />
  );

  const renderObstacleInquiries = () => <ObstacleInquiries />;


  const renderWayOutAcknowledgement = () => (
    <WayOutAcknowledgement wayOutResponse={wayOutResponse} setWayOutResponse={setWayOutResponse} />
  );
  
  const renderQualificationQuestions = () => <QualificationQuestions />;
  
  const renderCloseOptionA = () => <CloseOptionA />;
  
  const renderCloseOptionB = () => (
    <CloseOptionB leadData={leadData} leadGoal={leadGoal} setLeadGoal={setLeadGoal} />
  );
  
  const renderObjectionHandling = () => (
    <ObjectionHandling handleObjectionResponse={handleObjectionResponse} />
  );
  
  const renderFunnelingQuestions = () => <FunnelingQuestions />;
  

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box mb={2}>
        <Heading size="md" color="yellow.500">{step.title}</Heading>
      </Box>
      <Box flex="1" overflowY="auto">
        <VStack align="stretch" spacing={2} width="100%">
          {step.id === 'establish_disarm' && renderEstablishAndDisarm()}
          {step.id === 'provide_value' && renderAcknowledgeAndProvideValue()}
          {step.id === 'golden_turn' && renderGoldenTurn()}
          {step.id === 'current_state_questions' && renderCurrentStateQuestions()}
          {step.id === 'obstacle_inquiries' && renderObstacleInquiries()}
          {step.id === 'way_out_acknowledgement' && renderWayOutAcknowledgement()}
          {step.id === 'qualification_questions' && renderQualificationQuestions()}
          {step.id === 'close_option_a' && renderCloseOptionA()}
          {step.id === 'close_option_b' && renderCloseOptionB()}
          {step.id === 'objection_handling' && renderObjectionHandling()}
          {step.id === 'funneling_questions' && renderFunnelingQuestions()}
          
          <Accordion allowMultiple index={expandedSections} mt={2}>
            {/* Display script for the current lead type */}
            {step.scripts && step.scripts[leadSource] && (
              <AccordionItem>
                <AccordionButton onClick={() => toggleSection(0)}>
                  <Box flex="1" textAlign="left">
                    <Text fontWeight="bold" color="yellow.500">Script</Text>
                  </Box>
                  <AccordionIcon color="yellow.500" />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text>{step.scripts[leadSource].main}</Text>
                </AccordionPanel>
              </AccordionItem>
            )}


            {/* Display conditional statements */}
            {step.conditionalStatements && (
              <AccordionItem>
                <AccordionButton onClick={() => toggleSection(3)}>
                  <Box flex="1" textAlign="left">
                    <Text fontWeight="bold" color="yellow.500">Conditional Statements</Text>
                  </Box>
                  <AccordionIcon color="yellow.500" />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {step.conditionalStatements.map((statement, index) => (
                    <Box key={index} mb={2}>
                      <Text fontWeight="bold" color="yellow.500">{statement.condition}:</Text>
                      <Text>{statement.response}</Text>
                    </Box>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            )}

            {/* Display main script */}
            {step.mainScript && (
              <AccordionItem>
                <AccordionButton onClick={() => toggleSection(4)}>
                  <Box flex="1" textAlign="left">
                    <Text fontWeight="bold" color="yellow.500">Main Script</Text>
                  </Box>
                  <AccordionIcon color="yellow.500" />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <List spacing={2}>
                    {step.mainScript.map((line, index) => (
                      <ListItem key={index}>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        {line}
                      </ListItem>
                    ))}
                  </List>
                </AccordionPanel>
              </AccordionItem>
            )}

            {/* Display golden turn guidance */}
            {step.goldenTurnGuidance && (
              <AccordionItem>
                <AccordionButton onClick={() => toggleSection(5)}>
                  <Box flex="1" textAlign="left">
                    <Text fontWeight="bold" color="yellow.500">Golden Turn Guidance</Text>
                  </Box>
                  <AccordionIcon color="yellow.500" />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <List spacing={2}>
                    {step.goldenTurnGuidance.map((guidance, index) => (
                      <ListItem key={index}>
                        <ListIcon as={MdLightbulb} color="yellow.500" />
                        {guidance}
                      </ListItem>
                    ))}
                  </List>
                </AccordionPanel>
              </AccordionItem>
            )}
          </Accordion>
        </VStack>
      </Box>
    </Box>
  );
};

// Sidebar component
const Sidebar = ({ callFlow, currentStageIndex, setCurrentStageIndex, onPrevious }) => {
  const hoverBgColor = 'orange.800';
  const [leadActivities, setLeadActivities] = useState([]);




  if (!callFlow || callFlow.length === 0) {
    return <Box>No call flow data available</Box>;
  }

  // Calculate the current progress
  const calculateProgress = () => {
    const totalStages = callFlow.reduce((total, section) => total + section.stages.length, 0);
    const completedStages = callFlow.slice(0, currentStageIndex.section).reduce((total, section) => total + section.stages.length, 0) + currentStageIndex.stage;
    
    // Check if we're at the last stage of the last section
    const isLastStage = currentStageIndex.section === callFlow.length - 1 && 
                        currentStageIndex.stage === callFlow[callFlow.length - 1].stages.length - 1;
    
    const progressPercentage = isLastStage ? 100 : (completedStages / totalStages) * 100;
    
    // Add a small initial progress when on the first stage
    return Math.max(5, progressPercentage);
  };

  // Add this glowing animation
  const glowingAnimation = keyframes`
    0% { box-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700, 0 0 15px #ffd700, 0 0 20px #ffd700; }
    50% { box-shadow: 0 0 10px #ffd700, 0 0 15px #ffd700, 0 0 20px #ffd700, 0 0 25px #ffd700; }
    100% { box-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700, 0 0 15px #ffd700, 0 0 20px #ffd700; }
  `;

const stages = [
  { id: 'start', title: 'Start Page', icon: FaHome }, // Add this line
  { id: 'establish_disarm', title: 'Establish & Disarm', icon: MdHandshake },
    { id: 'provide_value', title: 'Acknowledge + Add Value', icon: MdLightbulb },
    { id: 'golden_turn', title: 'The Golden Turn', icon: MdRocketLaunch },
    { id: 'current_state_questions', title: 'Current State Questions', icon: MdQuestionAnswer },
    { id: 'obstacle_inquiries', title: 'Obstacle Inquiries', icon: MdZoomIn },
    { id: 'way_out_acknowledgement', title: 'The "Way Out" Acknowledgement', icon: MdChat },
    { id: 'qualification_questions', title: 'Qualification Questions', icon: MdAssignment },
    { id: 'close_option_a', title: 'Close Option A - Followup Commitment', icon: MdSchedule },
    { id: 'close_option_b', title: 'Close Option B - Direct Appointment', icon: MdMeetingRoom },
    { id: 'objection_handling', title: 'Objection Handling', icon: MdWarning },
    { id: 'funneling_questions', title: 'Funneling Questions', icon: MdSort },
  ];

  const totalHeight = stages.length * 60 ; // Total height of all stages minus last spacing

  return (
    <Box bg="black" p={4} height="100%" display="flex" flexDirection="column" overflowX="hidden">
        <Box mb={2}>
          <Heading size="md" color="yellow.500">Navigation</Heading>
        </Box>
        <Grid templateColumns="auto 1fr" gap={4} flex="1" overflow="hidden">
          {/* Vertical Progress Bar */}
          <Box position="relative" width="8px" height={`${totalHeight}px`} zIndex={0}>
            <Box
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              borderRadius="full"
              bg="linear-gradient(to bottom, #ffd700, #ff8c00)"
              filter="blur(4px)"
              opacity="0.6"
            />
            <Box
              position="relative"
              height="100%"
              width="100%"
              borderRadius="full"
              bg="black"
              overflow="hidden"
            >
              <Box
                height={`${calculateProgress()}%`}
                width="100%"
                bg="linear-gradient(to bottom, #ffd700, #ff8c00)"
                transition="height 0.5s ease-in-out"
                animation={`${glowingAnimation} 2s infinite`}
              />
            </Box>
          </Box>

          {/* Stage Buttons */}
          <VStack spacing={2} align="stretch" overflowY="auto" overflowX="hidden" pr={2}>
        {stages.map((stage, index) => (
          <StageButton
            key={stage.id}
            icon={stage.icon}
            label={stage.title}
            onClick={() => {
              if (stage.id === 'start') {
                setCurrentStageIndex({ section: 0, stage: 0 });
              } else {
                const sectionIndex = callFlow.findIndex(section => 
                  section.stages.some(s => s.id === stage.id)
                );
                const stageIndex = callFlow[sectionIndex].stages.findIndex(s => s.id === stage.id);
                setCurrentStageIndex({ section: sectionIndex, stage: stageIndex });
              }
            }}
            isActive={
              stage.id === 'start' 
                ? currentStageIndex.section === 0 && currentStageIndex.stage === 0
                : callFlow[currentStageIndex.section].stages[currentStageIndex.stage].id === stage.id
            }
          />
        ))}
      </VStack>
        </Grid>

        {/* Previous Button */}
        <Button 
          leftIcon={<MdNavigateBefore />} 
          onClick={onPrevious} 
          isDisabled={currentStageIndex.section === 0 && currentStageIndex.stage === 0} 
          colorScheme="yellow" 
          variant="outline"
          size="md"
          width="100%"
          height="80px"
          mt={4}
          fontSize="md"
          borderColor="yellow.500"
          color="yellow.500"
        >
          Previous
        </Button>

        
      </Box>
  );
};

const pulseKeyframes = keyframes`
  0% { box-shadow: 0 0 5px 0px currentColor; }
  50% { box-shadow: 0 0 15px 5px currentColor; }
  100% { box-shadow: 0 0 5px 0px currentColor; }
`;

// Add this new component for mindset reminders
const MindsetReminder = ({ reminder, onSelect }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.02, rotate: 0 }}
      whileTap={{ scale: 0.98 }}
      onClick={() => onSelect(reminder)}
    >
      <Box
        w="100%"
        h="80px"
        borderWidth="1px"
        borderRadius="xl"
        overflow="hidden"
        bg="rgba(0, 0, 0, 0.6)"
        borderColor="yellow.500"
        boxShadow={`0 4px 20px -2px ${reminder.color}`}
        transition="all 0.3s"
        p={3}
        cursor="pointer"
        position="relative"
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `linear-gradient(135deg, ${reminder.color}20, transparent)`,
          opacity: 0.6,
          zIndex: 0,
          boxShadow: `0 0 10px ${reminder.color}.500`,
        filter: 'blur(5px)',
        animation: `${pulseKeyframes} 3s infinite`,
        }}
      >
        <HStack spacing={3} position="relative" zIndex={1}>
          <Center
            bg={`${reminder.color}.500`}
            w="40px"
            h="40px"
            borderRadius="full"
            boxShadow={`0 2px 10px ${reminder.color}`}
          >
            <Icon as={reminder.icon} boxSize="20px" color="black" />
          </Center>
          <VStack align="start" spacing={0} flex={1}>
            <Heading size="sm" color="yellow.500" fontWeight="bold">
              {reminder.title}
            </Heading>
            <Text fontSize="xs" color="white" fontStyle="italic" noOfLines={2}>
              "{reminder.points[0]}"
            </Text>
          </VStack>
        </HStack>
      </Box>
    </motion.div>
  );
};

// Add this new component for Property Information
const PropertyInformation = ({ propertyData, handlePropertyDataChange }) => (
  <Box height="100%" display="flex" flexDirection="column">
    <Box mb={2}>
      <Heading size="md" color="yellow.500">Property Information</Heading>
    </Box>
    <Box flex="1" overflowY="auto">
      <Box 
        height="100%"
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'yellow.500',
            borderRadius: '24px',
          },
        }}
      >
        <SimpleGrid columns={1} spacing={2}>
          <FormControl>
            <FormLabel color="white">Location</FormLabel>
            <Input 
              value={propertyData.location} 
              onChange={(e) => handlePropertyDataChange('location', e.target.value)}
              bg="black !important"
              borderColor="yellow.500 !important"
              color="white !important"
              _hover={{ borderColor: "yellow.300 !important" }}
              _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
            />
          </FormControl>
          <FormControl>
            <FormLabel color="white">Property Types</FormLabel>
            <SimpleGrid columns={2} spacing={2}>
              {['Condo', 'House', 'Townhouse', 'Duplex'].map((type) => (
                <Button
                  key={type}
                  onClick={() => handlePropertyDataChange('propertyTypes', {
                    ...propertyData.propertyTypes,
                    [type.toLowerCase()]: !propertyData.propertyTypes[type.toLowerCase()]
                  })}
                  colorScheme={propertyData.propertyTypes[type.toLowerCase()] ? "yellow" : "gray"}
                  variant={propertyData.propertyTypes[type.toLowerCase()] ? "solid" : "outline"}
                >
                  {type}
                </Button>
              ))}
            </SimpleGrid>
          </FormControl>
          <FormControl>
            <FormLabel color="white">Bedrooms</FormLabel>
            <NumberInput 
              value={propertyData.bedrooms} 
              onChange={(valueString) => handlePropertyDataChange('bedrooms', valueString)}
              min={0}
              max={10}
            >
              <NumberInputField bg="black !important" borderColor="yellow.500 !important" color="white !important" _hover={{ borderColor: "yellow.300 !important" }} _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }} />
              <NumberInputStepper>
                <NumberIncrementStepper color="yellow.500" />
                <NumberDecrementStepper color="yellow.500" />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl>
            <FormLabel color="white">Bathrooms</FormLabel>
            <NumberInput 
              value={propertyData.bathrooms} 
              onChange={(valueString) => handlePropertyDataChange('bathrooms', valueString)}
              min={0}
              max={10}
              step={0.5}
            >
              <NumberInputField bg="black !important" borderColor="yellow.500 !important" color="white !important" _hover={{ borderColor: "yellow.300 !important" }} _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }} />
              <NumberInputStepper>
                <NumberIncrementStepper color="yellow.500" />
                <NumberDecrementStepper color="yellow.500" />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl>
            <FormLabel color="white">Square Footage</FormLabel>
            <NumberInput 
              value={propertyData.squareFootage} 
              onChange={(valueString) => handlePropertyDataChange('squareFootage', valueString)}
              min={0}
            >
              <NumberInputField bg="black !important" borderColor="yellow.500 !important" color="white !important" _hover={{ borderColor: "yellow.300 !important" }} _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }} />
              <NumberInputStepper>
                <NumberIncrementStepper color="yellow.500" />
                <NumberDecrementStepper color="yellow.500" />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl>
            <FormLabel color="white">Price</FormLabel>
            <NumberInput 
              value={propertyData.price} 
              onChange={(valueString) => handlePropertyDataChange('price', valueString)}
              min={0}
              precision={2}
              step={1000}
            >
              <NumberInputField bg="black !important" borderColor="yellow.500 !important" color="white !important" _hover={{ borderColor: "yellow.300 !important" }} _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }} />
              <NumberInputStepper>
                <NumberIncrementStepper color="yellow.500" />
                <NumberDecrementStepper color="yellow.500" />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </SimpleGrid>
        
        <Heading size="sm" mt={4} mb={2} color="yellow.500">Additional Features</Heading>
        <SimpleGrid columns={2} spacing={2}>
          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0" color="white">
              <Checkbox 
                isChecked={propertyData.balcony} 
                onChange={(e) => handlePropertyDataChange('balcony', e.target.checked)}
                colorScheme="yellow"
              >
                Balcony
              </Checkbox>
            </FormLabel>
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0" color="white">
              <Checkbox 
                isChecked={propertyData.parking} 
                onChange={(e) => handlePropertyDataChange('parking', e.target.checked)}
                colorScheme="yellow"
              >
                Parking
              </Checkbox>
            </FormLabel>
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0" color="white">
              <Checkbox 
                isChecked={propertyData.locker} 
                onChange={(e) => handlePropertyDataChange('locker', e.target.checked)}
                colorScheme="yellow"
              >
                Locker
              </Checkbox>
            </FormLabel>
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0" color="white">
              <Checkbox 
                isChecked={propertyData.petFriendly} 
                onChange={(e) => handlePropertyDataChange('petFriendly', e.target.checked)}
                colorScheme="yellow"
              >
                Pet Friendly
              </Checkbox>
            </FormLabel>
          </FormControl>
        </SimpleGrid>
      </Box>
    </Box>
  </Box>
);

// Utility function to validate Call SID
const isValidCallSid = (sid) => /^CA[a-fA-F0-9]{32}$/.test(sid);


// Main CallScript component
const CallScript = () => {
  const { currentTheme } = useTheme(); // Add this hook
  
  // Add this near the top with other state declarations
  const [isDuplicateAware, setIsDuplicateAware] = useState(false);

  // Add this useEffect at the top of your component
  useEffect(() => {
    // Reset scroll position when component mounts
    window.scrollTo(0, 0);
    // Disable smooth scrolling temporarily
    document.documentElement.style.scrollBehavior = 'auto';
    
    return () => {
      // Re-enable smooth scrolling when component unmounts
      document.documentElement.style.scrollBehavior = 'smooth';
    };
  }, []);

  const location = useLocation();
  const [leadData, setLeadData] = useState(location.state?.leadData || {
    name: '',
    email: '',
    phone: '',
    leadSource: 'website_signup',
    status: '',
    bucket: '',
    motivation: false,
    ability: false,
    willingness: false,
    location: '',
    propertyDetails: '',
    budget: '',
    notes: [], // Ensure notes is initialized as an empty array
    newNote: '',
    addedDate: new Date().toISOString().split('T')[0],
    greetingVariant: 'option1', // Add default greeting variant
  });
  const [leadSource, setLeadSource] = useState(location.state?.leadSource || 'website_signup');

  const [callSid, setCallSid] = useState(null);
  const bgColor = "black";
  const surfaceColor = "orange.800";
  const borderColor = "yellow.500";
  const textColor = "white";
  const [isOpen, setIsOpen] = useState(false);
  const [leadActivities, setLeadActivities] = useState([]);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const navigate = useNavigate();
  const [lead, setLead] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const { leadData: initialLeadData, leadSource: initialLeadSource, currentStageIndex: initialStageIndex } = location.state || {};
const [call, setCall] = useState(null);
const [searchQuery, setSearchQuery] = useState('');
const [searchResults, setSearchResults] = useState([]);
const [timelineFilter, setTimelineFilter] = useState('All');
const [callHistory, setCallHistory] = useState([]);
const deviceRef = useRef(null);
const [isSpeakerOn, setIsSpeakerOn] = useState(false); // Define isSpeakerOn state
const [callType, setCallType] = useState('direct');
const { device, isDeviceReady } = useContext(TwilioContext);
const [activeConnection, setActiveConnection] = useState(null);
const [callStatus, setCallStatus] = useState('idle');
const [activeCall, setActiveCall] = useState(null);
const [isCallActive, setIsCallActive] = useState(false);
const [isMuted, setIsMuted] = useState(false);

 // Get mindset reminders from callFlow 
 const mindsetReminders = useMemo(() => {
  const startPage = callFlow.find(section => section.id === 'start');
  return startPage?.stages[0]?.mindsetReminders || [];
}, []);


  // Add handleVariantChange to the main component
  const handleVariantChange = (newVariant) => {
    if (!newVariant) return;
    
    handleLeadDataChange('websiteSignupVariant', newVariant);
    
    toast({
      title: 'Script Variant Changed',
      description: `Switched to ${websiteSignupVariants[newVariant].name}`,
      status: 'info',
      duration: 2000,
      isClosable: true,
    });
  };

  // Add this state to track the selected greeting variant
  const [selectedGreetingVariant, setSelectedGreetingVariant] = useState('option1');

  // Update the handleGreetingVariantChange function
  const handleGreetingVariantChange = (newVariant) => {
    if (!newVariant) return;
    
    setSelectedGreetingVariant(newVariant);
    handleLeadDataChange('greetingVariant', newVariant);
    
    toast({
      title: 'Greeting Style Changed',
      description: `Switched to ${greetingVariants[newVariant].name}`,
      status: 'info',
      duration: 2000,
      isClosable: true,
    });
  };

  // Add this function to get the current greeting script
  const getCurrentGreetingScript = () => {
    const variant = leadData.greetingVariant || 'option1';
    return greetingVariants[variant]?.script.replace('[Prospect Name]', leadData.name || '[Prospect Name]');
  };

const pollCallStatus = useCallback(async (callSid) => {
  if (!callSid) {
    console.error('Cannot poll call status: CallSid is undefined');
    return;
  }

  const pollInterval = setInterval(async () => {
    try {
      const response = await axios.get(`/api/twilio/call-status/${callSid}`);
      const status = response.data.status;
      setCallStatus(status);

      if (['completed', 'failed', 'busy', 'no-answer'].includes(status)) {
        clearInterval(pollInterval);
        setIsCallActive(false);
        setActiveCall(null);

        toast({
          title: 'Call Status Update',
          description: `The call has been ${status}.`,
          status: status === 'completed' ? 'success' : 'error',
          duration: 5000,
          isClosable: true,
        });
      } else if (status === 'in-progress') {
        // Optionally, you might want to update UI or state here
        // but avoid showing a toast every 5 seconds
        console.log('Call in progress');
      }
    } catch (error) {
      console.error('Error polling call status:', error);
      clearInterval(pollInterval);
      setIsCallActive(false);
      setActiveCall(null);
      toast({
        title: 'Call Status Error',
        description: 'Failed to poll call status.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, 5000); // Poll every 5 seconds

  // Clean up the interval when the component unmounts or when the call ends
  return () => clearInterval(pollInterval);
}, [toast, setCallStatus, setIsCallActive, setActiveCall]);

  // Add this line here
  const [timelineItems, setTimelineItems] = useState([]);


   // Add this new useMemo hook
   const filteredTimelineItems = useMemo(() => {
    return timelineItems
      .filter(item => {
        if (timelineFilter === 'All') return true;
        if (timelineFilter === 'Notes') return item.type === 'Note';
        if (timelineFilter === 'Calls') return item.type === 'Call';
        if (timelineFilter === 'SMS') return item.type === 'SMS';
        return true;
      })
      .filter(
        item =>
          item.content?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.callType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.to?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.from?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.status?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (item.direction && item.direction.toLowerCase().includes(searchQuery.toLowerCase()))
      );
  }, [timelineItems, timelineFilter, searchQuery]);

  
// Use the initialLeadId from location.state, or null if it doesn't exist
const [leadId, setLeadId] = useState(location.state?.leadId || null);

// Fetch lead details

useEffect(() => {
  const fetchLead = async () => {
    try {
      const response = await axios.get(`/api/leads/${leadId}`);
      setLead(response.data);
    } catch (error) {
      console.error('Error fetching lead:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch lead details.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (leadId) {
    fetchLead();
  } else {
    // If there's no leadId, stop loading to render the Starter Page
    setIsLoading(false);
  }
}, [leadId, toast]);

 // Function to handle filter changes
 const handleFilterChange = (filter) => {
  setTimelineFilter(filter);
};



const handleSearch = async () => {
  try {
    const response = await axios.get(`/api/leads/search`, {
      params: { query: searchQuery },
    });
    setSearchResults(response.data);
  } catch (error) {
    console.error('Error searching leads:', error);
    // Handle error (e.g., show notification)
  }
};

  

  const checkForDuplicates = async (email, phone) => {
    try {
      const response = await axios.post('/api/leads/check', { 
        params: { email, phone }
      });
      return response.data;
    } catch (error) {
      console.error('Error checking for duplicates:', error);
      throw error;
    }
  };

  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
const [duplicateLeads, setDuplicateLeads] = useState([]);
  
  const handleAddLead = async () => {
    try {
      // Check for duplicates before adding the lead
      const response = await axios.post('/api/leads/check-duplicates', leadData);
      const { duplicates } = response.data;

      if (duplicates && duplicates.length > 0) {
        setDuplicateLeads(duplicates);
        setIsDuplicateModalOpen(true);
      } else {
        // Proceed with adding the lead
        await addLead();
      }
    } catch (error) {
      console.error('Error checking for duplicates:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while checking for duplicates.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const addLead = async (forceProceed = false) => {
    try {
      const response = await axios.post('/api/leads', { ...leadData, forceProceed });
      setLeadId(response.data._id);
      toast({
        title: 'Lead Added',
        description: 'The new lead has been successfully added.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding lead:', error);
      toast({
        title: 'Error',
        description: 'Failed to add the lead. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleProceedAnyway = async () => {
    setIsDuplicateModalOpen(false);
    const success = await createLead(true);
    if (success) {
      console.log('Lead created successfully after duplicate warning');
    } else {
      console.error('Failed to create lead after duplicate warning');
    }
  };


  const handleCancel = () => {
    setIsDuplicateModalOpen(false);
  };

  const handleNewLeadSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting new lead for duplicate check:', leadData);
      
      const response = await axios.post('/api/leads/check-duplicates', leadData);
      console.log('Duplicate Check Response:', response.data);
      const { duplicates } = response.data;  

      if (duplicates && duplicates.length > 0) {
        console.log('Duplicates found:', duplicates);
        setDuplicateLeads(duplicates);
        setIsDuplicateModalOpen(true);
      } else {
        console.log('No duplicates found, creating new lead');
        await createLead();
      }
    } catch (error) {
      console.error('Error checking for duplicates:', error.response ? error.response.data : error);
      toast({
        title: 'Error',
        description: 'An error occurred while checking for duplicates.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };


  const createLead = async (forceProceed = false) => {
    try {
      const response = await axios.post('/api/leads', { ...leadData, forceProceed });
      setLeadId(response.data._id);
      toast({
        title: 'Lead Added',
        description: 'The new lead has been successfully added.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error creating lead:', error.response ? error.response.data : error);
      toast({
        title: 'Error',
        description: `Failed to save the lead: ${error.response?.data?.message || error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
  };

  const handleMergeLead = async (mergedLeadData) => {
    try {
      console.log('Merging leads:', mergedLeadData);
      const response = await axios.post('/api/leads/merge', mergedLeadData);
      if (response.data) {
        toast({
          title: "Leads Merged",
          description: "The selected leads have been successfully merged.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsDuplicateModalOpen(false);
        // Update your state or refetch leads as needed
      }
    } catch (error) {
      console.error('Error merging leads:', error);
      let errorMessage = 'An unknown error occurred while merging leads.';
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
        errorMessage = `Failed to merge leads: ${error.response.data.message || error.response.statusText}`;
      } else if (error.request) {
        console.error('Error request:', error.request);
        errorMessage = 'No response received from server while trying to merge leads.';
      } else {
        console.error('Error message:', error.message);
        errorMessage = `Error: ${error.message}`;
      }
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };


  
  const [callOption, setCallOption] = useState('direct'); // Add this state

  const callOptions = [
    { value: 'direct', label: 'Connect Directly', icon: FaPhone },
    { value: 'conference', label: 'Start Conference Call', icon: FaUsers },
    { value: 'personal', label: 'Call Personal Number', icon: FaUserAlt },
  ];

  // Ensure fetchTwilioToken is memoized or defined outside to prevent re-creation
  const fetchTwilioToken = useCallback(async () => {
    try {
      const response = await axios.get('/api/twilio/token');
      return response.data.token;
    } catch (error) {
      console.error('Error fetching Twilio token:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch Twilio token. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      throw error;
    }
  }, [toast]);

  // Define handleDeviceError with useCallback to memoize
  const handleDeviceError = useCallback((error) => {
    console.error('Twilio Device error:', error);
    toast({
      title: "Device Error",
      description: `An error occurred with the calling system: ${error.message}`,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }, [toast]);


  
  const handleIncomingCall = (connection) => {
    // Logic for handling incoming calls
    console.log('Incoming call', connection);
    // You might want to show a modal or notification here
  };
  

  

   // Function to add call log to lead
   const addCallLogToLead = async (leadId, callSid, callType) => {
    try {
      const response = await axios.post(`/api/leads/${leadId}/call-logs`, {
        callSid,
        callType,
        startTime: new Date(),
        status: 'initiated'
      });
      console.log('Call log added:', response.data);
    } catch (error) {
      console.error('Error adding call log:', error);
      toast({
        title: "Call Log Error",
        description: `Failed to add call log: ${error.message || 'Unknown error'}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
    // Function to handle connecting directly
    const connectDirectly = async () => {
      try {
        if (!lead.phone) {
          throw new Error('Missing required "To" parameter.');
        }
    
        if (deviceRef.current && isDeviceReady) {
          const params = {
            To: lead.phone,
          };
          const outgoingCall = deviceRef.current.connect({ params });
          setCall(outgoingCall);
          setIsCallActive(true);
    
          outgoingCall.on('accept', call => {
            setCallSid(call.parameters.CallSid);
          });
    
          outgoingCall.on('disconnect', () => {
            setIsCallActive(false);
            setCall(null);
            setCallSid(null);
            toast({
              title: 'Call Ended',
              description: 'The call has been disconnected.',
              status: 'info',
              duration: 3000,
              isClosable: true,
            });
          });
    
          outgoingCall.on('error', error => {
            console.error('Call Error:', error);
            setIsCallActive(false);
            setCall(null);
            setCallSid(null);
            toast({
              title: 'Call Error',
              description: `An error occurred: ${error.message}`,
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          });
        } else {
          throw new Error('Twilio Device not initialized or not ready.');
        }
      } catch (error) {
        console.error('Error starting call:', error);
        toast({
          title: 'Call Error',
          description: `Failed to start the call: ${error.message || 'Unknown error'}. Please try again.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        throw error;
      }
    };

    const handleSendSms = () => {
      // Implement SMS sending logic
      console.log('Sending SMS...');
    };
    
    const handleToggleMute = () => {
      if (activeConnection) {
        const newMuteState = !isMuted;
        activeConnection.mute(newMuteState);
        setIsMuted(newMuteState);
      }
    };

    const handleToggleSpeaker = () => {
      // Implement speaker toggle logic
      setIsSpeakerOn(!isSpeakerOn);
    };

 
  // Function to handle ending a call
  const handleEndCall = async () => {
    if (activeCall) {
      activeCall.disconnect();
    }
  
    setIsCallActive(false);
    setActiveCall(null);
    setCallSid(null);
  
    toast({
      title: 'Call Ended',
      description: 'The call has been successfully ended.',
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  
    // Only update the call log if we have a valid callSid
    if (callSid) {
      await updateCallLog(lead._id, callSid);
    }
  
    // Prompt for call notes
    const noteContent = prompt('Enter notes for this call:');
    if (noteContent) {
      await handleAddNote(noteContent);
    }
  
    // Refresh activities
    fetchActivities();
  };

  const updateCallLog = async (leadId, callSid) => {
    try {
      await axios.put(`/api/leads/${leadId}/call-logs/${callSid}`, {
        status: 'completed',
        endTime: new Date()
      });
      console.log('Call log updated successfully');
    } catch (error) {
      console.error('Error updating call log:', error);
      toast({
        title: "Call Log Error",
        description: `Failed to update call log: ${error.message || 'Unknown error'}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

    // Function to end a call
    const endCall = async () => {
      if (activeConnection) {
        activeConnection.disconnect();
      }
      if (callSid) {
        try {
          await axios.post(`/api/twilio/end-call/${callSid}`);
          setIsCallActive(false); // Set isCallActive to false when ending the call
          setCallSid(null);
          setCallStatus('completed');
          toast({
            title: 'Call Ended',
            description: 'The call has been successfully ended.',
            status: 'info',
            duration: 3000,
            isClosable: true,
          });
  
          // Prompt user to add notes after call
          const noteContent = prompt('Enter notes for this call:');
          if (noteContent) {
            await axios.post('/api/notes', {
              content: noteContent,
              createdBy: 'User', // Replace with actual user info if available
              leadIds: [lead._id],
            });
            toast({
              title: 'Note Added',
              description: 'Call notes have been added to the lead.',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          }
  
          fetchActivities(); // Refresh activities
        } catch (error) {
          toast({
            title: 'End Call Error',
            description: `Failed to end call: ${error.message}`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };
  

  // DuplicateLeadsModal Component for the bottom of the page
  const DuplicateLeadsModal = ({ isOpen, duplicates, onCancel, onContinueWithLead, onViewLeadDetails }) => {
    return (
      <Modal isOpen={isOpen} onClose={onCancel} size="xl" isCentered>
        <ModalOverlay 
          backdropFilter="blur(10px)"
        />
        <ModalContent 
          bg="blackAlpha.300"
          borderColor={borderColor} 
          borderWidth="2px"
          borderRadius="xl"
          boxShadow="0 0 20px rgba(255, 215, 0, 0.3)"
          p={6}
          maxWidth="800px"
          width="90%"
        >
          <ModalHeader 
            color="yellow.500" 
            fontSize="2xl" 
            fontWeight="bold"
            display="flex"
            alignItems="center"
          >
            <Icon as={FaExclamationTriangle} color="yellow.400" mr={4} boxSize="30px" />
            Duplicate Leads Found
          </ModalHeader>
          <ModalCloseButton color="yellow.500" />
          <ModalBody>
            <Text fontSize="lg" mb={4} color="white">
              We've identified potential duplicate leads in our system. Please review the following:
            </Text>
            <VStack spacing={6} align="stretch">
              {duplicates.map(lead => (
                <Box 
                  key={lead._id} 
                  p={4} 
                  borderWidth="1px" 
                  borderRadius="md" 
                  borderColor="yellow.500"
                  bg="rgba(0, 0, 0, 0.2)"
                  backdropFilter="blur(5px)"
                >
                  <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                    <GridItem>
                      <Text fontWeight="bold" color="yellow.500">Name:</Text>
                      <Text color="white">{lead.name}</Text>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold" color="yellow.500">Email:</Text>
                      <Text color="white">{lead.email}</Text>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold" color="yellow.500">Phone:</Text>
                      <Text color="white">{lead.phone}</Text>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold" color="yellow.500">Source:</Text>
                      <Text color="white">{lead.leadSource}</Text>
                    </GridItem>
                  </Grid>
                </Box>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <VStack spacing={4} width="100%">
              <Button 
                colorScheme="yellow" 
                onClick={() => onContinueWithLead(duplicates[0])}
                size="lg"
                width="100%"
              >
                Use Existing Lead
              </Button>
              <HStack spacing={4} width="100%" justifyContent="space-between">
                <Button 
                  colorScheme="green" 
                  onClick={() => onContinueWithLead(null)}
                  size="lg"
                  flex={1}
                >
                  Create New Lead
                </Button>
                <Button 
                  bg="black"
                  color="yellow.500"
                  borderColor="yellow.500"
                  borderWidth="1px"
                  onClick={onCancel}
                  size="lg"
                  flex={1}
                  _hover={{
                    bg: "yellow.500",
                    color: "black"
                  }}
                >
                  Cancel
                </Button>
                <Button 
                  colorScheme="blue" 
                  onClick={() => onViewLeadDetails(duplicates[0]._id)}
                  size="lg"
                  flex={1}
                >
                  View Lead Details
                </Button>
              </HStack>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const handleContinueWithLead = async (selectedLead) => {
    setIsDuplicateModalOpen(false);
    setIsDuplicateAware(true); // Set to true when continuing with a lead
  
    if (selectedLead) {
      // Use existing lead
      setLeadData(selectedLead);
      setLeadId(selectedLead._id);
      toast({
        title: "Lead Selected",
        description: `Continuing with ${selectedLead.name}'s lead information.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      // Create a new lead
      const success = await createLead();
      if (!success) {
        toast({
          title: "Error",
          description: "Failed to create a new lead.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      toast({
        title: "New Lead Created",
        description: "A new lead has been successfully created.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  
    // Navigate to the "Establish and Disarm" step
    const introSectionIndex = callFlow.findIndex(section => section.id === 'intro');
    const establishDisarmIndex = callFlow[introSectionIndex].stages.findIndex(stage => stage.id === 'establish_disarm');
    setCurrentStageIndex({ section: introSectionIndex, stage: establishDisarmIndex });
  
    // Update lead source if it's not already set
    if (selectedLead && !selectedLead.leadSource) {
      handleLeadSourceChange(leadSource);
    }
  };
  
  const handleViewLeadDetails = (leadId) => {
    // Open the LeadDetails page in a new tab
    window.open(`/leads/${leadId}`, '_blank');
  };

const [matchingLeads, setMatchingLeads] = useState([]);
const [isSearching, setIsSearching] = useState(false);
const [currentStageIndex, setCurrentStageIndex] = useState(location.state?.currentStageIndex || { section: 0, stage: 0 });

// Update the useEffect hook to use setTimelineItems instead of setFilteredTimelineItems
useEffect(() => {
  // Initialize with dummy data
  setTimelineItems([
    { type: 'Notes', timestamp: '2023-09-15 10:30', details: 'Added new note about property preferences' },
    { type: 'Calls', timestamp: '2023-09-14 14:45', details: 'Discussed financing options' },
    { type: 'Emails', timestamp: '2023-09-13 09:15', details: 'Sent follow-up email with listing details' },
    { type: 'Lead Browsing Activity', timestamp: '2023-09-12 16:20', details: 'Viewed 3 new listings' },
  ]);
}, []);



const callPersonalNumber = async () => {
  try {
    const response = await axios.post('/api/call-personal-number', { 
      to: leadData.phone,
      personalNumber: '6478356368',
      leadId: leadData._id,
    });
    console.log('Personal call initiation response:', response.data);
    
    if (response.data.success) {
      setCallSid(response.data.leadCallSid);
      setIsCallActive(true);
      
      // Return success response
      return { success: true, callSid: response.data.leadCallSid };
    } else {
      throw new Error(response.data.message || 'Failed to initiate personal call');
    }
  } catch (error) {
    console.error('Error initiating personal call:', error);
    throw error;
  }
};

const handleStartCall = async (callType) => {
  if (!device || !isDeviceReady) {
    console.error('Twilio Device not initialized or not ready');
    toast({
      title: 'Error',
      description: 'Unable to initiate call. Please try again later.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
    return;
  }

  try {
    if (callType === 'direct') {
      const call = await device.connect({ params: { To: formatPhoneNumber(lead.phone) } });
      setActiveCall(call);
      setIsCallActive(true);
      
      call.on('accept', (call) => {
        const sid = call.parameters.CallSid;
        setCallSid(sid);
        handleCallAccepted();
        pollCallStatus(sid);
      });
      call.on('disconnect', handleCallDisconnected);
      call.on('cancel', handleCallCanceled);
      call.on('error', handleCallError);
    } else if (callType === 'personal') {
      const response = await axios.post('/api/twilio/initiate-call', {
        to: formatPhoneNumber(lead.phone),
        leadId: lead._id,
        callType: 'personal',
      });

      if (response.data.success) {
        setCallSid(response.data.callSid);
        setIsCallActive(true);
        pollCallStatus(response.data.callSid);
      } else {
        throw new Error(response.data.message || 'Failed to initiate personal call');
      }
    }
  } catch (error) {
    console.error('Error starting call:', error);
    handleCallError(error);
  }
};


// Add these helper functions
const formatPhoneNumber = (phoneNumber) => {
  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, '');
  
  // Ensure the number starts with a country code (assuming US, so add +1 if not present)
  return cleaned.startsWith('1') ? `+${cleaned}` : `+1${cleaned}`;
};

const isValidPhoneNumber = (phoneNumber) => {
  // Basic validation: should be +1 followed by 10 digits
  return /^\+1\d{10}$/.test(phoneNumber);
};

// Add these helper functions
const handleCallAccepted = () => {
  console.log('Call accepted');
  setCallStatus('in-progress');
  toast({
    title: 'Call Connected',
    description: 'The call is now in progress.',
    status: 'success',
    duration: 3000,
    isClosable: true,
  });
};

const handleCallDisconnected = () => {
  console.log('Call disconnected');
  setCallStatus('completed');
  setIsCallActive(false);
  setActiveConnection(null);
  toast({
    title: 'Call Ended',
    description: 'The call has been disconnected.',
    status: 'info',
    duration: 3000,
    isClosable: true,
  });
};

const handleCallCanceled = () => {
  console.log('Call canceled');
  setCallStatus('canceled');
  setActiveConnection(null);
  toast({
    title: 'Call Canceled',
    description: 'The call was canceled.',
    status: 'warning',
    duration: 3000,
    isClosable: true,
  });
};

const handleCallError = (error) => {
  console.error('Call error:', error);
  setCallStatus('failed');
  setActiveConnection(null);
  toast({
    title: 'Call Error',
    description: `An error occurred: ${error.message}`,
    status: 'error',
    duration: 5000,
    isClosable: true,
  });
};


// Add this new function
const checkForExistingLeads = debounce(async (field, value) => {
  if (value.length < 3) return; // Only search if there are at least 3 characters

  setIsSearching(true);
  try {
    const response = await axios.get(`/api/leads/search?${field}=${value}`);
    setMatchingLeads(response.data);
  } catch (error) {
    console.error('Error searching for leads:', error);
  } finally {
    setIsSearching(false);
  }
}, 300);



// Modify the "Start New Lead" section in the render
<Box flex="1" display="flex" flexDirection="column" p={3}>
  <Heading size="lg" mb={4} color="yellow.500" textAlign="center">Start New Lead</Heading>
  <VStack spacing={3} align="stretch" width="100%" mb={4} flex="1" overflowY="auto">
    <Input
      placeholder="Name"
      value={leadData.name}
      onChange={(e) => handleLeadDataChange('name', e.target.value)}
      bg="black !important"
      borderColor="yellow.500 !important"
      color="white !important"
      _hover={{ borderColor: "yellow.300 !important" }}
      _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
      height="80px"
      fontSize="md"
    />
    <Input
      placeholder="Email"
      type="email"
      value={leadData.email}
      onChange={(e) => handleLeadDataChange('email', e.target.value)}
      bg="black !important"
      borderColor="yellow.500 !important"
      color="white !important"
      _hover={{ borderColor: "yellow.300 !important" }}
      _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
      height="80px"
      fontSize="md"
    />
    <Input
      placeholder="Phone"
      type="tel"
      value={leadData.phone}
      onChange={(e) => handleLeadDataChange('phone', e.target.value)}
      bg="black !important"
      borderColor="yellow.500 !important"
      color="white !important"
      _hover={{ borderColor: "yellow.300 !important" }}
      _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
      height="80px"
      fontSize="md"
    />
    {isSearching && <Spinner color="yellow.500" />}
    <MatchingLeadsPopover
      matchingLeads={matchingLeads}
      onSelectLead={(lead) => {
        setLeadData(lead);
        setLeadId(lead._id);
        setMatchingLeads([]);
      }}
      isOpen={matchingLeads.length > 0}
      onClose={() => setMatchingLeads([])}
    />
    {/* ... rest of the buttons ... */}
  </VStack>
  {/* ... rest of the content ... */}
</Box>


const [isSidebarOpen, setIsSidebarOpen] = useState(true);
const [isCallEnded, setIsCallEnded] = useState(false);
const currentStage = callFlow[currentStageIndex.section].stages[currentStageIndex.stage];
// Add new state for property data
const [propertyData, setPropertyData] = useState({
  location: '',
  propertyTypes: {
    condo: false,
    house: false,
    townhouse: false,
    duplex: false
  },
  bedrooms: 0,
  bathrooms: 0,
  squareFootage: 0,
  price: 0,
  balcony: false,
  parking: false,
  locker: false,
  petFriendly: false,
});
// Move this state declaration to the top level of the component
const [wayOutResponse, setWayOutResponse] = useState('yes');
  const [leadGoal, setLeadGoal] = useState('Generic Buyer');
  const [transcript, setTranscript] = useState('');
  const [operatorResults, setOperatorResults] = useState([]);

// Add this effect to listen for call status updates
useEffect(() => {
  if (callSid) {
    const intervalId = setInterval(async () => {
      try {
        const response = await axios.get(`/api/call-status/${callSid}`);
        console.log(`Call status for ${callSid}:`, response.data.status);
        if (['completed', 'failed', 'no-answer'].includes(response.data.status)) {
          setIsCallActive(false);
          setCallSid(null);
          clearInterval(intervalId);
          toast({
            title: "Call Ended",
            description: `The call has ended (${response.data.status}).`,
            status: "info",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Error checking call status:', error);
      }
    }, 5000); // Check every 5 seconds
    return () => clearInterval(intervalId);
  }
}, [callSid, toast]);
  


  // Update the ref whenever leadData changes
  useEffect(() => {
    leadDataRef.current = leadData;
  }, [leadData]);

const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const [activeTab, setActiveTab] = useState("call");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");

  const handleAddNote = async (noteContent) => {
    if (!noteContent.trim()) return;
  
    try {
      const response = await axios.post(`/api/leads/${leadId}/notes`, {
        content: noteContent,
      });
      const newNote = response.data;
  
      // Update leadData with the new note
      setLeadData((prevData) => ({
        ...prevData,
        notes: [...prevData.notes, newNote],
      }));
  
      toast({
        title: "Note Added",
        description: "Your note has been added successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding note:', error);
      toast({
        title: "Error",
        description: `Failed to add note: ${error.response?.data?.message || error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };



useEffect(() => {
  if (!activeConnection) return;

  const handleAccept = () => {
    console.log('Call accepted');
    setCallStatus('in-progress');
    setIsCallActive(true);
    toast({
      title: 'Call Connected',
      description: 'The call is now in progress.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleDisconnect = () => {
    console.log('Call disconnected');
    setCallStatus('completed');
    setIsCallActive(false);
    setActiveConnection(null);
    toast({
      title: 'Call Ended',
      description: 'The call has been disconnected.',
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleError = (error) => {
    console.error('Call error:', error);
    setCallStatus('failed');
    setIsCallActive(false);
    setActiveConnection(null);
    toast({
      title: 'Call Error',
      description: `An error occurred: ${error.message}`,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  activeConnection.on('accept', handleAccept);
  activeConnection.on('disconnect', handleDisconnect);
  activeConnection.on('error', handleError);

  return () => {
    activeConnection.off('accept', handleAccept);
    activeConnection.off('disconnect', handleDisconnect);
    activeConnection.off('error', handleError);
  };
}, [activeConnection, toast]);

useEffect(() => {
  return () => {
    if (deviceRef.current) {
      console.log('Cleaning up Twilio device');
      if (typeof deviceRef.current.destroy === 'function') {
        deviceRef.current.destroy();
      } else if (typeof deviceRef.current.disconnect === 'function') {
        deviceRef.current.disconnect();
      }
      deviceRef.current = null;
    }
  };
}, []);
  

  const handleReminderSelect = (reminder) => {
    setSelectedReminder(reminder);
    setIsOpen(true);
  };

  const MindsetReminderModal = ({ isOpen, onClose, reminder }) => {
    if (!reminder) return null;

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="black" color="white">
          <ModalHeader color="yellow.500">{reminder.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UnorderedList mt={4}>
              {reminder.points.map((point, index) => (
                <ListItem key={index}>{point}</ListItem>
              ))}
            </UnorderedList>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="yellow" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
<div>
  <Input
  placeholder="Search Leads"
  value={searchQuery}
  onChange={(e) => setSearchQuery(e.target.value)}
/>
<Button onClick={handleSearch}>Search</Button>
{/* Render search results */}
<List>
  {searchResults.map((lead) => (
    <ListItem key={lead._id}>
      <Text>{lead.name}</Text>
      {/* Add more lead details as needed */}
    </ListItem>
  ))}
</List>
</div>   
  
// Use this effect to update the lead data when it changes
useEffect(() => {
  if (location.state?.leadData) {
    setLeadData(location.state.leadData);
    setLeadSource(location.state.leadSource || '');
  }
}, [location.state]);


  

  
  // Add the new function here
  const handleObjectionResponse = (response) => {
    console.log("Objection response:", response);
    
    setLeadData(prevData => ({
      ...prevData,
      notes: [
        ...prevData.notes,
        { 
          content: `Objection response: ${response}`, 
          timestamp: new Date().toISOString()
        }
      ]
    }));
  
    nextStage();
  };


  // Handle changes in lead data
  const handlePropertyDataChange = (field, value) => {
    setPropertyData(prev => {
      if (field === 'propertyTypes') {
        return { ...prev, propertyTypes: value };
      }
      return { ...prev, [field]: value };
    });
  };

  // Save progress to database and local storage
  const saveProgress = async () => {
    try {
      if (!leadId) return;
      
      const response = await axios.put(`/api/leads/${leadId}`, leadData);
      
      // Verify the update was successful
      if (response.data) {
        toast({
          title: "Progress Saved",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error saving progress:', error);
      toast({
        title: "Error",
        description: "Failed to save progress",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };



// Update handleLeadDataChange to handle MAW and Bucket updates
const handleLeadDataChange = async (field, value) => {
  try {
    // Format phone number if field is phone
    if (field === 'phone') {
      value = formatPhoneNumber(value);
    }

    // Handle MAW and Bucket updates
    if (['motivation', 'ability', 'willingness', 'bucket'].includes(field)) {
      const response = await axios.put(`/api/leads/${leadId}`, {
        ...leadData,
        [field]: value
      });

      if (response.data) {
        setLeadData(response.data);
        toast({
          title: 'Update Successful',
          description: `Lead ${field} updated successfully`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      return;
    }

    // Handle other field changes as before
    setLeadData(prevData => ({
      ...prevData,
      [field]: value
    }));

    // Check for existing leads when name, email, or phone changes
    if (['name', 'email', 'phone'].includes(field)) {
      checkForExistingLeads(field, value);
    }
  } catch (error) {
    console.error('Error updating lead:', error);
    toast({
      title: 'Update Failed',
      description: error.response?.data?.message || 'Failed to update lead',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  }
};

// Add this new function to handle adding a note
const addNote = async (content) => {
  if (!content.trim() || !leadId) {
    toast({
      title: "Error",
      description: "Note content and lead ID are required",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    return;
  }
  
  try {
    const response = await axios.post(`/api/leads/${leadId}/notes`, {
      content,
      createdBy: 'System User' // Or pass actual user info if available
    });
    
    // Update lead data with new note
    setLeadData(prevData => ({
      ...prevData,
      notes: [...(prevData.notes || []), response.data],
      newNote: ''
    }));

    toast({
      title: "Note Added",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  } catch (error) {
    console.error('Error adding note:', error);
    toast({
      title: "Error",
      description: error.response?.data?.message || "Failed to add note",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  }
};

  // Memoized loadProgress function
  const loadProgress = useCallback(() => {
    if (location.state?.leadData) {
      // Ensure leadData.notes is an array
      const updatedLeadData = {
        ...location.state.leadData,
        notes: Array.isArray(location.state.leadData.notes) ? location.state.leadData.notes : []
      };
      setLeadData(updatedLeadData);
      setLeadSource(location.state.leadSource || '');
      setLeadId(location.state.leadId || null);
      setCurrentStageIndex(location.state.currentStageIndex || { section: 0, stage: 0 });
      setCallHistory(location.state.callHistory || []);
      return;
    }
  
    // Initialize with a fresh lead if no leadData is present
    const freshLead = {
      name: '',
      email: '',
      phone: '',
      leadSource: '',
      status: '',
      bucket: '',
      motivation: false,
      ability: false,
      willingness: false,
      location: '',
      propertyDetails: '',
      budget: '',
      notes: [], // Changed from '' to []
      addedDate: new Date().toISOString().split('T')[0],
    };
  
    setLeadData(freshLead);
    setLeadSource('');
    setLeadId(null);
    setCallHistory([]);
    setCurrentStageIndex({ section: 0, stage: 0 });
  
    toast({
      title: "Welcome to Call Script",
      description: "Please select a lead source to begin.",
      status: "info",
      duration: 2000,
      isClosable: true,
    });
  }, [location.state, toast, setLeadData, setLeadSource, setLeadId, setCurrentStageIndex, setCallHistory]);

  
  // Add the fetchActivities function here
  const fetchActivities = useCallback(async () => {
    if (!leadId) return;

    try {
      const response = await axios.get(`/api/leads/${leadId}/activities`);
      const activities = response.data;

      // Fetch additional details for calls
      const updatedActivities = await Promise.all(activities.map(async (activity) => {
        if (activity.type === 'Call' && activity.twilioCallSid) {
          const callDetails = await axios.get(`/api/twilio/calls/${activity.twilioCallSid}`);
          return { ...activity, ...callDetails.data };
        }
        return activity;
      }));

      setTimelineItems(updatedActivities);
    } catch (error) {
      console.error('Error fetching activities:', error);
      toast({
        title: "Error",
        description: "Failed to fetch lead activities",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [leadId, toast]);

  // Add this useEffect to fetch activities when leadId changes
  useEffect(() => {
    if (leadId) {
      fetchActivities();
    }
  }, [leadId, fetchActivities]);



// Update the useEffect
useEffect(() => {
  loadProgress();
  console.log('Current Lead Data:', leadDataRef.current);
}, [loadProgress]);

  // Navigate to next stage and Utilize console.log statements to verify that the lead data is correctly passed and received.
  const nextStage = () => {
    const { section, stage } = currentStageIndex;
    if (stage < callFlow[section].stages.length - 1) {
      setCurrentStageIndex({ section, stage: stage + 1 });
    } else if (section < callFlow.length - 1) {
      setCurrentStageIndex({ section: section + 1, stage: 0 });
    }
  };

  // Navigate to previous stage
  const prevStage = () => {
    const { section, stage } = currentStageIndex;
    if (stage > 0) {
      setCurrentStageIndex({ section, stage: stage - 1 });
    } else if (section > 0) {
      setCurrentStageIndex({ section: section - 1, stage: callFlow[section - 1].stages.length - 1 });
    }
  };
  const leadDataRef = useRef(leadData);
  // Toggle sidebar visibility
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

// Update the handleLeadSourceChange function:
const handleLeadSourceChange = (newLeadSource) => {
  setLeadSource(newLeadSource);
  setLeadData(prevData => ({
    ...prevData,
    leadSource: newLeadSource
  }));
  
  // Find the index of the "Introduction" section and the "Establish & Disarm" stage
  const introSectionIndex = callFlow.findIndex(section => section.id === 'intro');
  const establishStageIndex = callFlow[introSectionIndex].stages.findIndex(stage => stage.id === 'establish_disarm');
  
  // Set the current stage to "Establish & Disarm"
  setCurrentStageIndex({ section: introSectionIndex, stage: establishStageIndex });

  // Save the updated lead data to the backend
  saveProgress();
};

const handleClientResponse = (response) => {
  console.log("Client response:", response);
  
  // Update the leadData with the response
  setLeadData(prevData => ({
    ...prevData,
    previousResponse: response,
    notes: [
      ...prevData.notes,
      { 
        content: `Client response: ${response}`, 
        timestamp: new Date().toISOString()
      }
    ]
  }));

  if (response === 'yes_helpful') {
    // Move to the next stage
    nextStage();
  } else if (response === 'no_not_helpful') {
    // Update the note and move to Golden Turn stage
    updateNoteAndMoveToGoldenTurn();
  } else {
    // For other responses, just move to the next stage
    nextStage();
  }
};

const updateNoteAndMoveToGoldenTurn = () => {
  setLeadData(prevData => ({
    ...prevData,
    notes: [
      ...prevData.notes, 
      { content: 'Not interested in custom home list.', timestamp: new Date().toISOString() }
    ]
  }));

  // Navigate to the Golden Turn stage
  const introSectionIndex = callFlow.findIndex(section => section.id === 'intro');
  const goldenTurnStageIndex = callFlow[introSectionIndex].stages.findIndex(stage => stage.id === 'golden_turn');
  
  setCurrentStageIndex({ section: introSectionIndex, stage: goldenTurnStageIndex });

  // Save the updated lead data
  saveProgress();
};



  const isStartPage = currentStageIndex.section === 0 && currentStageIndex.stage === 0;

  const handleLeadSourceButtonClick = async (newLeadSource) => {
    const updatedLead = {
      ...leadData,
      leadSource: newLeadSource,
      status: leadData.status || '',
      bucket: leadData.bucket || '',
      motivation: leadData.motivation || false,
      ability: leadData.ability || false,
      willingness: leadData.willingness || false,
      location: leadData.location || '',
      propertyDetails: leadData.propertyDetails || '',
      budget: leadData.budget || '',
      notes: leadData.notes || [],
      addedDate: leadData.addedDate || new Date().toISOString().split('T')[0],
    };
  
    setLeadData(updatedLead);
    setLeadSource(newLeadSource);
  
    try {
      // Skip duplicate check if we're already aware it's a duplicate
      if (!isDuplicateAware && !leadId) {
        const checkResponse = await axios.post('/api/leads/check-duplicates', { 
          email: updatedLead.email, 
          phone: updatedLead.phone 
        });
        
        if (checkResponse.data.duplicates?.length > 0) {
          setDuplicateLeads(checkResponse.data.duplicates);
          setIsDuplicateModalOpen(true);
          setIsDuplicateAware(true); // Set to true when duplicates are found
          return;
        }
      }

      // Proceed with creating/updating lead
      let response;
      if (leadId) {
        response = await axios.put(`/api/leads/${leadId}`, updatedLead);
      } else {
        response = await axios.post('/api/leads', updatedLead);
        setLeadId(response.data._id);
      }

      // Update states and navigate
      setLeadData(response.data);
      setLeadSource(newLeadSource);

      // Find and set the correct stage index
      const introSectionIndex = callFlow.findIndex(section => section.id === 'intro');
      const establishStageIndex = callFlow[introSectionIndex].stages.findIndex(
        stage => stage.id === 'establish_disarm'
      );
      setCurrentStageIndex({ section: introSectionIndex, stage: establishStageIndex });

      toast({
        title: leadId ? "Lead Updated" : "New Lead Started",
        description: `${leadId ? 'Updated' : 'Starting with a new'} ${newLeadSource} lead${updatedLead.name ? ` for ${updatedLead.name}` : ''}. Lead saved to database.`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error handling lead source:', error);
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to process lead",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Calculate the current progress
  const calculateProgress = () => {
    const totalStages = callFlow.reduce((total, section) => total + section.stages.length, 0) + 1; // Add 1 for Start Page
    let completedStages = callFlow.slice(0, currentStageIndex.section).reduce((total, section) => total + section.stages.length, 0) + currentStageIndex.stage;
    
    // Add 1 to completedStages if we're past the Start Page
    if (currentStageIndex.section > 0 || currentStageIndex.stage > 0) {
      completedStages += 1;
    }
    
    // Check if we're at the last stage of the last section
    const isLastStage = currentStageIndex.section === callFlow.length - 1 && 
                        currentStageIndex.stage === callFlow[callFlow.length - 1].stages.length - 1;
    
    const progressPercentage = isLastStage ? 100 : (completedStages / totalStages) * 100;
    
    // Add a small initial progress when on the first stage
    return Math.max(5, progressPercentage);
  };

  const newLeadDisclosure = useDisclosure();
  const [pendingNewLeadSource, setPendingNewLeadSource] = useState(null);

  const startNewLead = (newLeadSource = null) => {
    if (leadId || Object.values(leadData).some(value => value !== '')) {
      setPendingNewLeadSource(newLeadSource);
      newLeadDisclosure.onOpen();
    } else {
      proceedWithNewLead(newLeadSource);
    }
  };

  const proceedWithNewLead = (newLeadSource) => {
    setLeadId(null);
    setLeadData({
      name: '',
      email: '',
      phone: '',
      leadSource: newLeadSource || leadSource,
      status: '',
      bucket: '',
      motivation: false,
      ability: false,
      willingness: false,
      location: '',
      propertyDetails: '',
      budget: '',
      notes: '',
      addedDate: new Date().toISOString().split('T')[0],
    });
    setCallHistory([]);
    setIsCallEnded(false);
    
    const introSectionIndex = callFlow.findIndex(section => section.id === 'intro');
    const establishStageIndex = callFlow[introSectionIndex].stages.findIndex(stage => stage.id === 'establish_disarm');
    
    setCurrentStageIndex({ section: introSectionIndex, stage: establishStageIndex });

    toast({
      title: "New Lead Started",
      description: `You're now working with a new ${newLeadSource || leadSource} lead.`,
      status: "info",
      duration: 2000,
      isClosable: true,
    });

    newLeadDisclosure.onClose();
  };

  // Add this new function to handle navigation to specific stages
  const navigateToStage = (sectionId, stageId) => {
    const sectionIndex = callFlow.findIndex(section => section.id === sectionId);
    if (sectionIndex !== -1) {
      const stageIndex = callFlow[sectionIndex].stages.findIndex(stage => stage.id === stageId);
      if (stageIndex !== -1) {
        setCurrentStageIndex({ section: sectionIndex, stage: stageIndex });
      }
    }
  };

  const MotionBox = motion(Box);

  const StageButton = ({ icon, label, onClick, isActive }) => (
    <Button
      leftIcon={<Icon as={icon} boxSize="1.5em" color={isActive ? "black" : "yellow.700"} />}
      onClick={onClick}
      width="100%"
      justifyContent="flex-start"
      bg={isActive ? "yellow.500" : "transparent"}
      color={isActive ? "black" : "yellow.500"}
      _hover={{
        bg: isActive ? "yellow.600" : "yellow.900",
        color: isActive ? "black" : "yellow.300",
      }}
      mb={1}
      height="50px"
      whiteSpace="normal"
      textAlign="left"
      px={2}
      overflow="hidden"
    >
      <Text fontSize="sm" isTruncated>{label}</Text>
    </Button>
  );

  // Add this glowing animation
  const glowingAnimation = keyframes`
    0% { box-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700, 0 0 15px #ffd700, 0 0 20px #ffd700; }
    50% { box-shadow: 0 0 10px #ffd700, 0 0 15px #ffd700, 0 0 20px #ffd700, 0 0 25px #ffd700; }
    100% { box-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700, 0 0 15px #ffd700, 0 0 20px #ffd700; }
  `;





  const MindsetReminder = ({ reminder, onSelect }) => {
    return (
      <motion.div
        whileHover={{ scale: 1.02, rotate: 0 }}
        whileTap={{ scale: 0.98 }}
        onClick={() => onSelect(reminder)}
      >
        <Box
          w="100%"
          h="80px"
          borderWidth="1px"
          borderRadius="xl"
          overflow="hidden"
          bg="rgba(0, 0, 0, 0.6)"
          borderColor="yellow.500"
          boxShadow={`0 4px 20px -2px ${reminder.color}`}
          transition="all 0.3s"
          p={3}
          cursor="pointer"
          position="relative"
          _before={{
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `linear-gradient(135deg, ${reminder.color}20, transparent)`,
            opacity: 0.6,
            zIndex: 0,
          }}
        >
          <HStack spacing={3} position="relative" zIndex={1}>
            <Center
              bg={`${reminder.color}.500`}
              w="40px"
              h="40px"
              borderRadius="full"
              boxShadow={`0 2px 10px ${reminder.color}`}
            >
              <Icon as={reminder.icon} boxSize="20px" color="black" />
            </Center>
            <VStack align="start" spacing={0} flex={1}>
              <Heading size="sm" color="yellow.500" fontWeight="bold">
                {reminder.title}
              </Heading>
              <Text fontSize="xs" color="white" fontStyle="italic" noOfLines={2}>
                "{reminder.points[0]}"
              </Text>
            </VStack>
          </HStack>
        </Box>
      </motion.div>
    );
  };

  
// Component to manage lead data
const LeadManagement = ({ leadData, handleLeadDataChange, setLeadSource, isInCallScript = false }) => {
  

// Add this alongside other handler functions
const handleIntroductionVariantChange = (newVariant) => {
  if (!newVariant) return;
  
  handleLeadDataChange('introductionVariant', newVariant);
  
  toast({
    title: 'Introduction Style Changed',
    description: `Switched to ${introductionVariants[newVariant].name}`,
    status: 'info',
    duration: 2000,
    isClosable: true,
  });
};

// Ensure leadData is always an object
const safeLeadData = leadData || {};

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box mb={2}>
        <Heading size="md" color="yellow.500">Lead Management</Heading>
      </Box>

      <Box flex="1" overflowY="auto">
        <Box 
          height="100%"
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'yellow.500',
              borderRadius: '24px',
            },
          }}
        >
          {/* Status */}
          <Select 
            placeholder="Status" 
            value={safeLeadData.status || ''} 
            onChange={(e) => handleLeadDataChange('status', e.target.value)}
            mb={2}
            bg="black !important"
            borderColor="yellow.500 !important"
            color="white !important"
            _hover={{ borderColor: "yellow.300 !important" }}
            _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
            sx={{
              '& option': {
                bg: 'black !important',
                color: 'white !important',
              }
            }}
          >
            <option value="New">New</option>
            <option value="Contacted">Contacted</option>
            <option value="Qualified">Qualified</option>
            <option value="Appointment Set">Appointment Set</option>
            <option value="Closed">Closed</option>
            <option value="Lost">Lost</option>
          </Select>
          
          {/* Basic Information */}
          <SimpleGrid columns={2} spacing={2} mb={2}>
            <Input 
              placeholder="Name" 
              value={safeLeadData.name || ''} 
              onChange={(e) => handleLeadDataChange('name', e.target.value)} 
          bg="black !important" 
          borderColor="yellow.500 !important" 
          color="white !important" 
          _hover={{ borderColor: "yellow.300 !important" }} 
          _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
            />
            <Input 
              placeholder="Email" 
              value={safeLeadData.email || ''} 
              onChange={(e) => handleLeadDataChange('email', e.target.value)} 
          bg="black !important" 
          borderColor="yellow.500 !important" 
          color="white !important" 
          _hover={{ borderColor: "yellow.300 !important" }} 
          _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
            />
            <Input 
              placeholder="Phone" 
              value={safeLeadData.phone || ''} 
              onChange={(e) => handleLeadDataChange('phone', e.target.value)}
              bg="black !important" 
              borderColor="yellow.500 !important" 
              color="white !important" 
              _hover={{ borderColor: "yellow.300 !important" }} 
              _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
            />
            <Select 
              placeholder="Lead Source" 
              value={leadData.leadSource} 
              onChange={(e) => {
                const newLeadSource = e.target.value;
                setLeadSource(newLeadSource);
                handleLeadDataChange('leadSource', newLeadSource);
              }}
              bg="black !important"
              borderColor="yellow.500 !important"
              color="white !important"
              _hover={{ borderColor: "yellow.300 !important" }}
              _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
              sx={{
                '& option': {
                  bg: 'black !important',
                  color: 'white !important',
                }
              }}
            >
              <option value="website_signup">Website Signup</option>
              <option value="Downloaded_Lead_Magnet">Lead Magnet</option>
              <option value="open_house">Open House</option>
              <option value="older_lead">Older Lead</option>
              <option value="referral">Referral</option>
              <option value="followup">Follow-up</option>
            </Select>
          </SimpleGrid>

          <SimpleGrid columns={2} spacing={2} mb={2}>
            <Input 
              placeholder="Job" 
              value={safeLeadData.job || ''} 
              onChange={(e) => handleLeadDataChange('job', e.target.value)} 
          bg="black !important" 
          borderColor="yellow.500 !important" 
          color="white !important" 
          _hover={{ borderColor: "yellow.300 !important" }} 
          _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
            />
          </SimpleGrid>

          {/* MAW Scoring */}
          <Text fontWeight="bold" mb={2} color="yellow.500">MAW Scoring</Text>
          <HStack spacing={4} mb={2} width="100%">
            <Button
              flex={1}
              size="lg"
              colorScheme={leadData.motivation ? "yellow" : "gray"}
              onClick={() => handleLeadDataChange('motivation', !leadData.motivation)}
              variant={leadData.motivation ? "solid" : "outline"}
            >
              Motivation
            </Button>
            <Button
              flex={1}
              size="lg"
              colorScheme={leadData.ability ? "yellow" : "gray"}
              onClick={() => handleLeadDataChange('ability', !leadData.ability)}
              variant={leadData.ability ? "solid" : "outline"}
            >
              Ability
            </Button>
            <Button
              flex={1}
              size="lg"
              colorScheme={leadData.willingness ? "yellow" : "gray"}
              onClick={() => handleLeadDataChange('willingness', !leadData.willingness)}
              variant={leadData.willingness ? "solid" : "outline"}
            >
              Willingness
            </Button>
          </HStack>

          {/* Bucket */}
          <Text fontWeight="bold" mb={2} color="yellow.500">Bucket</Text>
          <VStack spacing={2} align="stretch" width="100%" mb={4}>
            <Button
              size="lg"
              colorScheme={leadData.bucket === "A" ? "yellow" : "gray"}
              onClick={() => handleLeadDataChange('bucket', "A")}
              variant={leadData.bucket === "A" ? "solid" : "outline"}
              height="80px"
            >
              A 🔥 Hot Lead (60 days)
            </Button>
            <Button 
              size="lg"
              colorScheme={leadData.bucket === "B" ? "yellow" : "gray"}
              onClick={() => handleLeadDataChange('bucket', "B")}
              variant={leadData.bucket === "B" ? "solid" : "outline"}
              height="80px"
            >
              B 🪴 Nurture (60-120 days)
            </Button>
            <Button
              size="lg"
              colorScheme={leadData.bucket === "C" ? "yellow" : "gray"}
              onClick={() => handleLeadDataChange('bucket', "C")}
              variant={leadData.bucket === "C" ? "solid" : "outline"}
              height="80px"
            >
              C 🔭 Watch Lead (120+ days)
            </Button>
          </VStack>

          {/* Notes */}
          <Text fontWeight="bold" mb={2} color="yellow.500">Notes</Text>
<VStack spacing={2} align="stretch" mb={4}>
  <Textarea
    placeholder="Enter a new note here..."
    defaultValue={safeLeadData.newNote || ''}
    onChange={(e) => debouncedHandleNoteChange(e.target.value, handleLeadDataChange)}
    height="80px"
    bg="black !important"
    borderColor="yellow.500 !important"
    color="white !important"
    _hover={{ borderColor: "yellow.300 !important" }}
    _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
  />
  <Button
    colorScheme="yellow"
    onClick={() => addNote(safeLeadData.newNote || '')}
    isDisabled={!(safeLeadData.newNote || '').trim() || isLoading}
    isLoading={isLoading}
  >
    Add Note
  </Button>
</VStack>

          {!isInCallScript && (
            <Input
              mt={2}
              placeholder="Added Date"
              type="date"
              value={leadData.addedDate}
              onChange={(e) => handleLeadDataChange('addedDate', e.target.value)}
              bg="black !important"
              borderColor="yellow.500 !important"
              color="white !important"
              _hover={{ borderColor: "yellow.300 !important" }}
              _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
            />
          )}

          {/* Add Website Signup Variant selector when leadSource is website_signup */}
          {leadData.leadSource === 'website_signup' && (
            <Box mt={4}>
              <Text fontWeight="bold" mb={2} color="yellow.500">Website Signup Approach</Text>
              <Select
                value={leadData.websiteSignupVariant || 'option1'}
                onChange={(e) => {
                  handleLeadDataChange('websiteSignupVariant', e.target.value);
                  handleVariantChange(e.target.value);
                }}
                bg="black !important"
                borderColor="yellow.500 !important"
                color="white !important"
                _hover={{ borderColor: "yellow.300 !important" }}
                _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
                height="50px"
                sx={{
                  '& option': {
                    bg: 'black !important',
                    color: 'white !important',
                  }
                }}
              >
                <option value="option1">Thank You Visit</option>
                <option value="option2">Found What Looking For</option>
                <option value="option3">Market Assistance</option>
                <option value="option4">Search Progress</option>
                <option value="option5">Market Update Offer</option>
                <option value="option6">Custom Property List</option>
                <option value="option7">Community Welcome</option>
                <option value="option8">Motivation Inquiry</option>
              </Select>
            </Box>
          )}

          {/* Add this near the websiteSignupVariant selector */}
          {leadData.leadSource === 'website_signup' && (
            <Box mt={4}>
              <Text fontWeight="bold" mb={2} color="yellow.500">Greeting Style</Text>
              <Select
                value={leadData.greetingVariant || 'option1'}
                onChange={(e) => handleGreetingVariantChange(e.target.value)}
                bg="black !important"
                borderColor="yellow.500 !important"
                color="white !important"
                _hover={{ borderColor: "yellow.300 !important" }}
                _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
                height="50px"
                sx={{
                  '& option': {
                    bg: 'black !important',
                    color: 'white !important',
                  }
                }}
              >
                {Object.entries(greetingVariants).map(([key, variant]) => (
                  <option key={key} value={key}>
                    {variant.name}
                  </option>
                ))}
              </Select>
            </Box>
          )}

          

          {/* Add this near the websiteSignupVariant and greetingVariant selectors */}
          {leadData.leadSource === 'website_signup' && (
            <Box mt={4}>
              <Text fontWeight="bold" mb={2} color="yellow.500">Introduction Style</Text>
              <Select
                value={leadData.introductionVariant || 'option1'}
                onChange={(e) => handleIntroductionVariantChange(e.target.value)}
                bg="black !important"
                borderColor="yellow.500 !important"
                color="white !important"
                _hover={{ borderColor: "yellow.300 !important" }}
                _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
                height="50px"
                sx={{
                  '& option': {
                    bg: 'black !important',
                    color: 'white !important',
                  }
                }}
              >
                {Object.entries(introductionVariants).map(([key, variant]) => (
                  <option key={key} value={key}>
                    {variant.name}
                  </option>
                ))}
              </Select>
            </Box>
          )}

        </Box>
      </Box>
    </Box>
  );
};


const handleEditNote = async (noteId, updatedContent) => {
  try {
    const response = await axios.put(`/api/leads/${leadId}/notes/${noteId}`, { content: updatedContent });
    const updatedNote = response.data;

    setLeadData(prevData => ({
      ...prevData,
      notes: prevData.notes.map(note => 
        note._id === noteId ? updatedNote : note
      )
    }));

    toast({
      title: "Note Updated",
      description: "Your note has been updated successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  } catch (error) {
    console.error('Error updating note:', error);
    toast({
      title: "Error",
      description: `Failed to update note: ${error.response?.data?.message || error.message}`,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }
};

const handleDeleteNote = async (noteId) => {
  try {
    await axios.delete(`/api/leads/${leadId}/notes/${noteId}`);

    setLeadData(prevData => ({
      ...prevData,
      notes: prevData.notes.filter(note => note._id !== noteId)
    }));

    toast({
      title: "Note Deleted",
      description: "Your note has been deleted successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  } catch (error) {
    console.error('Error deleting note:', error);
    toast({
      title: "Error",
      description: `Failed to delete note: ${error.response?.data?.message || error.message}`,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }
};


return (
  <ChakraProvider theme={currentTheme}>
    <Box minHeight="100vh" bg="black" color="white" display="flex" flexDirection="column">
  {/* Call Actions */}
  <Box bg="black" borderColor="orange.400" borderWidth="1px" borderLeft="0" borderRight="0">
         <CallActions
    callType={callType}
    setCallType={setCallType}
    handleStartCall={handleStartCall}
    handleSendSms={handleSendSms}
    isCallActive={isCallActive}
    isMuted={isMuted}
    handleToggleMute={handleToggleMute}
    isSpeakerOn={isSpeakerOn}
    handleToggleSpeaker={handleToggleSpeaker}
    handleEndCall={handleEndCall}
    removeTopBorder={true} // Add this prop
  />
       </Box>


  {/* Header with Essential Skills and Add New Lead Button and Save Button*/}
  <Flex 
    width="100%" 
    px={4} 
    py={8} 
    alignItems="center" 
    overflow="hidden"
    borderBottom="1px solid"
    borderColor="orange.400"
  >

          {/* Essential Skills */}
          <Flex flex="1" justifyContent="flex-start">
            <HStack spacing={2} overflowX="auto" width="100%" css={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              'scrollbarWidth': 'none',
            }}>
              {essentialSkills
                .sort((a, b) => {
                  if (a.title === "Neutral Language") return 1;
                  if (b.title === "Neutral Language") return -1;
                  return 0;
                })
                .map((skill, index) => (
                  <Box key={index} flexShrink={0}> 
                    <EssentialSkillIcon skill={skill} />
                  </Box>
                ))}
            </HStack>
          </Flex>

          {/* Add New Lead Button and Save Button */}
          <Flex flex="1" justifyContent="flex-end">
            <HStack spacing={3}>
              {/* Save button */}
              <Button 
                leftIcon={<FaSave />} 
                onClick={saveProgress} 
                colorScheme="yellow"
                size="md"
                width="180px"
              >
                Save
              </Button>
              
              {/* View Details button (if leadId exists) */}
              {leadId && (
                <Button
                  leftIcon={<FaEye />}
                  colorScheme="yellow"
                  onClick={() => navigate(`/leads/${leadId}`)}
                  size="md"
                  width="180px"
                >
                  View Details
                </Button>
              )}

              {/* New Lead button */}
              <Button
                leftIcon={<FaPlus />}
                colorScheme="yellow"
                onClick={() => startNewLead()}
                size="md"
                width="180px"
              >
                New Lead
              </Button>
            </HStack>
          </Flex>
        </Flex>

                

        {/* Main Content */}
        <Box flex="1" width="100%" borderColor="yellow.500" pb={0} display="flex" flexDirection="column">
          {/* Render redesigned start page */}
          {isStartPage && (
            <Flex flex="1" width="100%">
              {/* Left Column: Navigate to Specific Stages (now using Sidebar) */}
              <Box flex="1" borderRight="1px solid" borderColor="yellow.500">
                <Sidebar 
                  callFlow={callFlow} 
                  currentStageIndex={currentStageIndex} 
                  setCurrentStageIndex={setCurrentStageIndex}
                  onPrevious={prevStage}
                />
              </Box>

              {/* Middle Column: Mindset Reminders */}
              <Box 
                flex="2"
                borderRight="1px solid" 
                borderColor="yellow.500" 
                p={4} 
                overflowY="auto"
                bg="black"
                boxShadow="inset 0 0 20px rgba(255, 215, 0, 0.2)"
              >
                <Heading size="lg" mb={4} color="yellow.500" textAlign="center">
                  Mindset Reminders
                </Heading>
                <VStack spacing={3} align="stretch" width="100%">
                  {mindsetReminders.map((reminder, index) => (
                    <MindsetReminder
                      key={index}
                      reminder={reminder}
                      onSelect={handleReminderSelect}
                    />
                  ))}
                </VStack>
              </Box>

              {/* Right Column: Start New Lead */}
              <Box flex="1" display="flex" flexDirection="column" p={3}>
                <Heading size="lg" mb={4} color="yellow.500" textAlign="center">Start New Lead</Heading>
                <VStack spacing={3} align="stretch" width="100%" mb={4} flex="1" overflowY="auto">
                  <Input
                    placeholder="Name"
                    value={leadData.name}
                    onChange={(e) => handleLeadDataChange('name', e.target.value)}
                    bg="black !important"
                    borderColor="yellow.500 !important"
                    color="white !important"
                    _hover={{ borderColor: "yellow.300 !important" }}
                    _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
                    height="80px"
                    fontSize="md"
                  />
                  <Input
                    placeholder="Email"
                    type="email"
                    value={leadData.email}
                    onChange={(e) => handleLeadDataChange('email', e.target.value)}
                    bg="black !important"
                    borderColor="yellow.500 !important"
                    color="white !important"
                    _hover={{ borderColor: "yellow.300 !important" }}
                    _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
                    height="80px"
                    fontSize="md"
                  />
                  <Input
                    placeholder="Phone"
                    type="tel"
                    value={leadData.phone}
                    onChange={(e) => handleLeadDataChange('phone', e.target.value)}
                    bg="black !important"
                    borderColor="yellow.500 !important"
                    color="white !important"
                    _hover={{ borderColor: "yellow.300 !important" }}
                    _focus={{ borderColor: "yellow.300 !important", boxShadow: "none !important" }}
                    height="80px"
                    fontSize="md"
                  />
                  {[
                    { type: 'website_signup', icon: FaGlobe, label: 'Website Signup' },
                    { type: 'Downloaded_Lead_Magnet', icon: FaClipboardList, label: 'Lead Magnet' },
                    { type: 'open_house', icon: FaHome, label: 'Open House' },
                    { type: 'older_lead', icon: FaHistory, label: 'Older Lead' },
                    { type: 'referral', icon: FaUserFriends, label: 'Referral' },
                    { type: 'followup', icon: FaRedoAlt, label: 'Follow-up' },
                  ].map((lead) => (
                    <Button
                      key={lead.type}
                      onClick={() => handleLeadSourceButtonClick(lead.type)}
                      colorScheme="yellow"
                      variant="outline"
                      height="80px"
                      width="100%"
                      _hover={{ bg: 'yellow.900' }}
                      borderWidth="2px"
                      leftIcon={<Icon as={lead.icon} boxSize="24px" color="yellow.500" />}
                      color="white"
                      justifyContent="flex-start"
                      fontSize="md"
                      fontWeight="bold"
                    >
                      {lead.label}
                    </Button>
                  ))}
                </VStack>
                <Button 
                  rightIcon={<MdNavigateNext />} 
                  onClick={nextStage} 
                  isDisabled={currentStageIndex.section === callFlow.length - 1 && currentStageIndex.stage === callFlow[callFlow.length - 1].stages.length - 1} 
                  colorScheme="yellow" 
                  variant="outline"
                  size="md"
                  width="100%"
                  height="80px"
                  fontSize="md"
                  borderColor="yellow.500"
                  color="yellow.500"
                >
                  Next
                </Button>
              </Box>
            </Flex>
          )}

          {/* Staging Pages, Render call script content when not on start page */}
          {!isStartPage && (
            <Box width="100%" display="flex" flexDirection="column" flex="1">
              <Grid templateColumns="22% 28% 28% 22%" gap={0} height="100%" flex="1">
                {/* Stage 1: Navigation */}
                <GridItem borderRight="1px solid" borderColor="yellow.500" height="100%" overflow="hidden">
  <Box bg="black" overflowY="auto" borderRadius="0" height="100%">
    <Sidebar 
      callFlow={callFlow} 
      currentStageIndex={currentStageIndex} 
      setCurrentStageIndex={setCurrentStageIndex}
      onPrevious={prevStage}
    />
  </Box>
</GridItem>

                {/* Stage 2: Lead Information */}
<GridItem borderRight="1px solid" borderColor="yellow.500" height="100%">
  <Box p={4} height="100%" overflowY="auto">
    <LeadManagement 
      leadData={{ ...leadData, leadSource: leadSource }}
      handleLeadDataChange={handleLeadDataChange}
      setLeadSource={setLeadSource}
      isInCallScript={true}
    />


  </Box>
</GridItem>


                {/* Stage 3: Call Script */}
                <GridItem borderRight="1px solid" borderColor="yellow.500" height="100%">
                  <Box p={4} height="100%" overflowY="auto">
                  <EnhancedCallScriptStep
  step={currentStage}
  leadSource={leadSource}
  leadData={leadData}
  propertyAddress={leadData.propertyAddress}
  onResponse={handleClientResponse}
  handleLeadSourceButtonClick={handleLeadSourceButtonClick}
  handleLeadDataChange={handleLeadDataChange}
  setCurrentStageIndex={setCurrentStageIndex}
  callFlow={callFlow}
  wayOutResponse={wayOutResponse}
  setWayOutResponse={setWayOutResponse}
  handleObjectionResponse={handleObjectionResponse}
  handleVariantChange={handleVariantChange}
  greetingVariant={leadData.greetingVariant} // Add this prop
  introductionVariant={leadData.introductionVariant}
  isDuplicateAware={isDuplicateAware} // Add this line
/>
                  </Box>
                </GridItem>

                {/* Stage 4: Property Details */}
                <GridItem height="100%" display="flex" flexDirection="column">
                  <Box p={4} height="100%" display="flex" flexDirection="column" overflowY="auto">
                    <PropertyInformation
                      propertyData={propertyData}
                      handlePropertyDataChange={handlePropertyDataChange}
                    />
                    <Button 
                      rightIcon={<MdNavigateNext />} 
                      onClick={nextStage} 
                      isDisabled={currentStageIndex.section === callFlow.length - 1 && currentStageIndex.stage === callFlow[callFlow.length - 1].stages.length - 1} 
                      colorScheme="yellow" 
                      variant="outline"
                      size="md"
                      width="100%"
                      height="80px"
                      mt="auto"
                      fontSize="md"
                      borderColor="yellow.500"
                      color="yellow.500"
                    >
                      Next
                    </Button>
                  </Box>
                </GridItem>
              </Grid>
            </Box>
          )}

{isCallEnded && (
            <Box p={6} borderRadius="lg" boxShadow="lg" bg={surfaceColor} borderColor={borderColor} borderWidth="1px" mt={4}>
              <Heading size="lg" mb={4} color="yellow.500">Call Transcript</Heading>
              <Text>The lead was not interested in further assistance. You can review and update the notes below.</Text>
              <Textarea
                mt={4}
                value={leadData.notes}
                onChange={(e) => handleLeadDataChange('notes', e.target.value)}
                height="100%"
                bg={surfaceColor}
                borderColor={borderColor}
                color={textColor}
              />
              <Button mt={4} colorScheme="yellow" onClick={() => setIsCallEnded(false)}>
                Start New Call
              </Button>
            </Box>
          )}


          <Modal isOpen={newLeadDisclosure.isOpen} onClose={newLeadDisclosure.onClose} isCentered>
            <ModalOverlay 
              bg="blackAlpha.300"
              backdropFilter="blur(10px)"
            />
            <ModalContent bg={surfaceColor} borderColor={borderColor} borderWidth="1px">
              <ModalHeader color="yellow.500">Start New Lead</ModalHeader>
              <ModalCloseButton />
              <ModalBody color={textColor}>
                <Text>You have unsaved changes. What would you like to do?</Text>
              </ModalBody>

              <ModalFooter>
                <VStack spacing={4} width="100%">
                  <Button 
                    colorScheme="yellow" 
                    onClick={() => { saveProgress(); proceedWithNewLead(pendingNewLeadSource); }}
                    width="100%"
                  >
                    Save Changes and Start New Lead
                  </Button>
                  <Button 
                    colorScheme="red" 
                    onClick={() => proceedWithNewLead(pendingNewLeadSource)}
                    width="100%"
                  >
                    Discard Changes and Start New Lead
                  </Button>
                  <Button 
                    variant="outline" 
                    onClick={newLeadDisclosure.onClose}
                    width="100%"
                    borderColor="yellow.500"
                    color="yellow.500"
                  >
                    Cancel
                  </Button>
                </VStack>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <MindsetReminderModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            reminder={selectedReminder}
          />

          {isDuplicateModalOpen && (
  <DuplicateLeadsModal
    isOpen={isDuplicateModalOpen}
    duplicates={duplicateLeads}
    onCancel={() => setIsDuplicateModalOpen(false)}
    onContinueWithLead={handleContinueWithLead}
    onViewLeadDetails={handleViewLeadDetails}
          />
          )}

          {/* Full-width Activity Timeline */}
<Box 
  width="100%" 
  bg="gray.900" 
  p={4} 
  borderTop="1px solid" 
  borderColor="yellow.500"
  mt="auto"
  style={{ scrollBehavior: 'auto' }} // Add this line
>
  <Heading size="md" color="orange.400" mb={3}>
    Activity Timeline
  </Heading>

  <Flex mb={3} gap={2}>
    <Input
      placeholder="Search timeline..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      bg="gray.800"
      color="white"
      borderColor="orange.400"
      borderWidth="1px"
      _hover={{ borderColor: 'orange.400' }}
      flex={1}
    />
    <ButtonGroup size="md" isAttached variant="outline">
      <Button
        onClick={() => handleFilterChange('All')}
        colorScheme={timelineFilter === 'All' ? 'orange' : 'gray'}
      >
        All
      </Button>
      <Button
        onClick={() => handleFilterChange('Notes')}
        colorScheme={timelineFilter === 'Notes' ? 'orange' : 'gray'}
      >
        Notes
      </Button>
      <Button
        onClick={() => handleFilterChange('Calls')}
        colorScheme={timelineFilter === 'Calls' ? 'orange' : 'gray'}
      >
        Calls
      </Button>
    </ButtonGroup>
  </Flex>

  <Box 
    maxHeight="300px" 
    overflowY="auto"
    style={{ scrollBehavior: 'auto' }} // Add this line
  >
    <LeadTimeline
      filter={timelineFilter}
      searchQuery={searchQuery}
      timelineItems={filteredTimelineItems}
    />
  </Box>
</Box>

        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default CallScript;