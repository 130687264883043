import { FaRobot, FaChartLine, FaRocket } from 'react-icons/fa';

export const enhancedFeatures = [
  {
    icon: FaRobot,
    title: 'ScriptSense™ AI Technology',
    description: 'Transform every conversation into a closing opportunity with our revolutionary AI technology.',
    feature: 'Real-time dialogue optimization',
    benefit: 'Close 30% more deals',
    highlights: [
      'Market-specific dialogue optimization',
      'Dynamic script adaptation',
      'Real-time conversation insights'
    ]
  },
  {
    icon: FaChartLine,
    title: 'LeadPulse™ Scoring System',
    description: 'Stop guessing which leads are ready to move with our advanced MAW scoring system.',
    feature: 'Predictive buyer readiness metrics',
    benefit: 'Save 15 hours per week',
    highlights: [
      'Instant lead qualification',
      'Predictive analytics',
      'Priority scoring dashboard'
    ]
  },
  {
    icon: FaRocket,
    title: 'OmniConnect™ Suite',
    description: 'Manage all client communications from one powerful platform with AI-powered insights.',
    feature: 'Unified communication platform',
    benefit: 'Never miss an opportunity',
    highlights: [
      'Integrated calling & messaging',
      'Automated follow-ups',
      'Conversation analytics'
    ]
  }
]; 