import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { getSocket, connectSocket, disconnectSocket } from '../socket';
import { Device } from '@twilio/voice-sdk';
import LeadsList from '../components/LeadsList';
import MessageThread from '../components/MessageThread';
import { 
  Box, 
  Button, 
  IconButton, 
  HStack, 
  Text, 
  useToast, 
  Flex,
  Heading,
  Divider,
  Input
} from '@chakra-ui/react';
import { MdCall, MdMic, MdMicOff, MdVolumeUp, MdVolumeOff, MdSend } from 'react-icons/md';
import { Client } from '@twilio/conversations';
import { useLocation, useNavigate } from 'react-router-dom';
import EmojiPicker from 'emoji-picker-react';
import { FaSmile } from 'react-icons/fa';
import { SMSTemplates } from '../components/SMSTemplates';

const Communications = () => {
  // State Variables
  const [messages, setMessages] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [page, setPage] = useState(1);
  const [visibleMessages, setVisibleMessages] = useState(10);
  const [leads, setLeads] = useState([]);

  // References
  const messageThreadRef = useRef(null);
  const deviceRef = useRef(null);

  // Call State
  const [isCallActive, setIsCallActive] = useState(false);
  const [activeCall, setActiveCall] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isSpeakerOn, setIsSpeakerOn] = useState(false);

  // Messaging State
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  const toast = useToast();

  const MESSAGES_PER_LOAD = 20;

  const [isLoading, setIsLoading] = useState(false);
  const [allMessagesLoaded, setAllMessagesLoaded] = useState(false);

  const location = useLocation();

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);

  const emojiPickerRef = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showLeadsList, setShowLeadsList] = useState(true);  // Changed to true by default
  const [showMessageThread, setShowMessageThread] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth < 768;
      setIsMobile(newIsMobile);
      // Always show leads list on desktop
      if (!newIsMobile) {
        setShowLeadsList(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial state
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchToken = async () => {
    try {
      const response = await axios.get('/api/twilio/token');
      return response.data.token;
    } catch (error) {
      console.error('Error fetching Twilio token:', error);
      throw error;
    }
  };

  // Twilio Device setup
  useEffect(() => {
    const setupTwilioDevice = async () => {
      try {
        const token = await fetchToken();
        const device = new Device(token, {
          codecPreferences: ['opus', 'pcmu'],
          fakeLocalDTMF: true,
          enableRingingState: true,
        });
        
        // Wait for a user interaction before calling device.register
        const userInteractionHandler = () => {
          device.register();
          window.removeEventListener('click', userInteractionHandler);
        };
        window.addEventListener('click', userInteractionHandler);

        deviceRef.current = device;
        console.log('Twilio device set up successfully');
      } catch (error) {
        console.error('Error setting up Twilio device:', error);
      }
    };

    setupTwilioDevice();
  }, []);

  // Update the Socket.IO event listener
  useEffect(() => {
    let socketInstance = null;
    
    const initializeSocket = async () => {
      try {
        // Get socket instance
        socketInstance = getSocket();
        
        if (socketInstance) {
          console.log('Socket connection initialized:', socketInstance.connected);

          // Handle new messages
          const handleNewMessage = (message) => {
            console.log('Received new message:', message);
            
            const newMessage = {
              id: message.messageSid || Date.now().toString(),
              body: message.body,
              direction: message.direction,
              dateCreated: new Date(message.timestamp),
              lead: message.leadId ? { _id: message.leadId } : null,
              from: message.from,
              to: message.to,
              status: message.status
            };

            setMessages(prevMessages => {
              if (!Array.isArray(prevMessages)) {
                console.warn('Previous messages is not an array:', prevMessages);
                return [newMessage];
              }
              return [...prevMessages, newMessage];
            });

            // Update lead information if needed
            if (selectedLead && message.leadId === selectedLead._id) {
              setSelectedLead(prevSelected => ({
                ...prevSelected,
                lastActivity: new Date(message.timestamp)
              }));
            }
          };

          socketInstance.on('new_message', handleNewMessage);
        }
      } catch (error) {
        console.error('Socket initialization error:', error);
      }
    };

    initializeSocket();

    // Cleanup function
    return () => {
      if (socketInstance) {
        socketInstance.off('new_message');
        disconnectSocket();
        console.log('Socket connection cleaned up');
      }
    };
  }, [selectedLead]);

  // Fetch Messages with Pagination
  const fetchMessages = useCallback(async () => {
    if (isLoading || allMessagesLoaded) return;
    setIsLoading(true);
    try {
      const response = await axios.get(`/api/twilio/paginated-with-leads?page=${page}&limit=${MESSAGES_PER_LOAD}`);
      console.log('Fetched paginated messages with leads:', response.data);
      
      setMessages(prevMessages => {
        const existingIds = new Set(prevMessages.map(msg => msg.id));
        const newUniqueMessages = response.data.messages.filter(msg => !existingIds.has(msg.id));
        return [...prevMessages, ...newUniqueMessages];
      });

      if (response.data.messages.length < MESSAGES_PER_LOAD) {
        setAllMessagesLoaded(true);
      }
      setPage(prevPage => prevPage + 1);
    } catch (error) {
      console.error('Error fetching paginated messages:', error);
      toast({
        title: 'Error Fetching Messages',
        description: error.message || 'An error occurred while fetching messages.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [page, toast, isLoading, allMessagesLoaded, MESSAGES_PER_LOAD]);

  // Initial fetch of messages
  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  // Initiate Call
  const initiateCall = async (lead) => {
    if (!deviceRef.current) {
      console.error('Twilio device not set up');
      return;
    }
    try {
      const call = await deviceRef.current.connect({ To: lead.phone });
      setActiveCall(call);
      setIsCallActive(true);
      console.log('Call initiated');
    } catch (error) {
      console.error('Error initiating call:', error);
      toast({
        title: 'Call Error',
        description: 'Failed to initiate call. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Toggle Mute
  const handleToggleMute = () => {
    if (activeCall) {
      activeCall.mute(!isMuted);
      setIsMuted(!isMuted);
    }
  };

  // Toggle Speaker
  const handleToggleSpeaker = () => {
    // This is a placeholder. Actual implementation depends on your audio setup
    setIsSpeakerOn(!isSpeakerOn);
    // You might need to implement actual audio routing here
  };

  // End Call
  const endCall = () => {
    if (activeCall) {
      activeCall.disconnect();
      setIsCallActive(false);
      setActiveCall(null);
    }
  };

  // Update the handleSendMessage function
  const handleSendMessage = async (message) => {
    if (!selectedLead || !message.trim()) {
      console.log('No lead selected or empty message');
      return;
    }

    setIsSending(true);
    try {
      const response = await axios.post('/api/twilio/send-sms', {
        to: selectedLead.phone,
        message: message,
        leadId: selectedLead._id
      });

      console.log('Message sent successfully:', response.data);

      const newMessage = {
        id: response.data.messageSid,
        body: message,
        direction: 'outgoing',
        dateCreated: new Date(),
        lead: selectedLead,
        from: process.env.REACT_APP_TWILIO_PHONE_NUMBER,
        to: selectedLead.phone,
        status: 'sent'
      };

      // Add to end of array for chronological order
      setMessages(prevMessages => [...prevMessages, newMessage]);

      // Clear the message input after successful send
      setMessage('');

      toast({
        title: 'Message Sent',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: 'Error Sending Message',
        description: error.response?.data?.error || error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSending(false);
    }
  };

  // Get Messages for Selected Lead
  const getMessagesForSelectedLead = useCallback(() => {
    if (!selectedLead || !Array.isArray(messages)) {
      return [];
    }
    return messages.filter(msg => 
      msg && msg.lead && msg.lead._id === selectedLead._id
    );
  }, [messages, selectedLead]);

  // Fetch More Data (for infinite scroll)
  const fetchMoreData = () => {
    if (!allMessagesLoaded) {
      setVisibleMessages(prev => prev + MESSAGES_PER_LOAD);
    }
  };

  // Handle Lead Selection
  const handleLeadSelect = useCallback((lead) => {
    setSelectedLead(lead);
    setPage(1);
    setAllMessagesLoaded(false);
    fetchMessages();
    if (isMobile) {
      setShowLeadsList(false);
      setShowMessageThread(true);
    }
  }, [fetchMessages, isMobile]);

  useEffect(() => {
    console.log('Communications component mounted, Socket.IO connected:', getSocket().connected);
    return () => {
      disconnectSocket();
      console.log('Communications component unmounted, Socket.IO disconnected');
    };
  }, []);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await axios.get('/api/leads');
        console.log('Fetched leads response:', response.data);
        
        // If the response contains a leads property, pass the whole response
        // The LeadsList component now handles both formats
        setLeads(response.data);
        
      } catch (error) {
        console.error('Error fetching leads:', error);
        toast({
          title: 'Error Fetching Leads',
          description: error.message || 'An error occurred while fetching leads.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchLeads();
  }, [toast]);

  useEffect(() => {
    const { state } = location;
    if (state?.leadId && leads.length > 0) {  // Check if leads array exists and has items
      const lead = leads.find(l => l._id === state.leadId);
      if (lead) {
        setSelectedLead(lead);
        fetchMessages(lead._id);
      }
    }
  }, [location, leads, fetchMessages]); // Add fetchMessages to dependencies

  // Handle emoji selection
  const addEmoji = (emojiObject) => {
    // Update to handle the new emoji-picker-react structure
    if (emojiObject && emojiObject.emoji) {
      setMessage(prevMessage => prevMessage + emojiObject.emoji);
    }
  };

  // Handle selecting a template
  const handleSelectTemplate = (template) => {
    setMessage(template);
    setShowTemplates(false);
  };

  // Handle click outside of emoji picker and templates
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
      // Close templates when clicking outside
      if (!event.target.closest('.templates-container')) {
        setShowTemplates(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Add error boundary for messages state
  const safeMessages = Array.isArray(messages) ? messages : [];

  const handleLeadNameClick = (leadId) => {
    navigate(`/leads/${leadId}`);
  };

  return (
    <Flex 
      height="100vh" 
      bg="black" 
      color="white"
      direction="column"
      overflow="hidden"
    >
      {/* Mobile Toggle Button */}
      {isMobile && (
        <Flex px={2} py={1} bg="gray.900">
          <Button
            onClick={() => {
              setShowLeadsList(!showLeadsList);
              setShowMessageThread(!showMessageThread);
            }}
            colorScheme="orange"
            size="sm"
            width="100%"
          >
            {showLeadsList ? 'View Messages' : 'View Leads'}
          </Button>
        </Flex>
      )}

      <Flex flex={1} direction={{ base: 'column', md: 'row' }} overflow="hidden">
        {/* Leads List */}
        {(showLeadsList || !isMobile) && (
          <Box 
            width={{ base: '100%', md: '400px' }}
            borderRight={{ base: 'none', md: '1px solid orange' }}
            overflowY="auto"
            display={{ base: showLeadsList ? 'block' : 'none', md: 'block' }}
            height="100%"
          >
            <LeadsList 
              leads={leads}
              onSelectLead={handleLeadSelect}
              selectedLead={selectedLead} 
            />
          </Box>
        )}

        {/* Message Thread */}
        {(!isMobile || showMessageThread) && (
          <Flex 
            flex={1} 
            direction="column"
            display={{ base: showMessageThread ? 'flex' : 'none', md: 'flex' }}
            height="100%"
            overflow="hidden"
          >
            {selectedLead ? (
              <>
                {/* Lead Info Header */}
                <Box 
                  position="sticky" 
                  top={0} 
                  zIndex={10} 
                  bg="black"
                  borderBottom="1px solid"
                  borderColor="orange.400"
                >
                  <Flex p={6} justify="space-between" align="center" flexWrap="wrap" minHeight="96px">
                    <Box 
                      mb={{ base: 2, md: 0 }} 
                      cursor="pointer" 
                      onClick={() => handleLeadNameClick(selectedLead._id)}
                      _hover={{ color: "orange.300" }}
                      flex="1"
                      textAlign="left"
                    >
                      <Heading size="sm">{selectedLead.name}</Heading>
                      <Text fontSize="xs" color="gray.400">{selectedLead.phone}</Text>
                    </Box>
                    <Box flexShrink={0} ml={4}>
                      <Button
                        leftIcon={<MdCall />}
                        colorScheme="green"
                        onClick={() => initiateCall(selectedLead)}
                        isDisabled={isCallActive}
                        size="sm"
                      >
                        Call Lead
                      </Button>
                    </Box>
                  </Flex>
                </Box>

                {/* Active Call Controls */}
                {isCallActive && (
                  <Box bg="gray.900" p={2}>
                    <Flex justify="space-between" align="center">
                      <Text fontSize="sm">
                        Active Call with {selectedLead.name}
                      </Text>
                      <HStack spacing={2}>
                        <IconButton
                          icon={isMuted ? <MdMicOff /> : <MdMic />}
                          onClick={handleToggleMute}
                          aria-label={isMuted ? 'Unmute' : 'Mute'}
                          size="sm"
                          variant="ghost"
                          color="white"
                          _hover={{ bg: 'gray.700' }}
                        />
                        <IconButton
                          icon={isSpeakerOn ? <MdVolumeUp /> : <MdVolumeOff />}
                          onClick={handleToggleSpeaker}
                          aria-label={isSpeakerOn ? 'Speaker Off' : 'Speaker On'}
                          size="sm"
                          variant="ghost"
                          color="white"
                          _hover={{ bg: 'gray.700' }}
                        />
                        <Button 
                          colorScheme="red" 
                          size="sm" 
                          onClick={endCall}
                          leftIcon={<MdCall />}
                        >
                          End Call
                        </Button>
                      </HStack>
                    </Flex>
                  </Box>
                )}

                {/* Message Thread */}
                <Box 
                  flex={1} 
                  overflow="hidden"
                  position="relative"
                >
                  <MessageThread 
                    ref={messageThreadRef}
                    messages={getMessagesForSelectedLead()}
                    selectedLead={selectedLead}
                    onLoadMore={fetchMessages}
                    hasMore={!allMessagesLoaded}
                    isLoading={isLoading}
                    onSendMessage={handleSendMessage}
                  />
                </Box>

                {/* Send SMS Box */}
                <Box 
                  p={2} 
                  bg="black" 
                  borderTop="1px solid" 
                  borderColor="orange.400"
                >
                  <Flex alignItems="center">
                    <IconButton
                      icon={<FaSmile />}
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                      variant="outline"
                      colorScheme="orange"
                      color="orange.300"
                      aria-label="Add Emoji"
                      size="sm"
                      mr={2}
                    />
                    <IconButton
                      icon={<MdSend />}
                      onClick={() => setShowTemplates(!showTemplates)}
                      variant="outline"
                      colorScheme="orange"
                      color="orange.300"
                      aria-label="Select Template"
                      size="sm"
                      mr={2}
                    />
                    <Input
                      placeholder="Type your message here..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSendMessage(message);
                        }
                      }}
                      mr={2}
                      bg="gray.900"
                      border="1px solid orange"
                      _focus={{ boxShadow: "none" }}
                      _hover={{ borderColor: "orange" }}
                      size="sm"
                      color="white"
                      flex={1}
                    />
                    <Button
                      colorScheme="orange"
                      onClick={() => handleSendMessage(message)}
                      isLoading={isSending}
                      leftIcon={<MdSend />}
                      size="sm"
                    >
                      Send
                    </Button>
                  </Flex>

                  {/* Emoji Picker */}
                  {showEmojiPicker && (
                    <Box 
                      ref={emojiPickerRef}
                      position="absolute" 
                      bottom="60px" 
                      left="10px" 
                      zIndex="10"
                      bg="gray.900"
                      border="2px solid orange"
                      borderRadius="md"
                      overflow="hidden"
                      boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                    >
                      <EmojiPicker 
                        onEmojiClick={addEmoji}
                        theme="dark"
                        emojiStyle="native"
                        width={350}
                        height={450}
                        searchDisabled={false}
                        previewConfig={{
                          showPreview: false
                        }}
                        lazyLoadEmojis={true}
                      />
                    </Box>
                  )}

                  {/* SMS Templates */}
                  {showTemplates && (
                    <Box 
                      className="templates-container"
                      position="absolute" 
                      bottom="60px" 
                      right="10px" 
                      zIndex="10"
                      bg="gray.900"
                      border="2px solid orange"
                      borderRadius="md"
                      boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                      width="400px"
                      maxHeight="500px"
                      overflowY="auto"
                    >
                      <SMSTemplates onSelect={handleSelectTemplate} />
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <Flex 
                justify="center" 
                align="center" 
                height="100%" 
                color="gray.500"
                p={4}
              >
                <Text fontSize="lg">Select a lead to view messages</Text>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Communications;
