import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Select,
  Grid,
  GridItem,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Text,
} from '@chakra-ui/react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ReportsPage() {
  const [metrics, setMetrics] = useState([]);
  const [timeframe, setTimeframe] = useState('weekly');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchMetrics = useCallback(async (selectedTimeframe) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/api/twilio/metrics?timeframe=${selectedTimeframe}`);
      if (response.data.success) {
        setMetrics(response.data.metrics);
      } else {
        setError('Failed to fetch metrics');
      }
    } catch (err) {
      console.error('Error fetching metrics:', err);
      setError('Error fetching metrics');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchMetrics(timeframe);
  }, [fetchMetrics, timeframe]);

  const handleTimeframeChange = (e) => {
    setTimeframe(e.target.value);
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: { color: 'white' },
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
      },
      x: {
        ticks: { color: 'white' },
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
      },
    },
    plugins: {
      legend: {
        labels: { color: 'white' },
      },
    },
  };

  const renderChart = (title, dataKey, color) => {
    if (metrics.length === 0) {
      return null;
    }

    const data = {
      labels: metrics.map(m => m._id.$dateToString || `${m._id.year}-${m._id.week}`),
      datasets: [
        {
          label: title,
          data: metrics.map(m => m[dataKey]),
          backgroundColor: color,
          borderColor: color,  // Add border color
          borderWidth: 1,      // Add border width
        },
      ],
    };

    // Add animation and other configurations
    const enhancedChartOptions = {
      ...chartOptions,
      animation: {
        duration: 750,
        easing: 'easeInOutQuart'
      },
      plugins: {
        ...chartOptions.plugins,
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          titleColor: 'white',
          bodyColor: 'white',
          padding: 10,
          displayColors: false
        }
      }
    };

    return (
      <GridItem key={title}>
        <Heading size="md" mb={4} color="primaryAccent">{title}</Heading>
        <Box height="300px" width="100%">
          <Bar 
            key={`${title}-${timeframe}`}  // Add unique key
            options={enhancedChartOptions} 
            data={data} 
          />
        </Box>
      </GridItem>
    );
  };

  return (
    <Box bg="black" minHeight="calc(100vh - 60px)" p={8} color="white">
      <Heading mb={6} className="gradient-text">Reports & Statistics</Heading>
      <FormControl width="200px" mb={6}>
        <FormLabel color="white">Timeframe</FormLabel>
        <Select 
          value={timeframe} 
          onChange={handleTimeframeChange}
          bg="black"
          color="white"
          borderColor="primaryAccent"
          _hover={{ borderColor: "secondaryAccent" }}
          _focus={{ borderColor: "secondaryAccent", boxShadow: "0 0 0 1px var(--secondary-accent)" }}
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="quarterly">Quarterly</option>
          <option value="yearly">Yearly</option>
        </Select>
      </FormControl>
      
      {loading ? (
        <Spinner size="xl" color="primaryAccent" />
      ) : error ? (
        <Alert status="error" bg="surfaceBg" color="white">
          <AlertIcon />
          <AlertTitle mr={2}>Error!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      ) : (
        <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap={6}>
          {metrics.length > 0 ? (
            <>
              {renderChart("Total Calls", "totalCalls", "rgba(255, 140, 0, 0.7)")}
              {renderChart("Dials Made", "dialed", "rgba(255, 140, 0, 0.7)")}
              {renderChart("Pickups", "pickups", "rgba(255, 215, 0, 0.7)")}
              {renderChart("Connections", "connections", "rgba(255, 140, 0, 0.7)")}
              {renderChart("Conversations ≥ 2 mins", "conversations", "rgba(255, 215, 0, 0.7)")}
              {renderChart("Appointments Booked", "appointmentsBooked", "rgba(255, 140, 0, 0.7)")}
              {renderChart("Showings Executed", "showingsExecuted", "rgba(255, 215, 0, 0.7)")}
              {renderChart("Offers Accepted", "offersAccepted", "rgba(255, 140, 0, 0.7)")}
            </>
          ) : (
            <Text>No data available</Text>
          )}
        </Grid>
      )}
    </Box>
  );
}

export default ReportsPage;
