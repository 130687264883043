import React, { useMemo } from 'react';
import {
  Box,
  HStack,
  Text,
  Tooltip,
  Progress,
  VStack,
} from '@chakra-ui/react';
import { FaFire, FaMoneyBillWave, FaHandshake } from 'react-icons/fa';
import { useTheme } from '../context/ThemeContext';

const MAWScore = ({ motivation, ability, willingness }) => {
  const { currentTheme } = useTheme();
  
  // Calculate weighted score (40/40/20 split)
  const score = useMemo(() => {
    const motivationScore = motivation ? 40 : 0;
    const abilityScore = ability ? 40 : 0;
    const willingnessScore = willingness ? 20 : 0;
    return motivationScore + abilityScore + willingnessScore;
  }, [motivation, ability, willingness]);

  // Determine color based on criteria met
  const criteriaCount = [motivation, ability, willingness].filter(Boolean).length;
  
  const scoreColor = useMemo(() => {
    if (score === 100) return currentTheme.colors.success;
    if (criteriaCount >= 2) return currentTheme.colors.warning;
    return currentTheme.colors.error;
  }, [score, criteriaCount, currentTheme.colors.success, currentTheme.colors.warning, currentTheme.colors.error]);

  const getTooltipText = (type, value) => {
    const status = value ? 'Confirmed' : 'Not Confirmed';
    switch (type) {
      case 'motivation':
        return `Motivation (40%): ${status}\nLead's eagerness to make a move`;
      case 'ability':
        return `Ability (40%): ${status}\nFinancial readiness to proceed`;
      case 'willingness':
        return `Willingness (20%): ${status}\nOpenness to guidance and cooperation`;
      default:
        return '';
    }
  };

  return (
    <VStack 
      spacing={2} 
      align="stretch" 
      w="100%" 
      p={4} 
      bg={currentTheme.colors.surfaceBg}
      borderRadius="md" 
      shadow="sm"
    >
      <HStack justify="space-between" mb={2}>
        <Text fontWeight="bold" fontSize="lg" color={currentTheme.colors.textColor}>
          Lead Readiness Score
        </Text>
        <Text
          fontWeight="bold"
          fontSize="xl"
          color={scoreColor}
        >
          {score}%
        </Text>
      </HStack>

      <Progress
        value={score}
        colorScheme={score === 100 ? "success" : criteriaCount >= 2 ? "warning" : "error"}
        size="lg"
        borderRadius="full"
        mb={3}
      />

      <HStack spacing={4} justify="space-between">
        <Tooltip label={getTooltipText('motivation', motivation)} placement="top">
          <Box
            p={2}
            borderRadius="md"
            bg={motivation ? currentTheme.colors.primaryAccent + '20' : currentTheme.colors.inactiveColor}
            color={motivation ? currentTheme.colors.primaryAccent : currentTheme.colors.textColor}
            cursor="help"
          >
            <HStack spacing={2}>
              <FaFire />
              <Text fontSize="sm" fontWeight="medium">40%</Text>
            </HStack>
          </Box>
        </Tooltip>

        <Tooltip label={getTooltipText('ability', ability)} placement="top">
          <Box
            p={2}
            borderRadius="md"
            bg={ability ? currentTheme.colors.primaryAccent + '20' : currentTheme.colors.inactiveColor}
            color={ability ? currentTheme.colors.primaryAccent : currentTheme.colors.textColor}
            cursor="help"
          >
            <HStack spacing={2}>
              <FaMoneyBillWave />
              <Text fontSize="sm" fontWeight="medium">40%</Text>
            </HStack>
          </Box>
        </Tooltip>

        <Tooltip label={getTooltipText('willingness', willingness)} placement="top">
          <Box
            p={2}
            borderRadius="md"
            bg={willingness ? currentTheme.colors.primaryAccent + '20' : currentTheme.colors.inactiveColor}
            color={willingness ? currentTheme.colors.primaryAccent : currentTheme.colors.textColor}
            cursor="help"
          >
            <HStack spacing={2}>
              <FaHandshake />
              <Text fontSize="sm" fontWeight="medium">20%</Text>
            </HStack>
          </Box>
        </Tooltip>
      </HStack>
    </VStack>
  );
};

export default MAWScore; 