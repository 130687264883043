import React from 'react';
import { 
  Box, Button, Container, Heading, Text, Stack, SimpleGrid, 
  Icon, useColorModeValue, Flex, chakra, VStack,
  Stat, StatLabel, StatNumber, StatHelpText
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { BsTelephone, BsPeople, BsGraphUp, BsChatDots } from 'react-icons/bs';
import { FaRobot, FaChartLine } from 'react-icons/fa';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useBreakpointValue } from '@chakra-ui/react';

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);

const Feature = ({ title, text, icon }) => {
  return (
    <Box
      p={6}
      bg="gray.800"
      borderRadius="lg"
      border="1px solid"
      borderColor="orange.400"
      cursor="pointer"
      _hover={{
        transform: "translateY(-5px)",
        boxShadow: "xl",
        borderColor: "orange.500"
      }}
      transition="all 0.3s"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Heading size="md" color="white" mb={2}>
            {title}
          </Heading>
          <Text color="gray.400">{text}</Text>
        </Box>
        <Icon as={icon} w={8} h={8} color="orange.400" />
      </Flex>
    </Box>
  );
};

const StatCard = ({ title, stat, icon, helpText, onClick }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        p={6}
        bg="gray.800"
        borderRadius="lg"
        border="1px solid"
        borderColor="orange.400"
        cursor={onClick ? "pointer" : "default"}
        onClick={onClick}
        _hover={{
          transform: "translateY(-5px)",
          boxShadow: "xl",
          borderColor: "orange.500"
        }}
        transition="all 0.3s"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Stat>
            <StatLabel color="gray.400">{title}</StatLabel>
            <StatNumber fontSize="3xl" color="white">{stat}</StatNumber>
            {helpText && (
              <StatHelpText color="orange.400">{helpText}</StatHelpText>
            )}
          </Stat>
          <Icon as={icon} w={8} h={8} color="orange.400" />
        </Flex>
      </Box>
    </motion.div>
  );
};

function NewHomePage() {
  const navigate = useNavigate();
  const bgGradient = useColorModeValue(
    'linear(to-r, orange.400, orange.600)',
    'linear(to-r, orange.200, orange.400)'
  );
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box bg="black" minH="100vh">
      <Container maxW="container.xl" pt={{ base: "80px", md: "8" }}>
        <MotionHeading
          mb={6}
          bgGradient={bgGradient}
          bgClip="text"
          fontSize={{ base: "2xl", md: "4xl" }}
          textAlign="center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Welcome to Real•Talk•Pro Dashboard
        </MotionHeading>

        <SimpleGrid 
          columns={{ base: 1, md: 2, lg: 4 }} 
          spacing={{ base: 4, md: 6 }}
          mb={{ base: 6, md: 8 }}
          px={{ base: 4, md: 0 }}
        >
          <StatCard
            title="Total Leads"
            stat="247"
            icon={BsPeople}
            helpText="+12% from last month"
            onClick={() => navigate('/leads')}
          />
          <StatCard
            title="Calls Made"
            stat="1,432"
            icon={BsTelephone}
            helpText="Last 30 days"
            onClick={() => navigate('/calls-log')}
          />
          <StatCard
            title="Messages Sent"
            stat="893"
            icon={BsChatDots}
            helpText="85% response rate"
            onClick={() => navigate('/communications')}
          />
          <StatCard
            title="Conversion Rate"
            stat="24%"
            icon={BsGraphUp}
            helpText="↑ 5% increase"
            onClick={() => navigate('/reports')}
          />
        </SimpleGrid>

        <Box
          p={{ base: 4, md: 6 }}
          bg="gray.800"
          borderRadius="lg"
          border="1px solid"
          borderColor="orange.400"
          mb={{ base: 6, md: 8 }}
          mx={{ base: 4, md: 0 }}
        >
          <Heading size="md" color="white" mb={4}>
            Quick Actions
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 3, md: 4 }}>
            <Button
              leftIcon={<BsPeople />}
              colorScheme="orange"
              variant="outline"
              onClick={() => navigate('/leads/new')}
            >
              Add New Lead
            </Button>
            <Button
              leftIcon={<BsTelephone />}
              colorScheme="orange"
              variant="outline"
              onClick={() => navigate('/call-script')}
            >
              Start Call Script
            </Button>
            <Button
              leftIcon={<BsGraphUp />}
              colorScheme="orange"
              variant="outline"
              onClick={() => navigate('/reports')}
            >
              View Reports
            </Button>
          </SimpleGrid>
        </Box>

        <SimpleGrid 
          columns={{ base: 1, md: 2, lg: 3 }} 
          spacing={{ base: 6, md: 10 }}
          mt={{ base: 12, md: 20 }}
          px={{ base: 4, md: 0 }}
        >
          <Feature
            icon={BsTelephone}
            title="Smart Calling System"
            text="Advanced Twilio integration with dynamic call scripts and AI-powered call analysis."
          />
          <Feature
            icon={FaRobot}
            title="AI-Powered Analytics"
            text="Real-time analytics and insights to optimize your marketing strategies."
          />
          <Feature
            icon={FaChartLine}
            title="Lead Generation Tools"
            text="Advanced tools to generate more leads and improve conversion rates."
          />
        </SimpleGrid>

        <VStack spacing={6} mt={20} textAlign="center">
          <Heading color="white" size="lg">
            Ready to Revolutionize Your Real Estate Business?
          </Heading>
          <Button
            leftIcon={<BsPeople />}
            colorScheme="orange"
            variant="outline"
            onClick={() => navigate('/leads/new')}
          >
            Get Started
          </Button>
        </VStack>

        <Text color="gray.400" textAlign="center" fontSize="sm">
          Last updated: {new Date().toLocaleDateString()}
        </Text>
      </Container>
    </Box>
  );
}

export default NewHomePage; 