// Import necessary icons at the top of the file
import { FaPhone, FaUserCircle, FaUserTie, FaBalanceScale, FaShieldAlt, FaHeart, FaClock, FaMicrophone, FaRunning, FaBullhorn, FaChartLine } from 'react-icons/fa';

const websiteSignupVariants = {
  option1: {
    id: 'thank_you_visit',
    script: "I'm just calling to THANK YOU for visiting our brokerage website! How's your day going so far?",
    description: "Friendly thank you call with day check-in"
  },
  option2: {
    id: 'found_what_looking_for',
    script: "I noticed you recently signed up on our website to explore properties in the [Specific Area] area. I wanted to make sure you've found what you were looking for.",
    description: "Direct follow-up checking on search success"
  },
  option3: {
    id: 'market_assistance',
    script: "I noticed you recently signed up on our website to explore properties in the [Specific Area] area. I just wanted to reach out and see if there's anything I can assist you with or if you have any questions about the current market.",
    description: "Market-focused assistance offer"
  },
  option4: {
    id: 'search_progress',
    script: "First, I wanted to thank you for signing up on our site. I'm reaching out to see how your property search is going and if there's any information I can provide to help you find exactly what you're looking for.",
    description: "Progress check with information offer"
  },
  option5: {
    id: 'market_update_offer',
    script: "Thanks for signing up on our site! Given the recent changes in the real estate market, I wanted to offer you a complimentary market update for the areas you're interested in. Would that be helpful for you?",
    description: "Complimentary market update offer"
  },
  option6: {
    id: 'custom_property_list',
    script: "I saw that you signed up on our website. To make your search easier, I'd be happy to create a personalized list of properties that match your preferences. Could you tell me more about what you're looking for?",
    description: "Personalized property list with preference inquiry"
  },
  option7: {
    id: 'community_welcome',
    script: "I hope you're doing well. I noticed you recently joined our community. I'm here to help if you need any assistance with your real estate needs, no matter how big or small. Feel free to reach out anytime!",
    description: "Welcoming community approach with open assistance"
  },
  option8: {
    id: 'motivation_inquiry',
    script: "I was curious—what prompted you to sign up on our site? Are you starting to look for a new home, or just exploring the market?",
    description: "Direct motivation and intent inquiry"
  }
};

const greetingVariants = {
  option1: {
    id: 'formal_greeting',
    script: "Hi, May I speak with [Prospect Name]?",
    description: "Formal greeting with request to speak",
    name: "Formal Greeting"
  },
  option2: {
    id: 'casual_greeting',
    script: "Hi, [Prospect Name]?",
    description: "Casual greeting with name confirmation",
    name: "Casual Greeting"
  }
};

const introductionVariants = {
  option1: {
    id: 'formal_intro',
    script: "This is Sasha, one of the account managers at Condos.ca.",
    description: "Professional introduction with role specification",
    name: "Formal Introduction",
    note: "Establish who you are. Keep the tone casual and friendly."
  },
  option2: {
    id: 'casual_intro',
    script: "Yeah, it's just Sasha from Condos.ca.",
    description: "Casual and friendly introduction",
    name: "Casual Introduction",
    note: "Establish who you are. Keep the tone casual and friendly."
  }
};

// Add new response objects
const websiteSignupInitialResponses = [
  { value: 'youre_welcome', label: "You're welcome", response: "I'm happy to connect with you today!" },
  { value: 'hi', label: "Hi", response: "Great to hear! I'm glad you could take a moment to chat." },
  { value: 'who_is_this', label: "Who is this again?", response: "It's [Your Name] from Condos.ca. I wanted to personally thank you for checking out our site." },
  { value: 'good_thanks', label: "Good, thanks.", response: "Glad to hear that! I know your time is valuable, so I'll be brief." },
  { value: 'not_bad_how_about_you', label: "Not bad, how about you?", response: "Doing great, thanks for asking! I appreciate you taking a moment to speak with me." },
  { value: 'busy_but_okay', label: "Busy, but okay.", response: "I completely understand. I promise to keep this short and valuable for you." }
];

const valuePropositionResponses = [
  { value: 'sure', label: "Sure.", response: "Fantastic! Let's dive right in." },
  { value: 'couple_minutes', label: "I have a couple of minutes.", response: "Perfect, I'll make sure to keep it concise." },
  { value: 'bit_busy', label: "Actually, I'm a bit busy right now.", response: "No problem at all. When would be a better time for a quick chat?" },
  { value: 'other', label: "Other", response: "Fantastic! Let's dive right in." }
];

const websiteUsageResponses = [
  { value: 'just_browsing', label: "I'm just browsing." },
  { value: 'looking_for_condo', label: "I'm looking for a condo in downtown." },
  { value: 'interested_in_investment', label: "I'm interested in investment properties." },
  { value: 'not_sure', label: "I'm not sure yet." }
];

const callFlow = [
    {
      id: 'start',
      title: 'Start Page',
      stages: [
        {
          id: 'start_page',
          title: 'Start Page',
          mindsetReminders: [
            {
              title: "Call Objective",
              points: [
                "Have a conversation to determine if there's a possibility of a sale",
                "You're not calling to sell anything on the first call",
                "Focus on discovering if you can help, not on booking an appointment",
                "Remember, every dollar in this business starts with a phone call"
              ],
              color: "pink",
              icon: FaPhone
            },
            {
              title: "Confidence in Your Value",
              points: [
                "You're better than 95% of other realtors out there",
                "You're saving them from potentially working with less qualified agents",
                "Your expertise can genuinely help them make the right decision",
                "You're not bothering them; you're offering valuable assistance"
              ],
              color: "teal",
              icon: FaShieldAlt
            },
            {
              title: "Consultant Mentality",
              points: [
                "You're a consultant/advisor, not a pushy salesperson",
                "Your goal is to add value and help them make the right decision",
                "Stop selling, start consulting"
              ],
              color: "blue",
              icon: FaUserTie
            },
            {
              title: "Empathic Prospect-Centric Approach",
              points: [
                "The call is about them, not you",
                "You're helping with one of the biggest decisions in their life",
                "Be genuinely curious about their situation",
                "The more calls you make, the more people you can help"
              ],
              color: "purple",
              icon: FaUserCircle
            },
            {
              title: "Authenticity",
              points: [
                "Be yourself, not a scripted robot",
                "Your genuine interest in helping will come through",
                "People appreciate honesty and authenticity"
              ],
              color: "yellow",
              icon: FaHeart
            },
            {
              title: "Assertive Energy and Tone",
              points: [
                "Maintain high-energy throughout the call",
                "Use an assertive and confident tone",
                "Your energy is contagious - keep it positive",
                "Speak with purpose and conviction"
              ],
              color: "red",
              icon: FaBullhorn
            },
            {
              title: "Mind Your Annunciation",
              points: [
                "Speak clearly and enunciate each word",
                "Avoid mumbling or speaking too quickly",
                "Pay attention to your pronunciation, especially with technical terms",
                "Adjust your speaking pace to match the prospect's comprehension"
              ],
              color: "orange",
              icon: FaMicrophone
            },
            {
              title: "Stand Up",
              points: [
                "Physically standing boosts your energy levels",
                "Standing improves your vocal projection",
                "Movement helps you sound more enthusiastic",
                "Better posture leads to better confidence"
              ],
              color: "orange",
              icon: FaRunning
            },
            {
              title: "Be Patient",
              points: [
                "Allow the prospect time to think and respond fully to your questions",
                "Don't rush the conversation or interrupt their thought process",
                "Silence can be powerful; it gives prospects space to reflect",
                "Patience demonstrates respect and builds trust"
              ],
              color: "blue",
              icon: FaClock
            },
            {
              title: "Detachment from Outcome",
              points: [
                "Don't pressure yourself to close every call",
                "Success is having a quality conversation, not just booking appointments",
                "Be okay with putting leads on long-term follow-up if they're not ready"
              ],
              color: "cyan",
              icon: FaBalanceScale
            },
            {
              title: "Dial for Money",
              points: [
                "Every call is a potential opportunity",
                "Stay focused on your financial goals",
                "Consistent calling leads to consistent income",
                "Track your metrics for improvement"
              ],
              color: "green",
              icon: FaChartLine
            }
          ]
        }
      ]
    },
    {
      id: 'intro',
      title: 'Introduction',
      stages: [
        {
          id: 'establish_disarm',
          title: 'Establish & Disarm',
          notes: [
            "Do not ask, 'How are you?'",
            "Use proper tone (lost in a city and need help, unattached from the outcome)",
            "Use neutral phrasing so they don't feel like their back is against the wall on a sales call"
          ],
          steps: [
            {
              id: 'greet',
              getScript: (variant = 'option1') => greetingVariants[variant]?.script || greetingVariants.option1.script,
              notes: "Establish it's the right person. Use an inquisitive tone."
            },
            {
              id: 'introduce',
              getScript: (variant = 'option2') => introductionVariants[variant]?.script || introductionVariants.option2.script,
              notes: "Establish who you are. Keep the tone casual and friendly."
            },
            {
              id: 'reason_for_call',
              script: {
                website_signup: websiteSignupVariants,
                Downloaded_Lead_Magnet: "You signed up for a copy of our list of properties under $X. I just wanted to confirm you actually received your copy.",
                open_house: "You had attended one of my open houses at ABC PROPERTY. I'm just following up because I told you I'll send a list of recent sales in the neighborhood. I just wanted to confirm you actually received your copy.",
                older_lead: "You and I connected, honestly I think it was January of last year, at the open house of 123 ABC PROPERTY. I know you are probably busy with [current season] coming up, but if you had 7 seconds for me I just had one quick question?"
              },
              notes: "Reference the previous interaction. Pause after mentioning the specific reason, sound inquisitive. Your tonality should become a bit curious at this point."
            }
          ],
          responseOptions: [
            { value: 'yes', label: 'Yes, received/haven\'t found' },
            { value: 'no', label: 'No, haven\'t received/found something' },
            { value: 'not_reviewed', label: 'Received but haven\'t reviewed' }
          ],
          nextStep: 'provide_value'
        },
        {
          id: 'provide_value',
          title: 'Acknowledge + Add Value',
          context: 'USE A BROAD FACT AS AN ANCHOR TO PROVIDE VALUE',
          responseAcknowledgments: {
            yes: {
              script: "Perfect. Out of the X properties I sent, which one would you say was the closest to something you are looking for?",
              followUp: {
                none: "I totally understand, we only met briefly and it's evident I wasn't able to get a clear idea of what you're looking for."
              }
            },
            no: {
              script: "Not a problem. What I'll do is I'll go ahead and re-send the original list so that you've got a fresh copy. Just before I let you go..."
            },
            not_reviewed: {
              script: "Totally understand. Not a problem. With [INSERT TIMEFRAME] coming up... I imagine you're probably busy."
            },
            still_looking: {
              script: "I see you're still in the market. Out of the properties you've seen so far, which one would you say was the closest to something you are looking for?"
            },
            found_something: {
              script: "Congratulations! Where did you end up buying?"
            }
          },
          valueStatements: {
              too_broad: "**Honestly, the list we sent is quite broad, it includes literally every single listing in Toronto under [$X PRICE]...** I'm not sure if it's for you, but I could send you a list that is more custom for you so that you don't have to go to 20, 30, 40 listings... And you're only seeing the 2-3 that actually work for you... (pause - use curious tone) Would That Help You?",
              too_narrow: "**Honestly, the list we sent is quite narrow. Unfortunately, there's far less inventory than usual in the neighborhood right now.** I'm not sure if it's for you, but what I could do is actually send you a more custom list that expands beyond just this neighborhood. That way, you'd have more options to go through – maybe 10, 20, or even 30 listings. This broader list would give you a better perspective of what's going on in the market right now. (pause - use curious tone) Would that be helpful to You?",
            already_bought: "I'm not sure if it's for you, but I could send you a quick email automatically, anytime a unit gets sold or comes up for sale in your building. So you can see what your unit is approximately worth... (pause - use curious tone) Would That Help You?"
          },
          nextStep: 'golden_turn'
        },
        {
          id: 'golden_turn',
          title: 'The Golden Turn',
          context: 'Start filling the M (motivation) of MAW',
          nextStep: 'current_state_questions'
        }
      ]
    },
    {
      id: 'body',
      title: 'Body',
      stages: [
        {
          id: 'current_state_questions',
          title: 'Current State Questions',
          context: 'BODY OF THE CALL',
          getQuestions: (LeadSource) => {
            if (LeadSource === 'end_user') {
              return [
                  "If you don't mind me asking, can you tell me a little bit about your current living situation?",
                  "How long have you been living there?",
                "I'm curious, what caused you to go with that property at the time over something else?",
                "If you even decided to move, what would you be looking for?"
              ];
            } else if (LeadSource === 'investor') {
              return [
                "Oh, a rental property. That's great. Do you have an existing portfolio now?",
                "I'm curious, what's piqued your interest to invest your money into real estate over other types of investments?",
                "How long have you been thinking about purchasing an investment property?",
                "We talk to a lot of different investors, what is the most important component to you when it comes to your investment property?"
              ];
            }
          },
          neutralPhrases: [
            'I guess I should probably ask…',
            'Just to make sure that I can help you best…',
            'Out of curiosity…',
            'With that being said, let me ask…',
            'Just before I let you go…',
            'Do you mind if I ask…',
            'I\'m guessing you haven\'t got around to it.'
          ],
          tips: [
              "Ask no more than 3-4 questions about their current situation to avoid making them feel interrogated.",
              "Use an open-ended, CURIOS, caring tone to avoid sounding interrogative",
            "The first thing mentioned is often the most important",
            "It's okay if they don't reveal obstacles or pains at this stage",
            "This process starts them thinking about why they engaged with your marketing",
            "Establish a baseline of their current situation",
            "Begin to create awareness of potential gaps between current and desired states"
          ],
          purpose: [
            "Helps understand the prospect's current situation and motivation",
            "Allows for appropriate advising",
              "Provides context for why they engaged with your marketing",
              "Prospects internally acknowledge their situation/pain points",
              "They provide information voluntarily, without feeling pressured",
              "This phase sets up for the 'obstacle inquiries' phase. Future questions will help surface needs, wants, and pain points."
          ],
          benefits: [
            "Prospects internally acknowledge their situation/pain points",
            "They provide information voluntarily, without feeling pressured"
          ],
          toneAndDelivery: "Use a curious, caring tone. Avoid sounding condescending or interrogative."
        },
        {
          id: 'obstacle_inquiries',
          title: 'Obstacle Inquiries',
          content: 'Identify potential obstacles in the lead\'s search.',
          introduction: "RESTATE THEIR CURRENT SITUATION TO ACKNOWLEDGE YOU ARE ACTIVELY LISTENING",
          doubtCausingQuestions: [
            "Do you (pause…curious tone) like the home that you're living in now?",
              "Do you like how things are going with your current rental and landlord?"
            ],
            openingPositiveQuestions: [
              "I'm curious, what do you like about the property you're in now?",
              "Why do you like....",
              "Is there anything else about the property that you really like? Tell me more about that"
            ],
            transitionToNegatives: [
              "In a perfect world, what would you change about your current property?",
              "If we could do one thing, what ideally would you change about that property?"
            ],
            exploringPotentialChanges: [
              "Why don't you like...?",
              "Is there anything else you don't like about...?"
          ],
          twoTruthsQuestion: {
            setup: "If they say they love their situation and they aren't looking to make a change.",
            question: "(Skeptically) It sounds like things are going fairly well for you. Is there anything you would change, if you could?",
            followUp: "Why would you change that?"
          },
            wrappingUpAndTransition: "Based on what you've shared, it sounds like [summarize key points]. Is there anything else you'd like to add about your current situation or what you're looking for?",
          keyTakeaways: [
              "Start with positivity to reduce sales resistance and stand out from other salespeople",
              "Use the two-lane approach to structure your conversation",
              "Build rapport throughout the process - make it feel like a conversation, not a sales pitch",
              "Use non-committal language when transitioning to negatives or exploring changes",
              "Always follow up with probing questions to get more details",
              "Help prospects uncover subconscious issues they might not be aware of",
              "Be thorough - explore both likes and dislikes to get a complete picture",
              "Listen actively and adjust your approach based on the prospect's responses",
              "Be patient - give prospects time to think and respond fully",
              "Avoid immediate problem-solving. Focus on uncovering issues rather than jumping to solutions at this stage",
              "Look for signs that prospects are selling themselves on the need for change",
              "Listen for responses like I hadn't actually thought about that before",
              "No one likes 100% of what they have - always probe for potential changes",
              "Uncovering hidden objections now can prevent issues later in the sales process",
              "Remember that every prospect is unique - tailor your questions to their situation",
              "When restating their situation, focus on key points to show active listening",
              "Use a curious, caring tone to maintain a non-threatening atmosphere",
              "Allow prospects to discover potential issues on their own through gentle guidance",
              "Keep the conversation feeling natural and avoid a rigid, scripted approach",
              "Use pauses and curious tones to encourage deeper reflection from the prospect",
              "Prepare for the way out acknowledgement phase that follows"
          ],
          tips: [
            "Use phrases like 'in a perfect world' or 'if you could change one thing' when asking about changes.",
            "Make the conversation feel more like a natural discussion than a sales pitch.",
            "Pay attention to both what prospects like and dislike about their current situation.",
            "Be prepared to transition the conversation towards potential solutions.",
            "Remember that this phase helps clarify what prospects want to change about their current situation."
          ],
          nextStep: 'ideal_criteria'
        },
        {
          id: 'way_out_acknowledgement',
          title: 'The "Way Out" Acknowledgement',
          content: 'Help prospects understand there is a solution to their current situation.',
          nextStep: 'qualification_questions'
        },
        {
          id: 'qualification_questions',
          title: 'Qualification Questions',
          content: 'Ask final qualification questions.',
          questions: [
            'Have you been pre-approved for a mortgage?',
            'Are you working with any other real estate agents?',
            'What\'s your timeline for making a purchase?'
          ],
          nextStep: 'close'
        },
      ]
    },
    {
      id: 'close',
      title: 'Close',
      stages: [
        {
          id: 'close_option_a',
          title: 'Close Option A - Strategic Followup',
          content: 'Plan for a strategic follow-up if the lead is not ready for an immediate appointment.',
          script: "Based on our conversation, I'd like to send you some personalized property recommendations. Would it be okay if I follow up with you next week to get your thoughts on those properties?"
        },
        {
          id: 'close_option_b',
          title: 'Close Option B - Direct Appointment',
          content: 'Attempt to schedule a direct appointment if the lead shows high interest.',
          script: "It sounds like you have a clear idea of what you're looking for. I have a few properties in mind that I think would be perfect for you. Would you be interested in viewing them this weekend?"
        },
        {
          id: 'objection_handling',
          title: 'Objection Handling',
          content: 'Address and overcome objections.',
          tips: ['Listen carefully', 'Empathize with concerns', 'Provide solutions'],
          commonObjections: [
            {
              objection: "I'm not ready to buy yet.",
              response: "I understand. Many of our clients start the process early to get a feel for the market. Would you be open to viewing some properties casually, just to get an idea of what's available?"
            },
            {
              objection: "I'm working with another agent.",
              response: "I respect that. May I ask if you've signed an exclusive agreement with them? If not, I'd be happy to show you how our services differ and potentially offer you more options."
            }
          ],
          nextStep: 'funneling_questions'
        },
        {
          id: 'funneling_questions',
          title: 'Funneling Appointment Questions',
          content: 'Ask funneling questions to narrow down preferences.',
          questions: [
            'What specific type of property are you most interested in?',
            'How important is [feature] to you on a scale of 1-10?',
            'Would you prefer [option A] or [option B]?'
          ],
          nextStep: 'end_call'
        }
      ]
    }
    ];
    
// Update exports at the end of file
export { 
  websiteSignupVariants, 
  greetingVariants, 
  introductionVariants, 
  websiteSignupInitialResponses, 
  valuePropositionResponses, 
  websiteUsageResponses 
};
export default callFlow;