import React, { useState, useEffect, forwardRef, memo, useRef, useMemo } from 'react';
import { Box, Text, VStack, Heading, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Spinner, InputGroup, Input, InputRightElement } from '@chakra-ui/react';
import PropTypes from 'prop-types'; // Add PropTypes

// Updated MessageBubble component with enhanced styling
const MessageBubble = memo(({ message, isOutgoing, onClick }) => (
  <Box 
    onClick={onClick}
    cursor="pointer"
    _hover={{ opacity: 0.9 }}
    alignSelf={isOutgoing ? 'flex-end' : 'flex-start'}
    bg={isOutgoing ? 'linear-gradient(45deg, #6b46c1, #805ad5)' : 'linear-gradient(45deg, #2d3748, #4a5568)'}
    color="white"
    borderRadius="2xl"
    px={4}
    py={3}
    my={2}
    maxWidth="75%"
    boxShadow="md"
    transition="all 0.3s ease"
  >
    <Text fontSize="md" fontWeight="medium">{message.body}</Text>
    <Text fontSize="xs" color="gray.200" textAlign="right" mt={1}>
      {new Date(message.dateCreated || message.timestamp).toLocaleString()}
    </Text>
    
    {/* Future Improvement: Add avatar or user icon */}
    {/* Future Improvement: Implement message status indicators (e.g., sent, delivered, read) */}
  </Box>
));

// Forwarding ref to allow parent component to control scroll
const MessageThread = forwardRef(({ 
  messages, 
  selectedLead, 
  onLoadMore, 
  hasMore, 
  isLoading,
}, ref) => {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const messagesEndRef = useRef(null);

  // Sort messages in reverse chronological order (newest at bottom)
  const sortedMessages = useMemo(() => {
    return [...messages].sort((a, b) => {
      const dateA = new Date(a.dateCreated || a.timestamp);
      const dateB = new Date(b.dateCreated || b.timestamp);
      return dateA - dateB; // Ascending order (older to newer)
    });
  }, [messages]);

  // Scroll to bottom when new messages arrive
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [sortedMessages]);

  const handleScroll = (event) => {
    const { scrollTop } = event.target;
    // Check if the user has scrolled to the top
    if (scrollTop === 0 && hasMore && !isLoading) {
      onLoadMore();
    }
  };

  return (
    <Box 
      height="100%" 
      display="flex" 
      flexDirection="column"
      overflow="hidden"
    >
      {/* Messages Container */}
      <Box 
        flex="1" 
        overflowY="auto" 
        px={4} 
        py={2}
        display="flex"
        flexDirection="column"
        height="100%"
        onScroll={handleScroll} // Add scroll event handler
      >
        {/* Load More Button */}
        {hasMore && (
          <Button
            onClick={onLoadMore}
            isLoading={isLoading}
            mb={4}
            size="sm"
            variant="outline"
          >
            Load Previous Messages
          </Button>
        )}

        {/* Messages */}
        {sortedMessages.map((message) => (
          <MessageBubble
            key={message.id || message.twilioMessageSid}
            message={message}
            isOutgoing={message.direction === 'outgoing'}
            onClick={() => setSelectedMessage(message)}
          />
        ))}
        
        {/* Invisible div for scroll reference */}
        <div ref={messagesEndRef} />
      </Box>

      {/* Message Details Modal */}
      <Modal isOpen={!!selectedMessage} onClose={() => setSelectedMessage(null)} isCentered>
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white">
          <ModalHeader>Message Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="stretch" spacing={3}>
              <Text>
                <strong>Direction:</strong> {selectedMessage?.direction || 'N/A'}
              </Text>
              <Text>
                <strong>From:</strong> {selectedMessage?.from || 'N/A'}
              </Text>
              <Text>
                <strong>To:</strong> {selectedMessage?.to || 'N/A'}
              </Text>
              <Text>
                <strong>Message:</strong> {selectedMessage?.message || selectedMessage?.body || 'N/A'}
              </Text>
              <Text>
                <strong>Date:</strong> {selectedMessage && 
                  new Date(selectedMessage.dateCreated || selectedMessage.timestamp).toLocaleString()}
              </Text>
              {selectedMessage?.lead && (
                <Box mt={4} p={2} bg="gray.700" borderRadius="md">
                  <Heading size="sm" mb={2}>Associated Lead</Heading>
                  <Text><strong>Name:</strong> {selectedMessage.lead.name || 'N/A'}</Text>
                  <Text><strong>Email:</strong> {selectedMessage.lead.email || 'N/A'}</Text>
                </Box>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setSelectedMessage(null)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
});

// Add PropTypes validation
MessageThread.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    twilioMessageSid: PropTypes.string,
    body: PropTypes.string,
    direction: PropTypes.string,
    dateCreated: PropTypes.string,
    timestamp: PropTypes.string,
  })).isRequired,
  selectedLead: PropTypes.object,
  onLoadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default MessageThread;
