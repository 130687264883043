export const enhancedPlans = [
  {
    name: 'Growth',
    price: '97',
    isPopular: false,
    features: [
      'ScriptSense™ Basic',
      'LeadPulse™ Scoring',
      'OmniConnect™ Essentials',
      'Basic AI Insights',
      '100 AI-Powered Calls/month',
      'Email Support'
    ]
  },
  {
    name: 'Pro',
    price: '197',
    isPopular: true,
    features: [
      'ScriptSense™ Advanced',
      'LeadPulse™ Premium',
      'OmniConnect™ Pro Suite',
      'Advanced AI Insights',
      'Unlimited AI-Powered Calls',
      'Priority Support',
      'Strategy Consulting'
    ]
  },
  {
    name: 'Enterprise',
    price: '497',
    isPopular: false,
    features: [
      'Custom ScriptSense™ Training',
      'Team LeadPulse™ Dashboard',
      'OmniConnect™ Enterprise',
      'Custom AI Model Training',
      'Dedicated Account Manager',
      'Team Analytics',
      'API Access'
    ]
  }
]; 