import React from 'react';
import {
  Box,
  Button,
  Container,
  Text,
  Stack,
  SimpleGrid,
  Icon,
  IconButton,
  Flex,
  Divider
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaInstagram
} from 'react-icons/fa';

const Footer = () => {
  return (
    <Box 
      borderTop="1px solid" 
      borderColor="orange.400" 
      py={12}
      bg="linear-gradient(180deg, rgba(0,0,0,0.8), rgba(0,0,0,1))"
      position="relative"
      bottom={0}
      width="100vw"
      mt="auto"
      marginLeft="calc(-50vw + 50%)"
      marginRight="calc(-50vw + 50%)"
    >
      <Container maxW="container.xl">
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={8} mb={8}>
          {/* Company Info */}
          <Stack spacing={4}>
            <Text
              fontSize="xl"
              fontWeight="bold"
              bgGradient="linear(to-r, orange.400, orange.600)"
              bgClip="text"
            >
              💰 Real Talk Pro
            </Text>
            <Text color="gray.400" fontSize="sm">
              Empowering real estate agents with AI-powered solutions for better client relationships and increased sales.
            </Text>
          </Stack>

          {/* Quick Links */}
          <Stack spacing={4}>
            <Text fontWeight="bold" color="yellow.400">Quick Links</Text>
            <Button variant="link" color="gray.400" size="sm">Features</Button>
            <Button variant="link" color="gray.400" size="sm">Pricing</Button>
            <Button variant="link" color="gray.400" size="sm">FAQ</Button>
          </Stack>

          {/* Contact */}
          <Stack spacing={4}>
            <Text fontWeight="bold" color="yellow.400">Contact</Text>
            <Text color="gray.400" fontSize="sm">Email: support@realtalkpro.com</Text>
            <Text color="gray.400" fontSize="sm">Phone: (555) 123-4567</Text>
            <Text color="gray.400" fontSize="sm">Toronto, ON, Canada</Text>
          </Stack>

          {/* Social */}
          <Stack spacing={4}>
            <Text fontWeight="bold" color="yellow.400">Follow Us</Text>
            <Flex gap={4}>
              <IconButton
                aria-label="Facebook"
                icon={<FaFacebook />}
                variant="ghost"
                colorScheme="yellow"
                size="sm"
              />
              <IconButton
                aria-label="Twitter"
                icon={<FaTwitter />}
                variant="ghost"
                colorScheme="yellow"
                size="sm"
              />
              <IconButton
                aria-label="LinkedIn"
                icon={<FaLinkedin />}
                variant="ghost"
                colorScheme="yellow"
                size="sm"
              />
              <IconButton
                aria-label="Instagram"
                icon={<FaInstagram />}
                variant="ghost"
                colorScheme="yellow"
                size="sm"
              />
            </Flex>
          </Stack>
        </SimpleGrid>

        <Divider borderColor="gray.700" my={8} />

        <Flex 
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          align="center"
          color="gray.500"
          fontSize="sm"
        >
          <Text>© {new Date().getFullYear()} RealTalkPro. All rights reserved.</Text>
          <Flex gap={4} mt={{ base: 4, md: 0 }}>
            <Button variant="link" size="sm" color="gray.500">Privacy Policy</Button>
            <Button variant="link" size="sm" color="gray.500">Terms of Service</Button>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer; 