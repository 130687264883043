import React from 'react';
import {
  Box,
  VStack,
  Heading,
  Switch,
  FormControl,
  FormLabel,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTheme } from '../context/ThemeContext';

const CustomizationPage = () => {
  const { isDark, toggleTheme, showBorders, toggleBorders } = useTheme();
  
  return (
    <Box
      p={8}
      pt={{ base: 6, md: 20 }}
      maxW="1200px"
      mx="auto"
    >
      <VStack spacing={8} align="stretch">
        <Heading
          mb={6}
          fontSize="2xl"
          textAlign="center"
          className="gradient-text"
        >
          Customize Your Experience
        </Heading>

        <Box
          p={6}
          borderRadius="lg"
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow="xl"
        >
          <VStack spacing={6} align="stretch">
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="theme-toggle" mb={0}>
                Dark Mode
              </FormLabel>
              <Switch
                id="theme-toggle"
                isChecked={isDark}
                onChange={toggleTheme}
                colorScheme="yellow"
              />
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="border-toggle" mb={0}>
                Show Borders
              </FormLabel>
              <Switch
                id="border-toggle"
                isChecked={showBorders}
                onChange={toggleBorders}
                colorScheme="yellow"
              />
            </FormControl>
          </VStack>
        </Box>

        <Box>
          <Text fontSize="sm" color="gray.500" textAlign="center">
            Changes are applied immediately and saved to your preferences
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};

export default CustomizationPage; 